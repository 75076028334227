import axios from "axios";
import { getCookie } from "../helpers/cookie";
import { getAPIURL } from "./utils";

const url = getAPIURL();

export const getAllCitiesName = async () => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${url}/api/cities/allCities`, config);

  return response;
};

export const saveNewCity = async (cityName) => {
  const token = getCookie();
  const data = {
    name: cityName,
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  let response;
  try {
    response = await axios.post(`${url}/api/cities`, data, config);
  } catch (e) {
    response = e;
  }

  return response;
};
export const checkIfCityExists = async (cityName) => {
  const token = getCookie();
  const data = {
    name: cityName,
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  let response;
  try {
    response = await axios.post(
      `${url}/api/cities/checkcityexist`,
      data,
      config
    );
  } catch (e) {
    response = e;
  }

  return response;
};

export const checkIfCitiesExists = async (CitesName, status) => {
  const token = getCookie();
  const data = {
    cities: CitesName,
    filterStatus: status,
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  let response;
  try {
    response = await axios.post(
      `${url}/api/cities/checkCitiesexist`,
      data,
      config
    );
  } catch (e) {
    response = e;
  }

  return response;
};

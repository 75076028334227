import { Link } from 'react-router-dom';
import { CardBody, Breadcrumb } from 'reactstrap';
import { Cols } from '../../components/SeparatorStyle/SeparatorStyle';
import { motion } from 'framer-motion';
import {
  leftAnimate,
  animationTopContainer,
  rightAnimate,
} from '../FramerMotion/FramerMotion';

const MyCouriers = () => {
  return (
    <main>
      <motion.div
        className="row"
        variants={leftAnimate}
        initial="hidden"
        animate="visible"
      >
        <Cols xxs="12">
          <h1 className="mb-5 pb-0">Courier Managment</h1>
          <Breadcrumb className="pt-0 mb-5 breadcrumb-container d-none d-sm-block d-lg-inline-block">
            <ol className="breadcrumb pt-0">
              <li className="breadcrumb-item">
                <Link to="/admin/myCouriers" style={{ color: 'black' }}>
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Courier Managment
              </li>
            </ol>
          </Breadcrumb>
        </Cols>
      </motion.div>
      {
        // (!allOrdersLoading) && (!pendingOrdersLoading) && (!fulfilledOrdersLoading) && (!refundedOrdersLoading) ?
        <motion.div
          className="row"
          variants={animationTopContainer}
          initial="hidden"
          animate="visible"
        >
          <Cols xxs="12" className="row icon-cards-row mb-5 mt-3">
            <Cols xxs="6" sm="4" md="4" className="mb-4">
              <motion.div
                className="card"
                variants={rightAnimate}
                whileHover={{ scale: 1.1 }}
              >
                <Link to="/admin/courierlistApp">
                  <CardBody className="text-center">
                    <i className="iconsminds-shopping-bag ml-2 " />
                    <i
                      className="iconsminds-arrow-right mt-3 "
                      style={{ float: 'right' }}
                    ></i>
                    <p className="card-text font-weight-semibold mb-0">
                      Change Courier
                    </p>
                  </CardBody>
                </Link>
              </motion.div>
            </Cols>
            <Cols xxs="6" sm="4" md="4" className="mb-4">
              <motion.div
                className="card"
                variants={rightAnimate}
                whileHover={{ scale: 1.1 }}
              >
                <Link to="/admin/citiesCouriersApp">
                  <CardBody className="text-center">
                    <i className="iconsminds-chrysler-building ml-2 " />
                    <i
                      className="iconsminds-arrow-right mt-3 "
                      style={{ float: 'right' }}
                    ></i>
                    <p className="card-text font-weight-semibold mb-0">
                      Change City
                    </p>
                  </CardBody>
                </Link>
              </motion.div>
            </Cols>
            <Cols xxs="6" sm="4" md="4" className="mb-4">
              <motion.div
                className="card"
                variants={rightAnimate}
                whileHover={{ scale: 1.1 }}
              >
                <Link to="/admin/CourierSelectionListApp">
                  <CardBody className="text-center ">
                    <i className="simple-icon-key ml-2" />
                    <i
                      className="iconsminds-arrow-right mt-3"
                      style={{ float: 'right' }}
                    ></i>
                    <p className="card-text font-weight-semibold mb-0">
                      Change Keys
                    </p>
                  </CardBody>
                </Link>
              </motion.div>
            </Cols>
          </Cols>
        </motion.div>
        // : <div className="loading" />
      }
    </main>
  );
};

export default MyCouriers;

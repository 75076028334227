const TableSkeleton = ({
  skeletonLength,
  itemsBar,
  tabs,
  fullfilmentTab,
  customers,
  skumapping,
  inventory,
}) => {
  const searchBar = new Array(itemsBar).fill(0).map((item, i) => {
    return (
      <p
        className="skeleton skeleton-loading search-skeleton-box"
        key={i + 1}
      ></p>
    );
  });

  const tableSkeleton = new Array(skeletonLength).fill(0).map((itm, i) => {
    return (
      <p
        className="skeleton skeleton-loading table-skeleton-text"
        key={i + 1}
      ></p>
    );
  });

  return (
    <>
      <div
        style={{
          marginTop: customers || skumapping || inventory ? "0px" : "36px",
        }}
      >
        {itemsBar && itemsBar ? (
          <div
            className={
              searchBar.length === 1
                ? ""
                : searchBar.length <= 3
                ? "d-flex justify-content-between"
                : ""
            }
          >
            {searchBar}
          </div>
        ) : (
          ""
        )}
        <div className={itemsBar && itemsBar.length > 2 ? "mt-5" : "mt-3"}>
          {tableSkeleton}
          <div className="text-center mx-4 d-flex justify-content-between align-items-center ">
            <div className="skeleton skeleton-text w-10"></div>
            <div className="d-flex align-items-center">
              <p className="skeleton pagination-skeleton me-2"></p>
              <div className="d-flex gap-1">
                <p className="skeleton pagination-skeleton-number"></p>
                <p className="skeleton pagination-skeleton-number"></p>
                <p className="skeleton pagination-skeleton-number"></p>
              </div>
              <p className="skeleton pagination-skeleton ms-2"></p>
            </div>
            <div className="skeleton skeleton-text w-10"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableSkeleton;

import { toast } from "react-toastify";

const { PDFDocument } = require("pdf-lib");
const { getLocalStorage } = require("./localStorage");
const { getCookie } = require("./cookie");

export const chunkArray = async (arr, size) => {
  const chunkedArray = [];
  for (let i = 0; i < arr.length; i += size) {
    chunkedArray.push(arr.slice(i, i + size));
  }
  return [...chunkedArray];
};
export const mergePDFs = async (base64PDFs) => {
  try {
    const mergedPDF = await PDFDocument.create();

    for (let i = 0; i < base64PDFs.length; i++) {
      const base64PDF = base64PDFs[i];
      if (
        base64PDF !== "eyJzdGF0dXMiOjEsIm1lc3NhZ2UiOiJBbHJlYWR5IFJlY2VpdmVkIn0="
      ) {
        const pdfBytes = Uint8Array.from(atob(base64PDF), (c) =>
          c.charCodeAt(0)
        );

        const pdfDoc = await PDFDocument.load(pdfBytes);
        const pages = await mergedPDF.copyPages(
          pdfDoc,
          pdfDoc.getPageIndices()
        );

        pages.forEach((page) => {
          mergedPDF.addPage(page);
        });
      } else {
        toast.info("The tracking has been completed.");
      }
    }
    // Serialize the merged PDF to base64
    const mergedPDFBase64 = await mergedPDF.saveAsBase64();

    // Use the mergedPDFBase64 as needed (e.g., send it to an API or display it on the page)
    return mergedPDFBase64;
  } catch (error) {
    error.message = `Error while merging PDFs: ${error.message}`;
    console.log(error.message || error);
  }
};

export const handleDownloadPdf = async (
  pdfdownloadString,
  selectedOrdersInBulk
) => {
  let orderNames = [];
  if (getCookie("token") && getLocalStorage("user")) {
    if (pdfdownloadString) {
      const link = document.createElement("a");
      link.setAttribute(
        "href",
        'data:"application/pdf;base64,' + pdfdownloadString
      );

      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0");
      let yyyy = today.getFullYear();

      today = dd + "-" + mm + "-" + yyyy;

      if (selectedOrdersInBulk.length === 1) {
        for (const order of selectedOrdersInBulk) {
          orderNames.push(order.name);
          link.setAttribute(
            "download",
            `${order.order_number}-${order.orderRefNumber.replace(
              `${order.name}-`,
              ""
            )}.pdf`
          );
        }
      } else {
        let storename = selectedOrdersInBulk.map((order) =>
          order.orderRefNumber.replace(`${order.name}-`, "")
        )[0];
        for (const order of selectedOrdersInBulk) {
          orderNames.push(order.name);
          link.setAttribute(
            "download",
            `${today}-${JSON.stringify(storename)}.pdf`
          );
        }
      }
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
  return orderNames;
};

import axios from "axios";
import { getCookie } from "../helpers/cookie";
import { getAPIURL } from "./utils";

const url = getAPIURL();

export const getLatestInvoice = async () => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${url}/api/invoices`, config);
  return response;
};

export const getAllInvoices = async () => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${url}/api/invoices/getAllInvoices`,
    config
  );
  return response;
};

export const getInvoiceById = async (invoiceId) => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${url}/api/invoices/invoiceGetById/${invoiceId}`,
    config
  );
  return response;
};

import React from "react";
import { useState, useCallback, useEffect } from "react";
import { getUserData } from "../../api/auth";
import {
  Row,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Breadcrumb,
} from "reactstrap";
import { Cols } from "../SeparatorStyle/SeparatorStyle";
import { Link } from "react-router-dom";
import { UserLoginDate } from "../../helpers/dateTime";
import { motion } from "framer-motion";
import { leftAnimate } from "../FramerMotion/FramerMotion";
const UserAndPermissions = () => {
  const [username, setUsername] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [loginDate, setLoginDate] = useState("");

  const loadUser = useCallback(async () => {
    try {
      const { data } = await getUserData();
      const { username, image, loginAt } = data.user;
      setUsername(username);
      setProfileImage(image);
      setLoginDate(loginAt);
    } catch (error) {
      console.log("error found when fetch order data", error);
    }
  }, []);
 
  useEffect(() => {
    loadUser();
  }, [loadUser]);

  return (
    <>
      <main>
        <Row
          className="top-text pt-3 mb-5"
          // variants={leftAnimate}
          // initial="hidden"
          // animate="visible"
        >
          <Cols xxs="12">
            <motion.div
              variants={leftAnimate}
              initial="hidden"
              animate="visible"
            >
              <h1>Users and Permissions</h1>
              <div style={{ display: "inline" }}>
                <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
                  <ol className="breadcrumb pt-0">
                    <li className="breadcrumb-item">
                      <Link to="/admin/dashboard" style={{ color: "black" }}>
                        Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      User and Permissions
                    </li>
                  </ol>
                </Breadcrumb>
              </div>
            </motion.div>
          </Cols>
          <h3>
            <b>Permissions </b>{" "}
          </h3>
          <p>Manage what User can see or do in your Store</p>
        </Row>
        <motion.div
          className="row pt-3"
          // variants={leftAnimate}
          // initial="hidden"
          // animate="visible"
        >
          <Cols xxs="12" className="row icon-cards-row mb-2 ps-0">
            <Cols className="mb-4">
              {username && profileImage && loginDate ? (
                <Card className="marginL-md">
                  <CardBody className="m-2">
                    <CardTitle tag="h6">
                      <b>Staff Permissions for Specific Apps and Staff</b>
                    </CardTitle>
                    <CardText>
                      Select which apps and channels your staff members can
                      access, install and manage.
                    </CardText>
                  </CardBody>
                </Card>
              ) : (
                <div className="staff-permissions-skeleton skeleton"></div>
              )}
            </Cols>
          </Cols>
        </motion.div>
        <motion.div
          className="row"
          // variants={leftAnimate}
          // initial="hidden"
          // animate="visible"
        >
          <Cols xxs="12" className="icon-cards-row mb-2 ps-0">
            <Cols className="mb-4">
              <Link to="/admin/account/personalSetting" className="">
                {username && profileImage && loginDate ? (
                  <Card className="marginL-md">
                    <CardBody className="m-2">
                      <CardTitle tag="h5">
                        <b>Store Owner</b>
                      </CardTitle>
                      <div style={{ display: "inline" }}>
                        <img
                          src={profileImage ? profileImage : "demo.png"}
                          alt="pic"
                          style={{ height: "40px", width: "40px" }}
                        ></img>
                      </div>
                      <div style={{ display: "inline", marginLeft: "10px" }}>
                        <b>{username}</b>
                        <CardText className="ml-5">
                          Last Login was {UserLoginDate(loginDate)}
                        </CardText>
                      </div>
                    </CardBody>
                  </Card>
                ) : (
                  <div className="store-owner-skeleton skeleton"></div>
                )}
              </Link>
            </Cols>
          </Cols>
        </motion.div>
      </main>
    </>
  );
};

export default UserAndPermissions;

import { PopoverBody, UncontrolledPopover } from "reactstrap";

export const popOverContent = (target, content) => {
  content = content ? String(content).trim() : "";
  return content.length > 20 ? (
    <>
      {content.slice(0, 20)}
      {" ..."}
      <i className="simple-icon-info pointer mx-1" id={target}>
        <UncontrolledPopover trigger="hover" placement="top" target={target}>
          <PopoverBody className="text-center">{content}</PopoverBody>
        </UncontrolledPopover>
      </i>
    </>
  ) : (
    content || "N/A"
  );
};

export const popOverList = (target, list) => {
  return (
    <>
      <i className="simple-icon-info pointer" id={target}>
        <UncontrolledPopover trigger="hover" placement="top" target={target}>
          <PopoverBody className="text-center">
            <ul className=" list-unstyled">
              {list?.map((obj, i) => (
                <li key={i}>{obj}</li>
              ))}
            </ul>
          </PopoverBody>
        </UncontrolledPopover>
      </i>
    </>
  );
};

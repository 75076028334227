import axios from "axios";
import { getCookie } from "../helpers/cookie";
import { getAPIURL } from "./utils";
import { toast } from "react-toastify";

const url = getAPIURL();

export const getDateRanges = async () => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let response;
  try {
    response = await axios.get(`${url}/api/dateRanges`, config);
  } catch (e) {
    response = e;
    console.log(response);
    toast.error(`failed to fetch date ranges`);
  }

  return response;
};

export const saveNewDateRange = async (list) => {
  const token = getCookie();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  let response;
  try {
    response = await axios.post(
      `${url}/api/dateRanges/save`,
      { ...list },
      config
    );
  } catch (e) {
    response = e;
    console.log(response);
    toast.error(`failed to save date range`);
  }

  return response;
};

import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
// import { leftAnimate } from '../../FramerMotion/FramerMotion';
import { motion } from "framer-motion";

const AllOrdersTab = ({ activeTab, setActiveTab }) => {
  return (
    <motion.div

    // variants={leftAnimate} initial="hidden" animate="visible"
    >
      <Nav
        tabs
        className="separator-tabs marginL-md"
        style={{ cursor: "pointer" }}
      >
        <NavItem>
          <NavLink
            className={`${classnames({
              active: activeTab === "AllOrders",
              "nav-link": true,
            })} ${activeTab === "AllOrders" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeOrderTab", "AllOrders");
              setActiveTab("AllOrders");
            }}
            location={{}}
            to="#"
          >
            All
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${classnames({
              active: activeTab === "Unfulfilled",
              "nav-link": true,
            })} ${activeTab === "Unfulfilled" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeOrderTab", "Unfulfilled");
              setActiveTab("Unfulfilled");
            }}
            location={{}}
            to="#"
          >
            Unfulfilled
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${classnames({
              active: activeTab === "Unpaid",
              "nav-link": true,
            })} ${activeTab === "Unpaid" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeOrderTab", "Unpaid");
              setActiveTab("Unpaid");
            }}
            location={{}}
            to="#"
          >
            UnPaid
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${classnames({
              active: activeTab === "Open",
              "nav-link": true,
            })} ${activeTab === "Open" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeOrderTab", "Open");
              setActiveTab("Open");
            }}
            location={{}}
            to="#"
          >
            Open
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${classnames({
              active: activeTab === "Close",
              "nav-link": true,
            })} ${activeTab === "Close" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeOrderTab", "Close");
              setActiveTab("Close");
            }}
            location={{}}
            to="#"
          >
            Close
          </NavLink>
        </NavItem>
      </Nav>
    </motion.div>
  );
};

export default AllOrdersTab;

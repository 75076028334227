import React from "react";
import ExcelToJson from "../standalone/ExcelToJson.jsx";

const CourierFileUpload = (props) => {
  const {
    setSelectedCourierForFileImport,
    selectedCourierForFileImport,
    setSelectedFileForImport,
    setUploadedFileData,
    couriers,
  } = props;

  const handleCourierChange = (e) => {
    setSelectedCourierForFileImport(e.target.value);
  };
  return (
    <>
      {couriers && couriers.length > 0 ? (
        <div className="container">
          <h6>
            <b>Choose Courier</b>
          </h6>

          <select
            className="custom-form-control file_upload_controls p-1 mb-2"
            onChange={(e) => handleCourierChange(e)}
            value={selectedCourierForFileImport}
          >
            <option value="" disabled>
              Select Courier
            </option>
            {couriers.map(({ _id, label, value }) => (
              <option className="option-styling" key={_id} value={value}>
                {label}
              </option>
            ))}
          </select>
          {selectedCourierForFileImport ? (
            <ExcelToJson
              setSelectedFileForImport={setSelectedFileForImport}
              setUploadedFileData={setUploadedFileData}
            />
          ) : null}
        </div>
      ) : null}
      <hr />
    </>
  );
};

export default CourierFileUpload;

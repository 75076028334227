import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import SpinnerButton from "../common/SpinnerButton.jsx";

export const CustomModal = ({
  size = "sm",
  isOpen,
  toggle,
  body,
  includeFooter,
  title,
  primaryBtnText = "",
  secondaryBtnText = "",
  primaryBtnWithSpinner = false,
  isProcessing = false,
  primaryBtnHandler = null,
  secondaryBtnHandler = null,
  outClickClose = false,
  scrollable = false,
}) => {
  return (
    <Modal
      scrollable={scrollable}
      isOpen={isOpen}
      toggle={outClickClose ? toggle : () => {}}
      size={size}
    >
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <>{body}</>
      </ModalBody>
      {includeFooter ? (
        <ModalFooter>
          <Button
            color="secondary"
            disabled={isProcessing}
            onClick={secondaryBtnHandler}
          >
            {secondaryBtnText}
          </Button>
          {primaryBtnWithSpinner ? (
            <SpinnerButton
              btnText={primaryBtnText}
              handler={primaryBtnHandler}
              isProcessing={isProcessing}
            />
          ) : (
            <Button
              color="primary"
              onClick={primaryBtnHandler}
              disabled={isProcessing}
            >
              {primaryBtnText}
            </Button>
          )}
        </ModalFooter>
      ) : null}
    </Modal>
  );
};

import React from "react";
import { useState } from "react";
import {
  Row,
  Card,
  CardBody,
  Button,
  Label,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import { Cols } from "../SeparatorStyle/SeparatorStyle";

const AddStaff = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [permission, setPermission] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleReset = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
  };

  return (
    <>
      <main>
        <div className="mb-4">
          <h2 style={{ display: "inline" }}>
            <b className="ml-2">Add Staff Account</b>
          </h2>
        </div>

        <Row>
          <Cols xxs="12" className="row icon-cards-row mb-2">
            <Cols xs="12" sm="12" md="4" className="mb-4 mt-3 mr-3">
              <h6>
                <b className="mt-5">Staff Account</b>
              </h6>
              <p className="mr-5">
                Give staff access to your store by sending them an invitation.
                If you're working with a designer, developer or marketer, find
                out how to give collaborator access to your store.
              </p>
            </Cols>
            <Cols className="mb-4 mt-3">
              <Form onSubmit={handleSubmit}>
                <Card>
                  <CardBody className="m-2">
                    <Row>
                      <Cols>
                        <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                          <Label for="firstname" className="mr-2">
                            First Name
                          </Label>
                          <Input
                            id="examplefirstname"
                            name="firstname"
                            type="text"
                            required
                            minLength="3"
                            maxLength="20"
                            value={firstName}
                            onChange={(e) =>
                              setFirstName(
                                e.target.value.replace(/[^a-zA-Z]/gi, "")
                              )
                            }
                          />
                        </FormGroup>
                      </Cols>
                      <Cols>
                        <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                          <Label for="lastname" className="mr-2">
                            Last Name
                          </Label>
                          <Input
                            id="examplelastname"
                            name="lastname"
                            type="text"
                            required
                            minLength="3"
                            maxLength="20"
                            value={lastName}
                            onChange={(e) =>
                              setLastName(
                                e.target.value.replace(/[^a-zA-Z]/gi, "")
                              )
                            }
                          />
                        </FormGroup>
                      </Cols>
                    </Row>
                    <Row>
                      <Cols>
                        <FormGroup className="mb-2 mt-2 me-sm-2 mb-sm-0">
                          <Label for="email">Email</Label>
                          <Input
                            className="mb-2"
                            id="exampleemail"
                            name="email"
                            type="email"
                            required
                            maxLength="320"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormGroup>
                      </Cols>
                    </Row>
                    <hr />
                    <FormGroup check>
                      <Input
                        type="checkbox"
                        checked={permission}
                        onChange={(e) => setPermission(e.target.checked)}
                      />
                      <Label check>
                        This staff account will have full permissions
                      </Label>
                    </FormGroup>
                    <Label>
                      <p className="mt-2">
                        <b>Edit Permissions</b>
                      </p>
                    </Label>
                    <Row>
                      <Cols className="ml-2">
                        <b>Generals</b>
                        <FormGroup check>
                          <Input type="checkbox" />
                          <Label check>Home</Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input type="checkbox" />
                          <Label check>Orders</Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input type="checkbox" />
                          <Label check>Draft Orders</Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input type="checkbox" />
                          <Label check>Products</Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input type="checkbox" />
                          <Label check>Gift Cards</Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input type="checkbox" />
                          <Label check>Customers</Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input type="checkbox" />
                          <Label check>Reports</Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input type="checkbox" />
                          <Label check>Dashboards</Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input type="checkbox" />
                          <Label check>Marketing</Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input type="checkbox" />
                          <Label check>Discounts</Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input type="checkbox" />
                          <Label check>Apps</Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input type="checkbox" />
                          <Label check>Settings</Label>
                        </FormGroup>
                      </Cols>
                      <Cols className="ml-1">
                        <b>Online Stores</b>
                        <FormGroup check>
                          <Input type="checkbox" />
                          <Label check>Themes</Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input type="checkbox" />
                          <Label check>Blog posts and pages</Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input type="checkbox" />
                          <Label check>Navigation</Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input type="checkbox" />
                          <Label check>Domains</Label>
                        </FormGroup>
                        <br /> <br /> <br />
                        <b>Locations</b>
                        <FormGroup check>
                          <Input type="checkbox" />
                          <Label check>Manage Locations</Label>
                        </FormGroup>
                      </Cols>
                    </Row>
                  </CardBody>
                </Card>
                <div>
                  <Button
                    type="submit"
                    className="mt-4 ml-2"
                    style={{ float: "right" }}
                  >
                    Send Invite
                  </Button>
                  <Button
                    onClick={handleReset}
                    className="mt-4"
                    style={{ float: "right" }}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </Cols>
          </Cols>
        </Row>
      </main>
    </>
  );
};

export default AddStaff;

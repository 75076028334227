import React, { useState, useEffect, useCallback, useRef } from "react";
import { Cols } from "../../SeparatorStyle/SeparatorStyle";
import Select from "react-select";
import DataTable from "./OrderTable.jsx";
import {
  Row,
  Spinner,
  Alert,
  Input,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import {
  updateOrderDispatchStatus,
  getOrderByDispatchStatus,
} from "../../../api/orders";
import { sortByDate, DateFormatted } from "../../../helpers/dateTime";
// import {
//   ConsigneeDetails,
//   NotePopup,
//   OrdersDetailsPopup,
// } from "../../Modals/Popups.jsx";
import { formatPhoneNumber } from "../../../helpers/phoneNumber.jsx";
import { saveBillingLogs } from "../../../api/billing";
import DateFilters from "../../Date/DateFilters.jsx";
// import { showItemDetails } from "../orderTabs/helperFunctions/tableDetailsInfo.jsx";
import { toast } from "react-toastify";
import { getCookie } from "../../../helpers/cookie";
import { useHistory } from "react-router-dom";
import { getAllStoresLoginUser } from "../../../api";
import { handleSearch } from "../../search/searchHook";
import { motion } from "framer-motion";
// import { rightAnimate } from "../../FramerMotion/FramerMotion";
import { popOverContent, popOverList } from "../../../popOvers/popOver";
import TableSkeleton from "../../UI/TableSkeleton";
const PendingOrders = ({
  setOrderConfirmed,
  setOrderPending,
  // orderPending,
  setOrderCancelled,
  setOrderManual,
  // setSkeletonTab,
}) => {
  const [search, setSearch] = useState("");
  const [citySearch, setCitySearch] = useState("");
  const [pendingOrders, setPendingOrders] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);
  // const [itemDetailsData, setItemDetailsData] = useState();
  // const [orderDetailsData, setOrderDetailsData] = useState();
  // const [noteDetailsData, setNoteDetailsData] = useState("");
  const [isAllOrdersChecked, setIsAllOrdersChecked] = useState(false);
  const [checkboxes, setCheckboxes] = useState({});
  // const [dateTime, setDateTime] = useState();
  const [selectedOrdersInBulk, setSelectedOrdersInBulk] = useState([]);
  const [areSelectedOrdersProcessing, setAreSelectedOrdersProcessing] =
    useState(false);
  const [count, setCount] = useState(0);
  const [checkCounter, setCheckCounter] = useState(0);
  // const [consigneeDetails, setConsigneeDetails] = useState();
  // const [showResetButton, setShowResetButton] = useState(false);
  const [pageLimit, setPageLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedDispatchStatus, setSelectedDispatchStatus] = useState(null);
  // const [searchNameBool, setSearchNameBool] = useState(false);
  // const [searchCityBool, setSearchCityBool] = useState(false);
  const dispatchStatusDDRef = useRef(null);
  const [emptyStatus, setEmptyStatus] = useState(false);
  const [showStoreColumn, setShowStoreColumn] = useState(false);
  const [filtersLoader, setFiltersLoader] = useState(false);
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [searchCityTrigger, setSearchCityTrigger] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);

  let statusDispatchOptions = [
    { value: "confirm", label: "Confirm" },
    { value: "cancel", label: "Cancel" },
    { value: "manual", label: "Manual" },
  ];
  const history = useHistory();
  const loadOrders = useCallback(
    async ({
      page = pageNumber,
      limit = pageLimit,
      startDateIs = startDate,
      endDateIs = endDate,
      title = search.trim().length > 2 ? search : "",
      searchByCity = citySearch.trim().length > 2 ? citySearch : "",
      filterByCity = "",
    }) => {
      try {
        setEmptyStatus(false);
        const { data } = await getOrderByDispatchStatus({
          dispatchStatus: "pending",
          isAssigned: false,
          title,
          page,
          limit,
          startDateIs,
          endDateIs,
          searchByCity,
          filterByCity,
        });
        if (data) {
          setPendingOrders(data.orders);
          setFiltersLoader(false);
          setLoading(false);
          // setSkeletonTab(false);
          setPaginationLoading(false);
          setCount(data.count);
          setCheckboxes({});
          setCheckCounter(0);
          setSelectedOrdersInBulk([]);
        }
        if (data.count === 0) {
          setEmptyStatus(true);
        } else {
          setEmptyStatus(false);
        }
        return data.orders;
      } catch (error) {
        console.log("error found when fetch order data", error);
      }
    },
    [
      setPendingOrders,
      pageNumber,
      pageLimit,
      endDate,
      searchTrigger,
      searchCityTrigger,
    ]
  );
  const totalPages = Math.ceil(count / pageLimit);

  const loadStores = useCallback(async () => {
    try {
      const { data } = await getAllStoresLoginUser();
      const { stores } = data[0];
      stores.length > 1 && setShowStoreColumn(true);
    } catch (error) {
      toast.error("Error found when fetch stores data!");
      console.log("Error found when fetch stores data!", error);
    }
  }, []);

  useEffect(() => {
    let token = getCookie();
    if (token) {
      setLoading(true);
      // setSkeletonTab(true);
      loadStores();
    } else {
      history.push("/signIn");
      toast.error("Your Session is Expired! Login Again...");
    }
  }, []);

  useEffect(() => {
    loadOrders({});
  }, [loadOrders]);

  // const consigneeDetail = (item, i) => {
  //   let orderItems = item.slice(0, 20);
  //   let itemsLength = item.length;
  //   let hoverIndex;
  //   return (
  //     <div>
  //       {orderItems}
  //       {itemsLength > 20 && (
  //         <>
  //           {" ... "}
  //           <i
  //             className="simple-icon-info pointer"
  //             id={"consigneeDetailPopover" + hoverIndex}
  //             onMouseEnter={() => {
  //               hoverIndex = i;
  //               setConsigneeDetails([item]);
  //             }}
  //             onMouseLeave={() => {
  //               hoverIndex = undefined;
  //               setConsigneeDetails();
  //             }}
  //           />
  //         </>
  //       )}
  //       {consigneeDetails && (
  //         <ConsigneeDetails
  //           consigneeDetails={consigneeDetails}
  //           index={hoverIndex}
  //         />
  //       )}
  //     </div>
  //   );
  // };

  // const orderDetails = (items) => {
  //   let orderItems = items[0].title.slice(0, 12);
  //   let itemsLength = items[0].title.length;
  //   return (
  //     <>
  //       <div>
  //         {`${orderItems}${itemsLength > 12 ? "...." : ""}`}
  //         {itemsLength > 12 ? (
  //           <i
  //             style={{ cursor: "pointer" }}
  //             className="p-2 simple-icon-info "
  //             id="orderDetailPopover"
  //             onMouseEnter={() => {
  //               setOrderDetailsData(items);
  //             }}
  //           />
  //         ) : (
  //           ""
  //         )}

  //         <OrdersDetailsPopup
  //           orderDetailsData={orderDetailsData && orderDetailsData}
  //         />
  //       </div>
  //     </>
  //   );
  // };

  // const noteDetails = (note) => {
  //   let noteText = note.slice(0, 20);
  //   let noteLenght = note.length;
  //   return (
  //     <div>
  //       {`${noteText}${noteLenght > 20 ? "..." : ""}`}

  //       {noteLenght > 20 ? (
  //         <i
  //           style={{ cursor: "pointer" }}
  //           className="p-2 simple-icon-info "
  //           id="noteDetailPopover"
  //           onMouseEnter={() => {
  //             setNoteDetailsData(note);
  //           }}
  //         />
  //       ) : (
  //         ""
  //       )}
  //       <NotePopup noteData={noteDetailsData && noteDetailsData} />
  //     </div>
  //   );
  // };

  const onCheckItem = (event, orderId) => {
    const orderDispatchStatus = dispatchStatusDDRef.current.value;
    setCheckboxes({ ...checkboxes, [orderId]: event.target.checked });
    let checkedOrders = [];
    pendingOrders.forEach((order) => {
      if (order.orderId === orderId) {
        checkedOrders.push({ ...order, dispatchStatus: orderDispatchStatus });
      }
    });
    if (event.target.checked) {
      setSelectedOrdersInBulk([...selectedOrdersInBulk, ...checkedOrders]);
    } else {
      if (selectedOrdersInBulk.length > 0) {
        const removeUnCheckedOrder = selectedOrdersInBulk.filter(
          (order) => order.orderId !== orderId
        );
        setSelectedOrdersInBulk(removeUnCheckedOrder);
      }
    }
    if (event.target.checked && orderId) {
      setCheckCounter(checkCounter + 1);
    } else if (!event.target.checked) {
      setCheckCounter(checkCounter - 1);
    }
  };

  const resetFilters = () => {
    setFiltersLoader(true);
    setStartDate(null);
    setEndDate(null);
  };

  const onCheckAllOrders = (e, ordersToMark) => {
    setIsAllOrdersChecked(e.target.checked);
    const orderIdsToMark = {};
    const ordersCompleteData = [];
    ordersToMark.forEach((order) => {
      orderIdsToMark[order.id] = e.target.checked;
      const matchedOrderInAllOrders = pendingOrders.find(
        (completeOrder) => completeOrder.orderId === order.id
      );
      ordersCompleteData.push({ ...matchedOrderInAllOrders });
    });

    if (e.target.checked) {
      setCheckboxes(orderIdsToMark);
      setSelectedOrdersInBulk(ordersCompleteData);
      setCheckCounter(ordersCompleteData.length);
    } else {
      if (selectedOrdersInBulk.length > 0) {
        setCheckboxes({});
        setSelectedOrdersInBulk([]);
        setCheckCounter(selectedOrdersInBulk.length);
      }
    }
    if (!e.target.checked) {
      return setCheckCounter(0);
    }
  };

  const handleOrderSubmit = async () => {
    if (Object.keys(checkboxes).length > 0) {
      const isActionSelected = selectedOrdersInBulk.some(
        (order) => order.dispatchStatus !== ""
      );
      if (!isActionSelected) {
        toast.info("Please select action to perform on selected orders");
      } else {
        setAreSelectedOrdersProcessing(true);
        try {
          const confirmedOrders = [];
          let haseDispatchStatus = selectedOrdersInBulk.filter(
            (obj) => obj.dispatchStatus
          );

          let orderId = [];
          let dispatchStatus = [];
          let storeId = [];
          let current_total_price = [];

          haseDispatchStatus.map((s) => {
            orderId.push(s.orderId);
            dispatchStatus.push(s.dispatchStatus);
            storeId.push(s.storeId);
            current_total_price.push(s.current_total_price);
            return s;
          });

          let Confirm_Orders_StoreId = [];
          let Confirm_Orders_current_total_price = [];

          haseDispatchStatus
            .filter((s) => s.dispatchStatus === "confirm")
            .map((s) => {
              Confirm_Orders_StoreId.push(s.storeId);
              Confirm_Orders_current_total_price.push(s.current_total_price);
              return s;
            });

          // let Confirm_Orders_orderId = haseDispatchStatus.map((s) => s.orderId);

          if (Confirm_Orders_StoreId.length > 0) {
            await saveBillingLogs(
              Confirm_Orders_StoreId,
              Confirm_Orders_current_total_price
            );
          }
          const orderDispatchStatusUpdateResult =
            await updateOrderDispatchStatus(orderId, dispatchStatus, storeId);

          if (orderDispatchStatusUpdateResult.status === 200) {
            let confirmOrders = [];
            let pendingOrders = [];
            let cancelOrders = [];
            let manualOrders = [];

            haseDispatchStatus.filter((order) => {
              order.dispatchStatus === "confirm" &&
                confirmOrders.push(order.name);
              // toast.success('Order Confirmed Successfully!');

              order.dispatchStatus === "pending" &&
                pendingOrders.push(order.name);
              // toast.success('Order Added To Pending Successfully!');

              order.dispatchStatus === "cancel" &&
                cancelOrders.push(order.name);
              // toast.success('Order Added To Cancelled Successfully!');

              order.dispatchStatus === "manual" &&
                manualOrders.push(order.name);
              // toast.success('Order Added To Manual Successfully!');

              order.dispatchStatus === "confirm" && setOrderConfirmed(true);
              order.dispatchStatus === "pending" && setOrderPending(true);
              order.dispatchStatus === "cancel" && setOrderCancelled(true);
              order.dispatchStatus === "manual" && setOrderManual(true);
              confirmedOrders.push(order.Confirm_Orders_orderId);
              return order;
            });

            confirmOrders.length > 0 &&
              toast.success(
                ` ${String(confirmOrders)} Confirmed Successfully!`
              );
            pendingOrders.length > 0 &&
              toast.success(` ${String(pendingOrders)} Pending Successfully!`);
            cancelOrders.length > 0 &&
              toast.success(` ${String(cancelOrders)} Cancelled Successfully!`);
            manualOrders.length > 0 &&
              toast.success(` ${String(manualOrders)} Manual Successfully!`);
          }

          await loadOrders({});
          setCheckCounter(0);
          setSelectedOrdersInBulk([]);
          setSelectedDispatchStatus(null);
          setAreSelectedOrdersProcessing(false);
        } catch (e) {
          setAreSelectedOrdersProcessing(false);
          toast.error(`${e.message}`);
        }
      }
    } else {
      toast.info("Please select orders!");
    }
  };

  const ordersData =
    pendingOrders &&
    pendingOrders.length > 0 &&
    pendingOrders.map((order, i) => {
      return {
        id: order.orderId,
        orderId: order.name,
        orderDate: DateFormatted(order.created_at, i),
        name: popOverContent(`Name-${i}`, order?.shipping_address?.name),
        address: popOverContent(
          `Adress-${i}`,
          `${order?.shipping_address?.address1 || ""} ${
            order?.shipping_address?.address2 || ""
          }`
        ),
        phone: order?.shipping_address?.phone
          ? formatPhoneNumber(order?.shipping_address?.phone)
          : "N/A",
        note: popOverContent(`Note-${i}`, order.note),

        amount: popOverContent(
          `Total-${i}`,
          Number(order.total_price_set.shop_money.amount).toLocaleString()
        ),
        // referenceNo: order.orderId,
        // details: orderDetails(order.line_items),
        items: (
          <>
            {order?.line_items.length}
            {"  "}
            {order?.line_items.length > 0
              ? popOverList(
                  `lineItem-${i}`,
                  order.line_items.map((o) => `${o.title} (${o.quantity})`)
                )
              : "N/A"}
          </>
        ),
        city: popOverContent(`City-${i}`, order?.shipping_address?.city),
        storeName: popOverContent(
          `Store-${i}`,
          order.shipperDetail[0]?.shopDetail.name.replace(`${order?.name}-`, "")
        ),
        storeId: order.storeId,
        // updated_at: order.updatedAt,
        // created_at: order.createdAt,
        actions: (
          <select
            defaultValue={""}
            key={order.orderId}
            onChange={(e) => handleOrderDispatchStatus(e, order)}
            className={"form-control form-select"}
            ref={dispatchStatusDDRef}
            disabled={checkboxes && checkboxes[order.orderId] ? false : true}
          >
            <option disabled value="">
              Select
            </option>
            <option value="Confirm">Confirm</option>
            <option value="Cancel">Cancel</option>
            <option value="manual">Manual Book</option>
          </select>
        ),
      };
    });

  const cols = React.useMemo(
    () => [
      {
        Header: "Order Id",
        accessor: "orderId",
        cellClass: " w-3 text-start",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Date",
        accessor: "orderDate",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Customer",
        accessor: "name",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Address",
        accessor: "address",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Phone",
        accessor: "phone",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Note",
        accessor: "note",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Total",
        accessor: "amount",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },

      {
        Header: "Items",
        accessor: "items",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Destination City",
        accessor: "city",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },

      {
        Header: "Actions",
        accessor: "actions",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
    ],
    []
  );

  const myCols = React.useMemo(
    () => [
      {
        Header: "Order Id",
        accessor: "orderId",
        cellClass: " w-3 text-start",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Date",
        accessor: "orderDate",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Customer",
        accessor: "name",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Address",
        accessor: "address",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Phone",
        accessor: "phone",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Note",
        accessor: "note",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Total",
        accessor: "amount",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },

      {
        Header: "Items",
        accessor: "items",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Destination City",
        accessor: "city",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Store",
        accessor: "storeName",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Actions",
        accessor: "actions",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
    ],
    []
  );

  const handleOrderDispatchStatus = (e, data) => {
    e.preventDefault();
    const dispatchAction = e.target.value.toLowerCase();
    const ordersWithDispatchStatusUpdated = selectedOrdersInBulk.map(
      (selectedOrder) => {
        if (selectedOrder.orderId === data.orderId) {
          return { ...selectedOrder, dispatchStatus: dispatchAction };
        }
        return selectedOrder;
      }
    );
    setSelectedOrdersInBulk([...ordersWithDispatchStatusUpdated]);
  };

  const handleOrderStatus = (e) => {
    if (e) {
      setSelectedDispatchStatus(e.label);
      let dispatchAction = e.value.toLowerCase();

      const ordersWithDispatchStatusUpdated = selectedOrdersInBulk.map(
        (data) => {
          return { ...data, dispatchStatus: dispatchAction };
        }
      );

      setSelectedOrdersInBulk([...ordersWithDispatchStatusUpdated]);
    } else {
      setSelectedDispatchStatus(null);

      const ordersWithDispatchStatusUpdated = selectedOrdersInBulk.map(
        (data) => {
          return { ...data, dispatchStatus: "" };
        }
      );

      setSelectedOrdersInBulk([...ordersWithDispatchStatusUpdated]);
    }
  };

  return (
    <>
      {loading ? (
        <TableSkeleton skeletonLength={10} itemsBar={3} />
      ) : emptyStatus &&
        !search.trim() &&
        !citySearch.trim() &&
        !startDate &&
        !endDate ? (
        <div
          className=" d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
          style={{
            height: "200px",
          }}
        >
          <span>No Order Found!</span>
        </div>
      ) : (
        <Row>
          <Cols xxs="12">
            <motion.div
              className="mb-4 px-2"
              // variants={rightAnimate}
              // initial="hidden"
              // animate="visible"
            >
              <div className="card-title mb-0">
                <div className="d-flex justify-content-between flex-wrap mt-4 ">
                  <div className="mb-2 me-1 position-relative">
                    <Input
                      type="text"
                      name="keyword"
                      id="search"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => {
                        handleSearch({
                          e,
                          setPageNumber,
                          setPageLimit,
                          setFilterLoading: setFiltersLoader,
                          setSearch,
                          setSearchTrigger,
                          searchTrigger,
                        });
                      }}
                      className="rounded-3"
                      disabled={
                        paginationLoading ||
                        filtersLoader ||
                        selectedOrdersInBulk.length > 0
                      }
                    />
                    {search.trim() && search.trim().length < 3 && (
                      <i
                        className="simple-icon-info pointer position-absolute text-danger fw-bold"
                        style={{ right: "-20px", bottom: "14px" }}
                        id={"SearchInfo"}
                      >
                        {" "}
                        <UncontrolledPopover
                          trigger="hover"
                          placement="top"
                          target={"SearchInfo"}
                        >
                          <PopoverBody className="text-center">
                            <i>Minimum 3 words required!</i>
                          </PopoverBody>
                        </UncontrolledPopover>
                      </i>
                    )}
                    {search &&
                      (filtersLoader && search.trim().length > 2 ? (
                        <Spinner
                          color="primary"
                          size={"sm"}
                          style={{ right: "10px", bottom: "14px" }}
                          className=" position-absolute"
                          type="grow"
                        ></Spinner>
                      ) : (
                        <span
                          className="far fa-close fs-6 position-absolute text-danger pointer"
                          style={{ right: "10px", bottom: "14px" }}
                          onClick={() => {
                            if (search) {
                              setSearch("");

                              setSearchTrigger(!searchTrigger);
                              setFiltersLoader(true);
                            }
                            pageLimit > 50 && setPageLimit(50);
                            pageNumber > 1 && setPageNumber(1);
                          }}
                        ></span>
                      ))}
                  </div>
                  <div>
                    <DateFilters
                      startDate={startDate}
                      setStartDate={setStartDate}
                      endDate={endDate}
                      setEndDate={setEndDate}
                      selectedDateFilter={selectedDateFilter}
                      setSelectedDateFilter={setSelectedDateFilter}
                      // setShowResetButton={setShowResetButton}
                      loadOrders={loadOrders}
                      resetFilters={resetFilters}
                      setFiltersLoader={setFiltersLoader}
                      paginationLoading={paginationLoading}
                      allOrdersFlag={"allOrdersFlag"}
                      selectedOrdersInBulk={selectedOrdersInBulk}
                    />
                  </div>
                  <div className=" d-flex flex-wrap alignTop">
                    <div className="mb-2 mr-2 position-relative confirm-city-search">
                      <Input
                        type="text"
                        name="keyword"
                        id="search"
                        placeholder="Search Cities"
                        value={citySearch}
                        onChange={(e) => {
                          setEmptyStatus(false);
                          handleSearch({
                            e,
                            setPageNumber,
                            setPageLimit,
                            setFilterLoading: setFiltersLoader,
                            setSearch: setCitySearch,
                            setSearchTrigger: setSearchCityTrigger,
                            searchTrigger: searchCityTrigger,
                          });
                        }}
                        className="rounded-3"
                        disabled={
                          paginationLoading ||
                          filtersLoader ||
                          selectedOrdersInBulk.length > 0
                        }
                      />
                      {citySearch &&
                        (filtersLoader && citySearch.trim().length > 2 ? (
                          <Spinner
                            color="primary"
                            size={"sm"}
                            style={{ right: "10px", bottom: "14px" }}
                            className=" position-absolute"
                            type="grow"
                          ></Spinner>
                        ) : (
                          <span
                            className="far fa-close fs-6 position-absolute text-danger pointer"
                            style={{ right: "10px", bottom: "14px" }}
                            onClick={() => {
                              if (citySearch) {
                                setCitySearch("");

                                setSearchCityTrigger(!searchCityTrigger);
                                setFiltersLoader(true);
                              }
                              pageLimit > 50 && setPageLimit(50);
                              pageNumber > 1 && setPageNumber(1);
                            }}
                          ></span>
                        ))}
                    </div>
                    {citySearch.trim() && citySearch.trim().length < 3 && (
                      <div className="d-flex align-items-center pb-2">
                        <i
                          className="simple-icon-info pointer text-danger fw-bold mr-2"
                          // style={{ left: '-20px', bottom: '14px' }}
                          id={"CitySearchInfo"}
                        >
                          {" "}
                          <UncontrolledPopover
                            trigger="hover"
                            placement="top"
                            target={"CitySearchInfo"}
                          >
                            <PopoverBody className="text-center">
                              <i>Minimum 3 words required!</i>
                            </PopoverBody>
                          </UncontrolledPopover>
                        </i>
                      </div>
                    )}

                    <div
                      className="openOrderSelect me-2 mb-2"
                      style={{ width: "145px" }}
                    >
                      <Select
                        className=""
                        placeholder="Actions"
                        value={
                          selectedDispatchStatus
                            ? selectedDispatchStatus.label
                            : ""
                        }
                        options={statusDispatchOptions}
                        isDisabled={
                          selectedOrdersInBulk.length > 1 ? false : true
                        }
                        isClearable
                        onChange={handleOrderStatus}
                      />
                    </div>
                    <button
                      disabled={
                        areSelectedOrdersProcessing ||
                        selectedOrdersInBulk.length > 0
                          ? false
                          : true
                      }
                      onClick={handleOrderSubmit}
                      type="button"
                      className="btn btn-primary custom-btn "
                      style={{
                        height: "36px",
                        marginTop: "1px",
                      }}
                    >
                      {selectedDispatchStatus !== null
                        ? `${selectedDispatchStatus} Orders`
                        : "Submit Orders"}{" "}
                      {/* {selectedOrdersInBulk.length === 1 && " Submit"} */}
                      {areSelectedOrdersProcessing ? (
                        <Spinner
                          style={{ width: "1rem", height: "1rem" }}
                          type="grow"
                          color="light"
                        />
                      ) : null}
                    </button>
                  </div>
                </div>
              </div>

              {paginationLoading && (
                <div className="mt-3 mx-3">
                  <Alert color="info">
                    <Spinner
                      color="light"
                      size={"sm"}
                      style={{ marginBottom: "3px" }}
                    ></Spinner>{" "}
                    &nbsp;
                    <span style={{ fontSize: "16px", color: "black" }}>
                      Orders Loading!
                    </span>
                  </Alert>
                </div>
              )}

              {filtersLoader ? (
                <TableSkeleton skeletonLength={10} />
              ) : pendingOrders?.length === 0 ? (
                <div
                  className=" d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
                  style={{
                    height: "200px",
                  }}
                >
                  <span>No Order Matched!</span>
                </div>
              ) : (
                <DataTable
                  setPageLimitInParent={setPageLimit}
                  setPageNumberInParent={setPageNumber}
                  fetchData={loadOrders}
                  pageCount={totalPages}
                  columns={showStoreColumn ? myCols : cols}
                  data={ordersData.sort(sortByDate)}
                  onCheckItem={onCheckItem}
                  checkedOrders={checkboxes}
                  isAllOrdersChecked={isAllOrdersChecked}
                  onCheckAllOrders={onCheckAllOrders}
                  checkCounter={checkCounter}
                  loadingBool={loading}
                  selectedOrdersActionBool={areSelectedOrdersProcessing}
                  setPaginationLoading={setPaginationLoading}
                  paginationLoading={paginationLoading}
                  totalCount={count}
                />
              )}
            </motion.div>
          </Cols>
        </Row>
      )}
    </>
  );
};

export default PendingOrders;

export const toMPFormat = (orders) => {
  const csvData = [];
  for (const order of orders) {
    let description = "";
    order.line_items.forEach(
      (item) =>
        (description = description.concat(`${item.sku}( ${item.quantity}) `))
    );
    // const pieces = order.line_items.reduce((prev, curr) => (prev.quantity + curr.quantity));
    csvData.push({
      "Consignee Name": order.shipping_address?.name
        ? order.shipping_address.name
        : "N/A",
      "Consignee Address": order.shipping_address?.address1
        ? order.shipping_address.address1
        : "N/A",
      "Consignee Mobile Number": order.shipping_address?.phone
        ? order.shipping_address.phone
        : "N/A",
      "Consignee Email": order.email || "",
      "Destination City": order.shipping_address?.city
        ? order.shipping_address.city
        : "N/A",
      Pieces: "1",
      Weight: order.total_weight ? order.total_weight : "N/A",
      "COD Amount": order.total_price_set?.shop_money?.amount
        ? order.total_price_set.shop_money.amount
        : "N/A",
      "Customer Reference Number": order.name ? order.name : "N/A",
      "Special Handling": "Yes",
      "Service Type": "By Road",
      "Product Details": description ? description : "N/A",
      Remarks: order.customer.note ? order.customer.note : "None",
    });
  }

  return csvData;
};

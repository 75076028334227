import React, { useState, useEffect, useCallback, useRef } from "react";
// import { startCase, camelCase } from "lodash";
import { Cols } from "../../SeparatorStyle/SeparatorStyle";
import CreatableSelect from "react-select/creatable";
import DataTable from "./OrderTable";
import {
  Row,
  Spinner,
  Button,
  Alert,
  Input,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import {
  updateOrderDispatchStatus,
  getOrderByDispatchStatus,
} from "../../../api/orders";
import { getAllCitiesName, getAllStoresLoginUser } from "../../../api";
import { sortByDate, DateFormatted } from "../../../helpers/dateTime";
import Select from "react-select";
import {
  OrdersDetailsPopup,
  // ConsigneeDetails,
  // NotePopup,
} from "../../Modals/Popups";
import { formatPhoneNumberWithButtons } from "../../../helpers/phoneNumber";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { saveBillingLogs } from "../../../api/billing";
import DateFilters from "../../Date/DateFilters.jsx";
// import { showItemDetails } from "../orderTabs/helperFunctions/tableDetailsInfo";
import {
  updateShippingCityFromOrders,
  saveVariants,
  updateOrderFields,
} from "../../../api/cityTypos";
import { getStoresWhatsappMsgs } from "../../../api/whatsappMsg";
import { useHistory } from "react-router-dom";
import { getCookie } from "../../../helpers/cookie";
import { handleSearch } from "../../search/searchHook";
import { motion } from "framer-motion";
// import { rightAnimate } from "../../FramerMotion/FramerMotion";
import { popOverContent, popOverList } from "../../../popOvers/popOver";
import TableSkeleton from "../../UI/TableSkeleton";
import { getAllBlockList } from "../../../api/blockList";
const OpenOrders = ({
  setOrderConfirmed,
  setOrderPending,
  setOrderCancelled,
  setOrderManual,
  // setSkeletonTab,
}) => {
  const [search, setSearch] = useState("");
  const [citySearch, setCitySearch] = useState("");
  const [unfulfilledOrders, setUnfulfilledOrders] = useState([]);
  const [filterArray, setFilterArray] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [editCityName, setEditCityName] = useState({});
  const [endDate, setEndDate] = useState(null);
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);
  const [orderDetailsData, setOrderDetailsData] = useState();
  // const [noteDetailsData, setNoteDetailsData] = useState("");
  // const [itemDetailsData, setItemDetailsData] = useState();
  const [checkboxes, setCheckboxes] = useState({});
  const [isAllOrdersChecked, setIsAllOrdersChecked] = useState(false);
  const dispatchStatusDDRef = useRef(null);
  const [selectedOrdersInBulk, setSelectedOrdersInBulk] = useState([]);
  // const [dateTime, setDateTime] = useState();
  const [areSelectedOrdersProcessing, setAreSelectedOrdersProcessing] =
    useState(false);
  // const [consigneeDetails, setConsigneeDetails] = useState();
  const [emptyStatus, setEmptyStatus] = useState(false);
  const [filtersLoader, setFiltersLoader] = useState(false);
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [searchCityTrigger, setSearchCityTrigger] = useState(false);
  const [tooltipMsg, setTooltipMsg] = useState("Copy!");
  const [count, setCount] = useState(0);
  const [checkCounter, setCheckCounter] = useState(0);
  const [selectedDispatchStatus, setSelectedDispatchStatus] = useState(null);
  // const [showResetButton, setShowResetButton] = useState(false);
  const [pageLimit, setPageLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [clickedOrderId, setClickedOrderId] = useState("");
  const [clickedHeader, setClickedHeader] = useState("");
  // const [dataDefaultCourier, setDataDefaultCourier] = useState();
  const shipmentOptionsDDRef = useRef(null);
  const [newOrder, setNewOrder] = useState("");
  const [cityOrderId, setCityOrderId] = useState();
  const [disabledCity, setDisabledCity] = useState(false);
  const [cities, setCities] = useState([]);
  const [filterByCityValue, setFilterByCityValue] = useState("");
  const [currentCityFilterValue, setCurrentCityFilterValue] = useState("");
  const [createOption, setCreateOption] = useState(false);
  const [newCity, setNewCity] = useState();
  // const [citiesCourier, setCitiesCourier] = useState([]);
  const [accountWhtsapMsgs, setAccountWhtsapMsgs] = useState([]);
  const [orderMsg, setOrderMsg] = useState({ message: "", openIn: "" });
  const history = useHistory();
  const [showStoreColumn, setShowStoreColumn] = useState(false);
  const [forCites, setForCites] = useState([]);
  const [blockList, setBlockList] = useState([]);

  let statusDispatchOptions = [
    { value: "confirm", label: "Confirm" },
    { value: "pending", label: "Pending" },
    { value: "cancel", label: "Cancel" },
    { value: "manual", label: "Manual" },
  ];

  let orderFilterOptions =
    !currentCityFilterValue && !filterByCityValue
      ? [
          { value: "incorrect", label: "All Incorrect Cities" },
          { value: "correct", label: "All Correct Cities" },
          { value: "incorrect-front", label: "Current Incorrect Cities" },
          { value: "correct-front", label: "Current Correct Cities" },
        ]
      : filterByCityValue
      ? [
          { value: "incorrect", label: "All Incorrect Cities" },
          { value: "correct", label: "All Correct Cities" },
        ]
      : [
          { value: "incorrect-front", label: "Current Incorrect Cities" },
          { value: "correct-front", label: "Current Correct Cities" },
        ];

  const getCurrentCityCourier = (destinationCity = " ") => {
    destinationCity = destinationCity.toLowerCase().trim();
    let selectedCourier = null;
    // for (const courierOfCities in citiesCourier) {
    //   const cities = citiesCourier[courierOfCities];
    //   const cityMatched = cities.some(
    //     (city) => city.toLowerCase().trim() === destinationCity
    //   );
    //   if (cityMatched) {
    //     selectedCourier = courierOfCities;
    //   }
    // }

    // if (dataDefaultCourier && !selectedCourier) {
    //   selectedCourier = Object.keys(dataDefaultCourier)[0];
    // }
    return selectedCourier;
  };

  // const toTitleCase = (str) => {
  //   return startCase(camelCase(str));
  // };
  const triggerFetch = async () => {
    const { data } = await getAllBlockList();

    if (data?.list) {
      setBlockList(
        data?.list?.map((m) => m?.phone?.toString()?.split(" ").join(""))
      );
    }
  };
  useEffect(() => {
    triggerFetch();
  }, []);

  const loadWhatsappMessages = async () => {
    try {
      const { data } = await getStoresWhatsappMsgs();
      if (data.length === 0) {
        toast.error("No whatsapp messages found!");
      }
      setAccountWhtsapMsgs(data);
    } catch (error) {
      console.log("Error found while fetch Whatsapp Messages", error);
    }
  };

  const loadStores = async () => {
    try {
      const { data } = await getAllStoresLoginUser();
      const { stores } = data[0];
      stores.length > 1 && setShowStoreColumn(true);
    } catch (error) {
      toast.error("Error found when fetch stores data!");
      console.log("Error found when fetch stores data!", error);
    }
  };

  const loadAllCities = async () => {
    try {
      const { data } = await getAllCitiesName();

      if (data) {
        const name = data.cities.map((city) => city?.value);
        setForCites(name);
        setCities(data.cities);
      }
    } catch (error) {
      toast.error("Error found when fetch Cities data!");
      console.log("Error found when fetch Cities data!", error);
    }
  };

  const loadOrders = useCallback(
    async ({
      page = pageNumber,
      limit = pageLimit,
      startDateIs = startDate,
      endDateIs = endDate,
      title = search.trim().length > 2 ? search : "",
      searchByCity = citySearch.trim().length > 2 ? citySearch : "",
      filterByCity = filterByCityValue?.value || "",
    }) => {
      try {
        setEmptyStatus(false);
        const { data } = await getOrderByDispatchStatus({
          dispatchStatus: "open",
          isAssigned: false,
          title,
          page,
          limit,
          startDateIs,
          endDateIs,
          searchByCity,
          filterByCity,
        });
        if (data) {
          setUnfulfilledOrders(data.orders);
          setFilterArray(data.orders);
          setFiltersLoader(false);
          setLoading(false);
          // setSkeletonTab(false);
          setPaginationLoading(false);
          setCount(data.count);
          setCheckboxes({});
          setCheckCounter(0);
          setSelectedOrdersInBulk([]);
        }

        if (data.count === 0) {
          setEmptyStatus(true);
        } else {
          setEmptyStatus(false);
        }
        return data.orders;
      } catch (error) {
        console.log("error found when fetch order data", error);
      }
    },
    [
      pageNumber,
      pageLimit,
      endDate,
      searchTrigger,
      searchCityTrigger,
      filterByCityValue,
      startDate,
    ]
  );

  const totalPages = Math.ceil(count / pageLimit);

  const handleCurrentCityFilter = () => {
    filterByCityValue && setFilterByCityValue("");
    const sorting = currentCityFilterValue;

    if (sorting?.value) {
      sorting?.value === "correct-front"
        ? setUnfulfilledOrders(
            filterArray.filter((obj) =>
              forCites.includes(obj.shipping_address?.city?.toLowerCase())
            )
          )
        : setUnfulfilledOrders(
            filterArray.filter(
              (obj) =>
                !forCites.includes(obj.shipping_address?.city?.toLowerCase())
            )
          );
    } else {
      setUnfulfilledOrders(filterArray);
    }
  };

  useEffect(() => {
    if (!filterByCityValue) {
      return handleCurrentCityFilter();
    }
  }, [filterArray, currentCityFilterValue]);

  const handleAllCityFilter = async (e) => {
    currentCityFilterValue && setCurrentCityFilterValue("");
    setFilterByCityValue(e);

    setPageNumber(1);
    setPageLimit(50);
    setFiltersLoader(true);
  };

  const handleChangeCity = (e, cityName) => {
    if (e.key === "Enter") {
      setValue(cityName);
    }
  };

  const handleFindCity = async (checkNewCity) => {
    setNewCity(checkNewCity);
    let checkCityInDB = await cities.find(
      (checkCity) => checkCity.label === checkNewCity
    );
    if (!checkCityInDB) {
      setCreateOption(true);
    } else {
      setCreateOption(false);
    }
  };

  const cancelEditCityMenu = () => {
    setCityOrderId();
    setCreateOption(false);
  };

  // const gettingForCites = async () => {
  //   // const { data } = await getOrderByDispatchStatus({
  //   //   dispatchStatus: 'open',
  //   //   isAssigned: false,
  //   //   filterByCity: 'incorrect',
  //   // });
  //   // if (data) {
  //   //   const data1 = data.orders.map((order) => order?.shipping_address?.city);
  //   //   setForCites(data1);
  //   // }
  // };

  useEffect(() => {
    let token = getCookie();
    if (token) {
      setLoading(true);
      // setSkeletonTab(true);
      // gettingForCites({});
      loadStores();
      loadWhatsappMessages();
      loadAllCities();
    } else {
      history.push("/signIn");
    }
  }, []);

  useEffect(() => {
    loadOrders({});
  }, [loadOrders]);

  // const consigneeDetail = (item, i) => {
  //   let orderItems = item.slice(0, 20);
  //   let itemsLength = item.length;
  //   let hoverIndex;
  //   return (
  //     <div>
  //       {orderItems}
  //       {itemsLength > 20 && (
  //         <>
  //           {" ... "}
  //           <i
  //             className="simple-icon-info pointer"
  //             id={"consigneeDetailPopover" + hoverIndex}
  //             onMouseEnter={() => {
  //               hoverIndex = i;
  //               setConsigneeDetails([item]);
  //             }}
  //             onMouseLeave={() => {
  //               hoverIndex = undefined;
  //               setConsigneeDetails();
  //             }}
  //           />
  //         </>
  //       )}
  //       {consigneeDetails && (
  //         <ConsigneeDetails
  //           consigneeDetails={consigneeDetails}
  //           index={hoverIndex}
  //         />
  //       )}
  //     </div>
  //   );
  // };

  const orderDetails = (items) => {
    let orderItems = items[0].title.slice(0, 30);
    let itemsLength = items[0].title.length;
    return (
      <div>
        {`${orderItems}${itemsLength > 20 ? "...." : ""}`}

        {itemsLength > 20 ? (
          <i
            style={{ cursor: "pointer" }}
            className="p-2 simple-icon-info "
            id="orderDetailPopover"
            onMouseEnter={() => {
              setOrderDetailsData(items);
            }}
          />
        ) : (
          ""
        )}
        <OrdersDetailsPopup
          orderDetailsData={orderDetailsData && orderDetailsData}
        />
      </div>
    );
  };

  // const noteDetails = (note) => {
  //   let noteText = note.slice(0, 20);
  //   let noteLenght = note.length;
  //   return (
  //     <div>
  //       {`${noteText}${noteLenght > 20 ? "..." : ""}`}

  //       {noteLenght > 20 ? (
  //         <i
  //           style={{ cursor: "pointer" }}
  //           className="p-2 simple-icon-info "
  //           id="noteDetailPopover"
  //           onMouseEnter={() => {
  //             setNoteDetailsData(note);
  //           }}
  //         />
  //       ) : (
  //         ""
  //       )}
  //       <NotePopup noteData={noteDetailsData && noteDetailsData} />
  //     </div>
  //   );
  // };

  const onCheckItem = (event, orderId) => {
    const orderDispatchStatus = dispatchStatusDDRef.current.value;
    setCheckboxes({ ...checkboxes, [orderId]: event.target.checked });
    let checkedOrders = [];
    unfulfilledOrders.forEach((order) => {
      if (order.orderId === orderId) {
        checkedOrders.push({ ...order, dispatchStatus: orderDispatchStatus });
      }
    });
    if (event.target.checked) {
      setSelectedOrdersInBulk([...selectedOrdersInBulk, ...checkedOrders]);
    } else {
      if (selectedOrdersInBulk.length > 0) {
        const removeUnCheckedOrder = selectedOrdersInBulk.filter(
          (order) => order.orderId !== orderId
        );
        setSelectedOrdersInBulk(removeUnCheckedOrder);
      }
    }
    if (event.target.checked && orderId) {
      setCheckCounter(checkCounter + 1);
    } else if (!event.target.checked) {
      setCheckCounter(checkCounter - 1);
    }
  };

  const resetFilters = async () => {
    setFiltersLoader(true);
    setStartDate(null);
    setEndDate(null);
  };

  const onCheckAllOrders = (e, ordersToMark) => {
    setIsAllOrdersChecked(e.target.checked);
    const orderIdsToMark = {};
    const ordersCompleteData = [];
    ordersToMark.forEach((order) => {
      orderIdsToMark[order.id] = e.target.checked;
      const matchedOrderInAllOrders = unfulfilledOrders.find(
        (completeOrder) => completeOrder.orderId === order.id
      );
      ordersCompleteData.push({ ...matchedOrderInAllOrders });
    });

    if (e.target.checked) {
      setCheckboxes(orderIdsToMark);
      setSelectedOrdersInBulk(ordersCompleteData);
      setCheckCounter(ordersCompleteData.length);
    } else {
      if (selectedOrdersInBulk.length > 0) {
        setCheckboxes({});
        setSelectedOrdersInBulk([]);
        setCheckCounter(selectedOrdersInBulk.length);
      }
    }
    if (!e.target.checked) {
      return setCheckCounter(0);
    }
  };

  const handleOrderDispatchStatus = (e, data) => {
    e.preventDefault();
    const dispatchAction = e.target.value.toLowerCase();
    const ordersWithDispatchStatusUpdated = selectedOrdersInBulk.map(
      (selectedOrder) => {
        if (selectedOrder.orderId === data.orderId) {
          return { ...selectedOrder, dispatchStatus: dispatchAction };
        }
        return selectedOrder;
      }
    );
    setSelectedOrdersInBulk([...ordersWithDispatchStatusUpdated]);
  };

  const handleOrderStatus = (e) => {
    if (e) {
      setSelectedDispatchStatus(e.label);
      let dispatchAction = e.value.toLowerCase();

      const ordersWithDispatchStatusUpdated = selectedOrdersInBulk.map(
        (data) => {
          return { ...data, dispatchStatus: dispatchAction };
        }
      );

      setSelectedOrdersInBulk([...ordersWithDispatchStatusUpdated]);
    } else {
      setSelectedDispatchStatus(null);

      const ordersWithDispatchStatusUpdated = selectedOrdersInBulk.map(
        (data) => {
          return { ...data, dispatchStatus: "" };
        }
      );

      setSelectedOrdersInBulk([...ordersWithDispatchStatusUpdated]);
    }
  };
  const setValue = async (cityName) => {
    setDisabledCity(true);
    try {
      if (createOption === true) {
        await updateShippingCityFromOrders(newCity, cityOrderId);
        setCreateOption(false);
      } else {
        await saveVariants(newCity, cityName, cityOrderId);
      }
      setCityOrderId();
      setDisabledCity(false);
      setEditCityName({ orderId: cityOrderId, cityName: newCity });
      const selectedCourier = getCurrentCityCourier(newCity);
      // setCourierAfterCityUpdate({
      //   orderId: cityOrderId,
      //   courier: selectedCourier,
      // });
      const shipmentOption = shipmentOptionsDDRef?.current?.value;

      const updatedCourierUnfulfilledInOrders = unfulfilledOrders.map(
        (order) => {
          if (order.orderId === cityOrderId) {
            return {
              ...order,
              courier: selectedCourier,
              shipmentType: order.shipmentType
                ? order.shipmentType
                : shipmentOption,
              shipping_address: { ...order.shipping_address, city: newCity },
            };
          }
          return order;
        }
      );

      setUnfulfilledOrders(updatedCourierUnfulfilledInOrders);

      if (selectedOrdersInBulk.length > 0) {
        const updatedCourierInSelectedOrders = selectedOrdersInBulk.map(
          (selectedOrder) => {
            if (selectedOrder.orderId === cityOrderId) {
              return {
                ...selectedOrder,
                courier: selectedCourier,
                shipmentType: selectedOrder.shipmentType
                  ? selectedOrder.shipmentType
                  : shipmentOption,
                shipping_address: {
                  ...selectedOrder.shipping_address,
                  city: newCity,
                },
              };
            }
            return selectedOrder;
          }
        );
        setSelectedOrdersInBulk(updatedCourierInSelectedOrders);
      }
      toast.success("Order's destination city updated successfully!");
    } catch (error) {
      setCityOrderId();
      setDisabledCity(false);
      toast.error(error.toString());
    }
  };

  const handleOrderSubmit = async () => {
    if (Object.keys(checkboxes).length > 0) {
      const isActionSelected = selectedOrdersInBulk.some(
        (order) => order.dispatchStatus !== ""
      );
      if (!isActionSelected) {
        toast.info("Please select action to perform on selected orders");
      } else {
        setAreSelectedOrdersProcessing(true);
        try {
          const confirmedOrders = [];
          let hasDispatchStatus = selectedOrdersInBulk.filter(
            (obj) =>
              obj.dispatchStatus &&
              !blockList.includes(obj?.shipping_address?.phone || obj?.phone)
          );

          let orderId = hasDispatchStatus.map((s) => s.orderId);
          let dispatchStatus = hasDispatchStatus.map((s) => s.dispatchStatus);
          let storeId = hasDispatchStatus.map((s) => s.storeId);
          // let current_total_price = hasDispatchStatus.map(
          //   (s) => s.current_total_price
          // );

          let Confirm_Orders_StoreId = hasDispatchStatus
            .filter((s) => s.dispatchStatus === "confirm")
            .map((s) => s.storeId);
          let Confirm_Orders_current_total_price = hasDispatchStatus
            .filter((s) => s.dispatchStatus === "confirm")
            .map((s) => s.current_total_price);
          // let Confirm_Orders_orderId = hasDispatchStatus.map((s) => s.orderId);

          if (Confirm_Orders_StoreId.length > 0) {
            await saveBillingLogs(
              Confirm_Orders_StoreId,
              Confirm_Orders_current_total_price
            );
          }
          const orderDispatchStatusUpdateResult =
            await updateOrderDispatchStatus(orderId, dispatchStatus, storeId);

          if (orderDispatchStatusUpdateResult.status === 200) {
            let confirmOrders = [];
            let pendingOrders = [];
            let cancelOrders = [];
            let manualOrders = [];

            hasDispatchStatus.filter((order) => {
              order.dispatchStatus === "confirm" &&
                confirmOrders.push(order.name);
              // toast.success('Order Confirmed Successfully!');

              order.dispatchStatus === "pending" &&
                pendingOrders.push(order.name);
              // toast.success('Order Added To Pending Successfully!');

              order.dispatchStatus === "cancel" &&
                cancelOrders.push(order.name);
              // toast.success('Order Added To Cancelled Successfully!');

              order.dispatchStatus === "manual" &&
                manualOrders.push(order.name);
              // toast.success('Order Added To Manual Successfully!');

              order.dispatchStatus === "confirm" && setOrderConfirmed(true);
              order.dispatchStatus === "pending" && setOrderPending(true);
              order.dispatchStatus === "cancel" && setOrderCancelled(true);
              order.dispatchStatus === "manual" && setOrderManual(true);
              confirmedOrders.push(order.Confirm_Orders_orderId);
              return order;
            });

            confirmOrders.length > 0 &&
              toast.success(
                ` ${String(confirmOrders)} Confirmed Successfully!`
              );
            pendingOrders.length > 0 &&
              toast.success(` ${String(pendingOrders)} Pending Successfully!`);
            cancelOrders.length > 0 &&
              toast.success(` ${String(cancelOrders)} Cancelled Successfully!`);
            manualOrders.length > 0 &&
              toast.success(` ${String(manualOrders)} Manual Successfully!`);
          }

          await loadOrders({});
          setCheckCounter(0);
          setSelectedOrdersInBulk([]);
          setSelectedDispatchStatus(null);
          setAreSelectedOrdersProcessing(false);
        } catch (e) {
          setAreSelectedOrdersProcessing(false);
          toast.error(`${e.message}`);
        }
      }
    } else {
      toast.info("Please select orders!");
    }
  };

  const changeButton = (orderId, inputHeader) => {
    setClickedOrderId(orderId);
    setClickedHeader(inputHeader);
  };

  const ChangeInputFields = (
    inputValue,
    orderId,
    Inputheader,
    i,
    adressName,
    OrderNo,
    ShipDetail,
    storeId
  ) => {
    if (orderId === clickedOrderId && Inputheader === clickedHeader) {
      return (
        <div className=" d-flex">
          <div style={{ minWidth: "100px" }}>
            <Input
              className="rounded-3"
              onChange={(e) => {
                setNewOrder(e.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  setDisabledCity(true);
                  changeInput(newOrder, Inputheader, orderId);
                }
              }}
              defaultValue={inputValue === "N/A" ? "" : inputValue}
            />
          </div>
          {disabledCity === false ? (
            <>
              {newOrder !== inputValue && (
                <span
                  className="far fa-check ms-2 pointer fs-5 text-success mt-2"
                  onClick={() => {
                    setDisabledCity(true);
                    changeInput(newOrder, Inputheader, orderId);
                  }}
                ></span>
              )}

              <span
                className="far fa-close ms-2 pointer mt-2 fs-5 text-danger"
                onClick={() => {
                  setClickedOrderId("");
                  setClickedHeader("");
                }}
              ></span>
            </>
          ) : (
            <div className="mt-2 ml-2">
              <Spinner size="sm" color="primary" />
            </div>
          )}
        </div>
      );
    } else {
      return (
        <span
          key={orderId}
          className={"d-flex justify-content-center ms-2 align-items-center"}
          style={{ whiteSpace: "nowrap" }}
        >
          {Inputheader === "name" && popOverContent(`Name-${i}`, inputValue)}

          {Inputheader === "address" &&
            popOverContent(`Address-${i}`, inputValue)}

          {Inputheader === "phone" && (
            <span
              style={
                blockList.includes(inputValue)
                  ? { textDecoration: "line-through" }
                  : {}
              }
            >
              {inputValue
                ? formatPhoneNumberWithButtons(
                    inputValue,
                    adressName,
                    OrderNo,
                    ShipDetail,
                    tooltipMsg,
                    setTooltipMsg,
                    i,
                    accountWhtsapMsgs,
                    storeId,
                    setOrderMsg,
                    orderMsg
                  )
                : "N/A"}
            </span>
          )}

          <span
            onClick={() => {
              changeButton(orderId, Inputheader);
              setNewOrder(inputValue);
            }}
            className="simple-icon-pencil fs-6 ms-2 pointer"
          ></span>
        </span>
      );
    }
  };
  const changeInput = async (input, toUpdate, orderId) => {
    try {
      await updateOrderFields(input, toUpdate, orderId);
      setDisabledCity(false);
      setClickedOrderId("");
      setClickedHeader("");
      toast.success(`Customer's ${toUpdate} updated successfully!`);
      loadOrders({});
    } catch (error) {
      toast.error(error.toString());
      setDisabledCity(false);
      setClickedOrderId("");
      setClickedHeader("");
    }
  };
  const ChangeCityName = (cityName, orderId) => {
    if (orderId === cityOrderId) {
      return (
        <>
          {disabledCity === false ? (
            <>
              <CreatableSelect
                key={orderId}
                options={cities}
                formatCreateLabel={() => "This is Correct"}
                defaultValue={{
                  label: cityName,
                  value: cityName.toLowerCase(),
                }}
                onKeyDown={() => handleChangeCity(cityName)}
                onChange={(e) => {
                  handleFindCity(e.label);
                }}
              />
              <Button
                onClick={() => {
                  setValue(newCity);
                }}
                color="primary"
                size="xs"
                className="mr-2 mt-1"
                hidden={createOption === false}
              >
                Add Anyway
              </Button>
              <Button
                onClick={() => setValue(cityName)}
                className="mr-2 mt-1"
                color="primary"
                size="xs"
                hidden={createOption === true}
              >
                Select
              </Button>
              <label
                onClick={cancelEditCityMenu}
                style={{ color: "red", cursor: "pointer" }}
                className="mt-1"
              >
                Cancel
              </label>
            </>
          ) : (
            <>
              <Select key={orderId} options={cities} isDisabled />
              <Spinner size="sm" color="primary" />
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          {Object.keys(editCityName).length > 0 &&
          editCityName.orderId === orderId ? (
            <span>{editCityName.cityName}</span>
          ) : (
            <span
              className={
                forCites?.includes(cityName?.toLowerCase()) ? "" : "text-danger"
              }
            >
              {popOverContent(`City-${orderId}`, cityName)}
            </span>
          )}

          <span
            className="simple-icon-pencil fs-6 ms-2 pointer"
            onClick={() => {
              setCityOrderId(orderId);
              setClickedOrderId("");
              setClickedHeader("");
            }}
          ></span>
        </>
      );
    }
  };

  const ordersData =
    unfulfilledOrders &&
    unfulfilledOrders.length > 0 &&
    unfulfilledOrders.map((order, i) => {
      return {
        id: order.orderId,
        orderDate: DateFormatted(order.created_at, i),
        name: ChangeInputFields(
          order?.shipping_address?.name,
          order.orderId,
          "name",
          i
        ),
        address: ChangeInputFields(
          `${
            (order?.shipping_address?.address1 || "") +
            (order?.shipping_address?.address2 || "")
          }`,
          order.orderId,
          "address",
          i
        ),
        phone: ChangeInputFields(
          order?.shipping_address?.phone,
          order.orderId,
          "phone",
          i,
          order?.shipping_address?.name,
          order?.order_number,
          order?.shipperDetail[0]?.shopDetail?.name,
          order.storeId
        ),
        note: popOverContent(`Note-${i}`, order.note),
        amount: popOverContent(
          `Total-${i}`,
          Number(order?.total_price_set?.shop_money?.amount).toLocaleString()
        ),
        referenceNo: order.orderId,
        details: orderDetails(order.line_items),
        orderId: order.name,
        items: (
          <>
            {order?.line_items.length}
            {"  "}
            {order?.line_items.length > 0
              ? popOverList(
                  `lineItem-${i}`,
                  order.line_items.map((o) => `${o.title} (${o.quantity})`)
                )
              : "N/A"}
          </>
        ),
        city: order?.shipping_address?.city
          ? ChangeCityName(order.shipping_address.city, order.orderId)
          : ChangeCityName("null", order.orderId),
        storeName: popOverContent(
          `Store-${i}`,
          order.shipperDetail[0]?.shopDetail.name.replace(`${order?.name}-`, "")
        ),
        storeId: order.storeId,
        // updated_at: order.updated_at,
        // created_at: order.created_at,
        actions: (
          <select
            defaultValue={""}
            key={order.orderId}
            onChange={(e) => handleOrderDispatchStatus(e, order)}
            className={"form-control form-select"}
            ref={dispatchStatusDDRef}
            disabled={checkboxes && checkboxes[order.orderId] ? false : true}
          >
            <option disabled value="">
              Select
            </option>
            <option value="Confirm">Confirm</option>
            <option value="Pending">Pending</option>
            <option value="Cancel">Cancel</option>
            <option value="manual">Manual Book</option>
          </select>
        ),
      };
    });

  const cols = React.useMemo(
    () => [
      {
        Header: "Order Id",
        accessor: "orderId",
        cellClass: " w-3 text-start",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Date",
        accessor: "orderDate",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Customer",
        accessor: "name",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Address",
        accessor: "address",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Phone",
        accessor: "phone",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Note",
        accessor: "note",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Total",
        accessor: "amount",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },

      {
        Header: "Items",
        accessor: "items",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Destination City",
        accessor: "city",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Actions",
        accessor: "actions",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
    ],
    []
  );

  const myCols = React.useMemo(
    () => [
      {
        Header: "Order Id",
        accessor: "orderId",
        cellClass: " w-5 text-start",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Date",
        accessor: "orderDate",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Customer",
        accessor: "name",
        cellClass: "w-10",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Address",
        accessor: "address",
        cellClass: " w-10",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Phone",
        accessor: "phone",
        cellClass: " w-10",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Note",
        accessor: "note",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Total",
        accessor: "amount",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },

      {
        Header: "Items",
        accessor: "items",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Destination City",
        accessor: "city",
        cellClass: " w-10",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Store",
        accessor: "storeName",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Actions",
        accessor: "actions",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
    ],
    []
  );

  return (
    <>
      {loading ? (
        <TableSkeleton skeletonLength={10} itemsBar={3} />
      ) : emptyStatus &&
        !search.trim() &&
        !citySearch.trim() &&
        !startDate &&
        !endDate &&
        !filterByCityValue ? (
        <div
          className="d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
          style={{
            height: "200px",
          }}
        >
          <span>No Order Found!</span>
        </div>
      ) : (
        <Row>
          <Cols xxs="12">
            <motion.div
              className="marginL-md px-2"
              // variants={rightAnimate}
              // initial="hidden"
              // animate="visible"
            >
              <div className={{ padding: "0px" }}>
                <div className="card-title mb-0">
                  <div className="d-flex justify-content-between flex-wrap mt-4">
                    <div className="me-1 position-relative">
                      <Input
                        type="text"
                        name="keyword"
                        id="search"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => {
                          handleSearch({
                            e,
                            setPageNumber,
                            setPageLimit,
                            setFilterLoading: setFiltersLoader,
                            setSearch,
                            setSearchTrigger,
                            searchTrigger,
                          });
                        }}
                        className="rounded-3"
                        disabled={
                          paginationLoading ||
                          filtersLoader ||
                          selectedOrdersInBulk.length > 0
                        }
                      />
                      {search.trim() && search.trim().length < 3 && (
                        <i
                          className="simple-icon-info pointer position-absolute text-danger fw-bold"
                          style={{ right: "-20px", bottom: "14px" }}
                          id={"SearchInfo"}
                        >
                          {" "}
                          <UncontrolledPopover
                            trigger="hover"
                            placement="top"
                            target={"SearchInfo"}
                          >
                            <PopoverBody className="text-center">
                              <i>Minimum 3 words required!</i>
                            </PopoverBody>
                          </UncontrolledPopover>
                        </i>
                      )}
                      {search &&
                        (filtersLoader && search.trim().length > 2 ? (
                          <Spinner
                            color="primary"
                            size={"sm"}
                            style={{ right: "10px", bottom: "14px" }}
                            className=" position-absolute"
                            type="grow"
                          ></Spinner>
                        ) : (
                          <span
                            className="far fa-close fs-6 position-absolute text-danger pointer"
                            style={{ right: "10px", bottom: "14px" }}
                            onClick={() => {
                              if (search) {
                                setSearch("");
                                if (search.trim().length > 2) {
                                  setSearchTrigger(!searchTrigger);
                                  setFiltersLoader(true);
                                }
                              }
                              pageLimit > 50 && setPageLimit(50);
                              pageNumber > 1 && setPageNumber(1);
                            }}
                          ></span>
                        ))}
                    </div>
                    <div>
                      <DateFilters
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        selectedDateFilter={selectedDateFilter}
                        setSelectedDateFilter={setSelectedDateFilter}
                        // setShowResetButton={setShowResetButton}
                        loadOrders={loadOrders}
                        resetFilters={resetFilters}
                        setFiltersLoader={setFiltersLoader}
                        paginationLoading={paginationLoading}
                        filtersLoader={filtersLoader}
                        selectedOrdersInBulk={selectedOrdersInBulk}
                        allOrdersFlag={"allOrdersFlag"}
                      />
                    </div>
                    <div className=" d-flex flex-wrap">
                      <div className="mb-2 mr-2 position-relative confirm-city-search">
                        <Input
                          type="text"
                          name="keyword"
                          id="search"
                          placeholder="Search Cities"
                          value={citySearch}
                          onChange={(e) => {
                            setEmptyStatus(false);
                            handleSearch({
                              e,
                              setPageNumber,
                              setPageLimit,
                              setFilterLoading: setFiltersLoader,
                              setSearch: setCitySearch,
                              setSearchTrigger: setSearchCityTrigger,
                              searchTrigger: searchCityTrigger,
                            });
                          }}
                          className="rounded-3"
                          disabled={
                            paginationLoading ||
                            filtersLoader ||
                            selectedOrdersInBulk.length > 0
                          }
                        />
                        {citySearch &&
                          (filtersLoader && citySearch.trim().length > 2 ? (
                            <Spinner
                              color="primary"
                              size={"sm"}
                              style={{ right: "10px", bottom: "14px" }}
                              className=" position-absolute"
                              type="grow"
                            ></Spinner>
                          ) : (
                            <span
                              className="far fa-close fs-6 position-absolute text-danger pointer"
                              style={{ right: "10px", bottom: "14px" }}
                              onClick={() => {
                                if (citySearch) {
                                  setCitySearch("");

                                  setSearchCityTrigger(!searchCityTrigger);
                                  setFiltersLoader(true);
                                }
                                pageLimit > 50 && setPageLimit(50);
                                pageNumber > 1 && setPageNumber(1);
                              }}
                            ></span>
                          ))}
                      </div>
                      {citySearch.trim() && citySearch.trim().length < 3 && (
                        <div className="d-flex align-items-center pb-2">
                          <i
                            className="simple-icon-info pointer text-danger fw-bold mr-2"
                            // style={{ left: '-20px', bottom: '14px' }}
                            id={"CitySearchInfo"}
                          >
                            {" "}
                            <UncontrolledPopover
                              trigger="hover"
                              placement="top"
                              target={"CitySearchInfo"}
                            >
                              <PopoverBody className="text-center">
                                <i>Minimum 3 words required!</i>
                              </PopoverBody>
                            </UncontrolledPopover>
                          </i>
                        </div>
                      )}
                      <div
                        className="openOrderSelect alignY-xxs mb-2 mr-2"
                        style={{ width: "160px" }}
                      >
                        <Select
                          options={orderFilterOptions}
                          hideSelectedOptions
                          placeholder="Filter Cities"
                          value={filterByCityValue || currentCityFilterValue}
                          onChange={(e) => {
                            if (e) {
                              e?.value.includes("-front")
                                ? setCurrentCityFilterValue(e)
                                : handleAllCityFilter(e);
                            } else {
                              currentCityFilterValue
                                ? setCurrentCityFilterValue("")
                                : handleAllCityFilter("");
                            }
                          }}
                          isClearable
                          isDisabled={
                            paginationLoading ||
                            filtersLoader ||
                            selectedOrdersInBulk.length > 0
                          }
                        />
                      </div>
                      <div
                        className="openOrderSelect alignY-xxs mb-2"
                        style={{ width: "130px" }}
                      >
                        <Select
                          className="me-2"
                          placeholder="Actions"
                          value={
                            selectedDispatchStatus
                              ? selectedDispatchStatus.label
                              : ""
                          }
                          options={statusDispatchOptions}
                          isDisabled={
                            selectedOrdersInBulk.length < 2 ||
                            areSelectedOrdersProcessing
                          }
                          isClearable
                          onChange={handleOrderStatus}
                        />
                      </div>
                      <div>
                        <button
                          disabled={
                            areSelectedOrdersProcessing ||
                            selectedOrdersInBulk.length === 0
                          }
                          onClick={handleOrderSubmit}
                          type="button"
                          className="btn btn-primary custom-btn"
                          style={{
                            height: "36px",
                            marginTop: "2px",
                          }}
                        >
                          {selectedDispatchStatus !== null
                            ? `${selectedDispatchStatus} Orders`
                            : "Submit Orders"}{" "}
                          {/* {selectedOrdersInBulk.length === 1 && " Submit"} */}
                          {areSelectedOrdersProcessing ? (
                            <Spinner
                              style={{ width: "1rem", height: "1rem" }}
                              type="grow"
                              color="light"
                            />
                          ) : null}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {paginationLoading && (
                  <div className="mt-3 mx-3">
                    <Alert color="info">
                      <Spinner
                        color="light"
                        size={"sm"}
                        style={{ marginBottom: "3px" }}
                      ></Spinner>{" "}
                      &nbsp;
                      <span style={{ fontSize: "16px", color: "black" }}>
                        Orders Loading!
                      </span>
                    </Alert>
                  </div>
                )}

                {filtersLoader ? (
                  <TableSkeleton skeletonLength={10} />
                ) : unfulfilledOrders?.length === 0 ? (
                  <div
                    className="d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
                    style={{
                      height: "200px",
                    }}
                  >
                    <span>No Order Matched!</span>
                  </div>
                ) : (
                  <DataTable
                    setPageLimitInParent={setPageLimit}
                    setPageNumberInParent={setPageNumber}
                    fetchData={loadOrders}
                    pageCount={totalPages}
                    columns={showStoreColumn ? myCols : cols}
                    data={ordersData.sort(sortByDate)}
                    onCheckItem={onCheckItem}
                    checkedOrders={checkboxes}
                    isAllOrdersChecked={isAllOrdersChecked}
                    onCheckAllOrders={onCheckAllOrders}
                    checkCounter={checkCounter}
                    loadingBool={loading}
                    selectedOrdersActionBool={areSelectedOrdersProcessing}
                    setPaginationLoading={setPaginationLoading}
                    paginationLoading={paginationLoading}
                    totalCount={count}
                  />
                )}
              </div>
            </motion.div>
          </Cols>
        </Row>
      )}
    </>
  );
};

export default OpenOrders;

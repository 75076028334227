export const toTPLFormat = (orders) => {
  const csvData = [];
  for (const order of orders) {
    let description = "";
    order.line_items.forEach(
      (item) =>
        (description = description.concat(`${item.sku}( ${item.quantity}) `))
    );
    const shipperAddress =
      order.shipperDetail[0].shopDetail.address1 ||
      order.shipperDetail[0].shopDetail.address2;
    const originCity = order.shipperDetail[0].shopDetail.city;
    // const pieces = 1;
    csvData.push({
      "Consignee Name": order.shipping_address?.name
        ? order.shipping_address.name
        : "N/A",
      "Consignee Address": order.shipping_address?.address1
        ? order.shipping_address.address1
        : "N/A",
      "Pickup Address": shipperAddress ? shipperAddress : "N/A",
      Contact: order.shipping_address?.phone
        ? order.shipping_address.phone
        : "N/A",
      Product: description ? description : "N/A",
      "No. of Pieces": "1",
      Dimension: "",
      "Weight (Kg)": order.total_weight ? order.total_weight : "N/A",
      Amount: order.total_price_set?.shop_money?.amount
        ? order.total_price_set.shop_money.amount
        : "N/A",
      "Additional Notes": order.customer?.note ? order.customer.note : "None",
      "Order Number": order.name ? order.name : "N/A",
      "Origin City": originCity ? originCity : "N/A",
      "Destination City": order.shipping_address?.city
        ? order.shipping_address.city
        : "N/A",
      "Vendor Address": "",
      "Vendor Phone": "",
      "Delivery Type": order.shipmentType ? order.shipmentType : "N/A",
      "Payment Mode": "Cash",
    });
  }

  return csvData;
};

import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  Card,
  CardBody,
  Breadcrumb,
  Button,
  Row,
  Input,
  Form,
  FormGroup,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Progress,
  Label,
} from "reactstrap";
import Swal from "sweetalert2";
import { ProductDetails } from "./ProductDetails.jsx";
import { Cols } from "../SeparatorStyle/SeparatorStyle";
import { toast } from "react-toastify";
import { useHistory, Link } from "react-router-dom";
import Select from "react-select";
// import DataTablePagination from '../wizard/DatatablePagination.jsx';
import {
  getAllStoresLoginUser,
  saveMasterStore,
  getVariantsForSkuMapping,
  updateVariantSKU,
  updateSKUMapping,
  unMapVariants,
} from "../../api";
import { motion } from "framer-motion";
import { leftAnimate } from "../FramerMotion/FramerMotion.jsx";
import TableSkeleton from "../UI/TableSkeleton.jsx";
const SKUMapping = () => {
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");
  const [masterStore, setMasterStore] = useState("");
  const [masterId, setMasterId] = useState("");
  const [masterStoreProducts, setMasterStoreProducts] = useState([]);
  const [otherStores, setOtherStores] = useState([]);
  const [otherStoreProducts, setOtherStoreProducts] = useState({});
  const [previouslyMappedValues, setPreviouslyMappedValues] = useState({});
  const [mappedData, setMappedData] = useState([]);
  const [unMappedData, setUnMappedData] = useState([]);
  const [SKUValue, setSKUValue] = useState("");
  const [SKUId, setSKUId] = useState("");
  const [newSKU, setNewSKU] = useState("");
  const [productDetails, setProductDetails] = useState();
  const [dropdown, setDropdown] = useState("");
  const completed = "0";
  const progressBar = true;
  const [loading, setLoading] = useState(false);
  const [currentSKU, setCurrentSKU] = useState("");
  const [currentProduct, setCurrentProduct] = useState("");
  const [variantId, setVariantId] = useState("");
  const [storeId, setStoreId] = useState("");
  const [rowId, setRowId] = useState("");
  const [updateBtn, setUpdateBtn] = useState("");
  // const [navigate, setNavigate] = useState(false);
  let masterStoreName = "";
  let searchCount = 1;
  let storesCount = 1;
  let count = 0;

  const modalToggle = () => {
    setModal(false);
    setMasterStore(selectedStore);
    let master = stores.find((m) => m.shopDetail.name === selectedStore);
    handleSaveMasterStore(master._id, true);
    let others = stores.filter((o) => o.shopDetail.name !== selectedStore);
    setOtherStores(others);
    loadProductsforMasterStore([master._id]);
    loadProductsforOtherStore(others.map((o) => o._id));
  };

  const loadMyStores = useCallback(async () => {
    try {
      const { data } = await getAllStoresLoginUser();
      const stores = data[0].stores;
      data[0].stores.length < 2 && setLoading(true);
      let masterStore = stores.filter((s) => s.isMasterStore);
      if (masterStore.length > 0) {
        masterStoreName = masterStore[0].shopDetail.name;
        setMasterStore(masterStoreName);
        setModal(false);
        let others = stores.filter((store) => !store.isMasterStore);
        setOtherStores(others);
        let masterProds = await loadProductsforMasterStore([
          masterStore[0]._id,
        ]);
        let otherStoreIds = others.map((o) => o._id);
        await loadProductsforOtherStore(otherStoreIds, masterProds);
      } else {
        setModal(true);
      }
      setStores(stores);
    } catch (err) {
      toast.error("Stores data loading error! ");
    }
  }, []);

  useEffect(() => {
    loadMyStores().then();
  }, []);

  const handleSaveMasterStore = async (id, isMaster) => {
    try {
      await saveMasterStore(id, isMaster);
    } catch (err) {
      toast.error("Error in Setting up Master Store!");
    }
  };

  const loadProductsforMasterStore = async (id) => {
    try {
      const { data } = await getVariantsForSkuMapping(id);
      setMasterStoreProducts(data);
      return data;
    } catch (err) {
      toast.error("Error! While Loading Master Store Products");
    }
  };

  const loadProductsforOtherStore = async (id, masterProds) => {
    try {
      const { data } = await getVariantsForSkuMapping(id);
      const itemsByStoreId = {};
      const prevMappedValuesClone = { ...previouslyMappedValues };

      for (const variant of data) {
        const item = {
          label: `${variant.sku}/${variant.productMain[0].title}`,
          value: variant,
        };
        if (
          Object.keys(itemsByStoreId).length > 0 &&
          itemsByStoreId[variant.storeId]
        ) {
          itemsByStoreId[variant.storeId] = [
            ...itemsByStoreId[variant.storeId],
            item,
          ];
        } else {
          itemsByStoreId[variant.storeId] = [item];
        }
        if (variant.masterId) {
          // const row = masterProds.find(
          //   (d) => d.masterId === variant.masterId && d.isMasterVariant === true
          // );
          const key = `${variant.masterId}/${variant.storeId}`;
          prevMappedValuesClone[key] = {
            label: `${variant.sku}/${variant.productMain[0].title}`,
            value: variant,
          };
        }
      }
      setPreviouslyMappedValues(prevMappedValuesClone);
      setOtherStoreProducts(itemsByStoreId);
      return Object.keys(prevMappedValuesClone);
    } catch (err) {
      toast.error("Error in Loading Other Stores Products!");
    }
  };

  const handleUpdateVariantSKU = async (variantId, sku, storeId, _id) => {
    try {
      await updateVariantSKU(variantId, sku, storeId);
      setSKUValue("");
      let list = masterStoreProducts.map((p) => {
        if (p.sku === SKUValue && p._id === _id) {
          p.sku = newSKU;
        }
        return p;
      });
      setMasterStoreProducts(list);
      setCurrentSKU(sku);
      setNewSKU("");
      setSKUId("");
    } catch (err) {
      toast.error("Error in Loading SKU Updation Result!");
    }
  };

  const handleMasterStore = (e) => {
    setSelectedStore(e.target.value);
  };

  const handleProductsList = (e, storeId, rowId, masterVariantId, masterId) => {
    setUpdateBtn(true);
    let subKey = `${masterId}/${storeId}`;
    let selectedSKUKeys = Object.keys(previouslyMappedValues);
    let matchedKey = selectedSKUKeys.find((k) => k === subKey);

    if (!e) {
      setUpdateBtn(true);
      let prevSelected = previouslyMappedValues[matchedKey];
      delete previouslyMappedValues[matchedKey];
      setPreviouslyMappedValues(previouslyMappedValues);
      setUnMappedData([
        ...unMappedData,
        { variantId: prevSelected.value.shopifyVariantId },
      ]);

      let prodArr = otherStoreProducts[storeId];
      let updatedArray = prodArr.filter((p) => p !== e);
      updatedArray.push(prevSelected);
      otherStoreProducts[storeId] = updatedArray;
      setOtherStoreProducts(otherStoreProducts);
      setDropdown("");

      let mappedFilter = mappedData.filter(
        (f) => f.variantId !== prevSelected.value.shopifyVariantId
      );
      setMappedData(mappedFilter);
    } else {
      let key = `${masterId}/${storeId}`;

      if (Object.keys(previouslyMappedValues).length > 0 && matchedKey) {
        let prevSelected = previouslyMappedValues[matchedKey];
        delete previouslyMappedValues[matchedKey];
        setPreviouslyMappedValues({ ...previouslyMappedValues, [key]: e });

        let prodArr = otherStoreProducts[storeId];
        let updatedArray = prodArr.filter((p) => p.label !== e.label);
        updatedArray.push(prevSelected);
        otherStoreProducts[storeId] = updatedArray;
        setOtherStoreProducts(otherStoreProducts);

        let mappedFilter = mappedData.filter(
          (f) => f.variantId !== prevSelected.value.shopifyVariantId
        );
        setMappedData([
          ...mappedFilter,
          {
            rowId,
            variantId: e.value.shopifyVariantId,
            isMasterVariant: false,
          },
        ]);
      } else {
        let prevData = mappedData;
        if (
          prevData.length === 0 ||
          !prevData.some((md) => md.variantId === masterVariantId)
        ) {
          prevData.push({
            rowId,
            variantId: masterVariantId,
            isMasterVariant: true,
          });
          setMappedData(prevData);
        }

        setMappedData([
          ...mappedData,
          {
            rowId,
            variantId: e.value.shopifyVariantId,
            isMasterVariant: false,
          },
        ]);

        let prodArr = otherStoreProducts[storeId];
        let updatedArray = prodArr.filter((p) => p !== e);
        otherStoreProducts[storeId] = updatedArray;
        setOtherStoreProducts(otherStoreProducts);

        setPreviouslyMappedValues({ ...previouslyMappedValues, [key]: e });
      }
    }
  };

  const handleSKUMappingUpdate = async (mappedData, unMappedData) => {
    try {
      if (unMappedData.length > 0) {
        const { modified, totalVariants } = handleSKUUNMapping(unMappedData);
        if (modified === totalVariants) {
          setDropdown("");
          setEditModal(false);
          setUpdateBtn(false);
          setUnMappedData([]);
          toast.success("Data UnMapped Successfully!");
          loadMyStores();
        }
      }
      if (mappedData.length > 0) {
        const { data } = await updateSKUMapping(mappedData);
        if (data && data.modified === data.totalVariants) {
          setDropdown("");
          setEditModal(false);
          setUpdateBtn(false);
          toast.success("Data Mapped Successfully!");
          loadMyStores();
        } else {
          toast.warning("Items not selected for Mapping!");
          setDropdown("");
          setUpdateBtn(false);
          setMappedData([]);
        }
      }
    } catch (err) {
      toast.error("Error in Updation of SKU Mapping!");
      setUpdateBtn(false);
    }
  };

  const handleSKUUNMapping = async (unMappedData) => {
    try {
      await unMapVariants(unMappedData);
    } catch (err) {
      toast.error("Error in Deletion of SKU Mapping!");
      setUpdateBtn(false);
    }
  };

  const handleCancelEditSKU = () => {
    setSKUValue("");
    setSKUId("");
  };

  const handleEditSKU = (value, _id) => {
    setSKUValue(value);
    setSKUId(_id);
  };

  const handleChangeSKUNumber = (variantId, sku, storeId, _id) => {
    if (_id === SKUId) {
      return (
        <>
          <div style={{ display: "inline" }}>
            <Row>
              <Cols sm={10}>
                <Input
                  bsSize="sm"
                  defaultValue={currentSKU}
                  style={{ float: "center", textAlign: "center" }}
                  onChange={(e) => setNewSKU(e.target.value)}
                />
              </Cols>
              <Cols sm={1}>
                <span
                  className="far fa-check mt-2 text-success fs-5 pointer"
                  hidden={newSKU === "" || newSKU === sku}
                  onClick={() =>
                    handleUpdateVariantSKU(variantId, newSKU, storeId, _id)
                  }
                ></span>
              </Cols>
              <Cols sm={1}>
                <i
                  className="far fa-times fs-5 text-danger mt-2 pointer"
                  onClick={handleCancelEditSKU}
                ></i>
              </Cols>
            </Row>
          </div>
        </>
      );
    } else {
      return (
        <>
          {sku}
          <i
            className="simple-icon-pencil ml-2 fs-6 fw-bold"
            onClick={() => handleEditSKU(sku, _id)}
            style={{
              verticalAlign: "-5px",
              fontSize: "18px",
              cursor: "pointer",
            }}
          ></i>
        </>
      );
    }
  };

  const handleProductDetails = (item) => {
    let orderItems = item.slice(0, 30);
    let itemsLength = item.length;
    return (
      <>
        <div>
          {`${orderItems}${itemsLength > 20 ? "...." : ""}`}
          {itemsLength > 20 ? (
            <i
              style={{ cursor: "pointer" }}
              className="p-2 simple-icon-info "
              id="productDetailPopover"
              onMouseEnter={() => {
                setProductDetails([item]);
              }}
            />
          ) : (
            ""
          )}

          <ProductDetails productDetails={productDetails && productDetails} />
        </div>
      </>
    );
  };

  useEffect(() => {
    loading &&
      Swal.fire({
        allowOutsideClick: false,
        title: "You must have atleast 2 stores!",
        html: "<span class='text-black'>Please add stores to use this feature!</span>",
        color: "#f8bb86",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#037310",
        // cancelButtonColor: "#D3D3D3",
        confirmButtonText: "Add Store",
        customClass: {
          cancelButton: "rounded btn btn-sm",
          confirmButton: "rounded",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          return history.replace("/admin/stores");
        } else {
          return history.replace("/admin/dashboard");
        }
      });
  }, [loading]);

  return (
    <>
      <main>
        <motion.div
          className="row top-text"
          variants={leftAnimate}
          initial="hidden"
          animate="visible"
        >
          <Cols xxs="12">
            <h1>SKU Mapping</h1>
            <div style={{ display: "inline" }}>
              <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
                <ol className="breadcrumb pt-0">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard" style={{ color: "black" }}>
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    SKU Mapping
                  </li>
                </ol>
              </Breadcrumb>
            </div>
          </Cols>
        </motion.div>
        {/* Progress Bar */}
        <div style={{ height: "12px" }}>
          <Progress
            animated
            color="primary"
            style={{ height: "12px" }}
            striped
            value={completed}
            className="mx-auto"
            hidden={progressBar}
          />
        </div>
        {/* Mapping Data Table */}
        {!modal ? (
          <>
            {stores.length === 0 ? (
              <>
                <Card>
                  <CardBody>
                    <TableSkeleton
                      skeletonLength={10}
                      itemsBar={2}
                      skumapping={true}
                    />
                  </CardBody>
                </Card>
              </>
            ) : (
              <motion.div
                className="card marginL-md"
                // variants={rightAnimate}
                // initial="hidden"
                // animate="visible"
              >
                <CardBody>
                  <Row>
                    <Cols>
                      <div className="container-fluid">
                        <div className="d-flex justify-content-between">
                          <Input
                            type="text"
                            className="w-20"
                            name="keyword"
                            id="search"
                            placeholder="Search in Master Store's SKU"
                          />

                          {otherStores.length > 0
                            ? otherStores.map((store) => (
                                <Input
                                  type="text"
                                  className="w-20"
                                  name="keyword"
                                  id="search"
                                  // onChange={(e) =>
                                  //   setSearchValue(e.target.value)
                                  // }
                                  placeholder={`Search in Store ${(searchCount += 1)}`}
                                />
                              ))
                            : ""}
                        </div>
                        <Table striped borderless hover responsive>
                          <thead>
                            <tr>
                              <th></th>
                              <th colSpan={"2"} className="text-center">
                                Master Store's SKU {`(${masterStore})`}
                              </th>
                              {otherStores.length > 0
                                ? otherStores.map((store) => (
                                    <th>{`Store ${(storesCount += 1)}`}</th>
                                  ))
                                : ""}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th>No.</th>
                              <th>SKU Number</th>
                              <th>Product Name</th>
                              {otherStores.length > 0
                                ? otherStores.map((store) => (
                                    <th>{store.shopDetail.name}</th>
                                  ))
                                : ""}
                              <th>Actions</th>
                            </tr>

                            {masterStoreProducts.length > 0 ? (
                              masterStoreProducts.map((p) => (
                                <tr key={p._id}>
                                  <td>{(count += 1)}</td>
                                  <td>{p.sku ? p.sku : "N/A"}</td>
                                  {/* <td> {p.productMain[0].title ? handleProductDetails(p.productMain[0].title) : 'N/A'}</td> */}
                                  <td>
                                    {" "}
                                    {p.productMain[0].title
                                      ? p.productMain[0].title
                                      : "N/A"}
                                  </td>
                                  {otherStores.map((store) => (
                                    <td key={store.shopifyVariantId}>
                                      {previouslyMappedValues[
                                        `${p.masterId}/${store._id}`
                                      ] !== undefined
                                        ? previouslyMappedValues[
                                            `${p.masterId}/${store._id}`
                                          ].label
                                        : ""}
                                    </td>
                                  ))}
                                  <td>
                                    <span
                                      className="simple-icon-pencil fw-bold fs-6 text-dark"
                                      style={{
                                        fontSize: "18px",
                                        cursor: "pointer",
                                        color: "purple",
                                      }}
                                      onClick={() => {
                                        setEditModal(!editModal);
                                        setCurrentProduct(
                                          p.productMain[0].title
                                        );
                                        setCurrentSKU(p.sku);
                                        setVariantId(p.shopifyVariantId);
                                        setStoreId(p.storeId);
                                        setRowId(p._id);
                                        setMasterId(p.masterId);
                                      }}
                                    ></span>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <td
                                colSpan={4}
                                style={{
                                  height: "150px",
                                  padding: "40px 50px",
                                }}
                              >
                                <div className="mx-5">
                                  <h2
                                    style={{
                                      color: "red",
                                      height: "50px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    No products to show
                                  </h2>
                                </div>
                              </td>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </Cols>
                  </Row>
                </CardBody>
              </motion.div>
            )}
          </>
        ) : (
          ""
        )}

        {/* Master Store Selection Modal */}
        <div>
          <Modal isOpen={modal && !loading} centered>
            <ModalHeader className="justify-content-center">
              <h2>
                <b>Please Select your Master Store</b>
              </h2>
            </ModalHeader>
            <ModalBody>
              <div className="text-center">
                <p
                  style={{
                    fontSize: "12px",
                    marginBottom: "-4px",
                    marginTop: "-4px",
                  }}
                >
                  <span
                    className="simple-icon-info text-info mr-1"
                    style={{ verticalAlign: "-2px", fontSize: "15px" }}
                  ></span>
                  This Store will be used to manage your SKU's accross other
                  stores.
                </p>
                <br />
                <select
                  className="form-select w-80 mx-auto mb-2 text-center"
                  aria-label="Default select example"
                  required
                  onChange={(e) => {
                    handleMasterStore(e);
                    setOtherStores(
                      stores.filter(
                        (store) => store.shopDetail.name !== e.target.value
                      )
                    );
                  }}
                  style={{ height: "40px", fontWeight: "bold" }}
                >
                  <option hidden disabled selected value="">
                    Select Master Store
                  </option>
                  {stores &&
                    stores.map((store) => (
                      <option value={store.shopDetail.name}>
                        {store.shopDetail.name}
                      </option>
                    ))}
                </select>
                <br />

                <span className="iconsminds-danger text-danger mr-1 fs-5"></span>
                <b>Choose Wisely! You won't be able to change it later.</b>
              </div>
            </ModalBody>
            <ModalFooter className="justify-content-center">
              <Button
                color="primary"
                onClick={modalToggle}
                disabled={selectedStore === ""}
              >
                Submit
              </Button>
            </ModalFooter>
          </Modal>
        </div>

        {/* Edit Mapping Modal */}
        <div>
          <Modal isOpen={editModal} size="lg">
            <ModalHeader
              toggle={() => {
                setEditModal(false);
              }}
            >
              <h3>
                <b>Update Mapping</b>
              </h3>
            </ModalHeader>
            <ModalBody>
              <div className="text-center">
                <Form className="mx-5">
                  <FormGroup row>
                    <Label sm={3}>
                      <b>SKU</b>
                    </Label>
                    <Cols sm={9}>
                      <div style={{ marginTop: "6px" }}>
                        {handleChangeSKUNumber(
                          variantId,
                          currentSKU,
                          storeId,
                          rowId
                        )}
                      </div>
                    </Cols>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={3}>
                      <b>Product</b>
                    </Label>
                    <Cols sm={9}>
                      <div style={{ marginTop: "6px", marginBottom: "6px" }}>
                        {currentProduct}
                      </div>
                    </Cols>
                  </FormGroup>
                  {otherStores.length > 0
                    ? otherStores.map((store) => (
                        <FormGroup row>
                          <Label sm={3} style={{ marginTop: "5px" }}>
                            <b>{store.shopDetail.name}</b>
                          </Label>
                          <Cols sm={8}>
                            <div style={{ display: "inline" }}>
                              <Select
                                isDisabled={
                                  `${rowId}/${store._id}` !== dropdown
                                }
                                defaultValue={{
                                  label:
                                    previouslyMappedValues[
                                      `${masterId}/${store._id}`
                                    ] !== undefined
                                      ? previouslyMappedValues[
                                          `${masterId}/${store._id}`
                                        ].label
                                      : "SKU/Product",
                                  value:
                                    previouslyMappedValues[
                                      `${masterId}/${store._id}`
                                    ] !== undefined
                                      ? previouslyMappedValues[
                                          `${masterId}/${store._id}`
                                        ].label
                                      : "SKU/Product",
                                }}
                                placeholder="SKU/Product"
                                options={
                                  otherStoreProducts[store._id] &&
                                  otherStoreProducts[store._id].filter(
                                    (product) => product.value.masterId === ""
                                  )
                                }
                                onChange={(e) =>
                                  handleProductsList(
                                    e,
                                    store._id,
                                    rowId,
                                    variantId,
                                    masterId
                                  )
                                }
                                isClearable={
                                  previouslyMappedValues[
                                    `${masterId}/${store._id}`
                                  ] !== undefined
                                }
                              />
                            </div>
                          </Cols>
                          <Cols sm={1}>
                            <span
                              className={
                                rowId + "/" + store._id !== dropdown
                                  ? "simple-icon-pencil fw-bold"
                                  : rowId + "/" + store._id === dropdown
                                  ? "far fa-check text-success fs-5"
                                  : ""
                              }
                              onClick={() => {
                                if (dropdown === `${rowId}/${store._id}`)
                                  setDropdown("");
                                else setDropdown(`${rowId}/${store._id}`);
                              }}
                              style={{
                                marginTop: "10px",
                                fontSize: "18px",
                                float: "right",
                                cursor: "pointer",
                              }}
                            ></span>
                          </Cols>
                        </FormGroup>
                      ))
                    : ""}
                </Form>
              </div>
            </ModalBody>
            <ModalFooter className="justify-content-center">
              <Button
                className="mr-2 mt-2"
                color="primary"
                onClick={() => handleSKUMappingUpdate(mappedData, unMappedData)}
                style={{ float: "right" }}
                disabled={!updateBtn}
              >
                Update
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </main>
    </>
  );
};

export default SKUMapping;

import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  Card,
  Breadcrumb,
  Button,
  Row,
  Input,
  CardBody,
} from "reactstrap";
import { ProductDetails } from "./ProductDetails.jsx";
import { Cols } from "../SeparatorStyle/SeparatorStyle";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import AddNewModal from "../Modals/SideBarModel.jsx";
import InventoryImport from "./InventoryImport.jsx";
import Swal from "sweetalert2";
import {
  updateStock,
  getAllStoresLoginUser,
  getMasterStoreVariants,
  getVariantsForInventoryUpdate,
} from "../../api";
import { motion } from "framer-motion";
import { leftAnimate } from "../FramerMotion/FramerMotion.jsx";
import TableSkeleton from "../UI/TableSkeleton.jsx";
const InventoryUpdate = () => {
  const history = useHistory();
  // const [stores, setStores] = useState([]);
  const [masterStore, setMasterStore] = useState("");
  const [otherStores, setOtherStores] = useState([]);
  const [masterStoreVariants, setMasterStoreVariants] = useState([]);
  const [otherStoreProducts, setOtherStoreProducts] = useState({});
  const [productDetails, setProductDetails] = useState();
  const [quantityData, setQuantityData] = useState([]);
  const [mergeCheck, setMergeCheck] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedFileForImport, setSelectedFileForImport] = useState(null);
  const [fileImportProcessing, setFileImportProcessing] = useState(false);
  const [uploadedFileData, setUploadedFileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const toggleModal = () => {
    setShowModal(!showModal);
    setSelectedFileForImport(null);
  };

  let masterStoreName = "";
  let searchCount = 1;
  let storesCount = 1;
  let count = 0;

  const loadMyStores = useCallback(async () => {
    try {
      const { data } = await getAllStoresLoginUser();
      // setStores(data[0].stores);
      const stores = data[0].stores;
      data[0].stores.length < 2 && setLoading(true);
      let masterStore = stores.filter((s) => s.isMasterStore);
      if (masterStore.length > 0) {
        masterStoreName = masterStore[0].shopDetail.name;
        setMasterStore(masterStoreName);
        let others = stores.filter((store) => !store.isMasterStore);
        let masterVariants = await loadVariantsforMasterStore(
          masterStore[0]._id
        );
        let storeAndMasterIds = [];
        for (let i = 0; i < masterVariants.length; i++) {
          for (let j = 0; j < others.length; j++) {
            storeAndMasterIds.push({
              storeId: others[j]._id,
              masterId: masterVariants[i].masterId,
            });
          }
        }
        loadVariantsforOtherStore(storeAndMasterIds);
        setOtherStores(others);
      }
    } catch (err) {
      toast.error("Stores data loading error!");
    }
  }, []);

  useEffect(() => {
    loadMyStores();
  }, []);

  const loadVariantsforMasterStore = async (id) => {
    try {
      const { data } = await getMasterStoreVariants(id);
      let variant = data.filter((s) => s.masterId !== "");
      setMasterStoreVariants(variant);
      return variant;
    } catch (err) {
      toast.error("Error in Loading Master Products!");
    }
  };

  const loadVariantsforOtherStore = async (storeAndMasterIds) => {
    try {
      const { data } = await getVariantsForInventoryUpdate(storeAndMasterIds);
      let prevData = {};
      for (const value of data) {
        let storeId = value.storeId;
        if (prevData[storeId]) {
          prevData[storeId] = [...prevData[storeId], value];
        } else {
          prevData[storeId] = [value];
        }
      }
      setOtherStoreProducts(prevData);
    } catch (err) {
      toast.error("Error in Loading Other Stores Products!");
    }
  };

  const handleQuantityList = async (e, data, rowId) => {
    let variantMasterId = data.masterId;
    let variantStoreId = data.storeId;
    let quantityValue = e.target.value;
    let mappedVariant = [];

    for (let storeId in otherStoreProducts) {
      let otherStoreVariant = otherStoreProducts[storeId].find(
        (p) => p.masterId === variantMasterId
      );
      if (otherStoreVariant) {
        mappedVariant.push({
          storeId: otherStoreVariant.storeId,
          inventoryItems: [
            {
              inventoryItemId: otherStoreVariant.shopifyInventoryItemId,
              locationId: otherStoreVariant?.shopifyLocationId[0]
                ? otherStoreVariant.shopifyLocationId[0]
                : "",
              available: quantityValue,
            },
          ],
        });
      }
    }

    let existingStoreData = quantityData.find(
      (s) => s.storeId === variantStoreId
    );
    if (existingStoreData) {
      let item = existingStoreData.inventoryItems.find(
        (j) => j.inventoryItemId === rowId
      );
      if (item) {
        item.available = quantityValue;
      } else {
        existingStoreData.inventoryItems.push({
          inventoryItemId: data.shopifyInventoryItemId,
          locationId: data?.inventoryLevel[0]?.shopifyLocationId
            ? data.inventoryLevel[0].shopifyLocationId
            : "",
          available: quantityValue,
        });
      }
    } else {
      setMergeCheck(true);
      existingStoreData = {
        storeId: data.storeId,
        inventoryItems: [
          {
            inventoryItemId: data.shopifyInventoryItemId,
            locationId: data?.inventoryLevel[0]?.shopifyLocationId
              ? data.inventoryLevel[0].shopifyLocationId
              : "",
            available: quantityValue,
          },
        ],
      };
    }

    if (mergeCheck) {
      let updatedQuantity = quantityData.filter(
        (q) => q.storeId !== existingStoreData.storeId
      );
      updatedQuantity.push(existingStoreData);

      for (let i = 0; i < mappedVariant.length; i++) {
        let existingMappedStore = updatedQuantity.find(
          (p) => p.storeId === mappedVariant[i].storeId
        );
        if (existingMappedStore) {
          let item = existingMappedStore.inventoryItems.find((existingItem) =>
            mappedVariant[i].inventoryItems.some(
              (currentItem) =>
                existingItem.inventoryItemId === currentItem.inventoryItemId
            )
          );
          if (item) {
            item.available = quantityValue;
          } else {
            existingMappedStore.inventoryItems.push(
              ...mappedVariant[i].inventoryItems
            );
          }
        } else {
          updatedQuantity.push(mappedVariant[i]);
        }
      }
      setQuantityData(updatedQuantity);
    } else {
      setQuantityData([existingStoreData, ...mappedVariant]);
    }
  };

  const handleStockUpdate = async (quantity) => {
    try {
      const { data, status } = await updateStock(quantity);
      if (status === 200) {
        setQuantityData([]);
        toast.success("Inventory Updated Successfully!");
      }
    } catch (err) {
      toast.error("Error in Quantity Updation!");
    }
  };

  const handleProductDetails = (item) => {
    let orderItems = item.slice(0, 30);
    let itemsLength = item.length;
    return (
      <>
        <div>
          {`${orderItems}${itemsLength > 20 ? "...." : ""}`}
          {itemsLength > 20 ? (
            <i
              style={{ cursor: "pointer" }}
              className="p-2 simple-icon-info "
              id="productDetailPopover"
              onMouseEnter={() => {
                setProductDetails([item]);
              }}
            />
          ) : (
            ""
          )}
          <ProductDetails productDetails={productDetails && productDetails} />
        </div>
      </>
    );
  };

  const handleFileImport = (e) => {
    setFileImportProcessing(true);
    if (!selectedFileForImport) {
      toast.warning("No file is selected!");
      setFileImportProcessing(false);
      return;
    }
    if (!uploadedFileData || uploadedFileData.length === 0) {
      toast.warning("Selected file is empty!");
      setFileImportProcessing(false);
      return;
    }
    const columnsOfInventory = {
      MasterSKU: "MasterSKU",
      Quantity: "Quantity",
    };
  };
  useEffect(() => {
    loading &&
      Swal.fire({
        allowOutsideClick: false,
        title: "You must have atleast 2 stores!",
        html: "<span class='text-black'>Please add stores to use this feature!</span>",
        color: "#f8bb86",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#037310",
        // cancelButtonColor: "#D3D3D3",
        confirmButtonText: "Add Store",
        customClass: {
          cancelButton: "rounded btn btn-sm",
          confirmButton: "rounded",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          return history.replace("/admin/stores");
        } else {
          return history.replace("/admin/dashboard");
        }
      });
  }, [loading]);
  return (
    <>
      <main>
        <Row className=" top-text">
          <Cols xxs="12">
            <div className="d-flex justify-content-between">
              <motion.div
                variants={leftAnimate}
                initial="hidden"
                animate="visible"
              >
                <h1>Inventory Update</h1>
                <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
                  <ol className="breadcrumb pt-0">
                    <li className="breadcrumb-item">
                      <Link to="/admin/dashboard" style={{ color: "black" }}>
                        Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Inventory Update
                    </li>
                  </ol>
                </Breadcrumb>
              </motion.div>
              <motion.div
              // variants={rightAnimate}
              // initial="hidden"
              // animate="visible"
              >
                {otherStores.length === 0 ? (
                  <div className="skeleton skeleton-button"></div>
                ) : (
                  <Button
                    color="primary"
                    className="mr-3"
                    style={{ float: "right", display: "inline" }}
                    disabled={quantityData.length === 0}
                    hidden={!otherStores.length > 0}
                    onClick={() => handleStockUpdate(quantityData)}
                  >
                    Update
                  </Button>
                )}
              </motion.div>
            </div>
          </Cols>
        </Row>

        {masterStore.length === 0 ? (
          <Card>
            <CardBody>
              <TableSkeleton
                skeletonLength={10}
                itemsBar={2}
                inventory={true}
              />
            </CardBody>
          </Card>
        ) : (
          <>
            {otherStores.length === 0 ? (
              <Card>
                <CardBody>
                  <TableSkeleton
                    skeletonLength={10}
                    itemsBar={2}
                    inventory={true}
                  />
                </CardBody>
              </Card>
            ) : (
              <motion.div
                className="card mb-3 marginL-md me-sm-0 me-3"
                // variants={rightAnimate}
                // initial="hidden"
                // animate="visible"
              >
                <CardBody>
                  <Row>
                    <Cols>
                      <div className="container-fluid">
                        <div className="d-flex justify-content-between">
                          <Input
                            type="text"
                            className="w-20"
                            name="keyword"
                            id="search"
                            placeholder="Search in Master Store's SKU"
                          />

                          {otherStores.length > 0
                            ? otherStores.map((store, index) => (
                                <Input
                                  key={index}
                                  type="text"
                                  className="w-20"
                                  name="keyword"
                                  id="search"
                                  placeholder={`Search in Store ${(searchCount += 1)}`}
                                />
                              ))
                            : ""}
                        </div>
                        <Table
                          striped
                          borderless
                          hover
                          className=" table-responsive-lg"
                        >
                          <thead>
                            <tr>
                              <th></th>
                              <th colSpan={"3"} className="text-center">
                                Master Store's SKU {`(${masterStore})`}
                              </th>
                              {otherStores.length > 0
                                ? otherStores.map((store) => (
                                    <th>{`Store ${(storesCount += 1)}`}</th>
                                  ))
                                : ""}
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <th>No.</th>
                              <th>SKU Number</th>
                              <th>Product Name</th>
                              <th>Quantity</th>
                              {otherStores.length > 0
                                ? otherStores.map((store) => (
                                    <th>{store.shopDetail.name}</th>
                                  ))
                                : ""}
                            </tr>
                            {masterStoreVariants.length > 0 ? (
                              masterStoreVariants.map((p) => (
                                <tr>
                                  <td>{(count += 1)}</td>
                                  <td>{p.sku ? p.sku : "N/A"}</td>
                                  <td> {p.productMain[0].title}</td>
                                  <td>
                                    <Input
                                      type="number"
                                      min={0}
                                      max={99999}
                                      onChange={(e) =>
                                        handleQuantityList(
                                          e,
                                          p,
                                          p.shopifyInventoryItemId
                                        )
                                      }
                                      defaultValue={
                                        p?.inventoryLevel[0]?.available
                                          ? p.inventoryLevel[0].available
                                          : ""
                                      }
                                      className="w-80 mx-auto text-center"
                                    />
                                  </td>
                                  {otherStores.map((store) => (
                                    <td>
                                      {Object.keys(otherStoreProducts).length >
                                        0 &&
                                        otherStoreProducts[store._id] &&
                                        otherStoreProducts[store._id].map(
                                          (variant) => (
                                            <Row>
                                              <Cols>
                                                {p.masterId ===
                                                  variant.masterId && (
                                                  <div
                                                    style={{
                                                      display: "inline",
                                                    }}
                                                  >
                                                    {handleProductDetails(
                                                      variant.productMain[0]
                                                        .title
                                                    )}
                                                  </div>
                                                )}
                                              </Cols>
                                            </Row>
                                          )
                                        )}
                                    </td>
                                  ))}
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan={12}
                                  style={{
                                    height: "150px",
                                    padding: "40px 50px",
                                  }}
                                >
                                  <div className="">
                                    <h2
                                      style={{
                                        color: "red",
                                        height: "50px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      No products to show
                                    </h2>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </Cols>
                  </Row>
                </CardBody>
              </motion.div>
            )}
            <div>
              {otherStores.length === 0 ? (
                <div className="skeleton skeleton-button"></div>
              ) : (
                <Button
                  color="primary"
                  className="mr-2 mt-2"
                  style={{ float: "right" }}
                  disabled={quantityData.length === 0}
                  hidden={!otherStores.length > 0}
                  onClick={() => handleStockUpdate(quantityData)}
                >
                  Update
                </Button>
              )}
            </div>
          </>
        )}

        {/* Import Inventory Modal */}

        <AddNewModal
          modalOpen={showModal}
          toggleModal={toggleModal}
          body={
            <InventoryImport
              setSelectedFileForImport={setSelectedFileForImport}
              setUploadedFileData={setUploadedFileData}
              includeFooter={false}
              primaryBtnText="Submit"
              secondaryBtnText="Cancel"
              primaryBtnWithSpinner={true}
              isProcessing={fileImportProcessing}
              primaryBtnHandler={handleFileImport}
              secondaryBtnHandler={toggleModal}
            />
          }
          includeFooter={true}
          primaryBtnText="Submit"
          secondaryBtnText="Cancel"
          primaryBtnWithSpinner={true}
          isProcessing={fileImportProcessing}
          primaryBtnHandler={handleFileImport}
          secondaryBtnHandler={toggleModal}
        />

        {/* My Design */}
        {/* <div>
                    <Modal
                        isOpen={modal}
                        toggle={() => setModal(false)}
                        centered
                    >
                        <ModalHeader className='justify-content-center' style={{ display: 'inline' }}>
                            <h2><b>Please Select File to Import</b>
                                <span
                                    class="far fa-close"
                                    onClick={modalToggle}
                                    value={selectedFile}
                                    onChange={(e) => handleFileChange(e)}
                                    style={{ float: 'right', cursor: 'pointer' }}
                                >
                                   
                                </span>
                            </h2>
                        </ModalHeader>
                        <ModalBody>
                            <div className='text-center'>
                                <Input
                                    id="exampleFile"
                                    name="file"
                                    type="file"
                                    accept=".xls,.xlsx,.csv"
                                    className="form-control w-60 mx-auto mb-2 text-center"
                                    style={{ fontWeight: 'bold', cursor: 'pointer' }}
                                />
                            </div>
                        </ModalBody>
                        <ModalFooter className='justify-content-center'>
                            <Button
                                color="primary"
                                onClick={modalToggle}
                                className='mx-2'
                            >
                                Submit
                            </Button>
                            <Button
                                color="danger"
                                onClick={modalToggle}
                                className='mx-2'
                            >
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Modal>
                </div> */}
      </main>
    </>
  );
};

export default InventoryUpdate;

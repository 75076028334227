import React from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  CardTitle,
  Button,
  CardText,
  Form,
} from "reactstrap";
import { Cols } from "../SeparatorStyle/SeparatorStyle";

const CustomerService = () => {
  const history = useHistory();

  return (
    <>
      <main>
        <Row>
          <Cols xxs="12">
            <div className=" mb-3 d-flex justify-content-between">
              <div className="d-flex">
                <i
                  onClick={() => history.goBack()}
                  className="simple-icon-arrow-left-circle mr-3 fs-3 fw-bold pointer"
                ></i>
                <h2 className="m-0">
                  <b>Customer Service</b>
                </h2>
              </div>
            </div>
          </Cols>
        </Row>
        <br />
        <h5 className="ml-3 mt-1 mb-3">
          <b>Store owner details</b>
        </h5>
        <br />
        <Form>
          <Row>
            <Cols xxs="12" className="row icon-cards-row mb-2">
              <Cols className="mb-4">
                <Card>
                  <CardBody className="m-2">
                    <CardTitle tag="h5">
                      <b>Name</b>
                    </CardTitle>
                    <CardText>
                      <b>Customer Service</b>
                    </CardText>
                    <hr />
                    <CardTitle tag="h5">
                      <b>Email</b>
                    </CardTitle>
                    <CardText>
                      <b>pk.Etijarat@gmail.com</b>
                    </CardText>
                  </CardBody>
                </Card>
              </Cols>
            </Cols>
          </Row>

          <Button className="float-right mr-4" color="secondary" outline>
            Save
          </Button>
        </Form>
      </main>
    </>
  );
};

export default CustomerService;

import axios from "axios";
import { getAPIURL } from "./utils";
import { getCookie } from "../helpers/cookie";
import { toast } from "react-toastify";

const url = getAPIURL();

// Getting all variants
export const getVariants = async ({ searchSKU, page, limit }) => {
  const token = getCookie();

  const params = new URLSearchParams();
  params.append("search", searchSKU?.toString().trim());
  params.append("limit", limit?.toString());
  params.append("page", page?.toString());

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params,
  };

  let response = await axios.get(`${url}/api/variants`, config);

  return response;
};

// Getting Variant by Shopify variant Id
export const getVariantDetails = async (shopifyVariantId) => {
  const token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  let response;
  try {
    response = await axios.get(
      `${url}/api/products/getVariantDetails/${shopifyVariantId}`,
      config
    );
  } catch (e) {
    response = e;
  }

  return response;
};

// Adding Variant by User Submit Data
export const variantAdd = async (
  option1,
  option2,
  option3,
  price,
  compare_at_price,
  sku,
  barcode,
  weight,
  weight_unit,
  storeId,
  shopifyProductId,
  noOfOptions
) => {
  const token = getCookie();
  const data = {
    option1,
    option2,
    option3,
    price,
    compare_at_price,
    sku,
    barcode,
    weight,
    weight_unit,
    storeId,
    shopifyProductId,
    noOfOptions,
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  let response;
  try {
    response = await axios.post(
      `${url}/api/products/addNewVariant`,
      data,
      config
    );
  } catch (e) {
    response = e;
    toast.response();
  }

  return response;
};

// Variant deleted by taking storeId, shopifyProductId, shopifyVariantId
export const variantDelete = async (
  storeId,
  shopifyProductId,
  shopifyVariantId
) => {
  const token = getCookie();
  const data = { storeId, shopifyProductId, shopifyVariantId };
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  let response;
  try {
    response = await axios.post(
      `${url}/api/products/deleteVariant`,
      data,
      config
    );
  } catch (e) {
    response = e;
    toast.response();
  }
  return response;
};

//Updating Variant by User Submit Data
export const VariantUpdate = async (
  title,
  option1,
  option2,
  option3,
  price,
  compare_at_price,
  sku,
  barcode,
  weight,
  weight_unit,
  storeId,
  shopifyProductId,
  shopifyVariantId,
  noOfOptions
) => {
  const token = getCookie();
  const data = {
    title,
    option1,
    option2,
    option3,
    price,
    compare_at_price,
    sku,
    barcode,
    weight,
    weight_unit,
    storeId,
    shopifyProductId,
    shopifyVariantId,
    noOfOptions,
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  let response;
  try {
    response = await axios.put(
      `${url}/api/products/updateVariantDetails`,
      data,
      config
    );
  } catch (e) {
    response = e;
    toast.response();
  }

  return response;
};

// Uploading Image by taking image file
export const variantImageUpload = async (
  file,
  storeId,
  shopifyProductId,
  shopifyVariantId
) => {
  const data = new FormData();
  data.append("file", file);
  data.append("storeId", storeId);
  data.append("shopifyProductId", shopifyProductId);
  data.append("shopifyVariantId", shopifyVariantId);
  const token = getCookie();
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  let response;
  try {
    response = await axios.post(
      `${url}/api/products/uploadVariantImageToShopify`,
      data,
      config
    );
  } catch (e) {
    response = e;
    toast.response();
  }
  return response;
};

// Deleting Image by taking storeId, shopifyProductId, imageId
export const variantImageDelete = async (
  shopifyProductId,
  shopifyVariantId,
  storeId,
  imageId
) => {
  const data = { shopifyProductId, shopifyVariantId, storeId, imageId };
  const token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",

      Authorization: `Bearer ${token}`,
    },
  };
  let response;
  try {
    response = await axios.post(
      `${url}/api/products/deleteImageFromShopify`,
      data,
      config
    );
  } catch (e) {
    response = e;
    toast.response();
  }
  return response;
};

export const toPostExFormat = (orders) => {
  const csvData = [];
  for (const order of orders) {
    let description = "";
    let total_items = 0;
    order.line_items.forEach((item) => {
      description = description.concat(`${item.sku}( ${item.quantity}) `);
      total_items += Number(item.quantity);
      return item;
    });
    csvData.push({
      "Order Reference Number": order.name ? order.name : "N/A",
      "Order Amount": order.total_price_set.shop_money.amount
        ? order.total_price_set.shop_money.amount
        : "N/A",
      "Order Detail": description ? description : "N/A",
      "Customer Name": order.shipping_address?.name
        ? order.shipping_address?.name
        : "N/A",
      "Customer Phone": order.shipping_address?.phone
        ? order.shipping_address?.phone
        : "N/A",
      "Order Address": order.shipping_address?.address1
        ? order.shipping_address?.address1
        : "N/A",
      City: order.shipping_address?.city ? order.shipping_address?.city : "N/A",
      Items: total_items,
      "Airway Bill Copies": 1,
      Notes: order?.note || "",
      "Address Code": "",
      "Order Type (Normal/Reversed/Replacement/Overland)":
        order?.shipmentType || "",
      "Booking Weight": Number(order?.total_weight) / 1000,
    });
  }

  return csvData;
};

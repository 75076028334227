import React, { useEffect, useState } from "react";
import { getVariantDetails, VariantUpdate } from "../../api/variant";
import VariantDetail from "./VariantDetail.jsx";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

function VariantEdit() {
  const { proId: shopifyProductId } = useParams();
  const { varId: shopifyVariantId } = useParams();
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState({});
  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const [newVariantDetail, setNewVariantDetail] = useState({
    title: "",
    option1: "",
    option2: "",
    option3: "",
    imageList: "",
    price: "",
    comparePrice: "",
    chargeTax: false,
    costPerItem: "",
    sku: "",
    barcode: "",
    trackQuantity: false,
    outOfStock: false,
    quantity: [],
    physicalProduct: false,
    shippingRate: { weight: "", unit: "kg" },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: dataVariant } = await getVariantDetails(shopifyVariantId);

        setLoading(false);
        setNewVariantDetail({
          title: dataVariant.title,
          option1: dataVariant.option1,
          option2: dataVariant.option2,
          option3: dataVariant.option3,
          imageList: "",
          price: dataVariant.price,
          comparePrice: dataVariant.compare_at_price,
          chargeTax: false,
          costPerItem: "",
          sku: dataVariant.sku,
          barcode: dataVariant.barcode,
          trackQuantity: false,
          outOfStock: false,
          quantity: [],
          physicalProduct: false,
          shippingRate: {
            weight: dataVariant.weight,
            unit: dataVariant.weight_unit,
          },
        });
        const { option1, option2, option3 } = dataVariant;
        setOptions({ option1, option2, option3 });
      } catch (error) {
        toast.error(error);
      }
    };
    fetchData();
  }, [shopifyVariantId]);

  const updateVariant = async (
    e,
    reRender,
    setReRender,
    noOfOptions,
    newVariantDetail,
    shopifyProductId,
    storeId,
    shopifyVariantId
  ) => {
    e.preventDefault();
    setLoading(true);
    try {
      const {
        title,
        option1,
        option2,
        option3,
        price,
        comparePrice,
        sku,
        barcode,
        shippingRate: { weight, unit },
      } = newVariantDetail;

      const {
        data: { variant },
      } = await VariantUpdate(
        title,
        option1,
        option2,
        option3,
        Number(price),
        Number(comparePrice),
        sku,
        barcode,
        Number(weight),
        unit,
        storeId,
        shopifyProductId,
        shopifyVariantId,
        noOfOptions
      );

      setNewVariantDetail({
        title: variant.title,
        option1: variant.option1,
        option2: variant.option2,
        option3: variant.option3,
        imageList: "",
        price: variant.price,
        comparePrice: variant.compare_at_price,
        chargeTax: false,
        costPerItem: "",
        sku: variant.sku,
        barcode: variant.barcode,
        trackQuantity: false,
        outOfStock: false,
        quantity: [],
        physicalProduct: false,
        shippingRate: { weight: variant.weight, unit: variant.weight_unit },
      });
      setReRender(!reRender);
      setTimeout(() => {
        setLoading(false);
        toast.success("Variant update successfully!");
      }, 4000);
      handleScrollToTop();
    } catch (error) {
      toast.error("Error! Variant not updated");
    }
  };
  return (
    <>
      {loading ? (
        <div className="loading" />
      ) : (
        <VariantDetail
          options={options}
          setOptions={setOptions}
          variantUpdated="variantUpdated"
          updateVariant={updateVariant}
          newVariantDetail={newVariantDetail}
          setNewVariantDetail={setNewVariantDetail}
          shopifyProductId={shopifyProductId}
          shopifyVariantId={shopifyVariantId}
        />
      )}
    </>
  );
}

export default VariantEdit;

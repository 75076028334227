import { CopyToClipboard } from "react-copy-to-clipboard";
import { BsWhatsapp } from "react-icons/bs";
import { MdContentCopy } from "react-icons/md";
import { PopoverBody, UncontrolledPopover } from "reactstrap";
import { popOverContent } from "../popOvers/popOver";
import { toast } from "react-toastify";

export const formatPhoneNumber = (phoneNumber, includeCountryCode = false) => {
  let phoneNum = phoneNumber || "";
  if (phoneNum) {
    phoneNum = String(phoneNum).trim();
    if (!includeCountryCode && phoneNumber.startsWith("92")) {
      phoneNum = `0${phoneNumber.substring(2)} `; // ex: 923331112227 => 03331112227
    }
    // if country code should be added
    if (includeCountryCode && phoneNumber.startsWith("92")) {
      phoneNum = `${phoneNumber} `; // ex: 923331112227 => +923331112227
    }
  }
  // if country code should not be added

  return phoneNum;
};

const formatPhoneNumberToPK = (str) => {
  str = String(str);
  // Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");

  // Check if the input is of correct length
  let match = cleaned.slice(cleaned.indexOf("3"));

  if (match && match.length === 10) {
    // Change this to format for any country code.
    return "+92" + match;
  }

  return null;
};

export const formatPhoneNumberWithButtons = (
  phoneNumber,
  userName,
  orderNumber,
  storeName,
  tooltipMsg,
  setTooltipMsg,
  index,
  accountWhtsapMsgs,
  storeId,
  setOrderMsg,
  orderMsg
) => {
  let phoneNum = formatPhoneNumber(phoneNumber, true);

  const formatMsg = (orderMsg?.message || "")
    ?.replace("[Name]", userName)
    ?.replace("[ID]", (String(orderNumber) || "N/A")?.replace("#", ""))
    ?.replace("[Store domain]", storeName || "our store");

  return (
    <>
      <div className="d-flex">
        <UncontrolledPopover
          target={"showCopyTextTooltip" + index}
          placement="top"
          trigger="hover"
        >
          <PopoverBody>{tooltipMsg}</PopoverBody>
        </UncontrolledPopover>

        {popOverContent(`Phone-${index}`, phoneNum)}
        <br />
        <CopyToClipboard text={phoneNum}>
          <MdContentCopy
            onMouseLeave={() => setTooltipMsg("Copy!")}
            onClick={() => setTooltipMsg("Copied!")}
            className="fs-6 pointer text-muted ml-2"
            id={"showCopyTextTooltip" + index}
          />
        </CopyToClipboard>
        {formatPhoneNumberToPK(phoneNumber) ? (
          <a
            onClick={() => {
              accountWhtsapMsgs.filter((obj) => {
                if (obj.storeId === storeId) {
                  setOrderMsg({
                    ...orderMsg,
                    message: obj.ordersMessage,
                    openIn: obj.openWhatsappIn,
                  });
                }
                return obj;
              });
            }}
            className=" text-success ml-2 pointer"
            style={{
              fontSize: "15px",
            }}
            target="_blank"
            rel="noreferrer"
            href={
              orderMsg.openIn === "web"
                ? `https://web.whatsapp.com/send?phone=${formatPhoneNumberToPK(
                    phoneNumber
                  )}&text=${formatMsg}`
                : `https://wa.me/${formatPhoneNumberToPK(
                    phoneNumber
                  )}?text=${formatMsg}`
            }
          >
            <BsWhatsapp />
          </a>
        ) : (
          <span
            className="pointer text-danger ml-2 simple-icon-info"
            style={{
              fontSize: "14px",
            }}
            onClick={() => {
              toast.warn(`${phoneNumber} is incorrect!`);
            }}
          ></span>
        )}
      </div>
    </>
  );
};

export const handleSearch = ({
  e,
  setPageNumber,
  setPageLimit,
  setFilterLoading,
  setSearch,
  setSearchTrigger,
  searchTrigger,
}) => {
  let searchValue = e.target.value;

  setSearch(searchValue);
  const timeOutId = setTimeout(async () => {
    if (searchValue === e.target.value && searchValue.trim().length > 2) {
      setPageNumber(1);
      setPageLimit(50);
      setFilterLoading(true);
      setSearchTrigger(!searchTrigger);
    }
    if (!searchValue && searchValue === e.target.value) {
      setPageNumber(1);
      setPageLimit(50);
      setFilterLoading(true);
      setSearchTrigger(!searchTrigger);
    }
  }, 2000);
  return () => clearTimeout(timeOutId);
};

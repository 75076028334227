import {
  ALL_ORDERS_REQUEST,
  ALL_ORDERS_SUCCESS,
  ALL_ORDERS_FAIL,
  THIRTY_ORDERS_REQUEST,
  THIRTY_ORDERS_SUCCESS,
  THIRTY_ORDERS_FAIL,
  FIFTEEN_ORDERS_REQUEST,
  FIFTEEN_ORDERS_SUCCESS,
  FIFTEEN_ORDERS_FAIL,
  LASTWEEK_ORDERS_REQUEST,
  LASTWEEK_ORDERS_SUCCESS,
  LASTWEEK_ORDERS_FAIL,
  TODAY_ORDERS_REQUEST,
  TODAY_ORDERS_SUCCESS,
  TODAY_ORDERS_FAIL,
  REFUNDED_ORDERS_REQUEST,
  REFUNDED_ORDERS_SUCCESS,
  REFUNDED_ORDERS_FAIL,
  FULFILLED_ORDERS_REQUEST,
  FULFILLED_ORDERS_SUCCESS,
  FULFILLED_ORDERS_FAIL,
  UNFULFILLED_ORDERS_REQUEST,
  UNFULFILLED_ORDERS_SUCCESS,
  UNFULFILLED_ORDERS_FAIL,
  OPEN_ORDERS_REQUEST,
  OPEN_ORDERS_SUCCESS,
  OPEN_ORDERS_FAIL,
  CLOSED_ORDERS_REQUEST,
  CLOSED_ORDERS_SUCCESS,
  CLOSED_ORDERS_FAIL,
  PENDING_ORDERS_REQUEST,
  PENDING_ORDERS_SUCCESS,
  PENDING_ORDERS_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_UNFULFILLED_ITEMS,
  ORDER_DETAILS_FULFILLED_ITEMS,
} from "../constants/orderConstants";

export const orderDetailsReducer = (state = { order: {} }, action) => {
  switch (action.type) {
    case ORDER_DETAILS_REQUEST:
      return { loading: true, order: {} };
    case ORDER_DETAILS_SUCCESS:
      return { loading: false, order: action.payload };
    case ORDER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_DETAILS_UNFULFILLED_ITEMS:
      return { ...state, unFulfilledItems: action.payload };
    case ORDER_DETAILS_FULFILLED_ITEMS:
      return { ...state, fulfilledItems: action.payload };
    default:
      return state;
  }
};

export const allOrdersReducer = (
  state = {
    allOrders: [],
    thirtyDaysOrders: [],
    fifteenDaysOrders: [],
    lastWeekDaysOrders: [],
    todayOrders: [],
    refundedOrders: [],
    pendingOrders: [],
    fulfilledOrders: [],
    openOrders: [],
    closedOrders: [],
    unfulfilledOrders: [],
  },
  action
) => {
  switch (action.type) {
    case ALL_ORDERS_REQUEST:
      return { ...state, allOrdersLoading: true, allOrders: [] };
    case ALL_ORDERS_SUCCESS:
      return { ...state, allOrdersLoading: false, allOrders: action.payload };
    case ALL_ORDERS_FAIL:
      return {
        ...state,
        allOrdersLoading: false,
        allOrdersError: action.payload,
      };

    case THIRTY_ORDERS_REQUEST:
      return { ...state, thirtyDaysOrdersLoading: true, thirtyDaysOrders: [] };
    case THIRTY_ORDERS_SUCCESS:
      return {
        ...state,
        thirtyDaysOrdersLoading: false,
        thirtyDaysOrders: action.payload,
      };
    case THIRTY_ORDERS_FAIL:
      return {
        ...state,
        thirtyDaysOrdersLoading: false,
        thirtyDaysOrdersError: action.payload,
      };

    case FIFTEEN_ORDERS_REQUEST:
      return {
        ...state,
        fifteenDaysOrdersLoading: true,
        fifteenDaysOrders: [],
      };
    case FIFTEEN_ORDERS_SUCCESS:
      return {
        ...state,
        fifteenDaysOrdersLoading: false,
        fifteenDaysOrders: action.payload,
      };
    case FIFTEEN_ORDERS_FAIL:
      return {
        ...state,
        fifteenDaysOrdersLoading: false,
        fifteenDaysOrdersError: action.payload,
      };

    case LASTWEEK_ORDERS_REQUEST:
      return {
        ...state,
        lastWeekDaysOrdersLoading: true,
        lastWeekDaysOrders: [],
      };
    case LASTWEEK_ORDERS_SUCCESS:
      return {
        ...state,
        lastWeekDaysOrdersLoading: false,
        lastWeekDaysOrders: action.payload,
      };
    case LASTWEEK_ORDERS_FAIL:
      return {
        ...state,
        lastWeekDaysOrdersLoading: false,
        lastWeekDaysOrdersError: action.payload,
      };

    case TODAY_ORDERS_REQUEST:
      return { ...state, todayOrdersLoading: true, todayOrders: [] };
    case TODAY_ORDERS_SUCCESS:
      return {
        ...state,
        todayOrdersLoading: false,
        todayOrders: action.payload,
      };
    case TODAY_ORDERS_FAIL:
      return {
        ...state,
        todayOrdersLoading: false,
        todayOrdersError: action.payload,
      };

    case REFUNDED_ORDERS_REQUEST:
      return { ...state, refundedOrdersLoading: true, refundedOrders: [] };
    case REFUNDED_ORDERS_SUCCESS:
      return {
        ...state,
        refundedOrdersLoading: false,
        refundedOrders: action.payload,
      };
    case REFUNDED_ORDERS_FAIL:
      return {
        ...state,
        refundedOrdersLoading: false,
        refundedOrdersError: action.payload,
      };

    case FULFILLED_ORDERS_REQUEST:
      return { ...state, fulfilledOrdersLoading: true, fulfilledOrders: [] };
    case FULFILLED_ORDERS_SUCCESS:
      return {
        ...state,
        fulfilledOrdersLoading: false,
        fulfilledOrders: action.payload,
      };
    case FULFILLED_ORDERS_FAIL:
      return {
        ...state,
        fulfilledOrdersLoading: false,
        fulfilledOrdersError: action.payload,
      };

    case UNFULFILLED_ORDERS_REQUEST:
      return {
        ...state,
        unfulfilledOrdersLoading: true,
        unfulfilledOrders: [],
      };
    case UNFULFILLED_ORDERS_SUCCESS:
      return {
        ...state,
        unfulfilledOrdersLoading: false,
        unfulfilledOrders: action.payload,
      };
    case UNFULFILLED_ORDERS_FAIL:
      return {
        ...state,
        unfulfilledOrdersLoading: false,
        unfulfilledOrdersError: action.payload,
      };

    case PENDING_ORDERS_REQUEST:
      return { ...state, pendingOrdersLoading: true, pendingOrders: [] };
    case PENDING_ORDERS_SUCCESS:
      return {
        ...state,
        pendingOrdersLoading: false,
        pendingOrders: action.payload,
      };
    case PENDING_ORDERS_FAIL:
      return {
        ...state,
        pendingOrdersLoading: false,
        pendingOrdersError: action.payload,
      };

    case OPEN_ORDERS_REQUEST:
      return { ...state, openOrdersLoading: true, openOrders: [] };
    case OPEN_ORDERS_SUCCESS:
      return { ...state, openOrdersLoading: false, openOrders: action.payload };
    case OPEN_ORDERS_FAIL:
      return {
        ...state,
        openOrdersLoading: false,
        openOrdersError: action.payload,
      };

    case CLOSED_ORDERS_REQUEST:
      return { ...state, closedOrdersLoading: true, closedOrders: [] };
    case CLOSED_ORDERS_SUCCESS:
      return {
        ...state,
        closedOrdersLoading: false,
        closedOrders: action.payload,
      };
    case CLOSED_ORDERS_FAIL:
      return {
        ...state,
        closedOrdersLoading: false,
        closedOrdersError: action.payload,
      };

    default:
      return state;
  }
};

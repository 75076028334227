import React, { useState, useCallback, useEffect } from "react";
import { startCase, camelCase } from "lodash";
import { Link } from "react-router-dom";
import { Row, CardBody, Card, Button, Badge } from "reactstrap";
import { Cols } from "./../SeparatorStyle/SeparatorStyle";
import { getUserAccount } from "../../api/auth";
// import {
//   animationTopContainer,
//   animationTop,
//   rightAnimate,
// } from "../FramerMotion/FramerMotion";
// import { motion } from "framer-motion";

const SwitchAccount = () => {
  const [accountsData, setAccountsData] = useState();

  const loadAccount = useCallback(async () => {
    try {
      const { data } = await getUserAccount();

      setAccountsData(data.account);
    } catch (error) {
      console.log("error found when fetch order data", error);
    }
  }, []);

  useEffect(() => {
    loadAccount();
  }, [loadAccount]);

  const toTitleCase = (str) => {
    return startCase(camelCase(str));
  };

  return (
    <main>
      <Row className="justify-content-center align-items-center">
        <Cols xxs="12" xs="12" sm="12" md="9" lg="7" xl="7">
          <Card>
            <CardBody className="px-4">
              <div className="text-center mb-4 mt-3">
                {accountsData ? (
                  <h2>
                    <b>Switch Account</b>
                  </h2>
                ) : (
                  <span className="skeleton skeleton-text w-25"></span>
                )}
              </div>

              {!accountsData ? (
                <div className="text-center">
                  <div
                    style={{ minHeight: "110px" }}
                    className={`d-flex flex-row card align-items-center skeleton`}
                  ></div>
                  <div className="skeleton skeleton-button my-4 align-self-center"></div>
                </div>
              ) : (
                <>
                  <div className={`d-flex flex-row card align-items-center `}>
                    <img
                      alt=""
                      src={accountsData?.userId?.image}
                      className="img-thumbnail list-thumbnail align-self-center m-4  rounded-circle small"
                    />
                    <div className=" d-flex flex-grow-1 min-width-zero">
                      <div className=" pl-0 align-self-center d-flex flex-column flex-lg-row min-width-zero card-body">
                        <div className="min-width-zero">
                          <div className="truncate card-subtitle">
                            <b>
                              {`${accountsData?.userId?.firstname || ""} ${
                                accountsData?.userId?.lastname || ""
                              }`}
                            </b>
                          </div>
                          <p className="text-muted text-small truncate card-text">
                            {toTitleCase(accountsData?.role?.name)}
                          </p>
                        </div>
                      </div>
                    </div>
                    {accountsData && (
                      <Badge className="mr-4" color="success" pill>
                        Active
                      </Badge>
                    )}
                  </div>

                  <div className="py-4">
                    {accountsData?.staff.length > 0 && (
                      <ul className="list-unstyled">
                        {accountsData?.staff.map((staff) => (
                          <li key={staff._id}>
                            <div className="d-flex flex-row card align-items-center">
                              <img
                                alt="profile"
                                src={staff?.userId?.image}
                                className="img-thumbnail list-thumbnail align-self-center m-4  rounded-circle small"
                              />
                              <div className=" d-flex flex-grow-1 min-width-zero">
                                <div className=" pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero card-body">
                                  <div className="min-width-zero">
                                    <b>
                                      <div className="truncate mb-1 card-subtitle">
                                        {`${staff?.userId?.firstname || ""} ${
                                          staff?.userId?.lastname || ""
                                        }`}
                                      </div>
                                    </b>
                                    <p className="text-muted text-small card-text">
                                      {toTitleCase(staff?.role?.name)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <Link to="/admin/dashboard">
                                <span className="mr-4 fs-5 fw-bold simple-icon-login"></span>
                              </Link>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                    {accountsData?.role?.name === "superAdmin" && (
                      <div className=" text-center">
                        <Link to="/admin/userAndPermissions/addStaff">
                          <Button color="primary">Add Staff</Button>
                        </Link>
                      </div>
                    )}
                  </div>
                </>
              )}
            </CardBody>
          </Card>
        </Cols>
      </Row>
    </main>
  );
};

export default SwitchAccount;

import React, { useEffect, useCallback, useState } from "react";
import { getOrderTracking } from "../api/orders";
import { useParams } from "react-router-dom";
import { BsCartX } from "react-icons/bs";

const OrderTracking = () => {
  const { trackingNumber } = useParams();
  const [orderTracking, setOrderTracking] = useState();
  const [processed, setProcessed] = useState(false);
  const [designing] = useState(false);
  const [shipped] = useState(false);
  const [inRoute] = useState(false);
  const [arrived] = useState(false);
  const [cancelled, setCancelled] = useState(false);

  const getOrderStatusResponse = useCallback(async () => {
    try {
      let { data } = await getOrderTracking(trackingNumber);
      setOrderTracking(data);
      if (
        data === "Click to Call a Rider" ||
        data === "Unbooked" ||
        data === "Awaiting Pickup" ||
        data === "Pickup Request not Send"
      ) {
        setProcessed(true);
      } else if (data === "Cancelled") {
        setCancelled(true);
      }
    } catch (error) {
      console.log("error found when fetch trackingNumber", error);
    }
  }, [trackingNumber]);

  useEffect(() => {
    getOrderStatusResponse();
  }, [getOrderStatusResponse]);
  return (
    <div>
      <div className="tracking">
        <div className="container px-1 px-md-4 py-5 mx-auto">
          {!orderTracking ? (
            <div className="loading" />
          ) : (
            <>
              <div className="card">
                <div className="row d-flex justify-content-between px-3 top">
                  <div className="d-flex">
                    <h5>
                      Tracking#{" "}
                      <span className="text-primary font-weight-bold">
                        {trackingNumber}
                      </span>
                    </h5>
                  </div>
                  <div className="d-flex flex-column text-sm-right">
                    {/* <p className="mb-0">Expected Arrival <span>01/06/20</span></p> */}
                    {/* <p>Grasshoppers <span className="font-weight-bold"><a href="https://www.grasshoppers.lk/customers/action/track/V534HB">V534HB</a></span></p> */}
                  </div>
                </div>
                {cancelled === true ? (
                  <h3 style={{ textAlign: "center" }}>
                    {" "}
                    Your order has been <BsCartX style={{ fontSize: "75px" }} />
                    Cancelled
                  </h3>
                ) : (
                  <>
                    <div className="row d-flex justify-content-center">
                      <div className="col-12">
                        <ul id="progressbar" className="text-center">
                          {processed === true ? (
                            <li className="step0 active"></li>
                          ) : (
                            <li className="step0"></li>
                          )}
                          {designing === true ? (
                            <li className=" active step0"></li>
                          ) : (
                            <li className="step0"></li>
                          )}
                          {shipped === true ? (
                            <li className=" active step0"></li>
                          ) : (
                            <li className="step0"></li>
                          )}
                          {inRoute === true ? (
                            <li className=" active step0"></li>
                          ) : (
                            <li className="step0"></li>
                          )}
                          {arrived === true ? (
                            <li className=" active step0"></li>
                          ) : (
                            <li className="step0"></li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="row justify-content-between top">
                      <div className="row d-flex icon-content">
                        {" "}
                        <img
                          className="icon"
                          src="https://i.imgur.com/9nnc9Et.png"
                          alt=""
                        />
                        <div className="d-flex flex-column">
                          <p className="font-weight-bold">
                            Order <br /> Processed
                          </p>
                        </div>
                      </div>
                      <div className="row d-flex icon-content">
                        {" "}
                        <img
                          className="icon"
                          src="https://i.imgur.com/GiWFtVu.png"
                          alt=""
                        />
                        <div className="d-flex flex-column">
                          <p className="font-weight-bold">
                            Order <br /> Designing
                          </p>
                        </div>
                      </div>
                      <div className="row d-flex icon-content">
                        {" "}
                        <img
                          className="icon"
                          src="https://i.imgur.com/u1AzR7w.png"
                          alt=""
                        />
                        <div className="d-flex flex-column">
                          <p className="font-weight-bold">
                            Order <br /> Shipped
                          </p>
                        </div>
                      </div>
                      <div className="row d-flex icon-content">
                        {" "}
                        <img
                          className="icon"
                          src="https://i.imgur.com/TkPm63y.png"
                          alt=""
                        />
                        <div className="d-flex flex-column">
                          <p className="font-weight-bold">
                            Order <br /> In Route
                          </p>
                        </div>
                      </div>
                      <div className="row d-flex icon-content">
                        {" "}
                        <img
                          className="icon"
                          src="https://i.imgur.com/HdsziHP.png"
                          alt=""
                        />
                        <div className="d-flex flex-column">
                          <p className="font-weight-bold">
                            Order <br /> Arrived
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* <div className="card">
                        <div className="row d-flex justify-content-between px-3 top">
                            <div className="d-flex">
                                <h6><span className="text-primary font-weight-bold">2020-05-27 11:26:08 AM </span>REQUEST CREATED</h6>
                            </div>
                        </div>
                    </div> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default OrderTracking;

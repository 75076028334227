import React from "react";
import { Link } from "react-router-dom";
import { Row, Breadcrumb } from "reactstrap";
import { Cols } from "../SeparatorStyle/SeparatorStyle";
import { leftAnimate } from "../FramerMotion/FramerMotion";
import { motion } from "framer-motion";
const OrderHeader = ({ OrdersComponent }) => {
  return (
    <>
      <Row className="top-text">
        <Cols>
          <motion.div
            className=""
            variants={leftAnimate}
            initial="hidden"
            animate="visible"
          >
            <h1>
              {OrdersComponent === "Fulfillment" ? "Fulfillment" : "Orders"}
            </h1>
            <Breadcrumb className=" breadcrumb-container d-none  d-lg-inline-block ">
              <ol className="breadcrumb ">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" style={{ color: "black" }}>
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {OrdersComponent}
                </li>
              </ol>
            </Breadcrumb>
          </motion.div>
        </Cols>
      </Row>
    </>
  );
};

export default OrderHeader;

import React from "react";
import { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Cols } from "../SeparatorStyle/SeparatorStyle";

const EditLabel = () => {
  const [labelData, setLabelData] = useState({
    storeName: "",
    url: "",
    owner: "",
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setLabelData({ ...labelData, [name]: value });
  };

  const { storeName, url, owner } = labelData;

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <main>
        <div className="container_login ml-5 ">
          <Row className="row h-100">
            <Cols
              xxs="12"
              md="10"
              className="mx-auto my-auto d-flex justify-content-center"
            >
              <Card className="login-card " style={{ marginRight: "80px" }}>
                <CardBody className="form-side">
                  <CardTitle className="mb-4">Label Managment</CardTitle>
                  <Form onSubmit={handleSubmit} noValidate>
                    <FormGroup className="form-group has-float-label">
                      <Label>Store Name </Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="storeName"
                        value={storeName}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                    <FormGroup className="form-group has-float-label">
                      <Label>URL</Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="url"
                        value={url}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                    <FormGroup className="form-group has-float-label">
                      <Label>Owner Name</Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="owner"
                        value={owner}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                    <footer className="d-flex justify-content-center">
                      <Button type="submit" className={"mt-3"} size={"lg"}>
                        Update
                      </Button>
                    </footer>
                  </Form>
                </CardBody>
              </Card>
            </Cols>
          </Row>
        </div>
      </main>
    </div>
  );
};

export default EditLabel;

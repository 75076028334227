import React from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

const AddNewModal = ({
  modalOpen,
  toggleModal,
  body,
  primaryBtnText = "",
  secondaryBtnText = "",
  // primaryBtnWithSpinner = false,
  // isProcessing = false,
  // includeFooter,
  primaryBtnHandler = null,
  secondaryBtnHandler = null,
}) => {
  return (
    <div>
      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        wrapClassName="modal-right"
        backdrop="static"
        style={{ height: "25%" }}
      >
        <ModalHeader toggle={toggleModal}>
          <h4>Actions</h4>
        </ModalHeader>
        <ModalBody>
          {body}

          <div className="container text-center">
            <Button
              className="modal-btn"
              color="primary"
              onClick={primaryBtnHandler}
            >
              {primaryBtnText}
            </Button>
            <Button
              className="modal-btn"
              color="dark"
              onClick={secondaryBtnHandler}
            >
              {secondaryBtnText}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddNewModal;

import React from "react";
import { Cols } from "../../SeparatorStyle/SeparatorStyle";
import { Row, Table } from "reactstrap";

const PaymentDetails = ({
  details,
  subAmount,
  amount,
  address,
  orderId,
  paymentStatus,
}) => {
  const array1 = details.map((getQuantity) => getQuantity.quantity);
  const reducer = (previousValue, currentValue) => previousValue + currentValue;

  const MYfont = {
    fontSize: "25px",
  };

  return (
    <>
      <Row style={{ minWidth: "1440px" }}>
        <Cols xxs="12">
          <div
            className="container mt-3"
            style={{ width: "70%", marginLeft: "0px" }}
          >
            <p>
              <h3>
                Shopify ID {orderId} &nbsp; &nbsp;
                <button
                  disabled
                  style={{
                    border: "1px solid black",
                    color: "black",
                    fontSize: "16px",
                    borderRadius: "16px",
                    padding: "10px 20px",
                  }}
                >
                  {paymentStatus} Payment
                </button>{" "}
                &nbsp; &nbsp; &nbsp; &nbsp;
                <b style={{ fontSize: "16px" }}>Location: {address}</b>
              </h3>
            </p>
            <Table style={{ width: "70%", border: "2px solid black" }}>
              <thead className="mb-3">
                <tr style={{ MYfont }}>
                  <th style={{ textAlign: "center", width: "40%" }}>Product</th>
                  {/* <th style={{ textAlign: 'center', width: '15%' }}>Image</th> */}
                  <th style={{ textAlign: "center", width: "15%" }}>
                    Quantity
                  </th>
                  <th style={{ textAlign: "center", width: "15%" }}>
                    Cost/Unit
                  </th>
                  <th style={{ textAlign: "center", width: "15%" }}>Total</th>
                </tr>
              </thead>
              <tbody style={{ textAlign: "center" }}>
                {details.map((orderDetail) => (
                  <tr>
                    <td>{orderDetail.title}</td>
                    {/* <td><p style={{ textAlign: 'center' }}><img src={'./OrderReceiptMedia/image.jpg'} alt='Product Image' style={{ maxHeight: '80px', maxWidth: '80px' }} /></p></td> */}
                    <td>
                      {orderDetail.quantity}{" "}
                      {orderDetail.quantity > 1 ? "Pieces" : "Piece"}
                    </td>
                    <td>{orderDetail.price}</td>
                    <td>{orderDetail.price * orderDetail.quantity}.00</td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <b>
              <h2 className="mt-3" style={{ width: "70%" }}>
                {paymentStatus}
              </h2>
            </b>
            <Table
              className=" mt-1"
              style={{ width: "70%", border: "1px solid black" }}
            >
              <tr>
                <td style={{ border: "1px solid black" }} colSpan="2">
                  <b>Sub Total</b>
                </td>
                <td style={{ border: "1px solid black" }} colSpan="4">
                  {array1.reduce(reducer)}{" "}
                  {array1.reduce(reducer) > 1 ? "Items" : "Item"}
                </td>
                <td style={{ border: "1px solid black" }} colSpan="3">
                  Rs. {subAmount}.00
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black" }} colSpan="2">
                  <b>Shipping</b>
                </td>
                <td style={{ border: "1px solid black" }} colSpan="4">
                  Free Shipping (0.0 KG)
                </td>
                <td style={{ border: "1px solid black" }} colSpan="3">
                  Rs. 0.00
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black" }} colSpan="2">
                  <b>Total</b>
                </td>
                <td
                  style={{
                    borderLeft: "hidden",
                    borderBottom: "1px solid black",
                  }}
                  colSpan="4"
                ></td>
                <td style={{ border: "1px solid black" }} colSpan="3">
                  Rs. {amount}.00
                </td>
              </tr>
            </Table>
          </div>
        </Cols>
      </Row>
    </>
  );
};

export default PaymentDetails;

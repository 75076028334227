import React, { useEffect, useState } from "react";
import XLSX from "xlsx";
import {
  Card,
  CardBody,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { Breadcrumb, Button, Row } from "reactstrap";
import { Cols } from "../SeparatorStyle/SeparatorStyle";
import { Link, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { leftAnimate } from "../FramerMotion/FramerMotion";
import { toast } from "react-toastify";
import { stockQuantityUpdate } from "../../api";
import ResellingProducts from "./ResellingProducts";
import { getResellingVariants } from "../../api/reselling";

const ResellingProductsHead = () => {
  const { accountId } = useParams();
  const [pageLimit, setPageLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);

  const [filterLoading, setFilterLoading] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [masterCheckBool, setMasterCheckBool] = useState(false);
  const [search, setSearch] = useState("");
  const [searchTrigger, setSearchTrigger] = useState(false);

  const [loading, setLoading] = useState(true);
  const [emptyStatus, setEmptyStatus] = useState(false);
  const [variants, setVariants] = useState([]);
  const [count, setCount] = useState("");
  const [selectedList, setSelectedList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [dataTrigger, setDataTrigger] = useState(false);
  const [modal, setModal] = useState(false);
  const [reseller, setReseller] = useState("");

  const toggle = () => {
    setModal(!modal);
    setSelectedFile(null);
    setUpdateLoader(false);
  };

  const fetchResellingVariants = async ({
    searchSKU = search.trim().length > 2 ? search : "",
    limit = pageLimit,
    page = pageNumber,
  }) => {
    try {
      const { data } = await getResellingVariants({
        searchSKU,
        page,
        limit,
        accountId,
      });

      if (data) {
        setVariants(data?.variants);
        setCount(data?.totalCount);
        setReseller(data.reseller);

        if (data.totalCount === 0 && !search.trim()) {
          setEmptyStatus(true);
        } else {
          emptyStatus && setEmptyStatus(false);
        }

        setLoading(false);
        setFilterLoading(false);
        setPaginationLoading(false);
      }
    } catch (err) {
      toast.error(
        err?.message ||
          err.response.data?.message ||
          "Error fetching resellingVariants!"
      );
    }
  };

  useEffect(() => {
    return fetchResellingVariants({});
  }, [searchTrigger]);

  const handleUpdateInventory = async (data) => {
    try {
      const response = await stockQuantityUpdate(data);
      if (response.status === 200) {
        // setTimeout(async () => {
        await fetchResellingVariants({});
        setDataTrigger(!dataTrigger);

        selectedList.length && setSelectedList([]);
        selectedFile && toggle();

        toast.success(response.data.message);
        setSaveLoader(false);
        // }, 4000);
      } else {
        toast.error("Inventory update failed!");
      }
    } catch (err) {
      console.log({ err });
      toast.error(
        err?.message || err.response.data?.message || "Inventory update failed!"
      );
      setSaveLoader(false);
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    const fileExtension = file.name.split(".").pop();

    if (fileExtension !== "xlsx") {
      toast.error("Invalid file format. Only XLSX files are allowed.");
    } else {
      setSelectedFile(file);
    }
  };

  const handleFileUpload = () => {
    if (selectedFile) {
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(selectedFile);
      fileReader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Validate Headers
        const headers = rows[0];
        if (headers[0] !== "sku" || headers[1] !== "quantity") {
          toast.error(
            'Invalid file headers. The headers must be "sku" and "quantity".'
          );
          return;
        }

        // Validate the file content here
        if (rows.length < 2) {
          toast.error(
            "Invalid file content. The file must contain at least two rows of data."
          );
          return;
        }

        // Continue with the file upload if validation passes

        const format = rows.slice(1).map((row) => {
          const obj = {};
          headers.forEach((key, i) => (obj[key] = row[i] || ""));
          return obj;
        });
        setUpdateLoader(true);
        handleUpdateInventory(format);
      };
    } else {
      toast.warning("Please select a file to upload.");
    }
  };

  return (
    <main>
      <Row className="mb-2">
        <Cols>
          <motion.div
            className="top-text"
            variants={leftAnimate}
            initial="hidden"
            animate="visible"
          >
            {loading ? (
              <div className=" skeleton skeleton-text w-15"></div>
            ) : (
              <h1>
                {reseller?.userName}
                <br />
                <p>{reseller?.userId?.email}</p>
              </h1>
            )}
            <Breadcrumb className=" breadcrumb-container d-none  d-lg-inline-block ">
              <ol className="breadcrumb ">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" style={{ color: "black" }}>
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/admin/reselling" style={{ color: "black" }}>
                    Reselling
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Products
                </li>
              </ol>
            </Breadcrumb>

            <Button
              className="float-end ms-3 btn btn-success"
              color={!selectedList.length ? "secondary" : "success"}
              disabled={saveLoader || !selectedList.length}
              onClick={() => {
                let format = selectedList.map((obj) => {
                  return { sku: obj.sku, quantity: obj.inventory_quantity };
                });
                setSaveLoader(true);
                handleUpdateInventory(format);
              }}
            >
              Save {saveLoader && <Spinner size={"sm"} type="grow"></Spinner>}
            </Button>

            <Button
              color="primary"
              className="float-end"
              onClick={toggle}
              disabled={saveLoader}
            >
              Import
              <i className="iconsminds-down fs-8 fw-bolder"></i>
            </Button>
          </motion.div>
        </Cols>
      </Row>
      {/* <div className="mb-5 separator"></div> */}
      <Card>
        <CardBody>
          <TabContent>
            <TabPane>
              <ResellingProducts
                selectedList={selectedList}
                setSelectedList={setSelectedList}
                dataTrigger={dataTrigger}
                accountId={accountId}
                variants={variants}
                count={count}
                loading={loading}
                emptyStatus={emptyStatus}
                setPageLimit={setPageLimit}
                pageLimit={pageLimit}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                setFilterLoading={setFilterLoading}
                filterLoading={filterLoading}
                setPaginationLoading={setPaginationLoading}
                paginationLoading={paginationLoading}
                setMasterCheckBool={setMasterCheckBool}
                masterCheckBool={masterCheckBool}
                setSearch={setSearch}
                search={search}
                setSearchTrigger={setSearchTrigger}
                searchTrigger={searchTrigger}
                fetchResellingVariants={fetchResellingVariants}
              />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>

      {/* Import Inventory Modal */}
      <Modal isOpen={modal}>
        <ModalHeader toggle={!updateLoader && toggle}>
          <b>Inventory Import</b>
        </ModalHeader>
        <ModalBody>
          <Input
            type="file"
            accept=".xlsx"
            className="mx-auto"
            onChange={handleFileSelect}
            multiple={false}
          />
        </ModalBody>
        <ModalFooter>
          <div className="text-center">
            <Button
              color="primary"
              className="me-3"
              onClick={handleFileUpload}
              disabled={updateLoader || !selectedFile}
            >
              Update Inventory{" "}
              {updateLoader && <Spinner size={"sm"} type="grow"></Spinner>}
            </Button>
            <Button color="secondary" onClick={toggle} disabled={updateLoader}>
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </main>
  );
};

export default ResellingProductsHead;

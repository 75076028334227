import { Alert, Button, Card, CardBody, CardTitle } from 'reactstrap';
import { Link } from 'react-router-dom';
const VerifyMsg = () => {
  return (
    <div className="fixed-background">
      <div className="d-flex justify-content-center align-items-center h-100">
        <Card className="text-center py-5 px-3">
          <CardTitle className="text-success display-6 fw-bold vstack gap-2 ">
            <i className="simple-icon-check fw-bold"></i>
            Verification Email has been sent!
          </CardTitle>
          <CardBody className=" gap-2">
            <p className="lead">
              Please check your inbox to verify email address
            </p>
            <Alert color="warning" className="w-75 mx-auto">
              Make sure to check spam folder!
            </Alert>
            <Button
              color="primary"
              tag={Link}
              to={{ pathname: 'https://mail.google.com/mail/u/0/#spam' }}
              target="_blank"
            >
              View Email
            </Button>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default VerifyMsg;

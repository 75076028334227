import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { motion } from "framer-motion";
// import { leftAnimate } from '../../FramerMotion/FramerMotion';
const OrderTabs = ({ activeTab, setActiveTab }) => {
  return (
    <motion.div
    // variants={leftAnimate} initial="hidden" animate="visible"
    >
      <Nav
        tabs
        className="separator-tabs marginL-md mb-5"
        style={{ cursor: "pointer" }}
      >
        <NavItem>
          <NavLink
            className={`${classnames({
              active: activeTab === "openOrders",
              "nav-link": true,
            })} ${activeTab === "openOrders" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeExportOrderTab", "openOrders");
              setActiveTab("openOrders");
            }}
            location={{}}
            to="#"
          >
            Open
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${classnames({
              active: activeTab === "pendingOrders",
              "nav-link": true,
            })} ${activeTab === "pendingOrders" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeExportOrderTab", "pendingOrders");
              setActiveTab("pendingOrders");
            }}
            location={{}}
            to="#"
          >
            Pending
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${classnames({
              active: activeTab === "confirmedOrders",
              "nav-link": true,
            })} ${activeTab === "confirmedOrders" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeExportOrderTab", "confirmedOrders");
              setActiveTab("confirmedOrders");
            }}
            location={{}}
            to="#"
          >
            Confirmed
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${classnames({
              active: activeTab === "assignedOrders",
              "nav-link": true,
            })} ${activeTab === "assignedOrders" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeExportOrderTab", "assignedOrders");
              setActiveTab("assignedOrders");
            }}
            location={{}}
            to="#"
          >
            Booked
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={` ${classnames({
              active: activeTab === "unAssignedOrders",
              "nav-link": true,
            })} ${activeTab === "unAssignedOrders" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeExportOrderTab", "unAssignedOrders");
              setActiveTab("unAssignedOrders");
            }}
            location={{}}
            to="#"
          >
            UnAssigned
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={` ${classnames({
              active: activeTab === "fulfilledOrders",
              "nav-link": true,
            })} ${activeTab === "fulfilledOrders" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeExportOrderTab", "fulfilledOrders");
              setActiveTab("fulfilledOrders");
            }}
            location={{}}
            to="#"
          >
            Fulfilled
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={` ${classnames({
              active: activeTab === "manualOrders",
              "nav-link": true,
            })} ${activeTab === "manualOrders" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeExportOrderTab", "manualOrders");
              setActiveTab("manualOrders");
            }}
            location={{}}
            to="#"
          >
            Manual
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={` ${classnames({
              active: activeTab === "cancelledOrders",
              "nav-link": true,
            })} ${activeTab === "cancelledOrders" ? null : "text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeExportOrderTab", "cancelledOrders");
              setActiveTab("cancelledOrders");
            }}
            location={{}}
            to="#"
          >
            Cancelled
          </NavLink>
        </NavItem>
      </Nav>
    </motion.div>
  );
};

export default OrderTabs;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  createWebhooks,
  deleteWebhooks,
  getWebhooks,
} from "../../api/webhooks";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

const Webhooks = () => {
  let topics = [
    // { topic: "app/uninstalled", callbackUrl: "/app/uninstalled" },
    { topic: "products/create", callbackUrl: "/products/create" },
    { topic: "products/delete", callbackUrl: "/products/delete" },
    { topic: "products/update", callbackUrl: "/products/update" },
    // { topic: "product_listings/add", callbackUrl: "/product_listings/add" },
    // {
    //   topic: "product_listings/remove",
    //   callbackUrl: "/product_listings/remove",
    // },
    // {
    //   topic: "product_listings/update",
    //   callbackUrl: "/product_listings/update",
    // },
    { topic: "inventory_items/create", callbackUrl: "/inventoryItems/create" },
    { topic: "inventory_items/update", callbackUrl: "/inventoryItems/update" },
    { topic: "inventory_items/delete", callbackUrl: "/inventoryItems/delete" },
    {
      topic: "inventory_levels/connect",
      callbackUrl: "/inventoryLevels/create",
    },
    {
      topic: "inventory_levels/disconnect",
      callbackUrl: "/inventoryLevels/delete",
    },
    {
      topic: "inventory_levels/update",
      callbackUrl: "/inventoryLevels/update",
    },

    { topic: "locations/create", callbackUrl: "/inventoryLocations/create" },
    { topic: "locations/delete", callbackUrl: "/inventoryLocations/delete" },
    { topic: "locations/update", callbackUrl: "/inventoryLocations/update" },
    // { topic: "carts/create", callbackUrl: "/carts/create" },
    // { topic: "carts/update", callbackUrl: "/carts/update" },
    // { topic: "checkouts/create", callbackUrl: "/checkouts/create" },
    // { topic: "checkouts/delete", callbackUrl: "/checkouts/delete" },
    // { topic: "checkouts/update", callbackUrl: "/checkouts/update" },
    // { topic: "collections/create", callbackUrl: "/collections/create" },
    // { topic: "collections/delete", callbackUrl: "/collections/delete" },
    // { topic: "collections/update", callbackUrl: "/collections/update" },
    { topic: "customers/create", callbackUrl: "/customers/create" },
    { topic: "customers/delete", callbackUrl: "/customers/delete" },
    { topic: "customers/update", callbackUrl: "/customers/update" },
    // { topic: "customer_groups/create", callbackUrl: "/customer_groups/create" },
    // { topic: "customer_groups/delete", callbackUrl: "/customer_groups/delete" },
    // { topic: "customers/disable", callbackUrl: "/customers/disable" },
    // { topic: "customers/enable", callbackUrl: "/customers/enable" },

    // { topic: "draft_orders/create", callbackUrl: "/draft_orders/create" },
    // { topic: "draft_orders/delete", callbackUrl: "/draft_orders/delete" },
    // { topic: "draft_orders/update", callbackUrl: "/draft_orders/update" },
    // {
    //   topic: "fulfillment_events/create",
    //   callbackUrl: "/fulfillment_events/create",
    // },
    // {
    //   topic: "fulfillment_events/delete",
    //   callbackUrl: "/fulfillment_events/delete",
    // },
    // { topic: "fulfillments/create", callbackUrl: "/fulfillments/create" },
    // { topic: "fulfillments/update", callbackUrl: "/fulfillments/update" },
    { topic: "orders/create", callbackUrl: "/orders/create" },
    { topic: "orders/delete", callbackUrl: "/orders/delete" },
    { topic: "orders/fulfilled", callbackUrl: "/orders/fulfilled" },
    {
      topic: "orders/partially_fulfilled",
      callbackUrl: "/orders/partialFulfilled",
    },
    {
      topic: "orders/edited",
      callbackUrl: "/orders/edit",
    },
    { topic: "orders/cancelled", callbackUrl: "/orders/cancelled" },
    { topic: "orders/paid", callbackUrl: "/orders/paid" },
    // { topic: "orders/updated", callbackUrl: "/orders/updated" },

    { topic: "refunds/create", callbackUrl: "/refunds/create" },
    { topic: "shop/update", callbackUrl: "/shop/update" },
    // { topic: "themes/create", callbackUrl: "/themes/create" },
    // { topic: "themes/delete", callbackUrl: "/themes/delete" },
    // { topic: "themes/publish", callbackUrl: "/themes/publish" },
  ];
  const { Id } = useParams();
  const [webhooksData, setWebhooksData] = useState([]);
  const [webhookCreateOptions, setWebhookCreateOptions] = useState([]);
  const [createWebhooksValue, setCreateWebhooksValue] = useState([]);
  const [loading, setLoading] = useState(true);
  const [handleWebhooksLoader, setHandleWebhooksLoader] = useState(false);
  const [confirmRemove, setConfirmRemove] = useState("");

  const handleconfirmToggle = () => {
    setConfirmRemove("");
  };

  const fetch = async ({ storeId = Id }) => {
    try {
      const { data } = await getWebhooks({ storeId });
      setWebhooksData(data);
      const formatOptions = topics.filter(
        (obj) => !data.some((t) => t.topic === obj.topic)
      );

      setWebhookCreateOptions(
        formatOptions.length
          ? formatOptions.map((f, i) => {
              return { label: f.topic, value: f.topic };
            })
          : []
      );
      setLoading(false);
      setCreateWebhooksValue([]);
    } catch (error) {
      console.log(`While fetching webhooks data ${error.message}`);
    }
  };
  useEffect(() => {
    fetch({});
  }, []);

  const handleCreateWebhooks = async () => {
    if (!createWebhooksValue.length) {
      toast.error(`Please Select Topics!`);
      return createWebhooksValue.length;
    }
    try {
      setHandleWebhooksLoader(true);
      await createWebhooks({
        storeId: Id,
        topics: createWebhooksValue.map((o) => o.value),
      });
      await fetch({});
      toast.success("Successfully Created!");
    } catch (error) {
      console.log(`While creating webhooks: ${error.message}`);
    }
    setHandleWebhooksLoader(false);
  };

  const handleDeleteWebhook = async (topicId) => {
    try {
      await deleteWebhooks({ storeId: Id, topics: [topicId] });
      await fetch({});
      toast.success("Successfully Deleted!");
      handleconfirmToggle();
    } catch (error) {
      console.log(`While deleting webhooks: ${error.message}`);
    }
    setHandleWebhooksLoader(false);
  };

  return (
    <>
      <main>
        <div className="container-fluid">
          <div className="dashboard-wrapper">
            <div className="disable-text-selection">
              <div className="row top-text mb-5">
                <div className="col-12">
                  <div className="mb-2">
                    <h1>Webhooks</h1>
                    <div className="top-right-button-container d-flex">
                      <div style={{ maxwidth: "280px", minWidth: "180px" }}>
                        <Select
                          isMulti
                          value={
                            createWebhooksValue.length
                              ? createWebhooksValue
                              : ""
                          }
                          noOptionsMessage={() => "No more topics"}
                          options={webhookCreateOptions}
                          placeholder={"Select Topics"}
                          onChange={(e) => {
                            setCreateWebhooksValue(e);
                          }}
                        />
                      </div>
                      <div>
                        <button
                          disabled={handleWebhooksLoader}
                          type="button"
                          className="top-right-button btn btn-primary ml-3"
                          onClick={() => {
                            handleCreateWebhooks();
                          }}
                        >
                          Register
                        </button>
                      </div>
                    </div>
                    <nav
                      className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block"
                      aria-label="breadcrumb"
                    >
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <span>Home</span>
                        </li>
                        <li className="breadcrumb-item active">webhooks</li>
                      </ol>
                    </nav>
                  </div>

                  <div className="separator" />
                </div>
              </div>
              <div className="row">
                <div className=" col-12">
                  {loading ? (
                    <span className="loading"></span>
                  ) : (
                    <div className="react-contextmenu-wrapper">
                      {!webhooksData.length ? (
                        <div
                          className=" d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
                          style={{
                            height: "200px",
                          }}
                        >
                          <span>No Webhook Found!</span>
                        </div>
                      ) : (
                        webhooksData
                          .sort((a, b) => b.id - a.id)
                          .map((obj, i) => (
                            <div
                              className="d-flex mb-3 flex-row card"
                              key={i + 1}
                            >
                              <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                                <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                                  <span
                                    aria-current="page"
                                    className="w-40 w-sm-100 active mr-2"
                                  >
                                    <p className="list-item-heading mb-1 truncate">
                                      {obj.topic}
                                    </p>
                                  </span>
                                  <p className="mb-1 text-muted text-small w-100 truncate">
                                    {obj.address}
                                  </p>
                                  {/* <p className="mb-1 text-muted text-small w-15 w-sm-100">
                            </p> */}
                                  <div className=" w-25">
                                    <span className="badge badge-outline-primary badge-pill">
                                      {new Date(obj.created_at)?.toDateString()}
                                    </span>
                                  </div>
                                </div>
                                <div className="custom-control custom-checkbox pl-1 align-self-center pr-4">
                                  <div className="item-check mt-2 custom-checkbox custom-control">
                                    {handleWebhooksLoader ? (
                                      <Spinner size="sm" color="primary" />
                                    ) : (
                                      <span
                                        className="simple-icon-trash fs-6 text-danger fw-bold pointer"
                                        onClick={() => {
                                          setConfirmRemove(obj);
                                        }}
                                      ></span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={confirmRemove}>
          <ModalHeader toggle={handleconfirmToggle}>
            Webhook Topic : <b>{confirmRemove?.topic}</b>
          </ModalHeader>
          <ModalBody>
            <strong>Note:</strong>
            <br />
            <p>
              Removing Topic
              <b> {confirmRemove?.topic} </b> your store's data will not be sync
              with Shopilam, You will not recieve any notification payload.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              disabled={handleWebhooksLoader}
              onClick={() => {
                setHandleWebhooksLoader(true);
                handleDeleteWebhook(confirmRemove?.id);
              }}
            >
              Remove
            </Button>
            <Button
              color="secondary"
              onClick={handleconfirmToggle}
              disabled={handleWebhooksLoader}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </main>
    </>
  );
};

export default Webhooks;

import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  getProductDetails,
  updateProduct,
  deleteProduct,
  updateOptions,
  deleteProductOptions,
  deleteProductOptionsValues,
} from "../../../api/products";
import DetailedUI from "./DetailedUI.jsx";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ContentState, convertFromHTML } from "draft-js";
import { convertToRaw, EditorState } from "draft-js";

const EditProduct = () => {
  const { id: shopifyProductId } = useParams();
  const [start, setStart] = useState(false);

  const history = useHistory();
  const [products, setProducts] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [productData, setProductData] = useState();
  const [description, setDescription] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getProductDetails(shopifyProductId);
        setProducts(data);
        setAttributes(data[0].attributes);
        setLoading(false);
        setDescription(editorState);
        setStart(true);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, [loading]);

  let editorState = EditorState.createWithContent(
    ContentState.createFromBlockArray(
      convertFromHTML(`<p>${products[0]?.body_html}</p>`)
    )
  );
  const updateProducts = async (productData) => {
    const {
      title,
      body_html,
      status,
      vendor,
      type,
      tags,
      shopifyProductId,
      storeId,
    } = productData;
    try {
      const { data } = await updateProduct({
        title,
        body_html,
        status,
        vendor,
        type,
        tags,
        shopifyProductId,
        storeId,
      });
      toast.success("Products data success");
    } catch (err) {
      toast.error("Products data loading error! ");
    }
  };

  const deleteProducts = async (shopifyProductId, storeId) => {
    try {
      const { data } = await deleteProduct({ shopifyProductId, storeId });
      history.push("/admin/ProductListing");
    } catch (err) {
      console.error(err);
    }
  };

  const handleRemoveOptions = async (shopifyProductId, optionName, storeId) => {
    try {
      const index = attributes.findIndex((object) => {
        return object.name === optionName;
      });
      var removedOption = attributes.splice(index, 1);
      const optionsUpdatedArr = attributes.map((object) => {
        return { ...object, product_id: shopifyProductId };
      });
      // 👇️ [{id: 1, color: 'red'}, {id: 2, color: 'red'}]

      for (let i = 0; i < optionsUpdatedArr.length; i++) {
        delete optionsUpdatedArr[i].position;
      }
      const { data } = await deleteProductOptions(
        shopifyProductId,
        optionName,
        storeId,
        optionsUpdatedArr
      );
      toast.success("Successfully delete Product Option");
    } catch (err) {
      console.error(err);
      toast.error("Delete Product Optionloading error! ");
    }
  };

  /**
   *  Delete options from product using option name which deletes option and all its values
    @param {} shopifyProductId
    @param {} optionName
    @param {} storeId
   *
   */
  const handleRemoveOptionValues = async (
    shopifyProductId,
    optionName,
    optionValue,
    storeId
  ) => {
    try {
      const index = attributes.findIndex((object) => {
        return object.name === optionName;
      });

      // var removedOption = attributes.splice(index, 1);

      const optionsUpdatedArr = attributes.map((object) => {
        return { ...object, product_id: shopifyProductId };
      });

      for (let i = 0; i < optionsUpdatedArr.length; i++) {
        delete optionsUpdatedArr[i].position;
      }
      const { data } = await deleteProductOptionsValues(
        shopifyProductId,
        optionName,
        optionValue,
        storeId,
        optionsUpdatedArr
      );
      toast.success("Successfully delete Product Option value");
    } catch (err) {
      toast.error("Delete Product Option value loading error! ");
    }
  };

  const addEditOption = async (shopifyProductId, storeId, options) => {
    try {
      const { data } = await updateOptions(shopifyProductId, storeId, options);
      toast.success("Successfully edit Product Option");
    } catch (err) {
      toast.error("edit Product Option error! ");
    }
  };

  return (
    <>
      <DetailedUI
        showEditProduct={false}
        editProduct={"editProduct"}
        loading={loading}
        addEditOption={addEditOption}
        updateProducts={updateProducts}
        deleteProducts={deleteProducts}
        handleRemoveOptions={handleRemoveOptions}
        handleRemoveOptionValues={handleRemoveOptionValues}
        products={products}
        attributes={attributes}
        setAttributes={setAttributes}
        setProducts={setProducts}
        body_html={productData}
        start={start}
        description2={description}
        setDescription={setDescription}
        editorState={editorState}
      />
    </>
  );
};

export default EditProduct;

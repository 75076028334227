import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Card, CardBody, Button, Input, Spinner } from "reactstrap";
import {
  defaultCourierCityFun,
  getUserPreferences,
  courierCityFun,
} from "../../api/courier";
import { getAllCitiesName } from "../../api/cities";
import { Cols } from "../SeparatorStyle/SeparatorStyle";

const CitiesCouriers = () => {
  let history = useHistory();
  const [couriersList, setCouriersList] = useState([]);
  const [search, setSearch] = useState("");
  const [dragFunction, setDragFunction] = useState(false);
  const [citiesList, setCitiesList] = useState([]);
  const [nationCity] = useState(history.location.state);
  const [dragCities, setDragCities] = useState({});
  const [compareIndex1, setCompareIndex1] = useState([]);
  const [citiesCourierSpinner, setCitiesCourierSpinner] = useState(false);

  const loadCourier = useCallback(async () => {
    try {
      const { data } = await getUserPreferences();
      let couriers = [];
      if (data && data.couriers.length > 1) {
        couriers = data.couriers.filter(
          (item) => item._id !== history.location.state._id
        );
      }

      setCouriersList(couriers);
    } catch (error) {
      console.log("error found when fetch order data", error);
    }
  }, [history?.location.state?._id]);

  const loadCities = useCallback(async () => {
    try {
      const { data } = await getAllCitiesName();
      setCitiesList(data.cities);
    } catch (error) {
      console.log("error found when fetch order data", error);
    }
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    loadCourier();
    loadCities();
    handleScrollToTop();
  }, [loadCourier, loadCities]);

  const handleDragstart = (e, label, courier = "", index) => {
    e.dataTransfer.setData("label", label);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleOnDrop = (e, status, courierN) => {
    setCompareIndex1([...new Set([...compareIndex1, courierN])]);
    setDragFunction(true);
    let label = e.dataTransfer.getData("label");
    const courierName = courierN;
    let lists1 = citiesList.filter((task) => {
      if (task.label === label) {
        task.status = status;
      }
      return task;
    });
    let lists2 = citiesList.filter((task) => task.label !== label);

    setCitiesList(lists2);
    const courierAssignedCities = lists1.filter(
      (list) => list.status === "courier"
    );

    if (dragCities[courierName]) {
      setDragCities({
        ...dragCities,
        [courierName]: [...dragCities[courierName], ...courierAssignedCities],
      });
    } else {
      setDragCities({
        ...dragCities,
        [courierName]: [...courierAssignedCities],
      });
    }
  };

  const handleCross = (e, label, courierName) => {
    let lists2 = dragCities[courierName].filter((task) => task.label !== label);
    const deleteCities = {
      ...dragCities,
      [courierName]: [...lists2],
    };

    if (deleteCities[courierName].length === 0) {
      setCompareIndex1(compareIndex1.filter((value) => value !== courierName));
    }

    setDragCities(deleteCities);
    let pushing = [...citiesList];
    pushing.push({ label });
    setCitiesList([...pushing]);
  };

  // executes on submit button and sets data in db of this on-boarding step
  const handleCity = async () => {
    let obj = {};
    for (const key in dragCities) {
      obj[key] = dragCities[key].map((city) => city.label);
    }

    let defaultCities = [];
    // extract city name and make array of un selected city as default courier cities
    for (const city of citiesList) {
      defaultCities.push(city.label);
    }
    if (dragFunction && dragCities) {
      setCitiesCourierSpinner((c) => !c);
      if (citiesList && citiesList.length > 0) {
        let defaultCityCourierUpdate = {
          //  nation wise courier
          [nationCity && nationCity.label]: defaultCities && defaultCities,
        };
        await defaultCourierCityFun(defaultCityCourierUpdate);

        setTimeout(() => {
          history.push("/questionnaire");
        }, 1000);
      }
      await courierCityFun(obj);
    } else {
      setCitiesCourierSpinner((c) => !c);
      let defaultCityCourierUpdate = {
        [nationCity && nationCity.label]: citiesList && citiesList,
      };
      await defaultCourierCityFun(defaultCityCourierUpdate);
      setTimeout(() => {
        history.push("/questionnaire");
      }, 1000);
    }
  };

  return (
    <div style={{ margin: "60px" }}>
      {nationCity && nationCity !== undefined ? (
        <>
          <Row>
            <Cols xxs="12" sm="6" lg="3" md="4">
              <Card style={{ height: "700px" }}>
                <div className="d-flex justify-content-center">
                  {nationCity.label === "PostEx" ? (
                    <img
                      src="./img/companiesLogo/postEx.png"
                      alt="postEx"
                      height="100px"
                    />
                  ) : nationCity.label === "Leopard" ? (
                    <img
                      src="./img/companiesLogo/LCS.png"
                      alt="leopard"
                      height="100px"
                    />
                  ) : nationCity.label === "Swyft" ? (
                    <img
                      src="./img/companiesLogo/swyft.png"
                      alt="swyft"
                      height="100px"
                    />
                  ) : nationCity.label === "M&P" ? (
                    <img
                      src="./img/companiesLogo/MP-Logo.png"
                      alt="m&p"
                      height="100px"
                    />
                  ) : nationCity.label === "Trax" ? (
                    <img
                      src="./img/companiesLogo/Trax.png"
                      alt="trax"
                      height="100px"
                    />
                  ) : (
                    nationCity.label === "TPL" && (
                      <img
                        src="./img/companiesLogo/Rider.png"
                        alt="tpl"
                        height="100px"
                      />
                    )
                  )}
                </div>
                <CardBody className="py-0">
                  <div className="search-sm d-inline-block pb-4 align-top d-flex justify-content-center">
                    <Input
                      type="text"
                      name="keyword"
                      id="search"
                      placeholder="Search City"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      style={{
                        padding: "7px",
                        fontSize: "16px",
                      }}
                    />
                  </div>
                </CardBody>
                <CardBody
                  className="pt-0 customScroll"
                  style={{ overflow: "auto", padding: "20px" }}
                >
                  <div>
                    {search === "" ? (
                      citiesList.map((city, index) => (
                        <Card
                          key={city._id}
                          className="mb-2"
                          draggable
                          onDragStart={(e) => {
                            handleDragstart(
                              e,
                              city.label,
                              nationCity.label,
                              index
                            );
                          }}
                        >
                          <CardBody id={city._id}>
                            <div className="d-flex align-items-center">
                              <img
                                src="./img/location/Location.png"
                                alt="not found"
                              />
                              <p
                                className="ms-3 mb-0"
                                style={{ color: "#909090" }}
                              >
                                {city.label}
                              </p>
                            </div>
                          </CardBody>
                        </Card>
                      ))
                    ) : (
                      <>
                        {citiesList
                          .filter((city) =>
                            city.label
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          )
                          .map((city) => (
                            <Card
                              key={city._id}
                              className="mb-2"
                              draggable
                              onDragStart={(e) => {
                                handleDragstart(
                                  e,
                                  city.label,
                                  nationCity.label
                                );
                              }}
                            >
                              <CardBody className="" id={city._id}>
                                <div className="d-flex align-items-center">
                                  <img
                                    src="./img/location/Location.png"
                                    alt="not found"
                                  />
                                  <p
                                    className="ms-3 mb-0"
                                    style={{ color: "#909090" }}
                                  >
                                    {city.label}
                                  </p>
                                </div>
                              </CardBody>
                            </Card>
                          ))}
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Cols>
            <Cols xxs="12" sm="6" lg="9" md="8" className="px-2 cities-courier">
              <Row className="d-flex customScroll flex-nowrap overflow-auto">
                {couriersList && couriersList.length >= 0 ? (
                  <>
                    {couriersList.map((courier, index) => (
                      <Cols xxs="6" md="6" sm="10" lg="4" className="px-1">
                        <Card
                          style={{ height: "700px" }}
                          id={courier.label}
                          onDragOver={(e) => handleDragOver(e)}
                          onDrop={(e) =>
                            handleOnDrop(e, "courier", courier.label, index)
                          }
                          key={`${courier.label}_tooltip`}
                        >
                          <div className="d-flex justify-content-center">
                            {courier.label === "PostEx" ? (
                              <img
                                src="./img/companiesLogo/postEx.png"
                                alt="postEx"
                                height="100px"
                              />
                            ) : courier.label === "Leopard" ? (
                              <img
                                src="./img/companiesLogo/LCS.png"
                                alt="leopard"
                                height="100px"
                              />
                            ) : courier.label === "Swyft" ? (
                              <img
                                src="./img/companiesLogo/swyft.png"
                                alt="swyft"
                                height="100px"
                              />
                            ) : courier.label === "M&P" ? (
                              <img
                                src="./img/companiesLogo/MP-Logo.png"
                                alt="m&p"
                                height="100px"
                              />
                            ) : courier.label === "TPL" ? (
                              <img
                                src="./img/companiesLogo/Rider.png"
                                alt="tpl"
                                height="100px"
                              />
                            ) : (
                              courier.label === "Trax" && (
                                <img
                                  src="./img/companiesLogo/Trax.png"
                                  alt="tpl"
                                  height="100px"
                                />
                              )
                            )}
                          </div>
                          {compareIndex1.includes(courier.label) ? null : (
                            <h4 className={"custom-placeholder"}>
                              Drag Here....
                            </h4>
                          )}

                          <div
                            style={{
                              padding: "12px",
                              overflow: "auto",
                            }}
                            className="customScroll"
                          >
                            {Object.keys(dragCities).length > 0 &&
                              dragCities[courier.label] &&
                              dragCities[courier.label].map((city, i) => (
                                <Card key={city._id} className="mb-2" draggable>
                                  <CardBody id={city._id}>
                                    <div className="d-flex justify-content-between ">
                                      <div className="d-flex align-items-center">
                                        <img
                                          src="./img/location/Location.png"
                                          alt="not found"
                                        />
                                        <p
                                          className="ms-3 mb-0"
                                          style={{ color: "#909090" }}
                                        >
                                          {city.label}
                                        </p>
                                      </div>
                                      <div>
                                        <div
                                          className="pointer text-danger"
                                          onClick={(e) =>
                                            handleCross(
                                              e,
                                              city.label,
                                              courier.label
                                            )
                                          }
                                        >
                                          x
                                        </div>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              ))}
                          </div>
                        </Card>
                      </Cols>
                    ))}
                  </>
                ) : (
                  <div className="loading"></div>
                )}
              </Row>
            </Cols>
          </Row>

          <footer className="float-right">
            <Button
              className="btn btn-secondary  me-2 py-2"
              onClick={() => history.push("/couriers")}
            >
              Go Back
            </Button>
            <Button
              type="button"
              outline
              color="primary"
              size="lg"
              className="py-2 my-4"
              style={citiesCourierSpinner ? { padding: "10px 69px" } : null}
              onClick={handleCity}
            >
              {citiesCourierSpinner ? (
                <Spinner size={"sm"}>Loading...</Spinner>
              ) : (
                " Save & Next"
              )}
            </Button>
          </footer>
        </>
      ) : (
        history.push("/couriers")
      )}
    </div>
  );
};

export default CitiesCouriers;

import axios from "axios";
import { getCookie } from "../helpers/cookie";
import { getAPIURL } from "./utils";
import { toast } from "react-toastify";

const url = getAPIURL();

export const getAllBlockList = async () => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let response;
  try {
    response = await axios.get(`${url}/api/blockList`, config);
  } catch (e) {
    response = e;
    console.log(response);
    toast.error(`failed to fetch blockList`);
  }

  return response;
};

export const saveNewBlockList = async (list) => {
  const token = getCookie();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  let response;
  try {
    response = await axios.post(
      `${url}/api/blockList/save`,
      { ...list },
      config
    );

    toast.success(`Saved Successfully!`);
  } catch (e) {
    response = e;
    console.log(response);
    toast.error(`failed to save blockList`);
  }

  return response;
};

export const deleteBlockList = async (list) => {
  const token = getCookie();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  let response;
  if (list?.length) {
    list = { list: list?.map((o) => o?._id) };

    try {
      response = await axios.post(
        `${url}/api/blockList/delete`,
        { ...list },
        config
      );
      toast.success(`Deleted Successfully!`);
    } catch (e) {
      response = e;
      console.log(response);
      toast.error(`failed to delete blockList`);
    }
  } else {
    response = {};
  }

  return response;
};

import axios from "axios";
import { getAPIURL } from "./utils";
import { getCookie } from "../helpers/cookie";
const url = getAPIURL();

// Get Shipper Info
export const getShipperInfo = (storeId) => {
  const token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let response;
  try {
    response = axios.get(`${url}/api/shippers/${storeId}`, config);
  } catch (err) {
    response = err;
  }
  return response;
};
export const getMultiShippers = (storeIds) => {
  const token = getCookie();

  let filter = storeIds.filter((val, id) => storeIds.indexOf(val) === id);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let response;

  try {
    response = axios.post(
      `${url}/api/shippers/getMultiShippers`,
      { data: filter },
      config
    );
  } catch (err) {
    response = err;
  }
  return response;
};

// Add New Shipper
export const addShipperInfo = (data) => {
  const token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let response;
  try {
    response = axios.post(`${url}/api/shippers/addShipperInfo`, data, config);
  } catch (e) {
    response = e;
  }

  return response;
};

// Update Shipper Info
export const updateShipperInfo = (
  storeId,
  storeDisplayName,
  orderRefNumPattern,
  supportPhoneNum,
  email,
  city,
  address
) => {
  const data = {
    storeDisplayName,
    orderRefNumPattern,
    supportPhoneNum,
    email,
    city,
    address,
  };
  const token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let response;
  try {
    response = axios.patch(
      `${url}/api/shippers/updateShipperInfo/${storeId}`,
      data,
      config
    );
  } catch (e) {
    response = e;
  }

  return response;
};

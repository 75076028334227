import axios from "axios";
import { getAPIURL } from "./utils";
import { getCookie } from "../helpers/cookie";

const url = getAPIURL();

export const getWebhooks = async ({ storeId }) => {
  const token = getCookie();

  const params = new URLSearchParams();
  params.append("storeId", storeId?.toString().trim());

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params,
  };

  let response = await axios.get(`${url}/webhooks/fetchWebhooks`, config);

  return response;
};

export const createWebhooks = async (data) => {
  const token = getCookie();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.post(
    `${url}/webhooks/createWebhooks`,
    data,
    config
  );

  return response;
};

export const deleteWebhooks = async (data) => {
  const token = getCookie();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data,
  };

  let response = await axios.delete(`${url}/webhooks/deletehWebhooks`, config);

  return response;
};

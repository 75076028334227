import axios from "axios";
import { getCookie } from "../helpers/cookie";
import { getAPIURL } from "./utils";

const url = getAPIURL();

export const getDashboardData = async ({ startDateIs, endDateIs }) => {
  let token = getCookie();
  const params = new URLSearchParams();

  params.append("startDate", startDateIs);
  params.append("endDate", endDateIs);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  };
  return await axios.get(`${url}/api/dashboard/getDashboardOrders`, config);
};

import React, { useEffect, useMemo, useState } from "react";
import { Fade, Input, Label } from "reactstrap";
import useExcelToJson from "../standalone/fileUpload";
import {
  deleteBlockList,
  getAllBlockList,
  saveNewBlockList,
} from "../../api/blockList";

const BlockList = () => {
  const [fetchedList, setFetchedList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isWaiting, setIsWaiting] = useState(false);
  const {
    fileName,
    uploadedFileData,
    handleFileUpload,
    setUploadedFileData,
    setFileName,
  } = useExcelToJson();

  const triggerFetch = async () => {
    const { data } = await getAllBlockList();

    if (data?.list) {
      setFetchedList(data?.list);
    }
    setIsLoading(false);
    setIsWaiting(false);
  };
  useEffect(() => {
    triggerFetch();
  }, []);

  const onItemCheck = (e, i) => {
    let tempList = fetchedList;
    tempList.map((obj, index) => {
      if (i === index) {
        obj.selected = e.target.checked;
      }
      return obj;
    });
    setFetchedList(tempList);
    setSelected(tempList.filter((o) => o?.selected));
  };

  const handleSaveImported = async () => {
    setIsWaiting(true);
    const { data } = await saveNewBlockList({ list: uploadedFileData });
    if (data?.saved) {
      triggerFetch();
      setUploadedFileData([]);
      setFileName("");
      setSelected([]);
    }
  };
  const handleDelete = async () => {
    setIsWaiting(true);

    const { data = {} } = await deleteBlockList(selected);
    if (data?.deleted) {
      triggerFetch();
      setSelected([]);
    }
  };

  return (
    <main>
      <div className="row top-text">
        <div className="cols-12">
          <h1 className="card-heading">BlockList</h1>
        </div>
      </div>
      <div className=" separator mb-5 mt-3" />
      <div className="row mb-5">
        <div className="cols-12">
          <div className="card">
            <div className="card-body">
              <Label
                className=" text-center mediaAlign w-100"
                for="blockListFile"
              >
                <div className="mt-5">
                  <Label for="blockListFile" className="fileinputAlign">
                    Import File
                  </Label>
                  <input
                    type="file"
                    hidden
                    id="blockListFile"
                    accept=".csv, .xlsx, .xls"
                    onChange={(e) => handleFileUpload(e.target.files[0])}
                  />
                </div>

                <p
                  style={{ fontSize: "12px" }}
                  className={`mx-1 ${fileName?.trim() ? "text-success" : ""}`}
                >
                  {fileName?.trim() ? fileName : "CSV, XLSX, XLS "}
                </p>
              </Label>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {uploadedFileData && uploadedFileData?.length > 0 && (
            <div
              className="card customScroll mb-5"
              style={{
                maxHeight: "500px",
                overflow: "auto",
              }}
            >
              <div className="card-body">
                {uploadedFileData.map((obj, i) => (
                  <div
                    className="d-flex mb-3 flex-row card"
                    style={{ background: "lightGray" }}
                    key={i + 1}
                  >
                    <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                      <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                        <p className="mb-1 text-muted text-small w-15 w-sm-100">
                          {obj?.orderId || "N/A"}
                        </p>
                        <span
                          aria-current="page"
                          className="w-50 w-sm-100 active mr-2"
                        >
                          <p className="list-item-heading mb-1 truncate">
                            {obj?.name || "N/A"}
                          </p>
                        </span>
                        <p className="mb-1 text-muted text-small w-50 truncate">
                          {obj?.phone || "N/A"}
                        </p>

                        {/* <div className=" w-25">
                            <span className="badge badge-outline-primary badge-pill">
                              {new Date(obj.created_at)?.toDateString()}
                            </span>
                          </div> */}
                      </div>
                      <div className="custom-control custom-checkbox pl-1 align-self-center pr-4">
                        <div className="item-check mt-2 custom-checkbox custom-control">
                          <span
                            className="simple-icon-close fs-6 text-danger fw-bold pointer"
                            onClick={() => {
                              setUploadedFileData(
                                uploadedFileData.filter((o, ind) => ind !== i)
                              );
                            }}
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="card" style={{ background: "lightGray" }}>
            <div className="card-body">
              {isLoading ? (
                <div className="position-relative mt-5">
                  <span className="loading bottom-50 position-absolute"></span>
                </div>
              ) : fetchedList?.length > 0 ? (
                fetchedList.map((obj, i) => (
                  <div className="d-flex mb-3 flex-row card" key={i + 1}>
                    <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                      <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                        <p className="mb-1 text-muted text-small w-15 w-sm-100">
                          {obj?.orderId || "N/A"}
                        </p>
                        <span
                          aria-current="page"
                          className="w-50 w-sm-100 active mr-2"
                        >
                          <p className="list-item-heading mb-1 truncate">
                            {obj?.name || "N/A"}
                          </p>
                        </span>
                        <p className="mb-1 text-muted text-small w-50 truncate">
                          {obj?.phone || "N/A"}
                        </p>

                        {/* <div className=" w-25">
                            <span className="badge badge-outline-primary badge-pill">
                              {new Date(obj.created_at)?.toDateString()}
                            </span>
                          </div> */}
                      </div>
                      <div className="custom-control custom-checkbox pl-1 align-self-center pr-4">
                        <div className="item-check custom-checkbox custom-control">
                          {/* <span className="simple-icon-trash fs-6 text-danger fw-bold pointer"></span> */}
                          <label className="custom-control mb-0 pointer pr-0">
                            <Input
                              className="item-check pointer"
                              type="checkbox"
                              checked={obj.selected}
                              label=""
                              style={{
                                border: "0.1px solid gray",
                                fontSize: "16px",
                              }}
                              onChange={(e) => onItemCheck(e, i)}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div
                  className=" d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
                  style={{
                    height: "150px",
                  }}
                >
                  <span>Empty!</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {((uploadedFileData && uploadedFileData?.length > 0) ||
        selected.length > 0) && (
        <Fade
          className="alert alert-muted w-auto position-fixed rounded-pill d-flex align-items-center justify-content-between flex-nowrap text-black shadow-lg"
          style={{
            bottom: "30px",
            left: "50%",
            zIndex: "2",
            whiteSpace: "nowrap",
          }}
          role="alert"
        >
          {uploadedFileData.length > 0 && (
            <button
              className="btn rounded-pill btn-primary"
              onClick={handleSaveImported}
              disabled={isWaiting}
            >
              Save
            </button>
          )}
          {selected.length > 0 && (
            <button
              className="btn rounded-pill btn-danger ml-3"
              onClick={handleDelete}
              disabled={isWaiting}
            >
              Delete
            </button>
          )}
        </Fade>
      )}
    </main>
  );
};

export default BlockList;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

// import { useSelector } from "react-redux";

import Select from "react-select";
import { Cols } from "../SeparatorStyle/SeparatorStyle";
import { getLocalStorage } from "../../helpers/localStorage";
import { roles } from "../../constants/roles";

// import { rightAnimate, leftAnimate } from "../FramerMotion/FramerMotion";
import { getAllStoresLoginUser, saveMasterStore } from "../../api";
import { toast } from "react-toastify";
import { accountDeletionById } from "../../api/auth"; //////
import { getDashboardData } from "../../api/dashboard";
import {
  isoLast15Days,
  isoLast30Days,
  isoLast90Days,
  isoLastWeek,
  isoToday,
  isoTodayEnd,
  isoYesterday,
} from "../../helpers/isoDates";
import DateRange from "../../helpers/DateRange";
import { saveNewDateRange } from "../../api/dateRanges";
const Dashboard = () => {
  // const current = useSelector((state) => state.orders.allOrders);
  // const thirtyDaysOrders = useSelector(
  //   (state) => state.orders.thirtyDaysOrders
  // );
  // const fifteenDaysOrders = useSelector(
  //   (state) => state.orders.fifteenDaysOrders
  // );
  // const lastWeekDaysOrders = useSelector(
  //   (state) => state.orders.lastWeekDaysOrders
  // );
  // const todayOrders = useSelector((state) => state.orders.todayOrders);

  // const [storesData, setStoresData] = useState([]);
  // const [totalOrders, setTotalOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [refundsAmountByDate, setRefundsAmountByDate] = useState(0);
  // const [unfulfilledRefundsByDate, setUnfulfilledRefundsByDate] = useState(0);
  // const [unfulfilledOrders, setUnfulfilledOrders] = useState([]);
  const [salesDataByStores, setSalesDataByStores] = useState([]);

  const daysFilterData = [
    { label: "Today", value: 0, key: 5 },
    { label: "Yesterday - Today", value: 1, key: 6 },
    { label: "Last Week", value: 7, key: 4 },
    { label: "Last 15 Days", value: 15, key: 3 },
    { label: "Last 30 Days", value: 30, key: 2 },
    { label: "Last 90 Days", value: 90, key: 1 },
  ];

  const [dateRange, setDateRange] = useState([null, null]);

  const [selectedOption, setSelectedOption] = useState({
    label: "Today",
    value: 0,
    key: 5,
  });

  const loadOrdersWithRefunds = useCallback(
    async ({ startDateIs = isoToday, endDateIs = isoTodayEnd }) => {
      try {
        setSalesDataByStores([]);
        setLoading(true);
        let { data } = await getDashboardData({
          startDateIs,
          endDateIs,
        });

        await handleCalculateSales(data.separatStoresData);
        setLoading(false);
        return data.orders;
      } catch (error) {
        console.log("error found when fetch order data", error);
      }
    },
    []
  );

  const handleSaveRanges = async () => {
    const { data } = await saveNewDateRange({
      start: dateRange[0],
      end: dateRange[1],
    });
  };
  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      setSelectedOption(null);
      handleSaveRanges();
      return loadOrdersWithRefunds({
        startDateIs: new Date(
          new Date(dateRange[0]).setHours(0, 0, 0, 0)
        ).toISOString(),
        endDateIs: new Date(
          new Date(dateRange[1]).setHours(23, 59, 59, 0)
        ).toISOString(),
      });
    } else {
      if (!dateRange[0] && !dateRange[1]) {
        let defaultDate = !selectedOption
          ? {
              label: "Today",
              value: 0,
              key: 5,
            }
          : { ...selectedOption };
        setSelectedOption(defaultDate);
        defaultDate?.label === "Today" && loadOrdersWithRefunds({});
      }
    }
  }, [dateRange[0] && dateRange[1]]);

  const handleMakeStoreMaster = async (id, isMaster) => {
    try {
      await saveMasterStore(id, isMaster);
    } catch (err) {
      toast.error("Error in Setting up Master Store!");
    }
  };
  const loadStores = useCallback(async () => {
    try {
      const { data } = await getAllStoresLoginUser();
      const { stores } = data[0];
      if (stores.length) {
        const isMasterStore = stores.find((store) => store.isMasterStore);
        if (!isMasterStore) await handleMakeStoreMaster(stores[0]._id, true);
      }
    } catch (error) {
      toast.error("Error in Fetching Stores Data!");
      console.log(error);
    }
  }, []);

  const calculateRefunds = async (refunds) => {
    let refundAmount = 0;

    for (let refund of refunds) {
      // refund.transactions.forEach((transaction) => {
      //   if (transaction.kind === "refund") {
      //     refundAmount += Number(transaction.amount);
      //   } else if (transaction.kind === "void") {
      //     refundAmount -= Number(transaction.amount);
      //   }
      // });
      for (let refundItem of refund.refund_line_items) {
        refundAmount +=
          Number(refundItem.subtotal) + Number(refundItem.total_tax);
      }

      if (refund?.order_adjustments.length) {
        refund?.order_adjustments.forEach((obj) => {
          if (obj.kind === "shipping_refund") {
            refundAmount += Number(obj.amount) * -1;
          } else {
            console.log({ adjustmentKind: obj.kind });
          }
        });
      }
    }
    return refundAmount;
  };
  // useEffect(() => {
  //   let arr = ['6574ab3a343d7c7b54b2b794'];
  //   const hit = async () => {
  //     try {
  //       for (let id of arr) {
  //         await accountDeletionById(id);
  //       }
  //     } catch (error) {
  //       console.log("Deleting Error...");
  //       console.log(error.response.data);
  //     }
  //   };
  //   return hit();
  // }, []);

  useEffect(() => {
    loadStores();
    // loadOrdersWithRefunds({});
  }, []);

  const handleCalculateSales = async (storesData) => {
    let totalSalesByStores = [];
    for (let storeData of storesData) {
      const { allRefunds, orders } = storeData;

      const copyArr = JSON.parse(JSON.stringify(orders));
      const copyArrRefunds = JSON.parse(JSON.stringify(allRefunds));

      storeData.totalRefunds = await calculateRefunds(copyArrRefunds);
      storeData.totalOrders = [...copyArr].map((o) => o?.total_price);

      let unfulfilled = [...copyArr].filter(
        (f) => f.fulfillment_status === null
      );
      let unfulfilledRefunds = copyArrRefunds.filter((obj) =>
        unfulfilled.some((r) => Number(r.orderId) === Number(obj.order_id))
      );
      storeData.unfulfilledRefunds = await calculateRefunds(unfulfilledRefunds);
      storeData.unfulfilledOrders = unfulfilled.map((o) => o?.total_price);
      delete storeData.orders;
      delete storeData.allRefunds;
      totalSalesByStores.push({ ...storeData });
    }
    setSalesDataByStores(totalSalesByStores);
    // console.log(totalSalesByStores);
  };

  const { admin } = roles;
  const history = useHistory();

  const handleDate = (e) => {
    setSelectedOption(e);
    setDateRange([null, null]);
    if (e.value === 0) {
      loadOrdersWithRefunds({ startDateIs: isoToday, endDateIs: isoTodayEnd });
    } else if (e.value === 1) {
      loadOrdersWithRefunds({
        startDateIs: isoYesterday,
        endDateIs: isoTodayEnd,
      });
    } else if (e.value === 7) {
      loadOrdersWithRefunds({
        startDateIs: isoLastWeek,
        endDateIs: isoTodayEnd,
      });
    } else if (e.value === 15) {
      loadOrdersWithRefunds({
        startDateIs: isoLast15Days,
        endDateIs: isoTodayEnd,
      });
    } else if (e.value === 30) {
      loadOrdersWithRefunds({
        startDateIs: isoLast30Days,
        endDateIs: isoTodayEnd,
      });
    } else if (e.value === 90) {
      loadOrdersWithRefunds({
        startDateIs: isoLast90Days,
        endDateIs: isoTodayEnd,
      });
    }
  };

  useEffect(() => {
    const role =
      getLocalStorage("user") &&
      getLocalStorage("user").role &&
      getLocalStorage("user").role.name;
    if (role === admin) {
    } else {
      history.push("/signIn");
    }
  }, []);

  const sumPrice = (a, b) => {
    return Number(a) + Number(b);
  };

  return (
    <main>
      <div className="container-fluid">
        <div className="container-fluid">
          <div className="row mb-4 top-text">
            <div className="col-12">
              <h1>Dashboard</h1>
              <nav
                className="breadcrumb-container d-none d-sm-block d-lg-inline-block"
                aria-label="breadcrumb"
              >
                <ol className="breadcrumb pt-0">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item">Dashboard</li>
                </ol>
              </nav>
              <div className="separator mb-1" />
            </div>
          </div>
        </div>
        {!loading && salesDataByStores?.length === 0 ? (
          <div
            className="d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
            style={{
              height: "200px",
            }}
          >
            <span>No Stores Found!</span>
          </div>
        ) : (
          <div className="mb-4 gap-3 d-flex flex-wrap justify-content-end align-items-center">
            <span className="">
              <DateRange
                dateRange={dateRange}
                loading={loading}
                setDateRange={setDateRange}
              />
            </span>
            <span style={{ width: "260px" }}>
              <Select
                hideSelectedOptions
                isDisabled={loading}
                // className="react-select"
                // classNamePrefix="react-select"
                // name="form-field-name"
                value={selectedOption}
                onChange={(e) => handleDate(e)}
                options={daysFilterData}
              />
            </span>
          </div>
        )}
        {loading && (
          <>
            <span className="skeleton skeleton-text w-15 mb-3"></span>
            <div className="row icon-cards-row">
              {new Array(4).fill("0").map((it, i) => (
                <Cols
                  xxs="12"
                  xs="6"
                  sm="6"
                  md="4"
                  lg="3"
                  className="mb-2"
                  key={i}
                >
                  <div className="skeleton skeleton-box"></div>
                </Cols>
              ))}
            </div>
          </>
        )}
        {salesDataByStores.map((s, i) => (
          <div key={i + 1}>
            {/* {i !== 0 && <div className="separator my-5" />} */}
            <h3 className="my-4 fw-bold">
              {s.shopName.split(" ").slice(0, 2).join(" ")}
              {s.isMaster && (
                <i className="ms-3 fw-light fs-6">{"*Master Store*"}</i>
              )}
            </h3>

            <div className="row icon-cards-row">
              {
                <>
                  <Cols xxs="12" xs="6" sm="6" md="4" lg="3" className="mb-2">
                    <div className="card">
                      <div className="card-body text-center">
                        <i className="iconsminds-shopping-basket" />
                        <h4 className="card-text text-secondary mb-0">
                          Total Orders
                        </h4>
                        <p className="lead text-center">
                          {s.totalOrders.length.toLocaleString()}
                        </p>
                      </div>
                    </div>
                  </Cols>
                  <Cols xxs="12" xs="6" sm="6" md="4" lg="3" className="mb-2">
                    <div className="card position-relative">
                      <span className="position-absolute badge-top-left badge badge-primary badge-pill">
                        {"Orders : "}
                        {Math.trunc(
                          s.totalOrders.reduce(sumPrice, 0)
                        ).toLocaleString()}
                      </span>
                      <span className="position-absolute badge-top-left-2 badge badge-danger badge-pill">
                        {"Returns : -"}
                        {Math.trunc(s.totalRefunds).toLocaleString()}
                      </span>
                      <div className="card-body text-center">
                        <i className="iconsminds-money-bag" />
                        <h4 className="card-text text-secondary mb-0">
                          Total Sales
                        </h4>
                        <p className="lead text-center">
                          Rs{" "}
                          {Math.trunc(
                            s.totalOrders.reduce(sumPrice, 0) - s.totalRefunds
                          ).toLocaleString()}
                        </p>
                      </div>
                    </div>
                  </Cols>
                  <Cols xxs="12" xs="6" sm="6" md="4" lg="3" className="mb-2">
                    <div className="card">
                      <div className="card-body text-center">
                        <i className="iconsminds-clock" />
                        <h4 className="card-text text-secondary mb-0">
                          Unfulfilled Orders
                        </h4>
                        <p className="lead text-center">
                          {s.unfulfilledOrders.length.toLocaleString()}
                        </p>
                      </div>
                    </div>
                  </Cols>
                  <Cols xxs="12" xs="6" sm="6" md="4" lg="3" className="mb-2">
                    <div className="card position-relative">
                      <span className="position-absolute badge-top-left badge badge-primary badge-pill">
                        {"Orders : "}
                        {Math.trunc(
                          s.unfulfilledOrders.reduce(sumPrice, 0)
                        ).toLocaleString()}
                      </span>
                      <span className="position-absolute badge-top-left-2 badge badge-danger badge-pill">
                        {"Returns : -"}
                        {Math.trunc(s.unfulfilledRefunds).toLocaleString()}
                      </span>
                      <div className="card-body text-center">
                        <i className="iconsminds-money-bag" />
                        <h4 className="card-text text-secondary mb-0">
                          Unfulfilled Sales
                        </h4>
                        <p className="lead text-center">
                          Rs{" "}
                          {Math.trunc(
                            s.unfulfilledOrders.reduce(sumPrice, 0) -
                              s.unfulfilledRefunds
                          ).toLocaleString()}
                        </p>
                      </div>
                    </div>
                  </Cols>
                </>
              }
            </div>
          </div>
        ))}
      </div>
    </main>
  );
};

export default Dashboard;

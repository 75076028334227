import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  STORE_SIGNUP_CREDENTIALS,
  SIGNUP_STEP1_COMPLETED,
} from '../constants/authConstants';
import { signIn, verifyEmail } from '../../api/auth';
import { setAuthDataInStorage } from '../../helpers/auth';
import { toast } from 'react-toastify';

export const login = (credentials) => async (dispatch) => {
  dispatch({ type: USER_LOGIN_REQUEST });
  try {
    const { data } = await signIn(credentials);
    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
    setAuthDataInStorage(data.token, data.user);
  } catch (e) {
    dispatch({ type: USER_LOGIN_FAIL, payload: e.errorMessage });
  }
};

export const userInfo = (username) => {
  return {
    type: 'userInfo',
    username: username,
  };
};
export const userImage = (image) => {
  return {
    type: 'updateUserImage',
    image: image,
  };
};

export const storeSignUpCredentials = (userInfo) => ({
  type: STORE_SIGNUP_CREDENTIALS,
  payload: userInfo,
});

export const signUpStep1Comp = () => ({ type: SIGNUP_STEP1_COMPLETED });
// -----------------email verifcation ------------
export const sendEmailVerification = async (credentials) => {
  let verificationToastId = toast('Hold Up, while we are setting things!', {
    isLoading: true,
    autoClose: false,
    closeButton: false,
  });
  try {
    if (!credentials) throw Error('No Credentials Found!');
    await verifyEmail(credentials);
    toast.update(verificationToastId, {
      isLoading: false,
      // render: 'Verification email has been sent!, Just a click away',
      render: 'Just a click away!',
      autoClose: true,
      type: toast.TYPE.SUCCESS,
    });
  } catch (error) {
    toast.update(verificationToastId, {
      type: toast.TYPE.ERROR,
      isLoading: false,
      autoClose: true,
      render: error.message,
    });
    return Promise.reject(error);
  }
};

import axios from "axios";
import { getCookie } from "../helpers/cookie";
import { getAPIURL } from "./utils";
const url = getAPIURL();

export const getStoresWhatsappMsgs = async () => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${url}/api/whatsappMsgs/getAllWhatsappMessagesOfAccount`,
    config
  );

  return response;
};

// export const addWhatsappMessages = async (data) => {
//   let token = getCookie();

//   const config = {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`,
//     },
//   };
//   const response = await axios.post(
//     `${url}/api/whatsappMsgs/addWhatsappMessages`,
//     data,
//     config
//   );
//   return response;
// };

export const updateMessages = async (data) => {
  const token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let response = await axios.put(
    `${url}/api/whatsappMsgs/updateWhatsapMsgs`,
    data,
    config
  );
  return response;
};

import React, { useState, useEffect } from "react";
import { Cols } from "../SeparatorStyle/SeparatorStyle";
import { Link, NavLink } from "react-router-dom";
import { Row, Card, CardBody, Breadcrumb } from "reactstrap";
import axios from "axios";
import { getCookie } from "../../helpers/cookie";

const Search = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);

  const fetchData = async () => {
    let token = getCookie();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .get(`/api/orders/allOrders`, config)
      .then((res) => {
        setItems(res.data.orders);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error found", err);
      });
  };
  useEffect(() => {
    if (items.length === 0) {
      fetchData();
    }
  }, [items]);

  return (
    <>
      <main>
        <Row>
          <Cols xxs="12">
            <h1>Search</h1>
            <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
              <ol className="breadcrumb pt-0">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" style={{ color: "black" }}>
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  all search
                </li>
              </ol>
            </Breadcrumb>
          </Cols>
        </Row>
        <Row>
          <Cols xxs="12" className="mb-4">
            <Card>
              <CardBody>
                {!isLoading ? (
                  items.map((item, i) => {
                    return (
                      <div
                        key={`item_${i}`}
                        className={`${items.length !== i + 1 ? "mb-3" : ""}`}
                      >
                        <NavLink
                          to={`#${item.shipping_address.name}`}
                          className="w-40 w-sm-100"
                        >
                          <p className="list-item-heading mb-1 color-theme-1">
                            {item.total_price_set.shop_money.amount}
                          </p>
                          <p className="mb-1 text-muted text-small">
                            Products | {item.shipping_address.city}
                          </p>
                          <p className="mb-4 text-small">
                            {item.shipping_address.address1}
                          </p>
                        </NavLink>
                        {items.length !== i + 1 && (
                          <div className={`separator`} />
                        )}
                      </div>
                    );
                  })
                ) : (
                  <div className="loading" />
                )}
              </CardBody>
            </Card>
          </Cols>
          {/* <Pagination
          currentPage={currentPage}
          totalPage={totalPage}
          onChangePage={(i) => setCurrentPage(i)}
        /> */}
        </Row>
      </main>
    </>
  );
};

export default Search;

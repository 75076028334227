import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Card,
  Col,
  Button,
  Input,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import isEmpty from "validator/lib/isEmpty";
import { getUserPreferences } from "../../../api/courier";

const CourierAPiKeys = () => {
  let history = useHistory();
  const [couriersList, setCouriersList] = useState([]);
  const [checkBox, setCheckBox] = useState(false);

  const [formData, setFormData] = useState({
    courierApi: "",
    loginId: "",
    successMsg: false,
    errorMsg: false,
    infoMsg: false,
  });

  const { courierApi, loginId } = formData;

  const handleChange = (evt) => {
    setFormData({
      ...formData,
      [evt.target.name]: evt.target.value,
      successMsg: false,
      errorMsg: false,
      infoMsg: false,
    });
  };

  const loadCourier = useCallback(async () => {
    try {
      const { data: userPreferenceCouriers } = await getUserPreferences();
      setCouriersList(userPreferenceCouriers.couriers);
    } catch (error) {
      console.log("error found when fetch courier data", error);
    }
  }, []);

  useEffect(() => {
    loadCourier();
  }, [loadCourier]);

  const checkboxHandle = (e, label) => {
    if (e.target.value === label) {
      setCheckBox(!checkBox);
    } else {
      setCheckBox(false);
    }
  };

  const handleCourierSubmit = (e) => {
    e.preventDefault();
    if (isEmpty(courierApi) || isEmpty(loginId)) {
      setFormData({
        ...formData,
        infoMsg: "",
      });
    } else {
      setFormData({
        ...formData,
      });
    }
  };

  return (
    <>
      {!couriersList ? (
        <div
          className=" position-relative"
          style={{
            height: "70vh",
          }}
        >
          <span className=" position-absolute loading"></span>
        </div>
      ) : (
        <>
          <Row>
            <Col sm="10" className="courier-Card">
              <Card body>
                <Form noValidate>
                  {couriersList &&
                    couriersList.map((courier) => (
                      <Row>
                        {courier.requiredFields.length !== 0 ? (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              marginBottom: "30px",
                            }}
                          >
                            <div
                              className="left_list_container"
                              style={{
                                width: "20%",
                                display: "flex",
                                marginLeft: "1.5rem",
                              }}
                            >
                              <Input
                                type="checkbox"
                                value={courier.label}
                                onClick={(e) =>
                                  checkboxHandle(e, courier.label)
                                }
                                checked={checkBox}
                              />
                              <h6>{courier.label}</h6>
                            </div>
                            <div
                              className="right_container"
                              style={{ width: "80%" }}
                            >
                              {courier.requiredFields.map((fields) =>
                                checkBox === true ? (
                                  <Col xs="8">
                                    <FormGroup className="form-group has-float-label mb-4">
                                      <Label>{fields.label}</Label>
                                      <Input
                                        type="text"
                                        name="courierApi"
                                        onChange={handleChange}
                                      />
                                    </FormGroup>
                                  </Col>
                                ) : (
                                  ""
                                )
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <br />
                        <br />
                      </Row>
                    ))}
                  <div className="d-flex justify-content-end align-items-center">
                    <Button
                      type="button"
                      className="btn btn-primary mb-4 float-right mt-5"
                      onClick={handleCourierSubmit}
                    >
                      Submit your Courier Keys
                    </Button>
                    <Button
                      type="button"
                      className="btn btn-primary mb-4 mr-3 ml-2 mt-5"
                      onClick={() => history.push("/waiting")}
                    >
                      Skip
                    </Button>
                  </div>
                </Form>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default CourierAPiKeys;

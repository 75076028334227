import React, { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
// import { ExportToCsv } from "export-to-csv";
import {
  Row,
  CardBody,
  Card,
  Input,
  Breadcrumb,
  Alert,
  Spinner,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import { Cols } from "../SeparatorStyle/SeparatorStyle";
import DataTable from "../tables/table.jsx";
import Select from "react-select";
import { BsWhatsapp } from "react-icons/bs";
import CopyToClipboard from "react-copy-to-clipboard";
import { MdContentCopy } from "react-icons/md";
import { getAllCustomers } from "../../api/customers";
import { updateCustomerPhone } from "../../api/customers";
import { Link } from "react-router-dom";
import { getStoresWhatsappMsgs } from "../../api/whatsappMsg";
import { getAllStoresLoginUser } from "../../api";
// import { toCustomersFormat } from "../../helpers/customersExport";
import { handleSearch } from "../search/searchHook";
import { motion } from "framer-motion";
import { leftAnimate } from "../FramerMotion/FramerMotion";
import { popOverContent } from "../../popOvers/popOver";
import TableSkeleton from "../UI/TableSkeleton";
const AllCustomers = () => {
  const [customers, setCustomers] = useState([]);

  const [showStoreColumn, setShowStoreColumn] = useState(false);
  const [tooltipMsg, setTooltipMsg] = useState("Copy");
  const [pageLimit, setPageLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filterLoading, setFilterLoading] = useState(false);

  const [paginationLoading, setPaginationLoading] = useState(false);
  const [emptyStatus, setEmptyStatus] = useState(false);
  const [count, setCount] = useState(0);
  const [editPhoneNumber, setEditPhoneNumber] = useState("");
  const [newPhone, setNewPhone] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [cross, setCross] = useState(false);
  const [accountWhtsapMsgs, setAccountWhtsapMsgs] = useState([]);
  const [customerMsg, setCustomerMsg] = useState({ message: "", openIn: "" });
  const [sortingValue, setSortingValue] = useState("");
  const [allSortValue, setAllSortValue] = useState("");

  const [sortingData, setSortingData] = useState([]);

  const [searchNameValue, setSearchNameValue] = useState("");
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [stores, setStores] = useState([]);

  let sortingSelect = [
    { value: "ascending-name", label: "Name (A-Z)" },
    { value: "descending-name", label: "Name (Z-A)" },
    { value: "ascending-city", label: "City (A-Z)" },
    { value: "descending-city", label: "City (Z-A)" },
    { value: "ascending-orders", label: "Orders (A-Z)" },
    { value: "descending-orders", label: "Orders (Z-A)" },
    { value: "ascending-spent", label: "Spent (A-Z)" },
    { value: "descending-spent", label: "Spent (Z-A)" },
  ];

  const fetchAllCustomers = useCallback(
    async ({
      page = pageNumber,
      limit = pageLimit,
      title = searchNameValue.trim().length > 2 ? searchNameValue : "",
      sorting = allSortValue?.value || "",
    }) => {
      setEmptyStatus(false);
      try {
        const { data } = await getAllCustomers({
          page,
          limit,
          title,
          sorting,
        });

        setCount(Math.ceil(data?.totalCustomers));

        if (data) {
          setSortingData(data?.customers);

          if (data.totalCustomers === 0) {
            setEmptyStatus(true);
          } else {
            setEmptyStatus(false);
          }
          setLoading(false);
          setFilterLoading(false);
          setPaginationLoading(false);
        }
      } catch (error) {
        toast.error("Error while fetch customers");
        console.log("Error found when fetch customers data!", error);
      }
    },
    [searchTrigger, allSortValue]
  );
  const totalPages = Math.ceil(count / pageLimit);
  // const handleExportCustomers = async (customers) => {
  //   const options = {
  //     fieldSeparator: ",",
  //     quoteStrings: '"',
  //     decimalSeparator: ".",
  //     showLabels: true,
  //     useTextFile: false,
  //     useBom: true,
  //     useKeysAsHeaders: true,
  //   };

  //   const toFormatedCustomers = toCustomersFormat(customers);

  //   let today = new Date().toISOString().slice(0, 10);
  //   const customersOptions = {
  //     filename: `Customers  ${today}`,
  //     ...options,
  //   };

  //   const csvExporter = new ExportToCsv(customersOptions);
  //   csvExporter.generateCsv(toFormatedCustomers);
  // };

  const loadWhatsappMessages = useCallback(async () => {
    try {
      const { data } = await getStoresWhatsappMsgs();
      if (data.length === 0) {
        toast.error("No whatsapp messages found!");
      }

      setAccountWhtsapMsgs(data);
    } catch (error) {
      toast.error("Error found while fetch Whatsapp Messages");
      console.log("Error found when fetch Whatsapp Messages data!", error);
    }
  }, []);
  const loadStores = useCallback(async () => {
    try {
      const { data } = await getAllStoresLoginUser();
      const { stores } = data[0];
      setStores(stores);
      stores.length > 1 && setShowStoreColumn(true);
    } catch (error) {
      toast.error("Error found when fetch stores data!");
      console.log(error);
    }
  }, []);

  useEffect(() => {
    setLoading(true);

    loadStores();
    loadWhatsappMessages();
  }, []);

  useEffect(() => {
    fetchAllCustomers({});
  }, [fetchAllCustomers]);

  const editPhoneNumberFunction = (
    phoneNumber,
    customerId,
    storeId,
    defaultAddressId,
    first_name,
    last_name,
    last_order_name,
    storeDisplayName
  ) => {
    storeDisplayName = stores.map(
      (obj) => obj._id === storeId && obj.shopDetail.name
    )[0];

    let formatPhoneNumber = (str) => {
      str = String(str);

      // Filter only numbers from the input
      let cleaned = ("" + str).replace(/\D/g, "");

      // Check if the input is of correct length
      let match = cleaned.slice(cleaned.indexOf("3"));

      if (match && match.length === 10) {
        // Change this to format for any country code.
        return "+92" + match;
      }

      return null;
    };

    const formatMsg = (customerMsg.message || "")
      ?.replace("[Name]", `${first_name} ${last_name}`)
      ?.replace("[ID]", (last_order_name || "N/A")?.replace("#", ""))
      ?.replace(
        "[Store domain]",
        storeDisplayName ? storeDisplayName : "our store"
      );

    return (
      <div key={customerId}>
        <div>
          {editPhoneNumber === customerId ? (
            <div className=" d-flex justify-content-center align-items-center">
              <Input
                className="bg-white rounded-3"
                type="tel"
                style={{
                  height: "35px",
                  // fontSize: "15px",
                  width: "140px",
                }}
                defaultValue={
                  phoneNumber !== "N/A" ? phoneNumber.replace(/\D/g, "") : ""
                }
                onChange={(e) =>
                  handlePhoneNumber(customerId, e, storeId, defaultAddressId)
                }
              />

              {cross && !spinner && (
                <span
                  className="far fa-check ms-2 fs-5 text-success pointer"
                  style={{}}
                  // hidden={createOption === true}
                  onClick={handlePhoneUpdate}
                ></span>
              )}
              {spinner && <Spinner className="ml-1" size={"sm"} />}

              {!spinner && (
                <span
                  className="far fa-close ms-2 text-danger pointer fs-5"
                  onClick={() => {
                    setEditPhoneNumber("");
                    setCross(false);
                  }}
                ></span>
              )}
            </div>
          ) : (
            <>
              {(
                <>
                  <UncontrolledPopover
                    trigger="hover"
                    placement="top"
                    target="showCopyTextTooltip"
                  >
                    <PopoverBody>{tooltipMsg}</PopoverBody>
                  </UncontrolledPopover>
                  <span
                    onClick={() => {
                      setTooltipMsg("Copied!");
                    }}
                    onMouseEnter={() => setTooltipMsg("Copy")}
                  >
                    {popOverContent(`Phone-${customerId}`, phoneNumber)}
                    <CopyToClipboard text={phoneNumber}>
                      <MdContentCopy
                        id="showCopyTextTooltip"
                        className="ml-2 text-muted fs-6 pointer"
                      />
                    </CopyToClipboard>
                  </span>
                  {formatPhoneNumber(phoneNumber) ? (
                    <a
                      onClick={() => {
                        accountWhtsapMsgs.filter((obj) => {
                          if (obj.storeId === storeId) {
                            setCustomerMsg({
                              ...customerMsg,
                              message: obj.customerMessage,
                              openIn: obj.openWhatsappIn,
                            });
                          }
                          return obj;
                        });
                      }}
                      className="pointer text-success ml-2"
                      style={{
                        fontSize: "14px",
                      }}
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Chat on WhatsApp"
                      href={
                        customerMsg.openIn === "desktop"
                          ? `https://wa.me/${formatPhoneNumber(
                              phoneNumber
                            )}?text=${formatMsg}`
                          : `https://web.whatsapp.com/send?phone=${formatPhoneNumber(
                              phoneNumber
                            )}&text=${formatMsg}`
                      }
                    >
                      <BsWhatsapp />
                    </a>
                  ) : (
                    <span
                      className="pointer text-danger ml-2 simple-icon-info"
                      style={{
                        fontSize: "14px",
                      }}
                      onClick={() => {
                        toast.warn(`${phoneNumber} is incorrect!`);
                      }}
                    ></span>
                  )}
                </>
              ) || "N/A"}

              <span
                className="simple-icon-pencil fs-6 ms-2 pointer"
                onClick={() => {
                  setEditPhoneNumber(customerId);
                  setSpinner(false);
                  setCross(false);
                }}
              ></span>
            </>
          )}
        </div>
      </div>
    );
  };

  const handlePhoneNumber = (customerId, e, storeId, defaultAddressId) => {
    setNewPhone({
      customerId: customerId,
      phone: e.target.value,
      storeId: storeId,
      defaultAddressId: defaultAddressId,
    });

    if (e.target.value !== editPhoneNumber) {
      setCross(true);
    } else {
      setCross(false);
    }
  };

  const handlePhoneUpdate = async () => {
    setSpinner(true);
    setPaginationLoading(true);
    let filterPhone = String(newPhone?.phone);

    if (filterPhone.startsWith("+92")) {
      newPhone.phone = filterPhone.replace("+92", "0");
    } else if (filterPhone.startsWith("92")) {
      newPhone.phone = filterPhone.replace("92", "0");
    }

    try {
      const { data } = await updateCustomerPhone(newPhone);
      if (data) {
        await fetchAllCustomers({});
        setEditPhoneNumber("");

        toast.success("Phone number updated successfully !");
        setSpinner(false);
        setPaginationLoading(false);
      }
    } catch (error) {
      setPaginationLoading(false);

      setSpinner(false);

      setEditPhoneNumber("");
      toast.error(error.toString());
      setCross(false);
    }
  };

  const handleSort = () => {
    let copyData = JSON.parse(JSON.stringify(sortingData));
    let sorting = sortingValue?.value;
    let sorted = [];

    if (sorting) {
      if (sorting.includes("-name")) {
        sorted =
          sorting === "ascending-name"
            ? copyData.sort((a, b) =>
                String(a?.first_name + " " + a?.last_name)
                  ?.toLowerCase()
                  ?.localeCompare(
                    String(b?.first_name + " " + b?.last_name)?.toLowerCase()
                  )
              )
            : copyData.sort((a, b) =>
                String(b?.first_name + " " + b?.last_name)
                  ?.toLowerCase()
                  ?.localeCompare(
                    String(a?.first_name + " " + a?.last_name)?.toLowerCase()
                  )
              );
      } else if (sorting.includes("-city")) {
        sorted =
          sorting === "ascending-city"
            ? copyData.sort((a, b) =>
                a.default_address?.city
                  ?.toLowerCase()
                  ?.localeCompare(b?.default_address?.city?.toLowerCase())
              )
            : copyData.sort((a, b) =>
                b.default_address?.city
                  ?.toLowerCase()
                  ?.localeCompare(a.default_address?.city?.toLowerCase())
              );
      } else if (sorting.includes("-orders")) {
        sorted =
          sorting === "ascending-orders"
            ? copyData.sort((a, b) => a?.orders_count - b?.orders_count)
            : copyData.sort((a, b) => b?.orders_count - a?.orders_count);
      } else if (sorting.includes("-spent")) {
        sorted =
          sorting === "ascending-spent"
            ? copyData.sort((a, b) => a?.total_spent - b?.total_spent)
            : copyData.sort((a, b) => b?.total_spent - a?.total_spent);
      }
    } else {
      // sorted = copyData.sort((a, b) => b._id.localeCompare(a._id));
      sorted = copyData;
    }

    setCustomers([...sorted]);
  };

  useEffect(() => {
    return handleSort();
  }, [sortingData, sortingValue]);

  const allCustomersData = customers.map((customer, i) => {
    return {
      customerId: customer.customerId ? customer.customerId : "N/A",
      first_name: (
        <span style={{ whiteSpace: "nowrap" }}>
          {popOverContent(
            `Name-${i}`,
            `${customer.first_name || ""} ${customer.last_name || ""}`
          )}
        </span>
      ),
      phone: editPhoneNumberFunction(
        customer?.default_address?.phone || customer.phone || "N/A",
        customer.customerId,
        customer.storeId,
        customer?.default_address?.id,
        customer?.first_name,
        customer?.last_name,
        customer?.last_order_name,
        customer?.storeDisplayName
      ),
      city: popOverContent(`City-${i}`, customer?.default_address?.city),
      orders_count: !customer.orders_count ? 0 : customer.orders_count,
      total_spent: popOverContent(
        `Spent-${i}`,
        Math.floor(Number(customer.total_spent)).toLocaleString()
      ),
      last_order_id: customer.last_order_id ? (
        <Link
          className="text-black"
          onClick={() => {
            localStorage.setItem(
              "refinedCustomer",
              JSON.stringify({
                customerName: customer.first_name + customer.last_name,
                total_spent: customer.total_spent,
                orders_count: customer.orders_count,
              })
            );
          }}
          to={{
            pathname: `/admin/orders/${customer.last_order_id}`,
          }}
        >
          {customer.last_order_name}
        </Link>
      ) : (
        "N/A"
      ),
      storeDisplayName: popOverContent(
        `Store-${i}`,
        customer?.storeDisplayName?.replace(".myshopify.com", "")
      ),
    };
  });

  const cols = useMemo(
    () => [
      {
        Header: "Customer Id",
        accessor: "customerId",
        cellClass: " w-10",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Customer name",
        accessor: "first_name",
        cellClass: " w-15",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Phone",
        accessor: "phone",
        cellClass: "w-15",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "City",
        accessor: "city",
        cellClass: "w-12",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Orders",
        accessor: "orders_count",
        cellClass: "w-10",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Spent",
        accessor: "total_spent",
        cellClass: "w-10",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Last Order ID",
        accessor: "last_order_id",
        cellClass: "w-10",
        Cell: (props) => <>{props.value}</>,
      },
    ],
    []
  );

  const myCols = useMemo(
    () => [
      {
        Header: "Customer Id",
        accessor: "customerId",
        cellClass: "w-10",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Customer name",
        accessor: "first_name",
        cellClass: " w-15",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Phone",
        accessor: "phone",
        cellClass: "w-15",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "City",
        accessor: "city",
        cellClass: "w-12",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Orders",
        accessor: "orders_count",
        cellClass: "w-10",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Spent",
        accessor: "total_spent",
        cellClass: "w-10",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Last Order ID",
        accessor: "last_order_id",
        cellClass: "w-10",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Store",
        accessor: "storeDisplayName",
        cellClass: "w-10",
        Cell: (props) => <>{props.value}</>,
      },
    ],
    []
  );

  return (
    <div>
      <main>
        {" "}
        <motion.div
          className="row top-text"
          variants={leftAnimate}
          initial="hidden"
          animate="visible"
        >
          <Cols xxs="12">
            <h1 className="mb-2 pb-0 mt-2">Customers</h1>
            <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
              <ol className="breadcrumb pt-0">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" style={{ color: "black" }}>
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Customers
                </li>
              </ol>
            </Breadcrumb>
          </Cols>
        </motion.div>
        <Row className="">
          <Cols xxs="12">
            {loading ? (
              <Card>
                <CardBody>
                  <TableSkeleton
                    skeletonLength={10}
                    itemsBar={2}
                    customers={true}
                  />
                </CardBody>
              </Card>
            ) : emptyStatus && !searchNameValue.trim() ? (
              <motion.div
                className="card"
                // variants={rightAnimate}
                // initial="hidden"
                // animate="visible"
              >
                <div
                  className="d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
                  style={{
                    height: "200px",
                  }}
                >
                  <span>No Customer Found!</span>
                </div>
              </motion.div>
            ) : (
              <motion.div
                className="card px-2"
                // variants={rightAnimate}
                // initial="hidden"
                // animate="visible"
              >
                <CardBody>
                  <div className="d-flex justify-content-between flex-wrap">
                    <div className="mr-2 position-relative">
                      <Input
                        value={searchNameValue}
                        className="bg-white rounded-2 mb-2"
                        type="text"
                        name="keyword"
                        placeholder="Search All Customers"
                        onChange={(e) => {
                          setEmptyStatus(false);
                          handleSearch({
                            e,
                            setPageNumber,
                            setPageLimit,
                            setFilterLoading,
                            setSearch: setSearchNameValue,
                            setSearchTrigger,
                            searchTrigger,
                          });
                        }}
                        disabled={filterLoading || paginationLoading}
                        style={{ minWidth: "200px" }}
                      />
                      {searchNameValue.trim() &&
                        searchNameValue.trim().length < 3 && (
                          <i
                            className="simple-icon-info pointer position-absolute text-danger fw-bold"
                            style={{ right: "-20px", bottom: "19px" }}
                            id={"SearchInfo"}
                          >
                            {" "}
                            <UncontrolledPopover
                              trigger="hover"
                              placement="top"
                              target={"SearchInfo"}
                            >
                              <PopoverBody className="text-center">
                                <i>Minimum 3 words required!</i>
                              </PopoverBody>
                            </UncontrolledPopover>
                          </i>
                        )}
                      {searchNameValue ? (
                        filterLoading ? (
                          <Spinner
                            color="primary"
                            size={"sm"}
                            style={{ right: "10px", bottom: "19px" }}
                            className=" position-absolute"
                            type="grow"
                          ></Spinner>
                        ) : (
                          <span
                            className="far fa-close fs-6 position-absolute text-danger pointer"
                            style={{ right: "10px", bottom: "19px" }}
                            onClick={() => {
                              if (searchNameValue) {
                                setSearchNameValue("");

                                setSearchTrigger(!searchTrigger);
                                setFilterLoading(true);
                              }
                              pageLimit > 50 && setPageLimit(50);
                              pageNumber > 1 && setPageNumber(1);
                            }}
                          ></span>
                        )
                      ) : null}
                    </div>

                    <div className="d-flex">
                      <Select
                        className="mr-3"
                        isClearable
                        hideSelectedOptions
                        value={allSortValue}
                        isDisabled={filterLoading || paginationLoading}
                        options={customers.length > 0 ? sortingSelect : []}
                        placeholder="Sort All Customers"
                        onChange={(e) => {
                          if (e) {
                            setAllSortValue(e);
                          } else {
                            setAllSortValue("");
                          }
                          setPaginationLoading(true);
                        }}
                      />
                      <Select
                        isClearable
                        hideSelectedOptions
                        value={sortingValue}
                        isDisabled={filterLoading || paginationLoading}
                        options={customers.length > 0 ? sortingSelect : []}
                        placeholder="Sort Current Customers"
                        onChange={(e) => {
                          if (e) {
                            setSortingValue(e);
                          } else {
                            setSortingValue("");
                          }
                        }}
                      />
                    </div>
                  </div>
                  {filterLoading ? (
                    <TableSkeleton skeletonLength={10} />
                  ) : (
                    <>
                      {paginationLoading && (
                        <div className="mt-3 mx-3">
                          <Alert color="info">
                            <Spinner
                              color="light"
                              size={"sm"}
                              style={{ marginBottom: "3px" }}
                            ></Spinner>{" "}
                            &nbsp;
                            <span style={{ fontSize: "16px", color: "black" }}>
                              {spinner
                                ? "Customers Updating!"
                                : "Customers Loading!"}
                            </span>
                          </Alert>
                        </div>
                      )}
                      {customers.length === 0 ? (
                        <div
                          className=" d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
                          style={{
                            height: "200px",
                          }}
                        >
                          <span>No Customer Matched!</span>
                        </div>
                      ) : (
                        <DataTable
                          setPageLimitInParent={setPageLimit}
                          setPageNumberInParent={setPageNumber}
                          fetchData={fetchAllCustomers}
                          pageCount={totalPages}
                          columns={showStoreColumn ? myCols : cols}
                          data={allCustomersData}
                          setPaginationLoading={setPaginationLoading}
                          paginationLoading={paginationLoading}
                          totalCount={count}
                        />
                      )}
                    </>
                  )}
                </CardBody>
              </motion.div>
            )}
          </Cols>
        </Row>
      </main>
    </div>
  );
};

export default AllCustomers;

import React from "react";
import { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAllInvoices } from "../../api/invoice";
import { getCurrentBillingData } from "../../api/billing";
import { Row, Card, Badge, CardBody, CardTitle, CardText } from "reactstrap";
import { Cols } from "../SeparatorStyle/SeparatorStyle";

const Billing = () => {
  const [invoices, setInvoices] = useState();
  const [latestInvoices, setLatestInvoices] = useState();
  const [bills, setBills] = useState([]);

  //Load latestInvoice at First Render
  const loadInvoices = useCallback(async () => {
    try {
      const { data: invoiceData } = await getAllInvoices();
      setInvoices(invoiceData);
      setLatestInvoices(invoiceData.find((i) => i.status === "on_hold"));
      const { data: billData } = await getCurrentBillingData();
      setBills(billData);
    } catch (error) {
      console.log("error found when fetch order data", error);
    }
  }, []);

  useEffect(() => {
    loadInvoices();
  }, [loadInvoices]);

  const DateFormat = (dateFormate) => {
    const date = new Date(dateFormate);

    let getMonth = date.toLocaleString("en-us", { month: "short" });
    let fullYear = date.getFullYear(dateFormate);
    let getDate = date.getDate(dateFormate);

    return `${getDate} ${getMonth} ${fullYear}`;
  };

  let today = new Date();
  let date =
    today.getDate() +
    " " +
    today.toLocaleString("en-us", { month: "short" }) +
    " " +
    today.getFullYear();

  return (
    <>
      <main>
        <div className="ml-3">
          <h2>
            <b>Billing</b>
          </h2>
          <hr />
          {/* <h5><b>Payment Methods</b></h5>
                    <p>Manage how you pay your bills in Shopilam.</p> */}
        </div>
        <br />

        {/* <Row>
                    <Cols xxs="12" className="row icon-cards-row mb-2">
                        <Cols className="mb-4">
                            <Card>
                                <CardBody className='m-2'>
                                    <div>
                                        <h5 style={{ display: 'inline' }}><b>Default Payment Method</b></h5>
                                        <Badge pill color='dark' style={{ display: 'inline' }} className='mr-3 float-right'>
                                           
                                        </Badge>
                                    </div> <br />
                                    <div style={{ display: 'inline' }}>
                                        <img src='demo.png' alt='pic' style={{ height: '40px', width: '40px' }}></img>
                                    </div>
                                    <div style={{ display: 'inline' }}>
                                        <a className='ml-3'><b>VISA</b>
                                            <span>
                                                <Badge pill color='info' style={{ display: 'inline' }} className='ml-2'>
                                                    Primary
                                                </Badge>
                                            </span>
                                        </a>
                                        <CardText className='ml-5 mt-1'>
                                            Ending in 6590
                                        </CardText>
                                    </div>
                                </CardBody>
                            </Card>
                        </Cols>
                    </Cols>
                </Row> */}
        <div className="ml-3">
          <h6>
            <b>Bills</b>
          </h6>
          <p>
            Your monthly bill is on a 30-day cycle. It includes your Shopilam
            Order Fulfillment and App Charges.
          </p>
        </div>
        <br />
        <Row>
          <Cols xxs="12" className="row icon-cards-row mb-2">
            <Cols className="mb-4">
              <Card>
                <CardBody className="m-2">
                  <CardTitle tag="h5">
                    <b>
                      Current Billing Cycle :{" "}
                      {DateFormat(latestInvoices && latestInvoices.startDate)} -{" "}
                      {DateFormat(latestInvoices && latestInvoices.endDate)}{" "}
                    </b>
                  </CardTitle>
                  <CardText className="mb-0">
                    If you reach $200.00 in fees before the end of your billing
                    cycle, a fee threshold bill will be issued automatically.
                  </CardText>
                  <div>
                    <CardTitle tag="h6" className="mb-4">
                      <span style={{ fontSize: "14px" }}>
                        Total Orders Fulfillment Amount
                      </span>
                      <span
                        className="float-right mr-2"
                        style={{ fontSize: "14px" }}
                      >
                        $
                        {parseFloat(
                          bills
                            .map((bill) => bill.amount)
                            .reduce((prev, curr) => prev + curr, 0)
                        ).toFixed(3)}
                      </span>
                    </CardTitle>
                  </div>{" "}
                  <hr />
                  <div>
                    <CardTitle tag="h6" className="mb-4">
                      <b style={{ fontSize: "14px" }}>Running Total</b>
                      <b
                        className="float-right mr-2"
                        style={{ fontSize: "14px" }}
                      >
                        {bills && date === DateFormat(bills.createdAt)
                          ? `$${parseFloat(
                              bills
                                .map((bill) => bill.amount)
                                .reduce((prev, curr) => prev + curr, 0)
                            ).toFixed(3)}`
                          : 0}
                      </b>
                    </CardTitle>
                  </div>
                  <Link to="/admin/billing/billingCycle">View details</Link>
                </CardBody>
              </Card>
            </Cols>
          </Cols>
        </Row>

        {invoices &&
        invoices.find(
          (invoice) =>
            invoice.status === "partial" ||
            invoice.status === "due" ||
            invoice.status === "overdue"
        ) ? (
          <Row>
            <Cols Cols xxs="12" className="row icon-cards-row mb-2">
              <Cols className="mb-4">
                <Card style={{ backgroundColor: "lightyellow" }}>
                  <CardBody className="m-2">
                    <div>
                      <CardTitle tag="h5" className="mb-4">
                        <b>Pending Bills</b>
                      </CardTitle>
                      <hr />
                    </div>
                    {invoices
                      .filter(
                        (invoice) =>
                          invoice.status === "partial" ||
                          invoice.status === "due" ||
                          invoice.status === "overdue"
                      )
                      .map((invoice) => (
                        <>
                          <div>
                            <CardTitle tag="h6" className="mb-4">
                              <Link to={`/admin/invoice/${invoice._id}`}>
                                <b style={{ fontSize: "14px", color: "black" }}>
                                  Billing Cycle
                                </b>
                              </Link>
                              <span
                                className="ml-3"
                                style={{ fontSize: "12px" }}
                              >
                                {DateFormat(invoice.startDate)}
                              </span>
                              <b
                                className="float-right mr-2"
                                style={{ fontSize: "14px" }}
                              >
                                <span className="mr-3">
                                  <Badge
                                    pill
                                    color={
                                      invoice.status === "partial"
                                        ? "light"
                                        : invoice.status === "due"
                                        ? "warning"
                                        : "danger"
                                    }
                                  >
                                    {invoice.status}
                                  </Badge>
                                </span>
                                ${invoice.totalAmount}
                              </b>
                            </CardTitle>
                          </div>
                          <hr />
                        </>
                      ))}
                  </CardBody>
                </Card>
              </Cols>
            </Cols>
          </Row>
        ) : null}

        <Row>
          <Cols xxs="12" className="row icon-cards-row mb-2">
            <Cols className="mb-4">
              {invoices &&
              invoices.find((invoice) => invoice.status === "paid") ? (
                <Card>
                  <CardBody className="m-2">
                    <div>
                      <CardTitle tag="h5" className="mb-4">
                        <b>Recent Bills</b>
                      </CardTitle>
                      <hr />
                    </div>
                    {invoices
                      .slice(0, 3)
                      .filter((invoice) => invoice.status === "paid")
                      .reverse()
                      .map((invoice) => (
                        <>
                          <div>
                            <CardTitle tag="h6" className="mb-4">
                              <b style={{ fontSize: "14px" }}>Billing Cycle</b>
                              <span
                                className="ml-3"
                                style={{ fontSize: "12px" }}
                              >
                                {DateFormat(invoice.startDate)}
                              </span>
                              <b
                                className="float-right mr-2"
                                style={{ fontSize: "14px" }}
                              >
                                <span className="mr-3">
                                  <Badge pill color="success">
                                    {invoice.status}
                                  </Badge>
                                </span>
                                ${invoice.totalAmount}
                              </b>
                            </CardTitle>
                          </div>
                          <hr />
                        </>
                      ))}
                    <br />
                    <Link to="/admin/billing/billingHistory">
                      View all billing history
                    </Link>
                  </CardBody>
                </Card>
              ) : null}
            </Cols>
          </Cols>
        </Row>

        {/* <Row>
                    <Cols xxs="12" className="row icon-cards-row mb-2">
                        <Cols className="mb-4">
                            <Card>
                                <CardBody className='m-2'>
                                    <div className='text-center'>
                                        <CardTitle tag="h2" className='mb-4'>
                                            <b>There are not Credits on your account right now</b>
                                        </CardTitle>
                                    </div>
                                    <div className='text-center'>
                                        <CardTitle tag="h4" className='mb-4'>
                                            Your Credits will be shown here when you have them
                                        </CardTitle>
                                    </div>
                                </CardBody>
                            </Card>
                        </Cols>
                    </Cols>
                </Row>
                <div className='ml-3'>
                    <h5><b>Statement Charges</b></h5>
                    <p>View summaries of your Shopilam charges, which include your Shopilam subscription, app charges, shipping lables, and transaction fees.</p>
                </div><br />
                <Row>
                    <Cols xxs="12" className="row icon-cards-row mb-2">
                        <Cols className="mb-4">
                            <Card>
                                <CardBody className='m-2'>
                                    <div>
                                        <CardTitle tag="h3" className='mb-4'>
                                            <b>Statement of Charges</b>
                                        </CardTitle>
                                    </div>
                                    <div>
                                        <CardTitle tag="h6" className='mb-4'>
                                            View Summeries of your charges
                                        </CardTitle>
                                    </div>
                                    <Link to='/admin/billing/statementOfCharges'>View statment of charges</Link>
                                </CardBody>
                            </Card>
                        </Cols>
                    </Cols>
                </Row> */}
      </main>
    </>
  );
};

export default Billing;

import axios from "axios";
import { getAPIURL } from "./utils";
import { getCookie } from "../helpers/cookie";

const url = getAPIURL();

export const getAllResellers = async () => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${url}/api/reselling/fetchResellers`,
    config
  );

  return response;
};

export const sendInviteForReselling = async (data) => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${url}/api/reselling/requestForReselling`,
    data,
    config
  );

  return response;
};

export const confirmInviteForReselling = async (data) => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${url}/api/reselling/handleResellingInvite`,
    data,
    config
  );

  return response;
};

export const removeFromReselling = async (data) => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${url}/api/reselling/handleRemoveFromReselling`,
    data,
    config
  );

  return response;
};

export const getResellingVariants = async ({
  searchSKU,
  page,
  limit,
  accountId,
}) => {
  let token = getCookie();
  const params = new URLSearchParams();

  params.append("searchSKU", searchSKU);
  params.append("page", page);
  params.append("limit", limit);
  params.append("resellerAccountId", accountId);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  };
  return await axios.get(`${url}/api/reselling/fetchResellingVariants`, config);
};

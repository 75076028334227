import React from "react";
import ExcelToJson from "../standalone/ExcelToJson.jsx";

function InventoryImport(props) {
  const { setSelectedFileForImport, setUploadedFileData } = props;
  return (
    <div className="container">
      <h6>
        <b>Choose Inventory File</b>
      </h6>
      <br />
      <ExcelToJson
        setSelectedFileForImport={setSelectedFileForImport}
        setUploadedFileData={setUploadedFileData}
      />
    </div>
  );
}

export default InventoryImport;

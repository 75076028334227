import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  ModalFooter,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Cols } from "../SeparatorStyle/SeparatorStyle";
import isEmpty from "validator/lib/isEmpty";
import { ErrorMessage, WarningMessage } from "../Messages/message.jsx";
import { addShipperInfo } from "../../api";
import { toast } from "react-toastify";

const SaveMultipleShippers = ({ toggleShipper, modalShipper, storeId }) => {
  let path = "/admin/SKUMapping";
  const history = useHistory();
  const [formData, setFormData] = useState({
    storeDisplayName: "",
    orderRefNumPattern: "",
    supportPhoneNum: "",
    email: "",
    city: "",
    address: "",
    shipper_address_id: "",
    errorMsg: false,
    warningMsg: false,
    successMsg: false,
  });
  const [keySpinner, setKeySpinner] = useState(false);

  const handleChange = (evt) => {
    setFormData({
      ...formData,
      [evt.target.name]: evt.target.value,
      errorMsg: false,
      warningMsg: false,
      successMsg: false,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      isEmpty(storeDisplayName) ||
      isEmpty(orderRefNumPattern) ||
      isEmpty(supportPhoneNum) ||
      isEmpty(email) ||
      isEmpty(city) ||
      isEmpty(address) ||
      isEmpty(shipper_address_id)
    ) {
      setFormData({
        ...formData,
        warningMsg: "All fields are required",
      });
    } else {
      setKeySpinner((k) => !k);
      const {
        storeDisplayName,
        orderRefNumPattern,
        supportPhoneNum,
        email,
        city,
        address,
        shipper_address_id,
      } = formData;
      const data = {
        storeDisplayName,
        orderRefNumPattern,
        supportPhoneNum,
        email,
        city,
        address,
        shipper_address_id,
        storeId,
      };
      setFormData({
        ...formData,
      });
      addShipperInfo(data)
        .then(async (response) => {
          setKeySpinner((k) => !k);
          setFormData({
            ...formData,
            successMsg: response.data.successMessage,
            errorMsg: false,
            warningMsg: false,
          });
          toast.success("Shipper Added Successfully!");
          toggleShipper();
          setTimeout(() => {
            history.push(path);
          }, 2000);
        })
        .catch((err) => {
          setKeySpinner((k) => !k);
          setFormData({
            ...formData,
            errorMsg: err.response.data.errorMessage,
          });
        });
    }
  };

  const {
    storeDisplayName,
    orderRefNumPattern,
    supportPhoneNum,
    email,
    city,
    address,
    shipper_address_id,
    errorMsg,
    warningMsg,
  } = formData;

  return (
    <>
      <div>
        <Modal isOpen={toggleShipper}>
          <ModalHeader toggle={modalShipper}>
            <b>Add Shipper Info</b>
          </ModalHeader>
          <ModalBody>
            <Row className="row h-100">
              <Cols xxs="12" md="10" className="mx-auto my-auto">
                <div className="form-side">
                  <Form onSubmit={handleSubmit} noValidate>
                    {errorMsg !== false ? ErrorMessage(errorMsg) : null}
                    {warningMsg !== false ? WarningMessage(warningMsg) : null}
                    <FormGroup className="form-group has-float-label">
                      <Label>Store Display Name</Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="storeDisplayName"
                        value={storeDisplayName}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup className="form-group has-float-label">
                      <Label>Order Reference Number Pattern</Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="orderRefNumPattern"
                        value={orderRefNumPattern}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup className="form-group has-float-label">
                      <Label>Support Phone Number</Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="supportPhoneNum"
                        value={supportPhoneNum}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup className="form-group has-float-label">
                      <Label>Shipper Email</Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="email"
                        value={email}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup className="form-group has-float-label">
                      <Label>City (Origin)</Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="city"
                        value={city}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup className="form-group has-float-label">
                      <Label>Address</Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="address"
                        value={address}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup className="form-group has-float-label">
                      <Label>Shipper Address Id</Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="shipper_address_id"
                        value={shipper_address_id}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Form>
                </div>
              </Cols>
            </Row>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-center">
            {
              <Button
                disabled={
                  keySpinner ||
                  storeDisplayName === "" ||
                  orderRefNumPattern === "" ||
                  supportPhoneNum === "" ||
                  email === "" ||
                  city === "" ||
                  address === "" ||
                  shipper_address_id === ""
                }
                type="submit"
                onClick={handleSubmit}
                className={"mt-3"}
                size={"lg"}
                style={keySpinner ? { padding: "10px 69px" } : null}
              >
                {keySpinner ? (
                  <Spinner size={"sm"}>Loading...</Spinner>
                ) : (
                  "Submit"
                )}
              </Button>
            }
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default SaveMultipleShippers;

import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ImageUploading from "react-images-uploading";
import emptVariantImg from "./emptyvariant.png";
import {
  Row,
  Form,
  Col,
  Label,
  Input,
  Card,
  FormGroup,
  Table,
  CardBody,
  CardTitle,
  Badge,
  Button,
  ModalFooter,
  ModalHeader,
  Modal,
  ModalBody,
} from "reactstrap";
import { getProductDetails } from "../../api/products";
import { toast } from "react-toastify";
import {
  variantDelete,
  variantImageDelete,
  variantImageUpload,
} from "../../api/variant";

function VariantDetail({
  options,
  setOptions,
  newVariantDetail,
  setNewVariantDetail,
  saveVariant,
  updateVariant,
  variantAdded,
  variantUpdated,
  shopifyProductId,
  shopifyVariantId,
}) {
  let currentVariantIndex;
  let userImageUploaded;
  const history = useHistory();
  const ref = useRef(null);
  const [noOfOptions, setNoOfOptions] = useState(0);
  const [reRender, setReRender] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openModalOne, setOpenModalOne] = useState(false);
  const [checkPhysical, setCheckPhysical] = useState(false);
  const [trackQuantity, setTrackQuantity] = useState(false);
  const [images, setImages] = React.useState([]);
  const maxNumber = 50;
  const [buttonUpdate, setButtonUpdate] = useState(false);
  const [dataProduct, setDataProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imageUploadIndex, setImageUploadIndex] = useState();
  // const [imageUploadOne, setImageUploadOne] = useState(false);
  let weightUnits = [
    {
      label: "kg",
      value: "kg",
    },
    {
      label: "oz",
      value: "oz",
    },
    {
      label: "lb",
      value: "lb",
    },
    {
      label: "g",
      value: "g",
    },
  ];

  const fetchData = async () => {
    try {
      const { data } = await getProductDetails(shopifyProductId);

      setDataProduct(data);

      setLoading(false);
    } catch (error) {
      toast.error(error);
    }
  };
  console.log("Data Product", dataProduct);

  useEffect(() => {
    fetchData();
    if (variantUpdated) {
      setButtonUpdate(true);
    } else {
      setButtonUpdate(false);
    }
  }, [reRender]);

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(imageList);
    setNewVariantDetail({ ...newVariantDetail, imageList: imageList });
  };
  userImageUploaded =
    newVariantDetail.imageList[imageUploadIndex] &&
    newVariantDetail.imageList[imageUploadIndex]["file"];

  const handlePhysical = function () {
    setCheckPhysical(!checkPhysical);
  };

  const currentVariantIndexFunc = function () {
    currentVariantIndex = dataProduct[0].variants.findIndex(
      (variant) => variant.shopifyVariantId === shopifyVariantId
    );
  };

  const handleTrackQuantity = function () {
    setTrackQuantity(!trackQuantity);
  };

  const handleChange = function (e) {
    const { name, value, checked } = e.target;
    if (e.target.type === "checkbox") {
      setNewVariantDetail({ ...newVariantDetail, [name]: checked });
    } else {
      if (name === "option1" || name === "option2" || name === "option3") {
        setOptions({ ...options, [name]: value });
      }
      setNewVariantDetail({ ...newVariantDetail, [name]: value });
    }
  };

  const handleShippingRate = function (e) {
    const { name, value } = e.target;
    const savedetails = newVariantDetail.shippingRate;
    setNewVariantDetail({
      ...newVariantDetail,
      shippingRate: { ...savedetails, [name]: value },
    });
  };

  const deleteVariant = async (storeId, shopifyProductId, shopifyVariantId) => {
    try {
      await variantDelete(storeId, shopifyProductId, shopifyVariantId);
      toast.success("Variant delete successfully!");
      history.push(`/admin/editProduct/${shopifyProductId}`);
    } catch (error) {
      toast.error("Error! Variant not deleted ");
    }
  };

  const imageUploadVariant = async (
    reRender,
    setReRender,
    file,
    storeId,
    shopifyProductId,
    shopifyVariantId
  ) => {
    try {
      setLoading(true);
      const { data } = await variantImageUpload(
        file,
        storeId,
        shopifyProductId,
        shopifyVariantId
      );
      console.log("UploadImage", data);
      setReRender(!reRender);
      setTimeout(() => {
        setLoading(false);
        toast.success("Variant Image Upload successfully!");
      }, 4000);
    } catch (error) {
      toast.error("Error! Image not Uploaded");
      setLoading(false);
    }
  };

  const imageDeleteVariant = async (
    reRender,
    setReRender,
    shopifyProductId,
    shopifyVariantId,
    storeId,
    imageId
  ) => {
    try {
      setLoading(true);
      await variantImageDelete(
        shopifyProductId,
        shopifyVariantId,
        storeId,
        imageId
      );

      setReRender(!reRender);
      setTimeout(() => {
        setLoading(false);
        toast.success("Variant Image has been deleted successfully");
      }, 4000);
    } catch (error) {
      toast.error("Error! Image not deleted");
      setLoading(false);
    }
  };

  return (
    <main className="container">
      {loading ? (
        <div className="loading" />
      ) : (
        <div>
          <Row>
            <Col>
              <Form
                onSubmit={
                  variantAdded
                    ? (e) =>
                        saveVariant(
                          e,
                          reRender,
                          setReRender,
                          noOfOptions,
                          newVariantDetail,
                          shopifyProductId,
                          dataProduct[0].storeId
                        )
                    : (e) =>
                        updateVariant(
                          e,
                          reRender,
                          setReRender,
                          noOfOptions,
                          newVariantDetail,
                          shopifyProductId,
                          dataProduct[0].storeId,
                          shopifyVariantId
                        )
                }
              >
                <Row>
                  <div className="col-md-4 col-12 d-flex flex-column">
                    <Card className="d-flex flex-row mb-3 card-border-set">
                      <Link className="d-flex" to="/">
                        <div>
                          <img
                            className="m-4 align-self-center list-thumbnail-letters"
                            src={`${
                              dataProduct[0].image?.src
                                ? dataProduct[0].image?.src
                                : emptVariantImg
                            }`}
                            alt="not found"
                          />
                        </div>
                      </Link>
                      <div className=" d-flex flex-grow-1 min-width-zero">
                        <CardBody className="pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                          <div className="min-width-zero">
                            <p className="list-item-heading mb-1 truncate">
                              {dataProduct[0].title}
                              <Badge
                                className="ms-2"
                                color="badge-light"
                                style={{
                                  backgroundColor: "#a7e0ce",
                                  color: "#444",
                                }}
                                pill
                              >
                                {dataProduct[0].status}
                              </Badge>
                            </p>
                            <p className="mb-2 text-muted text-small">
                              {dataProduct[0].variants?.length} variant
                            </p>
                            <Link
                              className="text-color"
                              to={`/admin/Products/EditProduct/${shopifyProductId}`}
                            >
                              Back to product
                            </Link>
                          </div>
                        </CardBody>
                      </div>
                    </Card>
                    <Card>
                      <div className="px-3 pt-3 card-border-set">
                        <CardTitle tag="h3" className="p-2 mb-0">
                          Variants
                        </CardTitle>
                      </div>
                      <hr className="mb-0" style={{ height: "0px" }} />
                      <div
                        className="overflow-auto"
                        style={{ maxHeight: "800px" }}
                      >
                        {currentVariantIndexFunc()}
                        {dataProduct[0].variants.map((variant, i) => (
                          <Link
                            key={i}
                            to={`/admin/products/${shopifyProductId}/variants/editVariant/${variant.shopifyVariantId}`}
                          >
                            <div
                              className="d-flex flex-row pointer variant-list"
                              style={{
                                backgroundColor:
                                  variant.shopifyVariantId === shopifyVariantId
                                    ? "#f1f8ff"
                                    : null,
                              }}
                            >
                              <div className="d-flex mx-4 my-3 align-self-center small">
                                <img
                                  src={
                                    variant.images && variant.images[0]
                                      ? variant.images[
                                          variant.images.length - 1
                                        ].src
                                      : emptVariantImg
                                  }
                                  alt="not found"
                                  height={60}
                                  width={70}
                                />
                              </div>
                              <div className=" d-flex flex-grow-1 min-width-zero">
                                <CardBody className="pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                                  <div className="min-width-zero">
                                    <p
                                      className="list-item-heading mb-1 truncate"
                                      style={{
                                        color:
                                          variant.shopifyVariantId ===
                                          shopifyVariantId
                                            ? ""
                                            : "#3A3A3A",
                                      }}
                                    >
                                      {variant.title}
                                    </p>
                                  </div>
                                </CardBody>
                              </div>
                            </div>
                            <hr className="m-0" style={{ height: "0px" }} />
                          </Link>
                        ))}
                      </div>
                    </Card>
                  </div>
                  <div className="col-md-8 col-12 mb-4">
                    <Card className="mb-4 pt-2 px-1">
                      <CardBody>
                        <CardTitle className="mb-4" tag="h5">
                          <strong>Options</strong>
                        </CardTitle>
                        {dataProduct[0].attributes.map((option, index) => (
                          <>
                            {() =>
                              setNoOfOptions(dataProduct[0].attributes.length)
                            }
                            <FormGroup key={index}>
                              <Label htmlFor={`exampleInputOption${index + 1}`}>
                                {option.name}
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id={`exampleInputOption${index + 1}`}
                                onChange={handleChange}
                                name={`option${index + 1}`}
                                required
                                value={options[`option${index + 1}`]}
                              />
                            </FormGroup>
                          </>
                        ))}

                        <div className=" mb-1">
                          <div
                            className="select-from-library-btn sfl-single mb-5"
                            data-library-id="#libraryModal"
                            data-count="1"
                          >
                            <div className="select-fromoutside-library-btn-border card d-flex flex-wrap flex-row my-2 media-thumb-container justify-content-center align-items-center">
                              <FormGroup
                                className="d-flex flex-wrap align-items-center py-3  px-2 mb-0"
                                style={{ gap: "10px" }}
                              >
                                {
                                  // imageUploadOne ?
                                  //   null
                                  //   :
                                  // <button
                                  //   className="btn btn-set-radius mr-2 p-1"
                                  //   style={{ width: "125px", height: "150px" }}
                                  //   onClick={(e) => {
                                  //     e.preventDefault();
                                  //     setOpenModalOne(true);
                                  //   }}
                                  // >
                                  //   <span
                                  //     className="py-1 px-2 "
                                  //     style={{
                                  //       backgroundColor: "rgb(213, 230, 247)",
                                  //     }}
                                  //   >
                                  //     Add image
                                  //   </span>
                                  // </button>
                                }
                                {/* <div
                                  className="d-flex justify-content-center align-items-center"
                                  style={{ width: "125px", height: "150px" }}
                                >
                                  <FormText>
                                    <Link to="/">Choose existing</Link>
                                  </FormText>

                                </div> */}

                                {/* ///////////////////////////////////////////////////// Image Working original /////////////////////////////////// */}
                                {/* {
                                  imageUploadOne && imageUploadIndex !== null &&
                                  <div>
                                    <div
                                      className="image-item-set mb-2"
                                    >
                                      <img
                                        src={newVariantDetail.imageList[imageUploadIndex] && newVariantDetail.imageList[imageUploadIndex]["data_url"]}
                                        alt="not found"
                                        width="125"
                                        height="150"
                                        style={{ backgroundColor: "#f1f8ff" }}
                                        className="border py-5"
                                      />
                                    </div>
                                    <div className="image-item__btn-wrapper text-center">
                                      <Link className='py-0 change-btn' onClick={(e) => {
                                        e.preventDefault()
                                        setOpenModalOne(true);
                                      }}>Change</Link>
                                    </div>
                                  </div>
                                } */}
                                {/* ///////////////////////////////////////////////////// Image Working continuo /////////////////////////////////// */}
                                {dataProduct[0].variants
                                  .filter(
                                    (variant, i) => i === currentVariantIndex
                                  )
                                  .map((variant, i) => {
                                    return variant.images &&
                                      variant.images[0]?.src ? (
                                      <div key={i}>
                                        <div className="image-item-set mb-2">
                                          <img
                                            src={
                                              variant.images &&
                                              variant.images[
                                                variant.images.length - 1
                                              ]?.src
                                            }
                                            alt="not found"
                                            width="125"
                                            height="150"
                                            style={{
                                              backgroundColor: "#f1f8ff",
                                            }}
                                            className="border p-1"
                                          />
                                        </div>
                                        <div className="image-item__btn-wrapper text-center d-flex justify-content-between align-items-center">
                                          <Link
                                            to={""}
                                            className="py-0 change-btn"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setOpenModalOne(true);
                                            }}
                                          >
                                            Change
                                          </Link>
                                          <button
                                            type="button"
                                            style={{ color: "#F70000" }}
                                            onClick={() =>
                                              imageDeleteVariant(
                                                reRender,
                                                setReRender,
                                                shopifyProductId,
                                                shopifyVariantId,
                                                dataProduct[0].storeId,
                                                variant.images[
                                                  variant.images.length - 1
                                                ].id
                                              )
                                            }
                                          >
                                            Delete
                                          </button>
                                        </div>
                                      </div>
                                    ) : (
                                      <button
                                        className="btn btn-set-radius mr-2 p-1"
                                        style={{
                                          width: "125px",
                                          height: "150px",
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setOpenModalOne(true);
                                        }}
                                      >
                                        <span
                                          className="py-1 px-2 "
                                          style={{
                                            backgroundColor:
                                              "rgb(213, 230, 247)",
                                          }}
                                        >
                                          Add image
                                        </span>
                                      </button>
                                    );
                                  })}
                              </FormGroup>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <Card className="mb-4 pt-2 px-1">
                      <CardBody>
                        <CardTitle className="mb-4" tag="h5">
                          <strong>Pricing</strong>
                        </CardTitle>

                        <div className="form-row">
                          <FormGroup className="col-md-6">
                            <Label htmlFor="examplePrice">Price</Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="examplePrice"
                              name="price"
                              onChange={handleChange}
                              value={newVariantDetail.price}
                              placeholder="PKR 130.00"
                            />
                          </FormGroup>
                          <FormGroup className="col-md-6">
                            <Label htmlFor="exampleComparePrice">
                              Compare at price
                            </Label>
                            <Input
                              className="form-control"
                              id="exampleComparePrice"
                              onChange={handleChange}
                              name="comparePrice"
                              value={newVariantDetail.comparePrice}
                              placeholder="PKR 0.00"
                              type="text"
                            />
                          </FormGroup>
                        </div>
                        <FormGroup check className="mb-5">
                          <Input
                            id="exampleCheckTax"
                            name="chargeTax"
                            checked={newVariantDetail.chargeTax}
                            onChange={handleChange}
                            type="checkbox"
                          />
                          <Label check htmlFor="exampleCheckTax">
                            Charge tax on this variant
                          </Label>
                        </FormGroup>
                        {/* <hr /> */}
                        {/* <FormGroup className="mt-4">
                          <Label htmlFor="exampleCostPerItem">Cost per Item</Label>
                          <Input
                            id="exampleCostPerItem"
                            onChange={handleChange}
                            name="costPerItem"
                            value={newVariantDetail.costPerItem}
                            placeholder="PKR 0.00"
                          />
                          <p className="text-muted mt-1">
                            Customers won't see this
                          </p>
                        </FormGroup> */}
                      </CardBody>
                    </Card>
                    <Card className="mb-4 pt-2 px-1">
                      <CardBody>
                        <div className="d-flex mb-3 justify-content-between">
                          <CardTitle tag="h5">
                            <strong>Inventory</strong>
                          </CardTitle>
                          <button
                            className="btn text-primary"
                            type="button"
                            onClick={() => setOpen(true)}
                          >
                            Adjustment history
                          </button>
                        </div>

                        <div className="form-row">
                          <FormGroup className="col-md-6">
                            <Label htmlFor="exampleSKU">
                              SKU (Stock Keeping Unit)
                            </Label>
                            <Input
                              id="exampleSKU"
                              className="form-control"
                              onChange={handleChange}
                              name="sku"
                              value={newVariantDetail.sku}
                              type="text"
                            />
                          </FormGroup>
                          <FormGroup className="col-md-6">
                            <Label htmlFor="exampleBarcode">
                              Barcode (ISBN, UPC, GTIN, etc)
                            </Label>
                            <Input
                              id="exampleBarcode"
                              className="form-control"
                              onChange={handleChange}
                              name="barcode"
                              value={newVariantDetail.barcode}
                              type="text"
                            />
                          </FormGroup>
                        </div>
                        <FormGroup check>
                          <Input
                            id="exampleCheckTrackQuantity"
                            onChange={handleChange}
                            onClick={handleTrackQuantity}
                            name="trackQuantity"
                            checked={newVariantDetail.trackQuantity}
                            type="checkbox"
                          />
                          <Label check htmlFor="exampleCheckTrackQuantity">
                            Track quantity
                          </Label>
                        </FormGroup>
                        {trackQuantity && (
                          <FormGroup check className="mb-5">
                            <Input
                              id="exampleCheckOutStock"
                              onChange={handleChange}
                              name="outOfStock"
                              checked={newVariantDetail.outOfStock}
                              type="checkbox"
                            />
                            <Label check htmlFor="exampleCheckOutStock">
                              Continue selling when out of stock
                            </Label>
                          </FormGroup>
                        )}

                        <hr />
                        <CardTitle tag="h5">Quantity</CardTitle>
                        <Table borderless responsive>
                          <thead>
                            <tr className="border-bottom">
                              <th>Location name</th>
                              <th className="text-right">Incoming</th>
                              <th className="text-right">Committed</th>
                              <th className="text-right">Available</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Faisalabad</td>
                              <td>
                                <div align="right">
                                  <Input
                                    id="exampleIncomming"
                                    className="text-center w-40"
                                    placeholder="0"
                                    name="imcomming"
                                    type="number"
                                  />
                                </div>
                              </td>
                              <td>
                                <div align="right">
                                  <Input
                                    id="exampleCommitted"
                                    className="text-center w-40"
                                    placeholder="0"
                                    name="committed"
                                    type="number"
                                  />
                                </div>
                              </td>
                              <td>
                                <div align="right">
                                  <Input
                                    id="exampleAvailable"
                                    className="text-center w-40"
                                    placeholder="0"
                                    name="available"
                                    type="number"
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                    <Card className="mb-4 pt-2 px-1">
                      <CardBody>
                        <CardTitle tag="h5">
                          <strong>Shipping</strong>
                        </CardTitle>

                        <FormGroup check>
                          <Input
                            id="exampleCheckPhysical"
                            name="physicalProduct"
                            checked={newVariantDetail.physicalProduct}
                            onClick={handlePhysical}
                            onChange={handleChange}
                            type="checkbox"
                          />
                          <Label check htmlFor="exampleCheckPhysical">
                            This is a physical product
                          </Label>
                        </FormGroup>
                      </CardBody>
                      {checkPhysical ? (
                        <>
                          <CardBody>
                            <CardTitle tag="p" className="mb-2">
                              <strong>WEIGHT</strong>
                            </CardTitle>
                            <p className="mb-2">
                              Used to calculate shipping rates at checkout and
                              label prices during fulfillment.
                            </p>

                            <div className="form-row">
                              <FormGroup className="col-md-4 mb-0">
                                <Label htmlFor="exampleShippingRate">
                                  <strong>Weight</strong>
                                </Label>
                                <Input
                                  id="exampleShippingRate"
                                  className="form-control"
                                  onChange={handleShippingRate}
                                  name="weight"
                                  value={newVariantDetail.shippingRate.weight}
                                  type="number"
                                  placeholder="0.0"
                                />
                              </FormGroup>
                              <FormGroup className="col-md-1 mb-0">
                                <div className="form-group position-relative error-l-50">
                                  <label htmlFor="">&nbsp;</label>
                                  <select
                                    className="custom-select"
                                    onChange={handleShippingRate}
                                    name="unit"
                                    required
                                  >
                                    {weightUnits.map((weightUnit, i) => {
                                      return (
                                        <option
                                          key={i}
                                          name={weightUnit.label}
                                          value={weightUnit.value}
                                        >
                                          {weightUnit.label}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <div className="invalid-tooltip">
                                    State is required!
                                  </div>
                                </div>
                              </FormGroup>
                            </div>
                          </CardBody>
                        </>
                      ) : (
                        <div>
                          <hr className="mt-0" />
                          <p className="text-muted p-3">
                            Customers won't enter their shipping address of
                            choose a shipping method when buying this product.
                          </p>
                        </div>
                      )}
                    </Card>
                  </div>
                </Row>
                <Row>
                  <div className="d-flex justify-content-between">
                    {buttonUpdate ? (
                      <>
                        <button
                          className="btn rounded btn-outline-danger"
                          type="button"
                          onClick={() => setOpenDelete(true)}
                        >
                          Delete variant
                        </button>
                        <button
                          className="btn rounded btn-primary"
                          type="submit"
                          color="primary"
                        >
                          Update variant
                        </button>
                      </>
                    ) : (
                      <>
                        <div></div>
                        <button
                          className="btn btn-primary"
                          type="submit"
                          color="primary"
                        >
                          Save variant
                        </button>
                      </>
                    )}
                  </div>
                </Row>
              </Form>
            </Col>
          </Row>
          <div className="Modal1">
            <Modal isOpen={openModalOne} toggle={() => setOpenModalOne(false)}>
              <ModalHeader
                className="p-3 text-muted"
                toggle={() => setOpenModalOne(false)}
              >
                Select variant image
              </ModalHeader>
              <ModalBody className="py-1" style={{ minHeight: "140px" }}>
                <p className="text-muted pt-1 mb-1">
                  You can only choose images as variant media
                </p>
                <ImageUploading
                  multiple
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper select-from-library-btn mb-5 pt-2 px-1">
                      <div className="d-flex flex-wrap" style={{ gap: "10px" }}>
                        <div
                          className="select-from-library-btn-border d-flex align-items-center justify-content-center mb-2 py-4"
                          style={{
                            width: images.length >= 1 ? "125px" : "625px",
                          }}
                        >
                          <button
                            className="btn btn-set-radius p-1"
                            style={{ backgroundColor: "rgb(213, 230, 247)" }}
                            onClick={onImageUpload}
                            ref={ref}
                            {...dragProps}
                          >
                            Add image
                          </button>
                        </div>
                        {images.map((image, index) => (
                          <label
                            key={index}
                            htmlFor={`customCheckThis${index}`}
                          >
                            <div className="image-item-set mb-2 pointer">
                              <img
                                // src={image["src"]}
                                src={image["data_url"]}
                                alt="not found"
                                width="125"
                                height="150"
                                style={{ backgroundColor: "#f1f8ff" }}
                                className="border pt-5 pb-4"
                              />

                              <div className="image-item__btn-wrapper">
                                <div className="custom-control custom-checkbox check-btn">
                                  <input
                                    type="radio"
                                    className="custom-control-input"
                                    name="img"
                                    checked={
                                      index === imageUploadIndex
                                        ? "checked"
                                        : null
                                    }
                                    id={`customCheckThis${index}`}
                                    onClick={(e) => {
                                      setImageUploadIndex(index);
                                      // setImageUploadOne(e.target.checked);
                                    }}
                                  />
                                  <label
                                    className="custom-control-label pointer"
                                    htmlFor={`customCheckThis${index}`}
                                  ></label>
                                </div>

                                <button
                                  className="btn pb-0 close-btn btn-set-radius"
                                  onClick={() => {
                                    onImageRemove(index);
                                    if (index === imageUploadIndex) {
                                      setImageUploadIndex(null);
                                      // setImageUploadOne(false);
                                    }
                                  }}
                                >
                                  <ion-icon name="close-outline"></ion-icon>
                                </button>
                              </div>
                            </div>
                          </label>
                        ))}
                      </div>
                    </div>
                  )}
                </ImageUploading>
              </ModalBody>
              <ModalFooter className="py-2 d-flex justify-content-between">
                <div className="fs-6">
                  <span
                    className="border py-1 px-2"
                    style={{ cursor: "pointer" }}
                  >
                    <ion-icon
                      style={{ color: "rgb(128 132 137)" }}
                      name="chevron-back-outline"
                    ></ion-icon>
                  </span>
                  <span
                    className="border py-1 px-2"
                    style={{ cursor: "pointer" }}
                  >
                    <ion-icon
                      style={{ color: "rgb(128 132 137)" }}
                      name="chevron-forward-outline"
                    ></ion-icon>
                  </span>
                </div>
                <div>
                  <Button
                    color="none"
                    className="border btn-set-radius"
                    onClick={() => setOpenModalOne(false)}
                  >
                    Cancel
                  </Button>{" "}
                  <Button
                    color="none"
                    className="border btn-set-radius"
                    onClick={() => ref.current.click()}
                  >
                    Add Image
                  </Button>{" "}
                  <button
                    className="btn btn-success btn-set-radius"
                    onClick={() => {
                      setOpenModalOne(false);
                      imageUploadVariant(
                        reRender,
                        setReRender,
                        userImageUploaded,
                        dataProduct[0].storeId,
                        shopifyProductId,
                        shopifyVariantId
                      );
                    }}
                  >
                    Done
                  </button>
                </div>
              </ModalFooter>
            </Modal>
          </div>
          <div className="Modal2">
            <Modal isOpen={open} toggle={() => setOpen(false)}>
              <ModalHeader
                className="p-3 text-muted"
                toggle={() => setOpen(false)}
              >
                Unsaved Changes
              </ModalHeader>
              <ModalBody className="pt-4 pb-2">
                <p>If you leave this page, any unsaved changes will be lost.</p>
              </ModalBody>
              <ModalFooter className="py-2">
                <Button
                  color="none"
                  className="border btn-set-radius"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>{" "}
                <Button
                  color="danger"
                  className="border btn-set-radius"
                  onClick={() => setOpen(false)}
                >
                  Leave page
                </Button>
              </ModalFooter>
            </Modal>
          </div>
          <div className="Modal3">
            <Modal isOpen={openDelete} toggle={() => setOpenDelete(false)}>
              <ModalHeader
                className="p-3 text-muted"
                toggle={() => setOpenDelete(false)}
              >
                {" "}
                Delete <strong>{dataProduct[0].title}</strong> -{" "}
                <strong>
                  {buttonUpdate &&
                    dataProduct[0].variants[currentVariantIndex]?.title}
                  ?{" "}
                </strong>
              </ModalHeader>
              <ModalBody className="pt-4 pb-2">
                <p>
                  Are you sure you want to delete the variant{" "}
                  <strong>{dataProduct[0]?.title}</strong> -{" "}
                  <strong>
                    {buttonUpdate &&
                      dataProduct[0].variants[currentVariantIndex]?.title}
                    ?{" "}
                  </strong>{" "}
                  This can’t be undone.
                </p>
              </ModalBody>
              <ModalFooter className="py-2">
                <Button
                  color="none"
                  className="border btn-set-radius"
                  onClick={() => setOpenDelete(false)}
                >
                  Cancel
                </Button>{" "}
                <Button
                  className="btn rounded btn-outline-danger"
                  type="button"
                  onClick={() =>
                    deleteVariant(
                      dataProduct[0].storeId,
                      shopifyProductId,
                      shopifyVariantId
                    )
                  }
                >
                  Delete variant
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      )}
    </main>
  );
}

export default VariantDetail;

import React from "react";
import { Row, Card, CardBody, Breadcrumb } from "reactstrap";
import { Cols } from "../SeparatorStyle/SeparatorStyle";
import { Link, useHistory } from "react-router-dom";

const AccountSetting = () => {
  const history = useHistory();

  const EditCourier = () => {
    history.push("/admin/couriers");
  };

  return (
    <main>
      <Row>
        <Cols xxs="12">
          <h1>Setting</h1>
          <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
            <ol className="breadcrumb pt-0">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard" style={{ color: "black" }}>
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Settings
              </li>
            </ol>
          </Breadcrumb>
        </Cols>
      </Row>
      {
        <Row>
          <Cols xxs="12" className="row icon-cards-row mb-2">
            <Cols xxs="6" sm="4" md="3" className="mb-4">
              <Card onClick={EditCourier}>
                <CardBody className="text-center">
                  <i className="iconsminds-box-close" />
                  <p className="card-text font-weight-semibold mb-0">
                    Edit your Couriers
                  </p>
                </CardBody>
              </Card>
            </Cols>
            <Cols xxs="6" sm="4" md="3" className="mb-4">
              <Card>
                <CardBody className="text-center">
                  <i className="iconsminds-city-hall" />
                  <p className="card-text font-weight-semibold mb-0">
                    Edit Couriers Cities
                  </p>
                </CardBody>
              </Card>
            </Cols>
            <Cols xxs="6" sm="4" md="3" className="mb-4">
              <Card>
                <CardBody className="text-center">
                  <i className="simple-icon-key" />
                  <p className="card-text font-weight-semibold mb-0">
                    Edit Couriers Keys
                  </p>
                </CardBody>
              </Card>
            </Cols>
          </Cols>
        </Row>
      }
    </main>
  );
};

export default AccountSetting;

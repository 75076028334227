import { getDateRanges } from "../api/dateRanges";
import React, { forwardRef, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";

const DateRange = ({ dateRange = [null, null], loading, setDateRange }) => {
  const [rangesHistory, setRangesHistory] = useState([]);

  const handleFetchRanges = async () => {
    const { data } = await getDateRanges();

    setRangesHistory(data?.ranges);
  };
  useEffect(() => {
    handleFetchRanges();
  }, [dateRange[0] && dateRange[1]]);

  const [startDate, endDate] = dateRange;
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <>
      {/* <button
        disabled={loading}
        className="btn tab btn-primary text-white rounded-3 mr-1 position-relative"
        onClick={onClick}
        ref={ref}
      >
        {value || "Date Range"}
      </button> */}
      <div className="btn-group rounded-3">
        <div className="btn-group rounded-start-3" role="group">
          <button
            type="button"
            className="btn btn-primary rounded-start-3 dropdown-toggle dropdown-toggle-split"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="sr-only">Toggle Dropleft</span>
          </button>
          <div className="dropdown-menu">
            {rangesHistory.length === 0 && (
              <span className="dropdown-item link">No Recents</span>
            )}
            {rangesHistory.map((range, i) => (
              <span
                onClick={() => {
                  setDateRange([new Date(range?.start), new Date(range?.end)]);
                }}
                className={`dropdown-item link`}
                key={i + 1}
              >
                {`${new Date(range?.start)?.toDateString()} - ${new Date(
                  range?.end
                )?.toDateString()}`}
              </span>
            ))}
          </div>
        </div>
        <button
          type="button"
          onClick={onClick}
          ref={ref}
          disabled={loading}
          className="btn btn-primary mr-3"
        >
          {value || "Date Range"}
        </button>
      </div>
    </>
  ));
  return (
    // <div className="qwerty mr-3">
    //   <div className="date-range-fields-container">
    //     <div className="dateTimeRange">
    <ReactDatePicker
      disabled={loading}
      dateFormat="MMMM d, yyyy h:mm aa"
      showYearDropdown
      showMonthDropdown
      className=""
      customInput={<ExampleCustomInput />}
      maxDate={new Date()}
      //   dateFormat={"dd mm yy"}
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={(update) => {
        setDateRange(update);
      }}
      isClearable={!loading}
      clearButtonClassName="pl-1 bg-danger"
    />
    //     </div>
    //   </div>
    // </div>
  );
};

export default DateRange;

// import React, { forwardRef, useState } from "react";
// import ReactDatePicker from "react-datepicker";

// const useDateRange = (initialValue = [null, null]) => {
//   const [dateRange, setDateRange] = useState(initialValue);

//   const DateRangeComponent = forwardRef(({ value, onChange }, ref) => {
//     const [startDate, endDate] = value || dateRange;

//     const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
//       <button
//         className="btn btn-primary text-white rounded-3 mr-3 position-relative"
//         onClick={onClick}
//         ref={ref}
//       >
//         {value || "Date Range"}
//       </button>
//     ));

//     return (
//       <ReactDatePicker
//         ref={ref}
//         dateFormat="MMMM d, yyyy h:mm pp"
//         showYearDropdown
//         showMonthDropdown
//         className="p-1 rounded-3"
//         customInput={<ExampleCustomInput />}
//         maxDate={new Date()}
//         selectsRange={true}
//         startDate={startDate}
//         endDate={endDate}
//         onChange={(update) => {
//         //   onchange && onChange(update);
//           setDateRange(update);
//         }}
//         isClearable={true}
//       />
//     );
//   });

//   return { dateRange, DateRangeComponent };
// };

// export default useDateRange;

import React from "react";
import { useHistory } from "react-router-dom";
import { setLocalStorage } from "../helpers/localStorage";
import { updateUserRole } from "../api/auth";
import "./loader.css";

const Waiting = () => {
  const history = useHistory();
  const updateRole = async () => {
    const { data } = await updateUserRole();
    setLocalStorage("user", data);
    history.push("/admin/dashboard");
  };

  setTimeout(() => {
    updateRole();
  }, 5000);

  return (
    <div className="container text-center" style={{ marginTop: "20vh" }}>
      <h1 className="text-center my-3">
        <b>Your Account is being Prepared...</b>
      </h1>
      <div className=" mx-auto loader"></div>
      <h3 className="text-center my-4">Please come back after a while!</h3>
    </div>
  );
};

export default Waiting;

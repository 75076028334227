import React from "react";
import DataTable from "../tables/table.jsx";
import {
  Row,
  Input,
  Alert,
  Spinner,
  CardBody,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import { Cols } from "../SeparatorStyle/SeparatorStyle.js";
import { handleSearch } from "../search/searchHook.js";
import TableSkeleton from "../UI/TableSkeleton.jsx";
import { popOverContent } from "./../../popOvers/popOver";

const ResellingProducts = ({
  selectedList,
  setSelectedList,
  dataTrigger,
  variants,
  count,
  loading,
  emptyStatus,
  setPageLimit,
  pageLimit,
  setPageNumber,
  pageNumber,
  setFilterLoading,
  filterLoading,
  setPaginationLoading,
  paginationLoading,
  setMasterCheckBool,
  masterCheckBool,
  setSearch,
  search,
  setSearchTrigger,
  searchTrigger,
  fetchResellingVariants,
}) => {
  const totalPages = Math.ceil(count / pageLimit);

  const handleUpdateQty = (evt, i) => {
    let temp = variants;

    temp[i].inventory_quantity = Number(evt.target.value);
    setSelectedList([...temp].filter((obj) => obj.selected));
  };

  const currentPageData =
    variants.length > 0
      ? variants
          .map((variant, index) => {
            return {
              check: (
                <label className="custom-control mb-0 pointer pr-0">
                  <Input
                    className="item-check pointer"
                    type="checkbox"
                    checked={variant.selected}
                    label=""
                    style={{
                      border: "0.1px solid gray",
                      fontSize: "16px",
                    }}
                    disabled={filterLoading || paginationLoading}
                    onChange={(e) => onItemCheck(variant, e, index)}
                  />
                </label>
              ),
              image: (
                <div>
                  <img
                    className="rounded-3"
                    src={
                      variant?.images && variant?.images[0]?.src
                        ? variant.images[0]?.src
                        : "./img/products/no-variant-image.png"
                    }
                    alt=""
                    height={50}
                    width={50}
                    // style={{ border: "1px solid lightgrey" }}
                  />
                </div>
              ),
              title: popOverContent(`variant-title${index + 1}`, variant.title),
              sku: popOverContent(`variant-sku${index + 1}`, variant.sku),
              available: (
                <Input
                  min={0}
                  onChange={(e) => handleUpdateQty(e, index)}
                  disabled={!variant.selected}
                  className="text-center"
                  type="number"
                  value={variant.inventory_quantity}
                />
              ),
            };
          })
          .filter(Boolean) // added to remove null values
      : [];

  const onMasterCheck = async (e) => {
    let tempList = variants;
    setMasterCheckBool(e.target.checked);
    tempList.map((user) => (user.selected = e.target.checked));
    setSelectedList(tempList.filter((e) => e.selected));
  };

  const onItemCheck = (item, e) => {
    let tempList = variants;
    tempList.map((user, index) => {
      if (user === item) {
        user.selected = e.target.checked;
      }
      return user;
    });
    setSelectedList(tempList.filter((e) => e.selected));
  };

  const cols = React.useMemo(
    () =>
      variants.length > 0
        ? [
            {
              Header: (
                <label className="custom-control mb-0 pointer align-self-center">
                  <Input
                    className="item-check pointer"
                    style={{
                      border: "0.1px solid gray",
                      fontSize: "16px",
                    }}
                    type="checkbox"
                    name="checkboxAllProducts"
                    checked={masterCheckBool}
                    disabled={filterLoading || paginationLoading}
                    onChange={(e) => onMasterCheck(e)}
                  />
                </label>
              ),
              cellClass: "w-1",
              accessor: "check",
              Cell: (props) => <>{props.value}</>,
            },
            {
              Header: "Variant",
              cellClass: "w-1",
              accessor: "image",
              Cell: (props) => <>{props.value}</>,
            },
            {
              Header: "Title",
              cellClass: "w-10",
              accessor: "title",
              Cell: (props) => <>{props.value}</>,
            },
            {
              Header: "SKU",
              accessor: "sku",
              cellClass: "w-10",
              Cell: (props) => <>{props.value}</>,
            },
            {
              Header: "Available",
              accessor: "available",
              cellClass: "w-5",
              Cell: (props) => <>{props.value}</>,
            },
          ]
        : [],
    [masterCheckBool, variants, paginationLoading, filterLoading, dataTrigger]
  );

  return (
    <>
      {loading ? (
        <TableSkeleton skeletonLength={10} itemsBar={1} />
      ) : emptyStatus ? (
        <div
          className=" d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
          style={{
            height: "200px",
          }}
        >
          <span>No Reselling Products Found!</span>
        </div>
      ) : (
        <div>
          <Row>
            <Cols xxs="12">
              <div className=" mobile-margin">
                <div className="card-title mb-0">
                  <div className="mt-3 d-flex justify-content-between flex-wrap">
                    <div className="mr-2 position-relative">
                      <Input
                        disabled={filterLoading || paginationLoading}
                        type="text"
                        name="keyword"
                        value={search}
                        id="search"
                        placeholder="Title, SKU"
                        onChange={(e) =>
                          handleSearch({
                            e,
                            setPageNumber,
                            setPageLimit,
                            setFilterLoading,
                            setSearch,
                            setSearchTrigger,
                            searchTrigger,
                          })
                        }
                        style={{ minWidth: "200px" }}
                        className="rounded-3"
                      />
                      {search.trim() && search.trim().length < 3 && (
                        <i
                          className="simple-icon-info pointer position-absolute text-danger fw-bold"
                          style={{ right: "-20px", bottom: "12px" }}
                          id={"SearchInfo"}
                        >
                          {" "}
                          <UncontrolledPopover
                            trigger="hover"
                            placement="top"
                            target={"SearchInfo"}
                          >
                            <PopoverBody className="text-center">
                              <i>Minimum 3 words required!</i>
                            </PopoverBody>
                          </UncontrolledPopover>
                        </i>
                      )}
                      {search &&
                        (filterLoading && search.trim().length > 2 ? (
                          <Spinner
                            color="primary"
                            size={"sm"}
                            style={{ right: "10px", bottom: "12px" }}
                            className=" position-absolute"
                            type="grow"
                          ></Spinner>
                        ) : (
                          <span
                            className="far fa-close fs-6 position-absolute text-danger pointer"
                            style={{ right: "10px", bottom: "12px" }}
                            onClick={() => {
                              if (search) {
                                setSearch("");
                                setSearchTrigger(!searchTrigger);
                                setFilterLoading(true);
                              }
                              pageLimit > 50 && setPageLimit(50);
                              pageNumber > 1 && setPageNumber(1);
                            }}
                          ></span>
                        ))}
                    </div>
                  </div>
                </div>
                <CardBody className="">
                  {paginationLoading && (
                    <div className="">
                      <Alert color="info">
                        <Spinner
                          color="light"
                          size={"sm"}
                          style={{ marginBottom: "3px" }}
                        ></Spinner>{" "}
                        &nbsp;
                        <span style={{ fontSize: "16px", color: "black" }}>
                          Reselling Products Loading!
                        </span>
                      </Alert>
                    </div>
                  )}
                  {filterLoading ? (
                    <TableSkeleton skeletonLength={10} />
                  ) : (
                    <>
                      {variants.length === 0 ? (
                        <div
                          className=" d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
                          style={{
                            height: "200px",
                          }}
                        >
                          <span>No Reselling Products Matched!</span>
                        </div>
                      ) : (
                        <DataTable
                          fetchData={fetchResellingVariants}
                          columns={cols}
                          data={currentPageData}
                          setPageLimitInParent={setPageLimit}
                          setPageNumberInParent={setPageNumber}
                          pageCount={totalPages}
                          setPaginationLoading={setPaginationLoading}
                          paginationLoading={paginationLoading}
                          selectedLength={selectedList.length}
                          totalCount={count}
                        />
                      )}
                    </>
                  )}
                </CardBody>
              </div>
            </Cols>
          </Row>
        </div>
      )}
    </>
  );
};

export default ResellingProducts;

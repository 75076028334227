import { PopoverHeader, PopoverBody, UncontrolledPopover } from "reactstrap";
import React from "react";
export const ItemsDetailsPopup = ({ itemDetailsData }) => {
  return (
    <div>
      {itemDetailsData && itemDetailsData ? (
        <UncontrolledPopover
          trigger="hover"
          placement="top"
          target="itemDetailPopover"
        >
          <>
            <PopoverHeader>Items Details</PopoverHeader>
            <PopoverBody>
              {itemDetailsData &&
                itemDetailsData.map((data, index) => (
                  <ul key={Math.random()}>
                    {data.title ? (
                      <li style={{ listStyle: "none" }} key={Math.random()}>
                        {" "}
                        {data.title}
                      </li>
                    ) : (
                      ""
                    )}
                    {data.variant_title ? (
                      <li style={{ listStyle: "none" }} key={Math.random()}>
                        {data.variant_title}
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                ))}
            </PopoverBody>
          </>
        </UncontrolledPopover>
      ) : (
        ""
      )}
    </div>
  );
};

export const ConsigneeDetails = ({ consigneeDetails, index }) => {
  return (
    <div>
      {consigneeDetails && consigneeDetails ? (
        <UncontrolledPopover
          trigger="hover"
          placement="top"
          target={"consigneeDetailPopover" + index}
        >
          <PopoverBody className="px-3">
            {consigneeDetails &&
              consigneeDetails.map((data, ind) => (
                <ul key={Math.random()} className=" list-unstyled">
                  {data ? (
                    <li style={{ listStyle: "none" }} key={ind}>
                      {" "}
                      {data}
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              ))}
          </PopoverBody>
        </UncontrolledPopover>
      ) : (
        ""
      )}
    </div>
  );
};

export const UserDetailsPopup = ({ userDetail, index }) => {
  return (
    <div>
      {userDetail && userDetail ? (
        <UncontrolledPopover
          trigger="hover"
          placement="top"
          target={"userDetailsPopover" + index}
        >
          <>
            <PopoverHeader>Customer Details</PopoverHeader>
            <PopoverBody>
              <ul className=" list-unstyled">
                {userDetail.shipping_address &&
                userDetail.shipping_address.name ? (
                  <li style={{ listStyle: "none" }} key={Math.random(1, 500)}>
                    {userDetail.shipping_address.name}
                  </li>
                ) : (
                  ""
                )}
                {userDetail.shipping_address &&
                userDetail.shipping_address.city &&
                userDetail.shipping_address.country ? (
                  <li style={{ listStyle: "none" }} key={Math.random(1, 800)}>
                    {userDetail.shipping_address.city} ,{" "}
                    {userDetail.shipping_address.country}
                  </li>
                ) : (
                  ""
                )}
                {userDetail.customer && userDetail.customer.orders_count ? (
                  <li style={{ listStyle: "none" }} key={Math.random(1, 900)}>
                    {userDetail.customer.orders_count}{" "}
                    {userDetail.customer.orders_count > 1 ? "orders" : "order"}
                  </li>
                ) : (
                  ""
                )}
                {userDetail.customer && userDetail.customer.email ? (
                  <li style={{ listStyle: "none" }} key={Math.random(1, 400)}>
                    {userDetail.customer.email}
                  </li>
                ) : (
                  ""
                )}
                {userDetail.customer && userDetail.customer.phone ? (
                  <li style={{ listStyle: "none" }} key={Math.random(1, 200)}>
                    {userDetail.customer.phone}
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </PopoverBody>
          </>
        </UncontrolledPopover>
      ) : (
        ""
      )}
    </div>
  );
};

export const OrdersDetailsPopup = ({ orderDetailsData }) => {
  return (
    <div>
      {orderDetailsData && orderDetailsData ? (
        <UncontrolledPopover
          trigger="hover"
          placement="top"
          target="orderDetailPopover"
        >
          <>
            <PopoverBody>
              {orderDetailsData &&
                orderDetailsData.map((data, index) => (
                  <ul key={Math.random()}>
                    {data.title ? (
                      <li style={{ listStyle: "none" }} key={index}>
                        {" "}
                        {data.title} ({data.quantity})
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                ))}
            </PopoverBody>
          </>
        </UncontrolledPopover>
      ) : (
        ""
      )}
    </div>
  );
};

export const UnFulfillOrderDetailsPopup = ({ itemDetailsData, index }) => {
  return (
    <div>
      {itemDetailsData && itemDetailsData && (
        <UncontrolledPopover
          trigger="hover"
          placement="top"
          target={"unFulfillOrderItemDetails" + index}
        >
          <>
            <PopoverBody className="px-3">
              {itemDetailsData &&
                itemDetailsData.map((data, index) => (
                  <ul key={Math.random()} className=" list-unstyled">
                    {data.title ? (
                      <li style={{ listStyle: "none" }} key={index}>
                        {" "}
                        {data.title} ({data.quantity})
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                ))}
            </PopoverBody>
          </>
        </UncontrolledPopover>
      )}
    </div>
  );
};

export const DateTimePopup = ({ dateTime, index }) => {
  return (
    <UncontrolledPopover
      trigger="hover"
      placement="top"
      target={"dateFormattedPopover" + index}
    >
      <PopoverBody className="px-3">{dateTime}</PopoverBody>
    </UncontrolledPopover>
  );
};

export const NotePopup = ({ noteData }) => {
  return (
    <div>
      {noteData && noteData ? (
        <UncontrolledPopover
          trigger="hover"
          placement="top"
          target="noteDetailPopover"
        >
          <>
            <PopoverBody className="px-3">{noteData}</PopoverBody>
          </>
        </UncontrolledPopover>
      ) : (
        ""
      )}
    </div>
  );
};

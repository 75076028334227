export const toLeopardFormat = (orders) => {
  const csvData = [];
  for (const order of orders) {
    let description = "";
    order.line_items.forEach(
      (item) =>
        (description = description.concat(`${item.sku}( ${item.quantity}) `))
    );
    // const pieces = order.line_items.reduce((prev, curr) => (prev.quantity + curr.quantity));
    const shipperAddress =
      order.shipperDetail[0].shopDetail.address1 ||
      order.shipperDetail[0].shopDetail.address2;
    csvData.push({
      shipperName: order.shipperDetail[0]?.shopDetail.name
        ? order.shipperDetail[0].shopDetail.name
        : "N/A",
      shipperPhone: order.shipperDetail[0]?.shopDetail.phone
        ? order.shipperDetail[0].shopDetail.phone
        : "N/A",
      shipperAddress: shipperAddress ? shipperAddress : "N/A",
      shipperEmail: order.shipperDetail[0]?.shopDetail.email
        ? order.shipperDetail[0].shopDetail.email
        : "N/A",
      "Origin City Name": order.shipperDetail[0]?.shopDetail.city
        ? order.shipperDetail[0].shopDetail.city
        : "N/A",
      consigneeName: order.shipping_address?.name
        ? order.shipping_address.name
        : "N/A",
      consigneePhone: order.shipping_address?.phone
        ? order.shipping_address.phone
        : "N/A",
      consigneeAddress: order.shipping_address?.address1
        ? order.shipping_address.address1
        : "N/A",
      "Destination CityName": order.shipping_address?.city
        ? order.shipping_address.city
        : "N/A",
      bookedPacketCollectAmount: order.total_price_set?.shop_money?.amount
        ? order.total_price_set.shop_money.amount
        : "N/A",
      bookedpacketorderid: order.name ? order.name : "N/A",
      ProductDescription: description ? description : "N/A",
      bookedPacketWeight: order.total_weight > 0 ? order.total_weight : 500,
      shipment_type: order.shipmentType ? order.shipmentType : "N/A",
      numberOfPieces: "1",
    });
  }

  return csvData;
};

// shipperName - NA
// shipperPhone - NA
// shipperAddress - NA
// shipperEmail - NA
// Origin City Name - NA
// consigneeEmail - NA
// shipment_type - HC

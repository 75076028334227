import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Tooltip,
  Spinner,
} from "reactstrap";
import { Cols } from "../SeparatorStyle/SeparatorStyle";
import isEmpty from "validator/lib/isEmpty";
import { saveShopifyStores } from "../../api/store";
import {
  ErrorMessage,
  WarningMessage,
  SuccessMessage,
} from "../Messages/message.jsx";
import { getUserData } from "../../api/auth";
import { initializeBulkDataJob } from "../../api/orders";

import accessTokenImg from "./img/adminAPI.jpg";
import APIkey from "./img/APIkey.jpg";
import secertKey from "./img/secretKey.jpg";
import SaveMultipleShippers from "./SaveMultipleShippers";
import { toast } from "react-toastify";

const SaveMultipleStores = ({ toggle, modal }) => {
  const [storeAdded, setStoreAdded] = useState(false);
  const [storeId, setStoreId] = useState("");
  const [formData, setFormData] = useState({
    accessToken: "",
    domainName: "",
    apiKey: "",
    sharedSecretKey: "",
    errorMsg: false,
    warningMsg: false,
    successMsg: false,
  });
  const [addShipperModal, setAddShipperModal] = useState(false);
  const toggleAddShipperModal = () => setAddShipperModal(!addShipperModal);

  const [currentUserData, setCurrentUserData] = useState("");
  const [isLoader, setIsLoader] = useState(false);

  const loadUser = useCallback(async () => {
    try {
      const userData = await getUserData();
      setCurrentUserData(userData.data.user.domainName);
    } catch (error) {
      console.log("error found when fetch order data", error);
    }
  }, []);

  useEffect(() => {
    if (!currentUserData) {
      loadUser();
    }
  }, [currentUserData, loadUser]);
  const handleChange = (evt) => {
    setFormData({
      ...formData,
      [evt.target.name]: evt.target.value,
      errorMsg: false,
      warningMsg: false,
      successMsg: false,
    });
  };

  const handleURLChange = (evt) => {
    evt.preventDefault();
    let refinedURL;

    let pasted = (evt.clipboardData || window.clipboardData).getData(
      "text/plain"
    );

    if (pasted.includes(".myshopify.com")) {
      let rawURL = pasted;
      let res = rawURL.substring(0, rawURL.indexOf(".myshopify.com") + 14);
      if (res.includes("/")) {
        let filter = res.slice(res.lastIndexOf("/") + 1, res.length);
        refinedURL = filter;
      } else {
        refinedURL = res;
      }
    } else {
      toast.error("Please use shopify store only.");
    }
    setFormData({ ...formData, domainName: refinedURL });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      isEmpty(domainName) ||
      isEmpty(accessToken) ||
      isEmpty(apiKey) ||
      isEmpty(sharedSecretKey)
    ) {
      setFormData({
        ...formData,
        warningMsg: "All fields are required",
      });
    } else {
      setIsLoader(true);

      const { domainName, accessToken, apiKey, sharedSecretKey } = formData;
      const data = { domainName, accessToken, apiKey, sharedSecretKey };
      setFormData({
        ...formData,
      });
      saveShopifyStores(data)
        .then(async (response) => {
          setIsLoader(false);
          setFormData({
            ...formData,
            successMsg: response.data.successMessage,
            errorMsg: false,
            warningMsg: false,
          });
          setStoreId(response.data.store.id);
          const storeId = response.data.store.id;

          toast.success(response.data.successMessage);
          await initializeBulkDataJob(storeId);

          setStoreAdded(true);
          toggle();
        })
        .catch((err) => {
          setIsLoader(false);
          setFormData({
            ...formData,
            errorMsg: err.response.data.errorMessage,
          });
        });
    }
  };

  const [tool, setTool] = useState({
    shopDomain: false,
    shopKey: false,
    apiKey: false,
    sharedKey: false,
  });
  const {
    domainName,
    accessToken,
    apiKey,
    sharedSecretKey,
    errorMsg,
    warningMsg,
    successMsg,
  } = formData;
  return (
    <div>
      {storeAdded && (
        <SaveMultipleShippers
          toggleShipper={toggleAddShipperModal}
          modalShipper={addShipperModal}
          storeId={storeId}
        />
      )}
      <Modal isOpen={modal}>
        <ModalHeader toggle={toggle}>
          <b>Connect your Shopify Store</b>
        </ModalHeader>
        <ModalBody>
          <Row className="row h-100">
            <Cols xxs="12" md="10" className="mx-auto my-auto">
              <div className="form-side">
                <Form
                  className="av-tooltip tooltip-label-bottom"
                  onSubmit={handleSubmit}
                  noValidate
                >
                  {errorMsg !== false ? ErrorMessage(errorMsg) : null}
                  {successMsg !== false ? SuccessMessage(successMsg) : null}
                  {warningMsg !== false ? WarningMessage(warningMsg) : null}
                  <FormGroup className="form-group has-float-label">
                    <Tooltip
                      autohide={false}
                      isOpen={tool.shopDomain}
                      target="shopDomain"
                      toggle={() =>
                        setTool({ ...tool, shopDomain: !tool.shopDomain })
                      }
                    >
                      Grab your store domain name from browser url. It looks
                      something like this <b>example.myshopify.com</b>
                    </Tooltip>
                    <Label>
                      Shopify Store Domain{" "}
                      <i
                        className="fa ms-1 fa-question-circle pointer"
                        id="shopDomain"
                      ></i>
                    </Label>
                    <Input
                      onPaste={(e) => handleURLChange(e)}
                      className="form-control"
                      type="text"
                      name="domainName"
                      value={domainName}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup className="form-group has-float-label">
                    <Tooltip
                      autohide={false}
                      isOpen={tool.shopKey}
                      target="shopKey"
                      toggle={() =>
                        setTool({ ...tool, shopKey: !tool.shopKey })
                      }
                    >
                      Admin API access token in your Shopify store under <hr />
                      <img
                        src={accessTokenImg}
                        alt=""
                        width={"180"}
                        height="100"
                        className="tooltipImg"
                      />
                    </Tooltip>
                    <Label>
                      Admin API access token
                      <i
                        className="fa ms-1 fa-question-circle pointer"
                        id="shopKey"
                        style={{ marginLeft: "5px" }}
                      ></i>
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="accessToken"
                      value={accessToken}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup className="form-group has-float-label">
                    <Tooltip
                      autohide={false}
                      isOpen={tool.apiKey}
                      target="apiKey"
                      toggle={() => setTool({ ...tool, apiKey: !tool.apiKey })}
                    >
                      API key under in your Shopify store Apps Development{" "}
                      <hr />
                      <img
                        src={APIkey}
                        alt=""
                        width={"180"}
                        height="100"
                        className="tooltipImg"
                      />
                    </Tooltip>
                    <Label>
                      API Key
                      <i
                        className="fa ms-1 fa-question-circle pointer"
                        id="apiKey"
                        style={{ marginLeft: "5px" }}
                      ></i>
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="apiKey"
                      value={apiKey}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup className="form-group has-float-label">
                    <Tooltip
                      autohide={false}
                      isOpen={tool.sharedKey}
                      target="sharedKey"
                      toggle={() =>
                        setTool({ ...tool, sharedKey: !tool.sharedKey })
                      }
                    >
                      API secret key in your Shopify store under <hr />
                      <img
                        src={secertKey}
                        alt=""
                        width={"180"}
                        height="100"
                        className="tooltipImg"
                      />
                    </Tooltip>
                    <Label>
                      API Secret Key
                      <i
                        className="fa  fa-question-circle pointer"
                        id="sharedKey"
                        style={{ marginLeft: "5px" }}
                      ></i>
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="sharedSecretKey"
                      value={sharedSecretKey}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Form>
              </div>
            </Cols>
          </Row>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          {
            <Button
              color="primary"
              style={isLoader ? { padding: "11px 85px" } : null}
              size={"lg"}
              onClick={handleSubmit}
              disabled={
                isLoader ||
                domainName === "" ||
                accessToken === "" ||
                apiKey === "" ||
                sharedSecretKey === ""
              }
            >
              {isLoader ? (
                <Spinner size={"sm"}>Loading...</Spinner>
              ) : (
                "Add Your Store"
              )}
            </Button>
          }
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SaveMultipleStores;

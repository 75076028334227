import { useState } from 'react';
import {
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Spinner,
  Button,
  InputGroupText,
  CustomInput,
} from 'reactstrap';
import isEmpty from 'validator/lib/isEmpty';
import equals from 'validator/lib/equals';
import {
  SuccessMessage,
  ErrorMessage,
  InfoMessage,
  WarningMessage,
} from '../components/Messages/message';
import { signup } from '../api/auth';
import { setAuthDataInStorage } from '../helpers/auth';
import CardWrapper from '../components/UI/CardWrapper';
import { useHistory, Link } from 'react-router-dom';

// import withLinkGuard from "../components/HOC/withLinkGuard";
// const SignUpStep2 = ({token}) => {
const SignUpStep2 = () => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [registerSpiner, setRegisterSpiner] = useState(false);
  const [base64Image, setBase64Image] = useState();
  const [validImage, setValidImage] = useState(false);
  const [formData, setFormData] = useState({
    image: '',
    password: '',
    confirmPassword: '',
    successMsg: false,
    errorMsg: false,
    infoMsg: false,
    warningMsg: false,
  });
  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleChange = (evt) => {
    const { name, value } = evt.target;
    setFormData({
      ...formData,
      [name]: value,
      successMsg: false,
      errorMsg: false,
      infoMsg: false,
      warningMsg: false,
    });
  };
  const handleImageChange = (e) => {
    if (!e.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setValidImage(true);
    } else if (e.target.files && e.target.files.length > 0) {
      function getBase64(file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          setBase64Image(reader.result);
          setValidImage(false);
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
      }
      getBase64(e.target.files[0]);
    }
    formData.errorMsg = false;
    formData.infoMsg = false;
    formData.warningMsg = false;
    formData.successMsg = false;
  };
  const {
    password,
    confirmPassword,
    successMsg,
    errorMsg,
    infoMsg,
    warningMsg,
  } = formData;
  const handleSubmit = async (e) => {
    e.preventDefault();
    let sizeInKb;

    if (base64Image) {
      // technique to calulate size of base64 images length
      let stringLength = base64Image.length - 'data:image/png;base64,'.length;
      let sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
      sizeInKb = sizeInBytes / 1000;
    }

    // Hint: https://stackoverflow.com/questions/29939635/how-to-get-file-size-of-newly-created-image-if-src-is-base64-string
    if (isEmpty(password) || isEmpty(confirmPassword)) {
      setFormData({
        ...formData,
        infoMsg: 'All Fields are required',
      });
    } else if (password.length < 3) {
      setFormData({
        ...formData,
        warningMsg: 'password must be at least 3 chars long',
      });
    } else if (!equals(password, confirmPassword)) {
      setFormData({
        ...formData,
        warningMsg: 'Both Password must be match',
      });
    } else if (validImage || !base64Image) {
      setFormData({
        ...formData,
        warningMsg: 'Please add a valid image',
      });
    } else if (
      password &&
      confirmPassword &&
      (!base64Image || sizeInKb > 100)
    ) {
      setFormData({
        ...formData,
        warningMsg: 'Add a image under 100KB',
      });
    } else {
      setRegisterSpiner((r) => !r);

      setFormData({
        ...formData,
      });
      let tempCredentials = localStorage.getItem('signup-credentials');
      if (!tempCredentials) return;
      tempCredentials = JSON.parse(tempCredentials);
      //commented for future use
      // const data = { password, image: base64Image,token };
      const data = { password, image: base64Image, token: tempCredentials };
      signup(data)
        .then((response) => {
          setRegisterSpiner(false);
          setAuthDataInStorage(response.data.token, response.data.user);
          localStorage.removeItem('signup-credentials');
          history.push('/storeAccessKeys');
        })
        .catch((err) => {
          setRegisterSpiner(false);
          setFormData({
            ...formData,
            errorMsg: err.response.data.errorMessage,
          });
        });
    }
  };
  return (
    <CardWrapper>
      <p className="text-right mb-0 font-weight-bold">Step 2</p>
      <div className="text-center">
        <img
          src="img/login/shopilam_logo_mobile.svg"
          alt="logo"
          className="mx-auto mb-3"
        />
      </div>
      <h2 className="font-weight-bold text-center mb-3">Just a step Away!</h2>
      <Form onSubmit={handleSubmit} noValidate>
        {infoMsg !== false ? InfoMessage(infoMsg) : null}
        {warningMsg !== false ? WarningMessage(warningMsg) : null}
        {successMsg !== false ? SuccessMessage(successMsg) : null}
        {errorMsg !== false ? ErrorMessage(errorMsg) : null}
        <FormGroup>
          <Label className="has-float-label mb-4">
            <InputGroup>
              <Input
                type={showPassword ? 'text' : 'password'}
                name="password"
                className="form-control"
                value={password}
                onChange={handleChange}
              />
              <InputGroupText>
                <span
                  className={showPassword ? 'far fa-eye-slash' : 'far fa-eye'}
                  style={{
                    fontSize: '20px',
                    cursor: 'pointer',
                  }}
                  onClick={handlePasswordVisibility}
                ></span>
              </InputGroupText>
            </InputGroup>
            <span>Password</span>
          </Label>
        </FormGroup>
        <FormGroup>
          <Label className="has-float-label mb-4">
            <InputGroup>
              <Input
                type={showPassword ? 'text' : 'password'}
                name="confirmPassword"
                className="form-control"
                value={confirmPassword}
                onChange={handleChange}
              />
              <InputGroupText>
                <span
                  className={showPassword ? 'far fa-eye-slash' : 'far fa-eye'}
                  style={{
                    fontSize: '20px',
                    cursor: 'pointer',
                  }}
                  onClick={handlePasswordVisibility}
                ></span>
              </InputGroupText>
            </InputGroup>
            <span>Confirm Password</span>
          </Label>
          <InputGroup className="mb-2">
            <CustomInput
              type="file"
              id="exampleCustomFileBrowser"
              name="image"
              onChange={(e) => handleImageChange(e)}
              required
            />
          </InputGroup>
        </FormGroup>
        <Button
          type="submit"
          className="btn btn_custom btn-sm w-100 my-2 btn-shadow rounded"
          disabled={registerSpiner}
        >
          {registerSpiner ? (
            <Spinner
              style={{
                height: '1.3rem',
                width: '1.3rem',
              }}
              type="grow"
              color="light"
            >
              Loading...
            </Spinner>
          ) : (
            'Sign up'
          )}
        </Button>
      </Form>
      <div className="mt-3 text-center">
        <p className="mb-2">
          Already have an account?
          <Link to="/signIn" className="font-weight-bold page_link ml-2">
            Login
          </Link>
        </p>
      </div>
    </CardWrapper>
  );
};

export default SignUpStep2;
// export default  withLinkGuard(SignUpStep2,'/signUp') ;

import React, { useState } from "react";
import {
  Breadcrumb,
  Card,
  CardBody,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Cols } from "../../SeparatorStyle/SeparatorStyle";
import { Link } from "react-router-dom";
import ProductTabs from "./ProductTabs";
import { motion } from "framer-motion";
import { leftAnimate } from "../../FramerMotion/FramerMotion";
import Products from "./Products";

const ProductHeader = () => {
  const activeTabFromStorage = localStorage.getItem("activeProductListingTab");
  const [activeTab, setActiveTab] = useState(
    activeTabFromStorage ? activeTabFromStorage : "allProducts"
  );
  const [activeProducts, setActiveProducts] = useState(false);
  const [draftProducts, setDraftProducts] = useState(false);
  const [archivedProducts, setArchivedProducts] = useState(false);
  // const [skeletonTabProduct, setSkeletonTabProduct] = useState(false);
  // setTimeout(() => {
  //   setSkeletonTabProduct(true);
  // }, 1000);
  return (
    <>
      <main>
        <Row>
          <Cols>
            <motion.div
              className="top-text"
              variants={leftAnimate}
              initial="hidden"
              animate="visible"
            >
              <h1>Products</h1>
              <Breadcrumb className=" breadcrumb-container d-none  d-lg-inline-block ">
                <ol className="breadcrumb ">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard" style={{ color: "black" }}>
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Products
                  </li>
                </ol>
              </Breadcrumb>
              {/* <Link
                className="mr-2 my-2 bold btn btn-primary"
                color="primary"
                to="/admin/AddProduct"
                style={{ float: 'right' }}             
              >
                Add Product
              </Link> */}
            </motion.div>
          </Cols>
        </Row>

        <Card>
          <CardBody>
            {/* {skeletonTabProduct ? ( */}
            <div className="table_tabs_header mb-3">
              <ProductTabs activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
            {/* ) : (
              <div className="tabs-bottom-border mt-2">
                {new Array(4).fill(0).map((item, i) => {
                  return (
                    <p
                      className="skeleton skeleton-loading tab-skeleton me-4 mb-0"
                      key={i + 1}
                    ></p>
                  );
                })}
              </div>
            )} */}

            <TabContent activeTab={activeTab}>
              <TabPane tabId="allProducts">
                {activeTab === "allProducts" ? (
                  <Products
                    // setSkeletonTab={setSkeletonTabProduct}
                    status={"all"}
                    setActiveProducts={setActiveProducts}
                    setDraftProducts={setDraftProducts}
                    setArchivedProducts={setArchivedProducts}
                  />
                ) : (
                  ""
                )}
              </TabPane>
              <TabPane tabId="activeProducts">
                {activeTab === "activeProducts" ? (
                  <Products
                    // setSkeletonTab={setSkeletonTabProduct}
                    status={"active"}
                    setActiveProducts={setActiveProducts}
                    activeProducts={activeProducts}
                    setDraftProducts={setDraftProducts}
                    setArchivedProducts={setArchivedProducts}
                  />
                ) : (
                  ""
                )}
              </TabPane>
              <TabPane tabId="draftProducts">
                {activeTab === "draftProducts" ? (
                  <Products
                    // setSkeletonTab={setSkeletonTabProduct}
                    status="draft"
                    setDraftProducts={setDraftProducts}
                    draftProducts={draftProducts}
                    setArchivedProducts={setArchivedProducts}
                  />
                ) : (
                  ""
                )}
              </TabPane>
              <TabPane tabId="archivedProducts">
                {activeTab === "archivedProducts" ? (
                  <Products
                    // setSkeletonTab={setSkeletonTabProduct}
                    status={"archived"}
                    setArchivedProducts={setArchivedProducts}
                    archivedProducts={archivedProducts}
                  />
                ) : (
                  ""
                )}
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </main>
    </>
  );
};

export default ProductHeader;

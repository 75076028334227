import React from "react";
import { PopoverBody, UncontrolledPopover } from "reactstrap";
// import { DateTimePopup } from "../components/Modals/Popups.jsx";
// import { popOverContent } from "../popOvers/popOver.js";
/**
 *
 * Function for converting date to  start of day
 */
export const setDateStart = (date) => {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  return date;
};
/**
 *
 * Function for converting date to end of day
 */
export const setDateEnd = (date) => {
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  return date;
};

export const DateFormatted = (dateFormate, index) => {
  const date = new Date(dateFormate);

  let getMonth = date.toLocaleString("en-us", { month: "short" });
  let getFullMonth = date.toLocaleString("en-us", { month: "long" });
  let fullYear = date.getFullYear(dateFormate);
  let getDate = date.getDate(dateFormate);

  let getTime = date.toLocaleString("en-US", {
    // weekday: 'long',
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  let dateFormatted = `${getDate} ${getMonth} `;

  let showDateTime = `${getDate} ${getFullMonth} ${fullYear} @ ${getTime}`;

  return (
    <>
      <span className="ms-2" style={{ whiteSpace: "nowrap" }}>
        {dateFormatted}
        
        {
          <i className="simple-icon-info pointer ml-1" id={`DateTime-${index}`}>
            <UncontrolledPopover
              trigger="hover"
              placement="top"
              target={`DateTime-${index}`}
            >
              <PopoverBody className="text-center">{showDateTime}</PopoverBody>
            </UncontrolledPopover>
          </i>
        }
      </span>
    </>
  );
};

export const getFullDate = (dateFormate) => {
  const date = new Date(dateFormate);

  let getFullMonth = date.toLocaleString("en-us", { month: "long" });
  let fullYear = date.getFullYear(dateFormate);
  let getDate = date.getDate(dateFormate);

  let getTime = date.toLocaleString("en-US", {
    // weekday: 'long',
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  let showDateTime = `${getDate} ${getFullMonth} ${fullYear} at ${getTime}`;

  return (
    <>
      <div>{showDateTime}</div>
    </>
  );
};

// export const compareDates=({from, to, check}) => {

//     let fromDate,toDate,checkDate;
//     fromDate = Date.parse(from);
//     toDate = Date.parse(to);
//     checkDate = Date.parse(check);

//     if((checkDate <= toDate && checkDate >= fromDate)) {
//         return true;
//     }
//     return false;
// }
export const sortByDate = (a, b) => {
  return Date.parse(b.created_at) - Date.parse(a.created_at);
};

export const UserLoginDate = (loginDate) => {
  const date = new Date(loginDate);
  let getFullMonth = date.toLocaleString("en-us", { month: "long" });
  let fullYear = date.getFullYear(loginDate);
  let getDate = date.getDate(loginDate);
  let weekDay = date.toLocaleString("en-US", { weekday: "long" });
  let getTime = date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
  return `${weekDay} ${getDate}, ${getFullMonth} ${fullYear} ${getTime}`;
};

import React, { useState } from "react";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";

const ExcelToJson = ({ setSelectedFileForImport, setUploadedFileData }) => {
  const [fileName, setFileName] = useState("");

  const fileValidation = (path) => {
    let allowedExtensions = /(\.csv|\.xlsx|\.xls)$/i;
    if (!allowedExtensions.exec(path)) {
      return false;
    } else {
      return true;
    }
  };

  const filePathSet = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const file = e.target.files[0];
    let result = fileValidation(file.name, e.target.value);
    if (result) {
      setFileName(file.name);
      setSelectedFileForImport(file);
      readFile(file);
    } else {
      setFileName("");
      toast.error("Invalid File Type!");
    }
  };

  const readFile = (file) => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const binaryStr = evt.target.result;
      const workBook = XLSX.read(binaryStr, { type: "binary" });

      /* Get first worksheet */
      const workSheetName = workBook.SheetNames[0];
      const workSheet = workBook.Sheets[workSheetName];

      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(workSheet, { header: 1 });

      /* Update state */
      const jsonData = convertToJson(data);
      jsonData && jsonData.length > 0
        ? setUploadedFileData(jsonData)
        : setUploadedFileData(null);
    };
    reader.readAsBinaryString(file);
  };

  const convertToJson = (csv, returnAsJson = false) => {
    const lines = csv.split("\n");
    const result = [];
    const headers = lines[0].split(",");

    for (let i = 1; i < lines.length; i++) {
      let obj = {};
      let currentLine = lines[i].split(",");

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentLine[j];
      }
      result.push(obj);
    }
    // discard last row. Last row only contains column names with empty values
    result.pop();
    return returnAsJson ? JSON.stringify(result) : result;
  };

  return (
    <div className="file_upload_controls text-center mr-auto mt-3">
      <label>
        Upload File
        <input
          type="file"
          accept=".csv, .xlsx, .xls"
          id="courierTrackingNumbersFile"
          name="courierTrackingNumbersFile"
          onChange={filePathSet}
        />
      </label>
      {fileName === "" ? (
        <p style={{ margin: "8px" }}>No File Chosen</p>
      ) : (
        <span style={{ margin: "3px", marginTop: "8px" }}>{fileName}</span>
      )}
    </div>
  );
};

export default ExcelToJson;

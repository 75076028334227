import axios from "axios";
import { getCookie } from "../helpers/cookie";
import { getAPIURL } from "./utils";
const url = getAPIURL();

// Save Questionnare Section
export const saveQuestions = async (data) => {
  const token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  let response;
  try {
    response = await axios.post(`${url}/api/questionnares`, data, config);
  } catch (e) {
    response = e;
  }
  return response;
};

import axios from "axios";
import { getCookie } from "../helpers/cookie";
import { getAPIURL } from "./utils";
import { toast } from "react-toastify";

const url = getAPIURL();

// Get Account for checking account preferences
export const getAccount = async () => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${url}/api/couriers/getAccount`, config);

  return response;
};

export const getCourier = async () => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${url}/api/couriers/name`, config);

  return response;
};

export const getUserPreferences = async () => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${url}/api/couriers/preferences`, config);

  return response;
};

export const savedCouriers = (userData) => {
  if (userData.length > 0) {
    let token = getCookie();
    let data = userData;
    const checking = {
      selectedCourier: data,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    return axios.post(`${url}/api/couriers/userCouriersName`, checking, config);
  }
};

export const savedUpdatedCouriers = (courierData) => {
  if (courierData.length > 0) {
    let token = getCookie();
    let data = courierData;
    const updateCourier = {
      couriers: data,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    return axios.post(
      `${url}/api/couriers/updateCourier`,
      updateCourier,
      config
    );
  }
};

export const defaultCourierCityFun = async (defaultCitesData) => {
  let token = getCookie();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  await axios.put(
    `${url}/api/couriers/defaultCityCourier`,
    defaultCitesData,
    config
  );
};

export const courierCityFun = async (CouriersCityName) => {
  let token = getCookie();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  await axios.put(
    `${url}/api/couriers/citiesCourier`,
    CouriersCityName,
    config
  );
};

export const skipCouriers = async () => {
  let token = getCookie();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    return await axios.post(`${url}/api/couriers/skip`, {}, config);
  } catch (error) {
    console.log(error);
    toast.error("Failed to skip couriers");
  }
};

export const couriersLoadSheet = async (data) => {
  let token = getCookie();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    return await axios.post(
      `${url}/api/couriers/LoadSheets`,
      { ...data },
      config
    );
  } catch (error) {
    console.log(error);
    toast.error("Failed to skip couriers");
  }
};

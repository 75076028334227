import React, { useEffect, useState, useCallback } from "react";
import { Row, Card, CardBody, CardTitle, Button } from "reactstrap";
import { Cols } from "../SeparatorStyle/SeparatorStyle";
import DropdownSelection from "./DropdownSelection.jsx";
import { getUserAccount } from "../../api/auth";
import { useHistory, NavLink, useLocation } from "react-router-dom";
import { skipCouriers } from "../../api/courier.js";
import { toast } from "react-toastify";

const CourierList = () => {
  let history = useHistory();

  const [currentUserStores, setCurrentUserStores] = useState("");
  const location = useLocation();
  const myparam = location?.state?.update;
  const loadCurrentUserStores = useCallback(async () => {
    try {
      const userAccountData = await getUserAccount();
      setCurrentUserStores(userAccountData.data.account.stores);
    } catch (error) {
      console.log("error found when fetch user's stores data", error);
    }
  }, []);

  useEffect(() => {
    if (!currentUserStores) {
      loadCurrentUserStores();
    }
  }, [currentUserStores, loadCurrentUserStores]);

  const handleSkipCourier = async () => {
    const data = await skipCouriers();
    data && toast.info(data?.message || data);
    data && history.push("/questionnaire");
  };

  return (
    <>
      {myparam ? (
        <main>
          {!currentUserStores ? (
            <div className="loading" />
          ) : currentUserStores.length > 0 ? (
            <Row className="custom-card-styling">
              <Cols xxs="10">
                {myparam ? null : (
                  <NavLink
                    to="/"
                    className="white row justify-content-center mb-2"
                  >
                    <span className="logo-single" />
                  </NavLink>
                )}
                <Card>
                  <CardBody>
                    <CardTitle className="text-center">
                      <h2 className="mt-3">
                        <b>Select All The Couriers You Work With </b>
                      </h2>
                    </CardTitle>
                    <br />
                    <DropdownSelection update={myparam} />
                  </CardBody>
                </Card>
              </Cols>
            </Row>
          ) : (
            history.push("/storeAccessKeys")
          )}
        </main>
      ) : (
        <div className="d-flex justify-content-center align-items-center main-wrapper margin_90">
          {!currentUserStores ? (
            <div className="loading" />
          ) : currentUserStores.length > 0 ? (
            <Row className="custom-card-styling ">
              <Cols xxs="10">
                {myparam ? null : (
                  <NavLink
                    to="/"
                    className="white row justify-content-center mb-2"
                  >
                    <span className="logo-single" />
                  </NavLink>
                )}

                <Card>
                  <CardBody>
                    <CardTitle className="text-center">
                      <h2 className="mt-3">
                        <b>Select All The Couriers You Work With</b>
                      </h2>
                      <Button onClick={handleSkipCourier}>Skip for now</Button>
                    </CardTitle>
                    <br />
                    <DropdownSelection />
                  </CardBody>
                </Card>
              </Cols>
            </Row>
          ) : (
            history.push("/storeAccessKeys")
          )}
        </div>
      )}
    </>
  );
};

export default CourierList;

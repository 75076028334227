import React from "react";
import { Badge } from "reactstrap";

export const fulFillmentStatus = (fulFillmentStatus) => {
  switch (fulFillmentStatus) {
    case "partial":
      return (
        <h6>
          <Badge color="warning" pill>
            o Partial Fulfilled
          </Badge>
        </h6>
      );
    case "fulfilled":
      return (
        <h6>
          <Badge color="light" pill>
            ● Fulfilled
          </Badge>
        </h6>
      );
    default:
      return (
        <h6>
          <Badge color="warning" pill>
            o Unfulfilled
          </Badge>
        </h6>
      );
  }
};

export const paymentStatus = (paymentStatus) => {
  switch (paymentStatus) {
    case "paid":
      return (
        <h6>
          <Badge color="light" pill>
            ● Paid
          </Badge>
        </h6>
      );
    case "refunded":
      return (
        <h6>
          <Badge color="light" pill>
            ● Refunded
          </Badge>
        </h6>
      );
    default:
      return (
        <h6>
          <Badge color="warning" pill>
            o Pending
          </Badge>
        </h6>
      );
  }
};

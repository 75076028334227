import {
  ALL_ORDERS_REQUEST,
  ALL_ORDERS_SUCCESS,
  ALL_ORDERS_FAIL,
  THIRTY_ORDERS_REQUEST,
  THIRTY_ORDERS_SUCCESS,
  THIRTY_ORDERS_FAIL,
  FIFTEEN_ORDERS_REQUEST,
  FIFTEEN_ORDERS_SUCCESS,
  FIFTEEN_ORDERS_FAIL,
  LASTWEEK_ORDERS_REQUEST,
  LASTWEEK_ORDERS_SUCCESS,
  LASTWEEK_ORDERS_FAIL,
  TODAY_ORDERS_REQUEST,
  TODAY_ORDERS_SUCCESS,
  TODAY_ORDERS_FAIL,
  REFUNDED_ORDERS_REQUEST,
  REFUNDED_ORDERS_SUCCESS,
  REFUNDED_ORDERS_FAIL,
  FULFILLED_ORDERS_REQUEST,
  FULFILLED_ORDERS_SUCCESS,
  FULFILLED_ORDERS_FAIL,
  UNFULFILLED_ORDERS_REQUEST,
  UNFULFILLED_ORDERS_SUCCESS,
  UNFULFILLED_ORDERS_FAIL,
  OPEN_ORDERS_REQUEST,
  OPEN_ORDERS_SUCCESS,
  OPEN_ORDERS_FAIL,
  PENDING_ORDERS_REQUEST,
  PENDING_ORDERS_SUCCESS,
  PENDING_ORDERS_FAIL,
  CLOSED_ORDERS_REQUEST,
  CLOSED_ORDERS_SUCCESS,
  CLOSED_ORDERS_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_UNFULFILLED_ITEMS,
  ORDER_DETAILS_FULFILLED_ITEMS,
} from "../constants/orderConstants";
import { getOrders, getOrderById } from "../../api/orders";
import { extractFulfilledAndUnfulfilled } from "../../helpers/orderUtills";

export const getOrderDetails = (id) => async (dispatch) => {
  dispatch({ type: ORDER_DETAILS_REQUEST });
  try {
    const { data } = await getOrderById(id);
    dispatch({ type: ORDER_DETAILS_SUCCESS, payload: data });

    // get fulfilled and unfulfilled items from line items
    const { fulfilledItems, unFulfilledItems } =
      extractFulfilledAndUnfulfilled(data);

    dispatch({
      type: ORDER_DETAILS_UNFULFILLED_ITEMS,
      payload: unFulfilledItems,
    });
    dispatch({ type: ORDER_DETAILS_FULFILLED_ITEMS, payload: fulfilledItems });
  } catch (e) {
    dispatch({ type: ORDER_DETAILS_FAIL, payload: e.message });
  }
};

export const getAllOrders =
  ({ returnCountOnly, startDateIs, endDateIs, page, limit }) =>
  async (dispatch) => {
    try {
      dispatch({ type: ALL_ORDERS_REQUEST });

      const { data } = await getOrders({
        returnCountOnly,
        startDateIs,
        endDateIs,
        page,
        limit,
      });

      dispatch({ type: ALL_ORDERS_SUCCESS, payload: data.orders });
    } catch (e) {
      dispatch({ type: ALL_ORDERS_FAIL, payload: e.message });
    }
  };

export const getThirtyOrders =
  ({ returnCountOnly, startDateIs, endDateIs, page, limit }) =>
  async (dispatch) => {
    try {
      dispatch({ type: THIRTY_ORDERS_REQUEST });
      const { data } = await getOrders({
        returnCountOnly,
        startDateIs,
        endDateIs,
        page,
        limit,
      });
      dispatch({ type: THIRTY_ORDERS_SUCCESS, payload: data.orders });
    } catch (e) {
      dispatch({ type: THIRTY_ORDERS_FAIL, payload: e.message });
    }
  };

export const getFifteenOrders =
  ({ returnCountOnly, startDateIs, endDateIs, page, limit }) =>
  async (dispatch) => {
    try {
      dispatch({ type: FIFTEEN_ORDERS_REQUEST });

      const { data } = await getOrders({
        returnCountOnly,
        startDateIs,
        endDateIs,
        page,
        limit,
      });
      dispatch({ type: FIFTEEN_ORDERS_SUCCESS, payload: data.orders });
    } catch (e) {
      dispatch({ type: FIFTEEN_ORDERS_FAIL, payload: e.message });
    }
  };

export const getLastWeekOrders =
  ({ returnCountOnly, startDateIs, endDateIs, page, limit }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LASTWEEK_ORDERS_REQUEST });

      const { data } = await getOrders({
        returnCountOnly,
        startDateIs,
        endDateIs,
        page,
        limit,
      });
      dispatch({ type: LASTWEEK_ORDERS_SUCCESS, payload: data.orders });
    } catch (e) {
      dispatch({ type: LASTWEEK_ORDERS_FAIL, payload: e.message });
    }
  };

export const getTodayOrders =
  ({ returnCountOnly, startDateIs, endDateIs, page, limit }) =>
  async (dispatch) => {
    try {
      dispatch({ type: TODAY_ORDERS_REQUEST });

      const { data } = await getOrders({
        returnCountOnly,
        startDateIs,
        endDateIs,
        page,
        limit,
      });
      dispatch({ type: TODAY_ORDERS_SUCCESS, payload: data.orders });
    } catch (e) {
      dispatch({ type: TODAY_ORDERS_FAIL, payload: e.message });
    }
  };

export const getRefundedOrders =
  ({ returnCountOnly, startDateIs, endDateIs }) =>
  async (dispatch) => {
    try {
      dispatch({ type: REFUNDED_ORDERS_REQUEST });

      const { data } = await getOrders({
        status: "refunded",
        statusType: "financial_status",
        returnCountOnly,
        startDateIs,
        endDateIs,
      });
      dispatch({ type: REFUNDED_ORDERS_SUCCESS, payload: data.orders });
    } catch (e) {
      dispatch({ type: REFUNDED_ORDERS_FAIL, payload: e.message });
    }
  };

export const getPendingOrders =
  ({ returnCountOnly, startDateIs, endDateIs }) =>
  async (dispatch) => {
    try {
      dispatch({ type: PENDING_ORDERS_REQUEST });

      const { data } = await getOrders({
        status: "pending",
        statusType: "financial_status",
        returnCountOnly,
        startDateIs,
        endDateIs,
      });
      dispatch({ type: PENDING_ORDERS_SUCCESS, payload: data.orders });
    } catch (e) {
      dispatch({ type: PENDING_ORDERS_FAIL, payload: e.message });
    }
  };

export const getFulfilledOrders =
  ({ returnCountOnly, startDateIs, endDateIs }) =>
  async (dispatch) => {
    try {
      dispatch({ type: FULFILLED_ORDERS_REQUEST });

      const { data } = await getOrders({
        status: "shipped",
        statusType: "fulfillment_status",
        returnCountOnly,
        startDateIs,
        endDateIs,
      });
      dispatch({ type: FULFILLED_ORDERS_SUCCESS, payload: data.orders });
    } catch (e) {
      dispatch({ type: FULFILLED_ORDERS_FAIL, payload: e.message });
    }
  };

export const getUnFulfilledOrders =
  ({ returnCountOnly, startDateIs, endDateIs }) =>
  async (dispatch) => {
    try {
      dispatch({ type: UNFULFILLED_ORDERS_REQUEST });

      const { data } = await getOrders({
        status: null,
        statusType: "fulfillment_status",
        returnCountOnly,
        startDateIs,
        endDateIs,
      });
      dispatch({ type: UNFULFILLED_ORDERS_SUCCESS, payload: data.orders });
    } catch (e) {
      dispatch({ type: UNFULFILLED_ORDERS_FAIL, payload: e.message });
    }
  };

export const getOpenOrders =
  ({ returnCountOnly, startDateIs, endDateIs }) =>
  async (dispatch) => {
    try {
      dispatch({ type: OPEN_ORDERS_REQUEST });

      const { data } = await getOrders({
        status: "open",
        statusType: "status",
        returnCountOnly,
        startDateIs,
        endDateIs,
      });
      dispatch({ type: OPEN_ORDERS_SUCCESS, payload: data.orders });
    } catch (e) {
      dispatch({ type: OPEN_ORDERS_FAIL, payload: e.message });
    }
  };

export const getClosedOrders =
  ({ returnCountOnly, startDateIs, endDateIs }) =>
  async (dispatch) => {
    try {
      dispatch({ type: CLOSED_ORDERS_REQUEST });

      const { data } = await getOrders({
        status: "closed",
        statusType: "status",
        returnCountOnly,
        startDateIs,
        endDateIs,
      });
      dispatch({ type: CLOSED_ORDERS_SUCCESS, payload: data.orders });
    } catch (e) {
      dispatch({ type: CLOSED_ORDERS_FAIL, payload: e.message });
    }
  };

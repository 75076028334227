import React, { useCallback, useEffect, useState } from "react";
import { Cols } from "../../SeparatorStyle/SeparatorStyle";
import Table from "../../tables/table.jsx";
import {
  Alert,
  Spinner,
  Input,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import { getOrders } from "../../../api/orders";
import { DateFormatted, sortByDate } from "../../../helpers/dateTime";
import {
  fulFillmentStatus,
  paymentStatus,
} from "./helperFunctions/ordersStatusInfo.jsx";
import { showCustomerDetail } from "./helperFunctions/tableDetailsInfo.jsx";
import { useHistory } from "react-router-dom";
import { getCookie } from "../../../helpers/cookie";
import { toast } from "react-toastify";
import { getAllStoresLoginUser } from "../../../api";
import { handleSearch } from "../../search/searchHook";
import { motion } from "framer-motion";
// import { rightAnimate, item } from "../../FramerMotion/FramerMotion";
import { popOverContent, popOverList } from "../../../popOvers/popOver";
import TableSkeleton from "../../UI/TableSkeleton";
const UnPaidOrders = ({ setSkeletonTab }) => {
  const [pendingOrders, setPendingOrders] = useState([]);
  // const [itemDetailsData, setItemDetailsData] = useState();
  // const [userDetail, setUserDetail] = useState();
  // const [dateTime, setDateTime] = useState();
  const [count, setCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [showStoreColumn, setShowStoreColumn] = useState(false);
  // const [noteDetailsData, setNoteDetailsData] = useState("");
  const [search, setSearch] = useState("");
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [emptyStatus, setEmptyStatus] = useState(false);
  const [filtersLoader, setFiltersLoader] = useState(false);
  const [searchTrigger, setSearchTrigger] = useState(false);
  let history = useHistory();

  const loadOrders = useCallback(
    async ({
      page = pageNumber,
      limit = pageLimit,
      title = search.trim().length > 2 ? search : "",
    }) => {
      try {
        setEmptyStatus(false);
        const { data } = await getOrders({
          payment: "pending",
          page,
          limit,
          title,
        });
        if (data) {
          setPendingOrders(data.orders);
          setLoading(false);
          // setSkeletonTab(false);
          setFiltersLoader(false);
          setPaginationLoading(false);
        }

        if (data.count === 0) {
          setEmptyStatus(true);
        } else {
          setEmptyStatus(false);
        }

        setCount(data.count);
      } catch (error) {
        console.log("error found when fetch order data", error);
      }
    },
    [searchTrigger]
  );

  const totalPages = Math.ceil(count / pageLimit);

  const loadStores = useCallback(async () => {
    try {
      const { data } = await getAllStoresLoginUser();
      const { stores } = data[0];
      stores.length > 1 && setShowStoreColumn(true);
    } catch (error) {
      toast.error("Error found when fetch stores data!");
      console.log("Error found when fetch stores data!", error);
    }
  }, []);

  useEffect(() => {
    // setSkeletonTab(true);
    loadStores();
  }, []);

  useEffect(() => {
    let token = getCookie();
    if (token) {
      loadOrders({});
    } else {
      history.push("/signIn");
      toast.error("Your Session is Expired! Login Again...");
    }
  }, [loadOrders]);

  // const noteDetails = (note) => {
  //   let noteText = note.slice(0, 20);
  //   let noteLenght = note.length;
  //   return (
  //     <div>
  //       {`${noteText}${noteLenght > 20 ? "..." : ""}`}

  //       {noteLenght > 20 ? (
  //         <i
  //           style={{ cursor: "pointer" }}
  //           className="p-2 simple-icon-info "
  //           id="noteDetailPopover"
  //           onMouseEnter={() => {
  //             setNoteDetailsData(note);
  //           }}
  //         />
  //       ) : (
  //         ""
  //       )}
  //       <NotePopup noteData={noteDetailsData && noteDetailsData} />
  //     </div>
  //   );
  // };

  const ordersData =
    pendingOrders &&
    pendingOrders.length > 0 &&
    pendingOrders.map((order, i) => {
      return {
        id: order?.orderId,
        orderId: order?.name ? order?.name : "N/A",
        orderDate: DateFormatted(order?.created_at, i),
        name: order?.shipping_address ? showCustomerDetail(order, i) : "N/A",
        amount: popOverContent(
          `Total-${i}`,
          Number(order?.total_price_set?.shop_money?.amount).toLocaleString()
        ),
        paymentStatus: paymentStatus(order?.financial_status),
        fulfillmentStatus: fulFillmentStatus(order?.fulfillment_status),
        note: order?.note || "N/A",
        items: (
          <>
            {order?.line_items.length}
            {"  "}
            {order?.line_items.length > 0
              ? popOverList(
                  `lineItem-${i}`,
                  order?.line_items.map((o) => `${o.title} (${o.quantity})`)
                )
              : "N/A"}
          </>
        ),
        delivery: order?.gateway ? order?.gateway : "N/A",

        storeName: popOverContent(
          `Store-${i}`,
          order?.orderRefNumber.replace(`${order?.name}-`, "")
        ),
      };
    });

  const cols = React.useMemo(
    () => [
      {
        Header: "Order Id",
        accessor: "orderId",
        cellClass: " w-4",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Date",
        accessor: "orderDate",
        cellClass: " w-6",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Customer",
        accessor: "name",
        cellClass: " w-12",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Total",
        accessor: "amount",
        cellClass: " w-6",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Payment",
        accessor: "paymentStatus",
        cellClass: " w-6",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Fulfillment",
        accessor: "fulfillmentStatus",
        cellClass: " w-6",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Note",
        accessor: "note",
        cellClass: " w-6",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Items",
        accessor: "items",
        cellClass: " w-6",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Delivery Method",
        accessor: "delivery",
        cellClass: " w-6",
        Cell: (props) => <>{props.value}</>,
      },
    ],
    []
  );

  const myCols = React.useMemo(
    () => [
      {
        Header: "Order Id",
        accessor: "orderId",
        cellClass: " w-4",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Date",
        accessor: "orderDate",
        cellClass: " w-6",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Customer",
        accessor: "name",
        cellClass: " w-12",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Total",
        accessor: "amount",
        cellClass: " w-6",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Payment",
        accessor: "paymentStatus",
        cellClass: " w-6",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Fulfillment",
        accessor: "fulfillmentStatus",
        cellClass: " w-6",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Note",
        accessor: "note",
        cellClass: " w-6",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Items",
        accessor: "items",
        cellClass: " w-6",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Delivery Method",
        accessor: "delivery",
        cellClass: " w-6",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Store",
        accessor: "storeName",
        cellClass: " w-10",
        Cell: (props) => <>{props.value}</>,
      },
    ],
    []
  );

  return (
    <>
      {loading ? (
        <TableSkeleton skeletonLength={10} itemsBar={1} tabs={5} />
      ) : emptyStatus && !search.trim() ? (
        <div
          className=" d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
          style={{
            height: "200px",
          }}
        >
          <span>No Order Found!</span>
        </div>
      ) : (
        <motion.div
          className="row"
          // variants={rightAnimate}
          // initial="hidden"
          // animate="visible"
        >
          <Cols xxs="12">
            <div className="mt-4 mb-4 marginL-md px-2">
              <div className="card-title order_table_card_title mb-0">
                <div className="mb-2 me-1 position-relative">
                  <Input
                    type="text"
                    name="keyword"
                    id="search"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => {
                      setEmptyStatus(false);
                      handleSearch({
                        e,
                        setPageNumber,
                        setPageLimit,
                        setFilterLoading: setFiltersLoader,
                        setSearch,
                        setSearchTrigger,
                        searchTrigger,
                      });
                    }}
                    className="rounded-3"
                    disabled={paginationLoading || filtersLoader}
                  />
                  {search.trim() && search.trim().length < 3 && (
                    <i
                      className="simple-icon-info pointer position-absolute text-danger fw-bold"
                      style={{ right: "-20px", bottom: "12px" }}
                      id={"SearchInfo"}
                    >
                      {" "}
                      <UncontrolledPopover
                        trigger="hover"
                        placement="top"
                        target={"SearchInfo"}
                      >
                        <PopoverBody className="text-center">
                          <i>Minimum 3 words required!</i>
                        </PopoverBody>
                      </UncontrolledPopover>
                    </i>
                  )}
                  {search &&
                    (filtersLoader && search.trim().length > 2 ? (
                      <Spinner
                        color="primary"
                        size={"sm"}
                        style={{ right: "10px", bottom: "12px" }}
                        className=" position-absolute"
                        type="grow"
                      ></Spinner>
                    ) : (
                      <span
                        className="far fa-close fs-6 position-absolute text-danger pointer"
                        style={{ right: "10px", bottom: "12px" }}
                        onClick={() => {
                          if (search) {
                            setSearch("");

                            setSearchTrigger(!searchTrigger);
                            setFiltersLoader(true);
                          }
                          pageLimit > 50 && setPageLimit(50);
                          pageNumber > 1 && setPageNumber(1);
                        }}
                      ></span>
                    ))}
                </div>
              </div>

              {paginationLoading && (
                <div className="mt-3 mx-3">
                  <Alert color="info">
                    <Spinner
                      color="light"
                      size={"sm"}
                      style={{ marginBottom: "3px" }}
                    ></Spinner>{" "}
                    &nbsp;
                    <span style={{ fontSize: "16px", color: "black" }}>
                      Orders Loading!
                    </span>
                  </Alert>
                </div>
              )}

              {filtersLoader ? (
                <TableSkeleton skeletonLength={10} />
              ) : pendingOrders.length === 0 ? (
                <div
                  className=" d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
                  style={{
                    height: "200px",
                  }}
                >
                  <span>No Order Matched!</span>
                </div>
              ) : (
                <Table
                  fetchData={loadOrders}
                  pageCount={totalPages}
                  setPageLimitInParent={setPageLimit}
                  setPageNumberInParent={setPageNumber}
                  columns={showStoreColumn ? myCols : cols}
                  data={ordersData.sort(sortByDate)}
                  setPaginationLoading={setPaginationLoading}
                  paginationLoading={paginationLoading}
                  totalCount={count}
                />
              )}
            </div>
          </Cols>
        </motion.div>
      )}
    </>
  );
};

export default UnPaidOrders;

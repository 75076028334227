import { setCookie, getCookie, deleteCookie } from "./cookie";
import { setLocalStorage, getLocalStorage } from "./localStorage";

export const setAuthDataInStorage = (token, user) => {
  setCookie("token", token);
  setLocalStorage("user", user);
};

export const isAuthenticated = () => {
  if (getCookie("token") && getLocalStorage("user")) {
    return getLocalStorage("user");
  } else {
    return false;
  }
};

export const logout = (next) => {
  localStorage.clear();
  deleteCookie("token");
  next();
};

export const sessionExpired = () => {
  localStorage.clear();
  deleteCookie("token");
};

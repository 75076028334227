import { getMultiShippers } from "../../api/shipper";

export const toSwyftFormat = async (orders) => {
  const getShippers = async (storeIds) => {
    let assignSwyftId = [];

    try {
      let { data } = await getMultiShippers(storeIds);

      if (data.length > 0) {
        orders.filter((order) => {
          data.filter((obj) => {
            if (order.storeId === obj.storeId) {
              assignSwyftId.push({
                ...order,
                swyftShipperAddressId: obj.shipper_address_id.swyft,
              });
            }
            return obj;
          });
          return order;
        });
      }

      return assignSwyftId;
    } catch (error) {
      console.log("Error found while fetch Shipper : ", error);
    }
  };

  let storeIds = orders?.map((obj) => obj.storeId);
  let formattedOrders = await getShippers(storeIds);

  const csvData = [];

  for (const order of formattedOrders) {
    let description = "";
    order.line_items.forEach(
      (item) =>
        (description = description.concat(`${item.sku}( ${item.quantity}) `))
    );
    // const pieces = order.line_items.reduce((prev, curr) => (prev.quantity + curr.quantity));

    csvData.push({
      ORDER_ID: order.name ? order.name : "N/A",
      ORDER_TYPE: "COD",
      CONSIGNEE_FIRST_NAME: order.shipping_address?.first_name
        ? order.shipping_address.first_name
        : "N/A",
      CONSIGNEE_LAST_NAME: order.shipping_address?.last_name
        ? order.shipping_address.last_name
        : "N/A",
      CONSIGNEE_EMAIL: "N/A",
      CONSIGNEE_PHONE: order.shipping_address?.phone
        ? order.shipping_address.phone
        : "N/A",
      CONSIGNEE_CITY: order.shipping_address?.city
        ? order.shipping_address.city
        : "N/A",
      CONSIGNEE_ADDRESS: order.shipping_address?.address1
        ? order.shipping_address.address1
        : "N/A",
      PACKAGING: order.shipmentType ? order.shipmentType : "N/A",
      PIECES: "1",
      DESCRIPTION: description ? description : "N/A",
      WEIGHT: order.total_weight ? order.total_weight : "N/A",
      COD: order.total_price_set?.shop_money?.amount
        ? order.total_price_set.shop_money.amount
        : "N/A",
      ORIGIN_CITY: order.shipperDetail[0]?.shopDetail?.city
        ? order.shipperDetail[0].shopDetail.city
        : "N/A",
      SHIPPER_ADDRESS_ID: order.swyftShipperAddressId
        ? order.swyftShipperAddressId
        : "N/A",
    });
  }

  return csvData;
};

// ORIGIN_CITY - NA
// SHIPPER_ADDRESS_ID - NA

import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  Card,
  Breadcrumb,
  Button,
  Row,
  Input,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Progress,
} from "reactstrap";
import { ProductDetails } from "./ProductDetails.jsx";
import { Cols } from "../SeparatorStyle/SeparatorStyle";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  getAllStoresLoginUser,
  saveMasterStore,
  getVariantsForSkuMapping,
  updateVariantSKU,
  updateSKUMapping,
} from "../../api";

const PrevSKUMapping = () => {
  const [modal, setModal] = useState(false);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");
  const [masterStore, setMasterStore] = useState("");
  const [masterStoreProducts, setMasterStoreProducts] = useState([]);
  const [otherStores, setOtherStores] = useState([]);
  const [otherStoreProducts, setOtherStoreProducts] = useState({});
  const [mappedData, setMappedData] = useState([]);
  const [selectedSKUs, setSelectedSKUs] = useState({});
  const [SKUValue, setSKUValue] = useState("");
  const [SKUId, setSKUId] = useState("");
  const [newSKU, setNewSKU] = useState("");
  const [productDetails, setProductDetails] = useState();
  const [dropdown, setDropdown] = useState("");
  const completed = "0";
  const progressBar = true;

  let masterStoreName = "";
  let searchCount = 1;
  let storesCount = 1;
  let count = 0;

  const modalToggle = () => {
    setModal(false);
    setMasterStore(selectedStore);
    let master = stores.find((m) => m.shopDetail.name === selectedStore);
    handleSaveMasterStore(master._id, true);
    let others = stores.filter((o) => o.shopDetail.name !== selectedStore);
    setOtherStores(others);
    loadProductsforMasterStore([master._id]);
    for (let i = 0; i < others.length; i++) {
      loadProductsforOtherStore([others[i]._id]);
    }
  };

  const loadMyStores = useCallback(async () => {
    try {
      const { data } = await getAllStoresLoginUser();
      const stores = data[0].stores;
      let masterStore = stores.filter((s) => s.isMasterStore);
      if (masterStore.length > 0) {
        masterStoreName = masterStore[0].shopDetail.name;
        setMasterStore(masterStoreName);
        setModal(false);
        let others = stores.filter((store) => !store.isMasterStore);
        setOtherStores(others);
        loadProductsforMasterStore([masterStore[0]._id]);
        for (let i = 0; i < others.length; i++) {
          loadProductsforOtherStore([others[i]._id]);
        }
      } else {
        setModal(true);
      }
      setStores(stores);
    } catch (err) {
      toast.error("Stores data loading error! ");
    }
  }, []);

  useEffect(() => {
    loadMyStores();
  }, [loadMyStores]);

  const handleSaveMasterStore = async (id, isMaster) => {
    try {
      await saveMasterStore(id, isMaster);
    } catch (err) {
      toast.error("Error in Setting up Master Store!");
    }
  };

  const loadProductsforMasterStore = async (id) => {
    try {
      const { data } = await getVariantsForSkuMapping(id);
      setMasterStoreProducts(data);
    } catch (err) {
      toast.error("Error in Loading Master Store Products!");
    }
  };

  const loadProductsforOtherStore = async (id) => {
    try {
      const { data } = await getVariantsForSkuMapping(id);
      let dropdownValues = data.map((d) => ({
        label: `${d.sku}/${d.productMain[0].title}`,
        value: d,
      }));
      let prevData = otherStoreProducts;
      prevData[id] = dropdownValues;
      setOtherStoreProducts(prevData);
    } catch (err) {
      toast.error("Error in Loading Other Stores Products!");
    }
  };

  const handleUpdateVariantSKU = async (variantId, sku, storeId, _id) => {
    try {
      await updateVariantSKU(variantId, sku, storeId);
      setSKUValue("");
      let list = masterStoreProducts.map((p) => {
        if (p.sku === SKUValue && p._id === _id) {
          p.sku = newSKU;
        }
        return p;
      });
      setMasterStoreProducts(list);
      setNewSKU("");
      setSKUId("");
    } catch (err) {
      toast.error("Error in Loading SKU Updation Result!");
    }
  };

  const handleMasterStore = (e) => {
    setSelectedStore(e.target.value);
  };

  const handleProductsList = (e, storeId, rowId, masterVariantId) => {
    let subKey = `${rowId}/${storeId}`;
    let selectedSKUKeys = Object.keys(selectedSKUs);
    let matchedKey = selectedSKUKeys.find((k) => k.includes(subKey));

    if (!e) {
      let prevSelected = selectedSKUs[matchedKey];
      delete selectedSKUs[matchedKey];
      setSelectedSKUs(selectedSKUs);

      let prodArr = otherStoreProducts[storeId];
      let updatedArray = prodArr.filter((p) => p !== e);
      updatedArray.push(prevSelected);
      otherStoreProducts[storeId] = updatedArray;
      setOtherStoreProducts(otherStoreProducts);
      setDropdown("");

      let mappedFilter = mappedData.filter(
        (f) => f.variantId !== prevSelected.value.shopifyVariantId
      );
      setMappedData(mappedFilter);
    } else {
      let selectedSKUId = e.value._id;
      let key = `${rowId}/${storeId}/${selectedSKUId}`;

      if (Object.keys(selectedSKUs).length > 0 && matchedKey) {
        let prevSelected = selectedSKUs[matchedKey];
        delete selectedSKUs[matchedKey];
        setSelectedSKUs({ ...selectedSKUs, [key]: e });

        let prodArr = otherStoreProducts[storeId];
        let updatedArray = prodArr.filter((p) => p !== e);
        updatedArray.push(prevSelected);
        otherStoreProducts[storeId] = updatedArray;
        setOtherStoreProducts(otherStoreProducts);

        let mappedFilter = mappedData.filter(
          (f) => f.variantId !== prevSelected.value.shopifyVariantId
        );
        setMappedData([
          ...mappedFilter,
          {
            rowId,
            variantId: e.value.shopifyVariantId,
            isMasterVariant: false,
          },
        ]);
      } else {
        let prevData = mappedData;
        if (
          prevData.length === 0 ||
          !prevData.some((md) => md.variantId === masterVariantId)
        ) {
          prevData.push({
            rowId,
            variantId: masterVariantId,
            isMasterVariant: true,
          });
          setMappedData(prevData);
        }

        setMappedData([
          ...mappedData,
          {
            rowId,
            variantId: e.value.shopifyVariantId,
            isMasterVariant: false,
          },
        ]);

        let prodArr = otherStoreProducts[storeId];
        let updatedArray = prodArr.filter((p) => p !== e);
        otherStoreProducts[storeId] = updatedArray;
        setOtherStoreProducts(otherStoreProducts);

        setSelectedSKUs({ ...selectedSKUs, [key]: e });
      }
    }
  };

  const handleSKUMappingUpdate = async (d) => {
    try {
      const data = updateSKUMapping(d);
      if (data.modified === data.totalVariants) {
        toast.success("Data Mapped Successfully!");
        setDropdown("");
        setMappedData([]);
      }
    } catch (err) {
      toast.error("Error in Updation of SKU Mapping!");
    }
  };

  const handleProductDetails = (item) => {
    let orderItems = item.slice(0, 30);
    let itemsLength = item.length;
    return (
      <>
        <div>
          {`${orderItems}${itemsLength > 20 ? "...." : ""}`}
          {itemsLength > 20 ? (
            <i
              style={{ cursor: "pointer" }}
              className="p-2 simple-icon-info "
              id="productDetailPopover"
              onMouseEnter={() => {
                setProductDetails([item]);
              }}
            />
          ) : (
            ""
          )}

          <ProductDetails productDetails={productDetails && productDetails} />
        </div>
      </>
    );
  };

  const handleCancelEditSKU = () => {
    setSKUValue("");
    setSKUId("");
  };

  const handleEditSKU = (value, _id) => {
    setSKUValue(value);
    setSKUId(_id);
  };

  const handleChangeSKUNumber = (variantId, sku, storeId, _id) => {
    if (_id === SKUId) {
      return (
        <>
          <div className="d-flex align-items-center">
            <Input
              bsSize="sm"
              defaultValue={sku}
              className=" ms-auto mt-2 text-center"
              // style={{ float: 'center', textAlign: 'center' }}
              onChange={(e) => setNewSKU(e.target.value)}
            />
            <span
              className="far fa-check mx-2 fs-5 pointer text-success mt-1"
              hidden={newSKU === "" || newSKU === sku}
              onClick={() =>
                handleUpdateVariantSKU(variantId, newSKU, storeId, _id)
              }
            ></span>
            <span
              className="far fa-close mx-1 fs-5 pointer text-danger mt-1"
              onClick={handleCancelEditSKU}
            ></span>
          </div>
        </>
      );
    } else {
      return (
        <>
          {sku}
          <span
            className="simple-icon-pencil ml-3 fs-6 fw-bold"
            onClick={() => handleEditSKU(sku, _id)}
            style={{
              verticalAlign: "-5px",
              fontSize: "18px",
              cursor: "pointer",
            }}
          ></span>
        </>
      );
    }
  };

  return (
    <>
      <main>
        <Row>
          <Cols xxs="12">
            <h1>SKU Mapping</h1>
            <div style={{ display: "inline" }}>
              <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
                <ol className="breadcrumb pt-0">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard" style={{ color: "black" }}>
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Shopilam SKU Mapping
                  </li>
                </ol>
              </Breadcrumb>
              {!modal ? (
                <Button
                  className="mr-2 mt-2"
                  onClick={() => handleSKUMappingUpdate(mappedData)}
                  style={{ float: "right" }}
                  disabled={mappedData.length === 0}
                >
                  Update
                </Button>
              ) : (
                ""
              )}
            </div>
          </Cols>
        </Row>
        <div style={{ height: "12px" }}>
          <Progress
            animated
            color="primary"
            style={{ height: "12px" }}
            striped
            value={completed}
            className="mx-auto"
            hidden={progressBar}
          />
        </div>
        {!modal ? (
          <>
            <Card className="mb-3">
              <Row>
                <Cols>
                  <div className="container-fluid">
                    <Table striped borderless hover>
                      <thead>
                        <tr>
                          <th></th>
                          <th colSpan={"2"}>
                            <Input
                              type="text"
                              className="w-90 mx-auto"
                              name="keyword"
                              id="search"
                              placeholder="Search in Master Store's SKU"
                            />
                          </th>
                          {otherStores.length > 0
                            ? otherStores.map((store) => (
                                <th>
                                  <Input
                                    type="text"
                                    className="w-90 mx-auto"
                                    name="keyword"
                                    id="search"
                                    placeholder={`Search in Store ${(searchCount += 1)}`}
                                  />
                                </th>
                              ))
                            : ""}
                        </tr>
                        <tr>
                          <th></th>
                          <th colSpan={"2"} className="text-center">
                            Master Store's SKU {`(${masterStore})`}
                          </th>
                          {otherStores.length > 0
                            ? otherStores.map((store) => (
                                <th>{`Store ${(storesCount += 1)}`}</th>
                              ))
                            : ""}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>No.</th>
                          <th>SKU Number</th>
                          <th>Product Name</th>
                          {otherStores.length > 0
                            ? otherStores.map((store) => (
                                <th>{store.shopDetail.name}</th>
                              ))
                            : ""}
                        </tr>

                        {masterStoreProducts.length > 0
                          ? masterStoreProducts.map((p) => (
                              <tr>
                                <td>{(count += 1)}</td>
                                <td>
                                  {handleChangeSKUNumber(
                                    p.shopifyVariantId,
                                    p.sku ? p.sku : "N/A",
                                    p.storeId,
                                    p._id
                                  )}
                                </td>
                                <td>
                                  {" "}
                                  {p.productMain[0].title
                                    ? handleProductDetails(
                                        p.productMain[0].title
                                      )
                                    : ""}
                                </td>
                                {otherStores.map((store) => (
                                  <td>
                                    <Row>
                                      <Cols>
                                        <div style={{ display: "inline" }}>
                                          <Select
                                            isDisabled={
                                              `${p._id}/${store._id}` !==
                                              dropdown
                                            }
                                            placeholder="SKU/Product"
                                            options={
                                              otherStoreProducts[store._id]
                                            }
                                            onChange={(e) =>
                                              handleProductsList(
                                                e,
                                                store._id,
                                                p._id,
                                                p.shopifyVariantId
                                              )
                                            }
                                            isClearable
                                          />
                                        </div>
                                      </Cols>
                                      <Cols xs="1" sm="1" md="1" lg="1">
                                        <span
                                          className={
                                            p._id + "/" + store._id !== dropdown
                                              ? "simple-icon-pencil fw-bold pointer mx-2"
                                              : p._id + "/" + store._id ===
                                                dropdown
                                              ? "far fa-check text-success pointer mx-2"
                                              : ""
                                          }
                                          onClick={() => {
                                            if (
                                              dropdown ===
                                              `${p._id}/${store._id}`
                                            )
                                              setDropdown("");
                                            else
                                              setDropdown(
                                                `${p._id}/${store._id}`
                                              );
                                          }}
                                          style={{
                                            marginTop: "10px",
                                            fontSize: "18px",

                                            float: "right",
                                            cursor: "pointer",
                                          }}
                                        ></span>
                                      </Cols>
                                    </Row>
                                  </td>
                                ))}
                              </tr>
                            ))
                          : ""}
                      </tbody>
                    </Table>
                  </div>
                </Cols>
              </Row>
            </Card>
            <div>
              {!modal ? (
                <Button
                  className="mr-2 mt-2"
                  onClick={() => handleSKUMappingUpdate(mappedData)}
                  style={{ float: "right" }}
                  disabled={mappedData.length === 0}
                >
                  Update
                </Button>
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          ""
        )}
        <div>
          <Modal isOpen={modal} centered>
            <ModalHeader className="justify-content-center">
              <h2>
                <b>Please Select your Master Store</b>
              </h2>
            </ModalHeader>
            <ModalBody>
              <div className="text-center">
                <p
                  style={{
                    fontSize: "12px",
                    marginBottom: "-4px",
                    marginTop: "-4px",
                  }}
                >
                  <span
                    className="simple-icon-info text-info mr-1"
                    style={{ verticalAlign: "-2px", fontSize: "16px" }}
                  ></span>
                  This Store will be used to manage your SKU's accross other
                  stores.
                </p>
                <br />
                <select
                  className="form-select w-80 mx-auto mb-2 text-center"
                  aria-label="Default select example"
                  required
                  onChange={(e) => {
                    handleMasterStore(e);
                    setOtherStores(
                      stores.filter(
                        (store) => store.shopDetail.name !== e.target.value
                      )
                    );
                  }}
                  style={{ height: "40px", fontWeight: "bold" }}
                >
                  <option hidden disabled selected value="">
                    Select Master Store
                  </option>
                  {stores &&
                    stores.map((store) => (
                      <option value={store.shopDetail.name}>
                        {store.shopDetail.name}
                      </option>
                    ))}
                </select>
                <br />
                <p>
                  <span
                    className="iconsminds-danger text-danger mr-1"
                    style={{ verticalAlign: "-3px", fontSize: "22px" }}
                  ></span>
                  <b>Choose Wisely! You won't be able to change it later.</b>
                </p>
              </div>
            </ModalBody>
            <ModalFooter className="justify-content-center">
              <Button
                color="primary"
                onClick={modalToggle}
                disabled={selectedStore === ""}
              >
                Submit
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </main>
    </>
  );
};

export default PrevSKUMapping;

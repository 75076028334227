import React, { useState, useEffect, useCallback } from "react";
import { useHistory, NavLink } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CardBody,
  Tooltip,
  Spinner,
} from "reactstrap";
import isEmpty from "validator/lib/isEmpty";
import { saveShopifyStores } from "../../api/store";
import { ErrorMessage, WarningMessage } from "../Messages/message.jsx";
import { getUserAccount } from "../../api/auth";
import { initializeBulkDataJob } from "../../api/orders";
import { skipCouriers } from "../../api/courier.js";
import accessTokenImg from "./img/adminAPI.jpg";
import APIkey from "./img/APIkey.jpg";
import secertKey from "./img/secretKey.jpg";
import { toast } from "react-toastify";

const StoreAccessKey = () => {
  let storeId = "";
  const history = useHistory();

  const [formData, setFormData] = useState({
    accessToken: "",
    domainName: "",
    apiKey: "",
    sharedSecretKey: "",
    errorMsg: false,
    warningMsg: false,
    successMsg: false,
  });
  const [keySpinner, setKeySpinner] = useState(false);
  const [saveNextSpinner, setSaveNextSpinner] = useState(false);
  const [saveNext, setSaveNext] = useState(false);
  const [currentUserData, setCurrentUserData] = useState("");
  const [storeResponse, setStoreResponse] = useState({
    storeId: "",
    successMessage: "",
  });

  const loadUser = useCallback(async () => {
    try {
      const userAccountData = await getUserAccount();
      setCurrentUserData(userAccountData.data.account.stores);
    } catch (error) {
      console.log("Error found when fetch user data", error);
    }
  }, []);

  useEffect(() => {
    if (!currentUserData) {
      loadUser();
    }
  }, [currentUserData, loadUser]);

  const handleSkipCourier = async () => {
    const data = await skipCouriers();
    data && toast.info(data?.message || data);
    data && history.replace("/questionnaire");
  };

  const saveNextHandler = () => {
    setSaveNextSpinner((k) => !k);
    toast.success(storeResponse.successMessage);
    setTimeout(() => {
      history.push(`/shipperInfo/${storeResponse.storeId}`);
    }, 1000);
  };

  const handleChange = (evt) => {
    setFormData({
      ...formData,
      [evt.target.name]: evt.target.value,
      errorMsg: false,
      warningMsg: false,
      successMsg: false,
    });
  };

  const handleURLChange = (evt) => {
    evt.preventDefault();
    let refinedURL;
    let pasted = (evt.clipboardData || window.clipboardData).getData(
      "text/plain"
    );

    if (pasted.includes(".myshopify.com")) {
      let rawURL = pasted;
      let res = rawURL.substring(0, rawURL.indexOf(".myshopify.com") + 14);
      if (res.includes("/")) {
        let filter = res.slice(res.lastIndexOf("/") + 1, res.length);
        refinedURL = filter;
      } else {
        refinedURL = res;
      }
    } else {
      alert("Please use shopify store only.");
    }
    setFormData({ ...formData, domainName: refinedURL });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      isEmpty(domainName) ||
      isEmpty(accessToken) ||
      isEmpty(apiKey) ||
      isEmpty(sharedSecretKey)
    ) {
      setFormData({
        ...formData,
        warningMsg: "All fields are required",
      });
      setSaveNext(false);
    } else {
      setKeySpinner((k) => !k);
      const { domainName, accessToken, apiKey, sharedSecretKey } = formData;
      const data = { domainName, accessToken, apiKey, sharedSecretKey };
      setFormData({
        ...formData,
      });
      saveShopifyStores(data)
        .then(async (response) => {
          setKeySpinner((k) => !k);
          setFormData({
            ...formData,
            successMsg: response.data.successMessage,
            errorMsg: false,
            warningMsg: false,
          });
          setSaveNext(true);
          storeId = response.data.store.id;
          await initializeBulkDataJob(storeId);

          setStoreResponse({
            storeId,
            successMessage: response.data.successMessage,
          });
        })

        .catch((err) => {
          setKeySpinner((k) => !k);
          setFormData({
            ...formData,
            errorMsg: err.response.data.errorMessage,
          });
        });
    }
  };

  const [tool, setTool] = useState({
    shopDomain: false,
    shopKey: false,
    apiKey: false,
    sharedKey: false,
  });

  const {
    domainName,
    accessToken,
    apiKey,
    sharedSecretKey,
    errorMsg,
    warningMsg,
  } = formData;

  return (
    <>
      {!currentUserData ? (
        <div className="loading" />
      ) : currentUserData.length === 0 ? (
        <div
          className="background show-spinner no-footer d-flex justify-content-center align-items-center vh-100"
          style={{ marginBottom: "-90px" }}
        >
          <div className="container_login ">
            <Row className="row h-100">
              <Col
                // xxs="12"
                // md="10"
                className="col-12 mx-auto my-auto d-flex justify-content-center"
              >
                <div>
                  <NavLink
                    to="/"
                    className="white row justify-content-center mb-2"
                  >
                    <span className="logo-single" />
                  </NavLink>
                  <Card className="login-card">
                    <CardBody className="form-side">
                      <CardTitle className="mb-4 align-items-center d-flex justify-content-center">
                        <div className="">
                          <b>Connect Your Shopify Store</b>
                        </div>

                        <div className="pl-3 position-relative">
                          <Tooltip
                            autohide={false}
                            isOpen={tool.addLater}
                            target="addLater"
                            disabled={
                              domainName === "" ||
                              accessToken === "" ||
                              apiKey === "" ||
                              sharedSecretKey === ""
                            }
                            toggle={() =>
                              setTool({ ...tool, addLater: !tool.addLater })
                            }
                          >
                            You can still use various Shopilam features even if
                            you do not have a Shopify account
                          </Tooltip>
                          <button
                            onClick={handleSkipCourier}
                            className="py-1 px-2 pointer btn btn-sm btn-info rounded-pill d-flex"
                          >
                            Add Later
                            <i
                              className="simple-icon-info pointer ms-2 mt-1"
                              id="addLater"
                            ></i>
                          </button>
                        </div>
                      </CardTitle>
                      <br />
                      <Form
                        className="av-tooltip tooltip-label-bottom"
                        onSubmit={handleSubmit}
                        noValidate
                      >
                        {errorMsg !== false ? ErrorMessage(errorMsg) : null}
                        {warningMsg !== false
                          ? WarningMessage(warningMsg)
                          : null}
                        <FormGroup>
                          <Tooltip
                            autohide={false}
                            isOpen={tool.shopDomain}
                            target="shopDomain"
                            toggle={() =>
                              setTool({ ...tool, shopDomain: !tool.shopDomain })
                            }
                          >
                            Grab your store domain name from browser url. It
                            looks something like this{" "}
                            <b>example.myshopify.com</b>
                          </Tooltip>
                          <Label>
                            Shopify Store Domain
                            <i
                              className="simple-icon-info ms-2 pointer"
                              id="shopDomain"
                            ></i>
                          </Label>
                          <Input
                            onPaste={(e) => handleURLChange(e)}
                            className="form-control"
                            type="text"
                            name="domainName"
                            value={domainName}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Tooltip
                            autohide={false}
                            isOpen={tool.shopKey}
                            target="shopKey"
                            toggle={() =>
                              setTool({ ...tool, shopKey: !tool.shopKey })
                            }
                          >
                            Admin API access token in your Shopify store under{" "}
                            <hr />
                            <img
                              src={accessTokenImg}
                              alt=""
                              width={"180"}
                              height="100"
                              className="tooltipImg"
                            />
                          </Tooltip>
                          <Label>
                            Admin API access token
                            <i
                              className="simple-icon-info ms-2 pointer"
                              id="shopKey"
                            ></i>
                          </Label>
                          <Input
                            className="form-control"
                            type="text"
                            name="accessToken"
                            value={accessToken}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Tooltip
                            autohide={false}
                            isOpen={tool.apiKey}
                            target="apiKey"
                            toggle={() =>
                              setTool({ ...tool, apiKey: !tool.apiKey })
                            }
                          >
                            API key under in your Shopify store Apps Development{" "}
                            <hr />
                            <img
                              src={APIkey}
                              alt=""
                              width={"180"}
                              height="100"
                              className="tooltipImg"
                            />
                          </Tooltip>
                          <Label>
                            API Key
                            <i
                              className="simple-icon-info ms-2 pointer"
                              id="apiKey"
                            ></i>
                          </Label>
                          <Input
                            className="form-control"
                            type="text"
                            name="apiKey"
                            value={apiKey}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Tooltip
                            autohide={false}
                            isOpen={tool.sharedKey}
                            target="sharedKey"
                            toggle={() =>
                              setTool({ ...tool, sharedKey: !tool.sharedKey })
                            }
                          >
                            API secret key in your Shopify store under <hr />
                            <img
                              src={secertKey}
                              alt=""
                              width={"180"}
                              height="100"
                              className="tooltipImg"
                            />
                          </Tooltip>
                          <Label>
                            API Secret Key
                            <i
                              className="simple-icon-info ms-2 pointer"
                              id="sharedKey"
                            ></i>
                          </Label>
                          <Input
                            className="form-control"
                            type="text"
                            name="sharedSecretKey"
                            value={sharedSecretKey}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <footer className="d-flex justify-content-center">
                          {!saveNext && (
                            <Button
                              outline
                              color="primary"
                              size="lg"
                              disabled={
                                domainName === "" ||
                                accessToken === "" ||
                                apiKey === "" ||
                                sharedSecretKey === ""
                              }
                              type="submit"
                              className={"mt-3"}
                              style={
                                keySpinner ? { padding: "10px 90px" } : null
                              }
                            >
                              {keySpinner ? (
                                <Spinner size={"sm"}>Loading...</Spinner>
                              ) : (
                                "Verify Connection"
                              )}
                            </Button>
                          )}
                          {saveNext && (
                            <div>
                              <span className="text-success d-flex justify-content-center">
                                Credentials Verified!
                              </span>
                              <Button
                                outline
                                color="primary"
                                type="button"
                                className="mt-3"
                                size={"lg"}
                                style={
                                  saveNextSpinner
                                    ? { padding: "10px 69px" }
                                    : null
                                }
                                onClick={saveNextHandler}
                              >
                                {saveNextSpinner ? (
                                  <Spinner size={"sm"}>Loading...</Spinner>
                                ) : (
                                  "Save & Next"
                                )}
                              </Button>
                            </div>
                          )}
                        </footer>
                      </Form>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        history.push(`/shipperInfo/${storeId}`)
      )}
    </>
  );
};

export default StoreAccessKey;

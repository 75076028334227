import React, { useState, useEffect, useCallback } from "react";
import { Cols } from "../../SeparatorStyle/SeparatorStyle";
import DataTable from "../../tables/table.jsx";
import {
  Row,
  Button,
  Alert,
  Spinner,
  Input,
  PopoverBody,
  UncontrolledPopover,
} from "reactstrap";
import { getUserPreferences } from "../../../api/courier";
import {
  getOrderByDispatchStatus,
  updateOrderDispatchStatus,
  addTrackingNumbersFromFile,
} from "../../../api/orders";
import { sortByDate, DateFormatted } from "../../../helpers/dateTime";
// import {
//   ConsigneeDetails,
//   NotePopup,
//   OrdersDetailsPopup,
// } from "../../Modals/Popups.jsx";
import { formatPhoneNumber } from "../../../helpers/phoneNumber.jsx";
import CourierFileUpload from "../../Couriers/CourierFileUpload.jsx";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddNewModal from "../../Modals/SideBarModel.jsx";
import DateFilters from "../../Date/DateFilters.jsx";
// import { showItemDetails } from "../orderTabs/helperFunctions/tableDetailsInfo.jsx";
import { useHistory } from "react-router-dom";
import { getCookie } from "../../../helpers/cookie";
import { getAllStoresLoginUser } from "../../../api";
import { handleSearch } from "../../search/searchHook";
// import { rightAnimate } from "../../FramerMotion/FramerMotion";
import { popOverContent, popOverList } from "../../../popOvers/popOver";
import TableSkeleton from "../../UI/TableSkeleton";
const UnAssignedOrders = ({
  setOrderExported,
  orderExported,
  // setSkeletonTab,
}) => {
  const [search, setSearch] = useState("");
  const [citySearch, setCitySearch] = useState("");
  const [selectedOrdersInBulk, setSelectedOrdersInBulk] = useState([]);
  const [isAllOrdersChecked, setIsAllOrdersChecked] = useState(false);
  const [courierAssignedOrders, setCourierAssignedOrders] = useState([]);
  const [checkboxes, setCheckboxes] = useState({});
  const [couriers, setCouriers] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);
  // const [orderDetailsData, setOrderDetailsData] = useState();
  // const [noteDetailsData, setNoteDetailsData] = useState("");
  // const [dateTime, setDateTime] = useState();
  const [showModal, setShowModal] = useState(false);
  const [fileImportProcessing, setFileImportProcessing] = useState(false);
  const [selectedCourierForFileImport, setSelectedCourierForFileImport] =
    useState("");
  const [selectedFileForImport, setSelectedFileForImport] = useState(null);
  const [uploadedFileData, setUploadedFileData] = useState(null);
  const [count, setCount] = useState(0);
  // const [consigneeDetails, setConsigneeDetails] = useState();
  // const [showResetButton, setShowResetButton] = useState(false);
  const [pageLimit, setPageLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  // const [itemDetailsData, setItemDetailsData] = useState();
  const history = useHistory();
  const [showStoreColumn, setShowStoreColumn] = useState(false);
  const [emptyStatus, setEmptyStatus] = useState(false);
  const [filtersLoader, setFiltersLoader] = useState(false);
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [searchCityTrigger, setSearchCityTrigger] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const loadCouriers = async () => {
    try {
      const { data } = await getUserPreferences();
      setCouriers(data.couriers);
    } catch (error) {
      console.log("error found when fetch order data", error);
    }
  };

  const loadUnAssignedOrders = useCallback(
    async ({
      page = pageNumber,
      limit = pageLimit,
      startDateIs = startDate,
      endDateIs = endDate,
      title = search.trim().length > 2 ? search : "",
      searchByCity = citySearch.trim().length > 2 ? citySearch : "",
      filterByCity = "",
    }) => {
      try {
        setEmptyStatus(false);
        const { data } = await getOrderByDispatchStatus({
          dispatchStatus: "unAssigned",
          isAssigned: true,
          title,
          page,
          limit,
          startDateIs,
          endDateIs,
          searchByCity,
          filterByCity,
        });
        if (data) {
          setCourierAssignedOrders(data.orders);
          setFiltersLoader(false);
          setLoading(false);
          // setSkeletonTab(false);
          setPaginationLoading(false);
          setCheckboxes({});
          setSelectedOrdersInBulk([]);
          setCount(data.count);
        }
        if (data.count === 0) {
          setEmptyStatus(true);
        } else {
          setEmptyStatus(false);
        }
        return data.orders;
      } catch (error) {
        console.log("error found when fetch order data", error);
      }
    },
    [pageNumber, pageLimit, endDate, searchTrigger, searchCityTrigger]
  );
  const totalPages = Math.ceil(count / pageLimit);

  const loadStores = useCallback(async () => {
    try {
      const { data } = await getAllStoresLoginUser();
      const { stores } = data[0];
      stores.length > 1 && setShowStoreColumn(true);
    } catch (error) {
      toast.error("Error found when fetch stores data!");
      console.log("Error found when fetch stores data!", error);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    // setSkeletonTab(true);
    loadCouriers();
    loadStores();
  }, []);

  useEffect(() => {
    let token = getCookie();
    if (token) {
      loadUnAssignedOrders({});
    } else {
      history.push("/signIn");
      toast.error("Your session expired");
    }
    // if (couriers.length <= 0) {
    // }
    if (orderExported && courierAssignedOrders.length > 0) {
      setOrderExported(false);
    }
  }, [loadUnAssignedOrders]);

  // const noteDetails = (note) => {
  //   let noteText = note.slice(0, 20);
  //   let noteLenght = note.length;
  //   return (
  //     <div>
  //       {`${noteText}${noteLenght > 20 ? "..." : ""}`}

  //       {noteLenght > 20 ? (
  //         <i
  //           style={{ cursor: "pointer" }}
  //           className="p-2 simple-icon-info "
  //           id="noteDetailPopover"
  //           onMouseEnter={() => {
  //             setNoteDetailsData(note);
  //           }}
  //         />
  //       ) : (
  //         ""
  //       )}
  //       <NotePopup noteData={noteDetailsData && noteDetailsData} />
  //     </div>
  //   );
  // };

  const onCheckItem = (event, orderId) => {
    setCheckboxes({ ...checkboxes, [orderId]: event.target.checked });
    if (event.target.checked) {
      setSelectedOrdersInBulk([
        ...selectedOrdersInBulk,
        courierAssignedOrders.find((order) => order.orderId === orderId),
      ]);
    } else {
      if (selectedOrdersInBulk.length > 0) {
        const removeUnCheckedOrder = selectedOrdersInBulk.filter(
          (order) => order.orderId !== orderId
        );
        setSelectedOrdersInBulk(removeUnCheckedOrder);
      }
    }
  };

  const resetFilters = () => {
    setFiltersLoader(true);
    setStartDate(null);
    setEndDate(null);
  };

  const onCheckAllOrders = (e, ordersToMark) => {
    setIsAllOrdersChecked(e.target.checked);
    const orderIdsToMark = {};
    const ordersCompleteData = [];
    ordersToMark.forEach((order) => {
      orderIdsToMark[order.id] = e.target.checked;
      const matchedOrderInAllOrders = courierAssignedOrders.find(
        (completeOrder) => completeOrder.orderId === order.id
      );
      ordersCompleteData.push({ ...matchedOrderInAllOrders });
    });

    if (e.target.checked) {
      setCheckboxes(orderIdsToMark);
      setSelectedOrdersInBulk(ordersCompleteData);
    } else {
      if (selectedOrdersInBulk.length > 0) {
        setCheckboxes({});
        setSelectedOrdersInBulk([]);
      }
    }
  };

  const handleFileImport = async (e) => {
    setFileImportProcessing(true);
    if (!selectedCourierForFileImport) {
      toast.info("Select a courier before submitting file");
      setFileImportProcessing(false);
      return;
    }
    if (!selectedFileForImport) {
      toast.info("No file selected to submit");
      setFileImportProcessing(false);
      return;
    }

    const columnsOfCouriers = {
      swyft: { refNumber: "", trackingNumber: "" },
      "m&p": { refNumber: "", trackingNumber: "" },
      postex: { refNumber: "", trackingNumber: "" },
      tpl: { refNumber: "", trackingNumber: "" },
      leopard: { refNumber: "Order Ref", trackingNumber: "Tracking #" },
    };
    const refNumberCol =
      columnsOfCouriers[selectedCourierForFileImport.toLowerCase()].refNumber;
    const trackingNumberCol =
      columnsOfCouriers[selectedCourierForFileImport.toLowerCase()]
        .trackingNumber;

    if (!refNumberCol && !trackingNumberCol) {
      toast.info("Selected courier company not supported");
      setFileImportProcessing(false);
      return;
    }
    if (!uploadedFileData || uploadedFileData.length === 0) {
      toast.warning("Selected file empty");
      setFileImportProcessing(false);
      return;
    }

    let ordersRefAndTracking = [];
    let unMatchedOrders = [];
    uploadedFileData.forEach((data) => {
      if (data[refNumberCol] && data[trackingNumberCol]) {
        // const refAndTracking = {
        //   refNumber: data[refNumberCol],
        //   trackingNumber: data[trackingNumberCol],
        // };
      }
    });

    if (unMatchedOrders.length > 0) {
      unMatchedOrders.forEach((unMatchedOrder) =>
        toast.warning(
          `${
            unMatchedOrder.refNumber.split("-")[0]
          } do not exist in un-assigned orders yet`
        )
      );

      setFileImportProcessing(false);
    }

    if (ordersRefAndTracking.length === 0 && unMatchedOrders.length === 0) {
      toast.info("Invalid file columns");
      setFileImportProcessing(false);
      return;
    }
    try {
      const AssignedOrders = [];
      const successOrders = [];
      const { status } = await addTrackingNumbersFromFile({
        orders: ordersRefAndTracking,
        companyName: selectedCourierForFileImport,
      });
      if (status === 201) {
        const assignedOrders = courierAssignedOrders.filter((assignedOrder) => {
          return ordersRefAndTracking.some((order) => {
            return order.refNumber === assignedOrder.orderRefNumber;
          });
        });

        for (const order of assignedOrders) {
          let orderId = order.orderId;
          let storeId = order.storeId;
          const orderDispatchStatusUpdateResult =
            await updateOrderDispatchStatus({
              orderId,
              dispatchStatus: "assigned",
              storeId,
            });
          if (orderDispatchStatusUpdateResult.status === 200) {
            AssignedOrders.push(order.orderId);
            successOrders.push(`${order.name} ${order.storeName}`);
          }
        }
        successOrders.length > 0 &&
          toast.success(
            `Successfully add tracking for  ${String(successOrders)}`
          );
        const remainingAssignedOrders = courierAssignedOrders.filter(
          (assignedOrder) => !AssignedOrders.includes(assignedOrder.orderId)
        );
        setCourierAssignedOrders(remainingAssignedOrders);
        setFileImportProcessing(false);
        setSelectedCourierForFileImport("");
        setShowModal(false);
      }
    } catch (e) {
      toast.error(e);
      setFileImportProcessing(false);
      setSelectedCourierForFileImport("");
      setShowModal(false);
    }
  };

  // const consigneeDetail = (item, i) => {
  //   let orderItems = item.slice(0, 20);
  //   let itemsLength = item.length;
  //   let hoverIndex;
  //   return (
  //     <div>
  //       {orderItems}
  //       {itemsLength > 20 && (
  //         <>
  //           {" ... "}
  //           <i
  //             className="simple-icon-info pointer"
  //             id={"consigneeDetailPopover" + hoverIndex}
  //             onMouseEnter={() => {
  //               hoverIndex = i;
  //               setConsigneeDetails([item]);
  //             }}
  //             onMouseLeave={() => {
  //               hoverIndex = undefined;
  //               setConsigneeDetails();
  //             }}
  //           />
  //         </>
  //       )}
  //       {consigneeDetails && (
  //         <ConsigneeDetails
  //           consigneeDetails={consigneeDetails}
  //           index={hoverIndex}
  //         />
  //       )}
  //     </div>
  //   );
  // };

  // const orderDetails = (items) => {
  //   let orderItems = items[0].title.slice(0, 12);
  //   let itemsLength = items[0].title.length;
  //   return (
  //     <>
  //       <div>
  //         {`${orderItems}${itemsLength > 12 ? "...." : ""}`}
  //         {itemsLength > 12 ? (
  //           <i
  //             style={{ cursor: "pointer" }}
  //             className="p-2 simple-icon-info "
  //             id="orderDetailPopover"
  //             onMouseEnter={() => {
  //               setOrderDetailsData(items);
  //             }}
  //           />
  //         ) : (
  //           ""
  //         )}
  //         <OrdersDetailsPopup
  //           orderDetailsData={orderDetailsData && orderDetailsData}
  //         />
  //       </div>
  //     </>
  //   );
  // };

  const ordersData =
    courierAssignedOrders &&
    courierAssignedOrders.length > 0 &&
    courierAssignedOrders.map((order, i) => {
      return {
        id: order.orderId,
        orderId: order.name,
        name: popOverContent(`Name-${i}`, order?.shipping_address?.name),
        orderDate: DateFormatted(order.created_at, i),
        address: popOverContent(
          `Adress-${i}`,
          `${order?.shipping_address?.address1 || ""} ${
            order?.shipping_address?.address2 || ""
          }`
        ),
        phone: popOverContent(
          `Phone-${i}`,
          formatPhoneNumber(order?.shipping_address?.phone)
        ),
        note: popOverContent(`Note-${i}`, order.note),
        weight: order.total_weight,
        amount: popOverContent(
          `Total-${i}`,
          Number(order?.total_price_set?.shop_money?.amount).toLocaleString()
        ),

        city: popOverContent(`City-${i}`, order?.shipping_address?.city),
        items: (
          <>
            {order?.line_items.length}
            {"  "}
            {order?.line_items.length > 0
              ? popOverList(
                  `lineItem-${i}`,
                  order.line_items.map((o) => `${o.title} (${o.quantity})`)
                )
              : "N/A"}
          </>
        ),
        storeName: popOverContent(
          `Store-${i}`,
          order.shipperDetail[0]?.shopDetail.name.replace(`${order?.name}-`, "")
        ),
        courier: order.courier,
      };
    });

  const cols = React.useMemo(
    () => [
      {
        Header: "Order Id",
        accessor: "orderId",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Date",
        accessor: "orderDate",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Customer",
        accessor: "name",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Address",
        accessor: "address",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Phone",
        accessor: "phone",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Note",
        accessor: "note",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Total",
        accessor: "amount",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },

      {
        Header: "Items",
        accessor: "items",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Destination City",
        accessor: "city",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Courier",
        accessor: "courier",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
    ],
    []
  );

  const myCols = React.useMemo(
    () => [
      {
        Header: "Order Id",
        accessor: "orderId",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Date",
        accessor: "orderDate",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Customer",
        accessor: "name",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Address",
        accessor: "address",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Phone",
        accessor: "phone",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Note",
        accessor: "note",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Total",
        accessor: "amount",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },

      {
        Header: "Items",
        accessor: "items",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Destination City",
        accessor: "city",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Store",
        accessor: "storeName",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Courier",
        accessor: "courier",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
    ],
    []
  );

  return (
    <>
      {loading ? (
        <TableSkeleton skeletonLength={10} itemsBar={3} />
      ) : emptyStatus &&
        !search.trim() &&
        !citySearch.trim() &&
        !startDate &&
        !endDate ? (
        <div
          className=" d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
          style={{
            height: "200px",
          }}
        >
          <span>No Order Found!</span>
        </div>
      ) : (
        <Row>
          <Cols xxs="12">
            <div
              className="mb-4 px-2"
              // variants={rightAnimate}
              // initial="hidden"
              // animate="visible"
            >
              <div className={{ padding: "0px" }}>
                <div className="card-title mb-0">
                  <div className="d-flex justify-content-between flex-wrap mt-4 ">
                    <div className="mb-2 me-1 position-relative">
                      <Input
                        type="text"
                        name="keyword"
                        id="search"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => {
                          handleSearch({
                            e,
                            setPageNumber,
                            setPageLimit,
                            setFilterLoading: setFiltersLoader,
                            setSearch,
                            setSearchTrigger,
                            searchTrigger,
                          });
                        }}
                        className="rounded-3"
                        disabled={
                          paginationLoading ||
                          filtersLoader ||
                          selectedOrdersInBulk.length > 0
                        }
                      />
                      {search.trim() && search.trim().length < 3 && (
                        <i
                          className="simple-icon-info pointer position-absolute text-danger fw-bold"
                          style={{ right: "-20px", bottom: "14px" }}
                          id={"SearchInfo"}
                        >
                          {" "}
                          <UncontrolledPopover
                            trigger="hover"
                            placement="top"
                            target={"SearchInfo"}
                          >
                            <PopoverBody className="text-center">
                              <i>Minimum 3 words required!</i>
                            </PopoverBody>
                          </UncontrolledPopover>
                        </i>
                      )}
                      {search &&
                        (filtersLoader && search.trim().length > 2 ? (
                          <Spinner
                            color="primary"
                            size={"sm"}
                            style={{ right: "10px", bottom: "14px" }}
                            className=" position-absolute"
                            type="grow"
                          ></Spinner>
                        ) : (
                          <span
                            className="far fa-close fs-6 position-absolute text-danger pointer"
                            style={{ right: "10px", bottom: "14px" }}
                            onClick={() => {
                              if (search) {
                                setSearch("");

                                setSearchTrigger(!searchTrigger);
                                setFiltersLoader(true);
                              }
                              pageLimit > 50 && setPageLimit(50);
                              pageNumber > 1 && setPageNumber(1);
                            }}
                          ></span>
                        ))}
                    </div>
                    <div>
                      <DateFilters
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        selectedDateFilter={selectedDateFilter}
                        setSelectedDateFilter={setSelectedDateFilter}
                        // setShowResetButton={setShowResetButton}
                        loadOrders={loadUnAssignedOrders}
                        resetFilters={resetFilters}
                        setFiltersLoader={setFiltersLoader}
                        paginationLoading={paginationLoading}
                        filtersLoader={filtersLoader}
                        allOrdersFlag={"allOrdersFlag"}
                        selectedOrdersInBulk={selectedOrdersInBulk}
                      />
                    </div>
                    <div className=" d-flex flex-wrap alignTop">
                      <div className="mb-2 mr-2 position-relative confirm-city-search">
                        <Input
                          type="text"
                          name="keyword"
                          id="search"
                          placeholder="Search Cities"
                          value={citySearch}
                          onChange={(e) => {
                            setEmptyStatus(false);
                            handleSearch({
                              e,
                              setPageNumber,
                              setPageLimit,
                              setFilterLoading: setFiltersLoader,
                              setSearch: setCitySearch,
                              setSearchTrigger: setSearchCityTrigger,
                              searchTrigger: searchCityTrigger,
                            });
                          }}
                          className="rounded-3"
                          disabled={
                            paginationLoading ||
                            filtersLoader ||
                            selectedOrdersInBulk.length > 0
                          }
                        />
                        {citySearch &&
                          (filtersLoader && citySearch.trim().length > 2 ? (
                            <Spinner
                              color="primary"
                              size={"sm"}
                              style={{ right: "10px", bottom: "14px" }}
                              className=" position-absolute"
                              type="grow"
                            ></Spinner>
                          ) : (
                            <span
                              className="far fa-close fs-6 position-absolute text-danger pointer"
                              style={{ right: "10px", bottom: "14px" }}
                              onClick={() => {
                                if (citySearch) {
                                  setCitySearch("");

                                  setSearchCityTrigger(!searchCityTrigger);
                                  setFiltersLoader(true);
                                }
                                pageLimit > 50 && setPageLimit(50);
                                pageNumber > 1 && setPageNumber(1);
                              }}
                            ></span>
                          ))}
                      </div>
                      {citySearch.trim() && citySearch.trim().length < 3 && (
                        <div className="d-flex align-items-center pb-2">
                          <i
                            className="simple-icon-info pointer text-danger fw-bold mr-2"
                            // style={{ left: '-20px', bottom: '14px' }}
                            id={"CitySearchInfo"}
                          >
                            {" "}
                            <UncontrolledPopover
                              trigger="hover"
                              placement="top"
                              target={"CitySearchInfo"}
                            >
                              <PopoverBody className="text-center">
                                <i>Minimum 3 words required!</i>
                              </PopoverBody>
                            </UncontrolledPopover>
                          </i>
                        </div>
                      )}
                      <Button
                        className="btn btn-secondary custom-btn"
                        style={{ height: "35px" }}
                        onClick={toggleModal}
                      >
                        Import Courier Files
                      </Button>
                    </div>
                  </div>
                </div>

                {paginationLoading && (
                  <div className="mt-3 mx-3">
                    <Alert color="info">
                      <Spinner
                        color="light"
                        size={"sm"}
                        style={{ marginBottom: "3px" }}
                      ></Spinner>{" "}
                      &nbsp;
                      <span style={{ fontSize: "16px", color: "black" }}>
                        Orders Loading!
                      </span>
                    </Alert>
                  </div>
                )}

                {filtersLoader ? (
                  <TableSkeleton skeletonLength={10} />
                ) : courierAssignedOrders?.length === 0 ? (
                  <div
                    className=" d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
                    style={{
                      height: "200px",
                    }}
                  >
                    <span>No Order Matched!</span>
                  </div>
                ) : (
                  <DataTable
                    setPageLimitInParent={setPageLimit}
                    setPageNumberInParent={setPageNumber}
                    fetchData={loadUnAssignedOrders}
                    pageCount={totalPages}
                    columns={showStoreColumn ? myCols : cols}
                    data={ordersData.sort(sortByDate)}
                    setPaginationLoading={setPaginationLoading}
                    paginationLoading={paginationLoading}
                    onCheckItem={onCheckItem}
                    checkedOrders={checkboxes}
                    isAllOrdersChecked={isAllOrdersChecked}
                    onCheckAllOrders={onCheckAllOrders}
                    totalCount={count}
                  />
                )}
              </div>
            </div>
          </Cols>
        </Row>
      )}

      <AddNewModal
        modalOpen={showModal}
        toggleModal={toggleModal}
        body={
          <CourierFileUpload
            couriers={couriers.filter((courier) => courier?.label)}
            setSelectedCourierForFileImport={setSelectedCourierForFileImport}
            selectedCourierForFileImport={selectedCourierForFileImport}
            setSelectedFileForImport={setSelectedFileForImport}
            setUploadedFileData={setUploadedFileData}
            includeFooter={false}
            primaryBtnText="Submit"
            secondaryBtnText="Cancel"
            primaryBtnWithSpinner={true}
            isProcessing={fileImportProcessing}
            primaryBtnHandler={handleFileImport}
            secondaryBtnHandler={toggleModal}
          />
        }
        includeFooter={true}
        primaryBtnText="Submit"
        secondaryBtnText="Cancel"
        primaryBtnWithSpinner={true}
        isProcessing={fileImportProcessing}
        primaryBtnHandler={handleFileImport}
        secondaryBtnHandler={toggleModal}
      />
    </>
  );
};

export default UnAssignedOrders;

import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CardBody,
  Spinner,
} from "reactstrap";
import { defaultCourierCityFun, getUserPreferences } from "../../api/courier";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ErrorMessage } from "../Messages/message";
import { motion } from "framer-motion";

import { Cols } from "../SeparatorStyle/SeparatorStyle";

const CourierSelectionList = () => {
  let history = useHistory();
  const [checkboxIndex, setCheckboxIndex] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [courierSelectionSpinner, setCourierSelectionSpinner] = useState(false);
  const [selectedDefaultCourier, setSelectedDefaultCourier] = useState([]);
  const [selectedDefaultCourierCities, setSelectedDefaultCourierCities] =
    useState({});
  const [couriers, setCouriers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultLabel, setDefaultLabel] = useState();

  const handleChange = (e, index) => {};

  const defaultSelectedCourier = async () => {
    const { data } = await getUserPreferences();
    setLoading(false);
    setSelectedOptions(data?.defaultCitiesCourier);
    setCouriers(data.couriers);
    for (let value of Object.values(data.defaultCitiesCourier)) {
      setSelectedDefaultCourierCities(value);
    }
    for (let value of Object.keys(data.defaultCitiesCourier)) {
      setDefaultLabel(value);
    }
    setSelectedDefaultCourier(Object.keys(data.defaultCitiesCourier));
    setCheckboxIndex(true);
  };

  useEffect(() => {
    setLoading(true);
    defaultSelectedCourier();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const checkboxHandler = (e, data) => {
    if (selectedDefaultCourier.includes(data.label)) {
      setSelectedOptions("");
      setSelectedDefaultCourier([""]);
    } else {
      setSelectedOptions(data);
      setSelectedDefaultCourier([data.label]);
    }
    setCheckboxIndex(e.target.checked);
  };

  const SubmitCourier = async (e) => {
    e.preventDefault();
    setCourierSelectionSpinner(true);
    setDefaultLabel("");
    try {
      if (selectedOptions) {
        await defaultCourierCityFun({
          [selectedDefaultCourier]: selectedDefaultCourierCities,
        });
        defaultSelectedCourier();
        setCourierSelectionSpinner(false);
        toast.success("Default courier selected successfully!");
        setErrorMessage("");
      } else {
        setErrorMessage("Please select one nation/default courier!");
        setCourierSelectionSpinner(false);
        handleScrollToTop();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <main>
      <motion.div
      //  variants={rightAnimate} initial="hidden" animate="visible"
      >
        {errorMessage && (
          <div className="d-flex justify-content-center">
            {ErrorMessage(errorMessage)}
          </div>
        )}
        {loading ? (
          <>
            <div className="d-flex justify-content-center mb-4">
              <p className="skeleton skeleton-text w-10 "></p>
            </div>
            {new Array(3).fill(0).map((item, i) => {
              return (
                <Row className="justify-content-center" key={i + 1}>
                  <Cols sm="8" xxs="9" md="8" lg="8" xl="5">
                    <Card className="mb-4">
                      <CardBody>
                        <div className="d-flex justify-content-center">
                          <p className="skeleton skeleton-text w-30"></p>
                        </div>
                        <p className="skeleton skeleton-text mt-3"></p>
                        <p className="skeleton skeleton-text mt-3"></p>
                        <div className="d-flex justify-content-center">
                          <p className="skeleton skeleton-button mt-2"></p>
                        </div>
                      </CardBody>
                    </Card>
                  </Cols>
                </Row>
              );
            })}
            <div className="d-flex justify-content-center">
              <div className="skeleton skeleton-button me-2 my-4"></div>
              <div className="skeleton skeleton-button me-2 my-4"></div>
            </div>
          </>
        ) : (
          <>
            <div className="text-center mb-4">
              <h1>
                <b> Courier Keys</b>
              </h1>
            </div>
            {couriers?.map((data, index) => (
              <Row className="justify-content-center" key={`${data.label}-row`}>
                <Cols sm="8" xxs="9" md="8" lg="8" xl="5">
                  <Card className="courier-selection-list mb-4 mx-auto ">
                    <CardBody>
                      <CardTitle className="mb-4 text-align-center">
                        <span className="bold">{data.label} Courier Keys</span>
                      </CardTitle>

                      <Form
                        className="av-tooltip tooltip-label-bottom"
                        noValidate
                      >
                        <FormGroup>
                          <Label>API Key</Label>
                          <Input
                            className="form-control"
                            type="text"
                            name="apiKey"
                            onChange={(e) => handleChange(e, index)}
                          />
                        </FormGroup>
                        {data.label === "Swyft" ||
                        data.label === "M&P" ||
                        data.label === "TPL" ? (
                          <FormGroup>
                            <Label>Secret Key</Label>
                            <Input
                              className="form-control"
                              type="text"
                              name="SecretKey"
                              onChange={(e) => handleChange(e, index)}
                            />
                          </FormGroup>
                        ) : (
                          data.label === "Leopard" && (
                            <FormGroup>
                              <Label>Password</Label>
                              <Input
                                className="form-control"
                                type="password"
                                name="password"
                                onChange={(e) => handleChange(e, index)}
                              />
                            </FormGroup>
                          )
                        )}
                        <div className="mb-3">
                          <Input
                            type="checkbox"
                            className="select-keys"
                            disabled={selectedDefaultCourier?.includes(
                              data.label
                            )}
                            checked={
                              selectedDefaultCourier?.includes(data.label) &&
                              checkboxIndex
                            }
                            onChange={(e) => {
                              checkboxHandler(e, data);
                            }}
                          />
                          <span className="ms-3">
                            This is my NationWide Courier
                          </span>
                        </div>
                        <div className="d-flex justify-content-center">
                          <Button
                            type="button"
                            outline
                            color="primary"
                            size="lg"
                            className="py-2"
                            disabled
                            onClick={handleSubmit}
                          >
                            Save
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Cols>
              </Row>
            ))}
            <footer className="d-flex justify-content-center">
              <Button
                size="sm"
                className="btn btn-secondary py-2 my-4 me-2"
                onClick={() => history.push("/admin/myCouriers")}
              >
                Go Back
              </Button>
              <Button
                type="button"
                outline
                color="primary"
                size="lg"
                disabled={
                  courierSelectionSpinner ||
                  selectedDefaultCourier.includes(defaultLabel)
                }
                className="py-2 my-4"
                style={
                  courierSelectionSpinner ? { padding: "10px 69px" } : null
                }
                onClick={SubmitCourier}
              >
                {courierSelectionSpinner ? (
                  <Spinner size={"sm"}>Loading...</Spinner>
                ) : (
                  "Update"
                )}
              </Button>
            </footer>
          </>
        )}
      </motion.div>
    </main>
  );
};

export default CourierSelectionList;

import axios from "axios";
import { getAPIURL } from "./utils";
import { getCookie } from "../helpers/cookie";

const url = getAPIURL();

// export const store = (storeData) => {
//   const config = {
//     headers: {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//     },
//   };
//   return axios.post(`${url}/api/stores`, storeData, config);
// };

// export const verifiShopifyStore = (keys) => {
//   const token = getCookie();
//   const config = {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`,
//     },
//   };

//   return axios.post(`${url}/api/stores/checkData`, keys, config);
// };

export const saveShopifyStores = (storeData) => {
  const token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return axios.post(`${url}/api/stores/saveStore`, storeData, config);
};

export const deleteShopifyStores = (storeId) => {
  const token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.post(`${url}/api/stores/deleteStore`, { storeId }, config);
};

export const getAllStoresLoginUser = async () => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${url}/api/stores/getAllStore`, config);

  return response;
};

// Save Master Store
export const saveMasterStore = async (storeId, status) => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${url}/api/stores/updateMasterStore`,
    { storeId, isMasterStore: status },
    config
  );

  return response;
};

const visible = {
  x: 0,
  opacity: 1,
  transition: { duration: 1 },
};
const hidden = { x: 200, opacity: 0 };

export const leftAnimate = {
  hidden: { opacity: 0, x: -300 },
  visible: visible,
};
export const rightAnimate = {
  hidden,
  visible,
};

export const animationTop = {
  hidden: { y: -300, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { duration: 1 },
  },
};

export const footerAnimation = {
  hidden: { y: 300, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      // delay: 0.8,
      duration: 1,
    },
  },
};
export const signInFooterAnimation = {
  hidden: { y: 300, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      // delay: 1,
      duration: 1,
    },
  },
};

export const animationTopContainer = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.8,
      // staggerChildren: 0.2,
    },
  },
};

import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { getInvoiceById } from "../api/invoice";
import { getCurrentBillingData } from "../api/billing";
import { useParams } from "react-router-dom";
import { Badge } from "reactstrap";

const Invoice = () => {
  const { id } = useParams();
  const [bills, setBills] = useState([]);
  const [invoice, setInvoice] = useState([]);

  const totalFulfillment = bills
    .map((bill) => bill.amount)
    .reduce((prev, curr) => prev + curr, 0);

  //Load Invoices & store data
  const loadInvoice = useCallback(async () => {
    try {
      const { data } = await getInvoiceById(id);
      setInvoice(data);
      const billData = await getCurrentBillingData();
      setBills(billData.data);
    } catch (error) {
      console.log("error found when fetch order data", error);
    }
  }, [id]);

  useEffect(() => {
    loadInvoice();
  }, [loadInvoice]);
  return (
    <>
      <div className="mains">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="">
              <div className="card login-card shadow">
                <div className="card-body">
                  <div className="hr">
                    <h5 className="card-title">
                      <b style={{ fontSize: "18px" }}>Shopilam Invoice</b>
                    </h5>
                    <p>
                      You've been charged <b>${invoice.totalAmount}</b> for your
                      bill.
                      {/* </b> will appear on your
                                            credit card statement as <b>Shopilam *117006156</b> */}
                      <span className="ml-2">
                        <Badge pill color="warning" className=" mb-2">
                          {invoice.status}
                        </Badge>
                      </span>
                    </p>
                  </div>

                  <div className="list-box hr">
                    <h6>
                      <b>Charges</b>
                    </h6>
                    <ul className="d-flex justify-content-between">
                      <li>Fulfillment Total</li>
                      <li>${parseFloat(totalFulfillment).toFixed("3")}</li>
                    </ul>
                  </div>
                  <div className="list-box hr">
                    <h6>
                      <b>Earnings</b>
                    </h6>
                    <ul className="d-flex justify-content-between">
                      <li>Referal Commission</li>
                      <li>-${invoice.receivedReferralCommission}</li>
                    </ul>
                  </div>
                  <div className="list-box hr">
                    <ul className="d-flex justify-content-between">
                      <li>Subtotal</li>
                      <li>${parseFloat(totalFulfillment).toFixed("3")}</li>
                    </ul>
                    <ul className="d-flex justify-content-between">
                      <li>Tax</li>
                      <li>${invoice.tax}</li>
                    </ul>
                    <ul className="d-flex justify-content-between">
                      <li>
                        <b>Total</b>
                      </li>
                      <li>
                        <b>
                          $
                          {parseFloat(
                            invoice.tax +
                              totalFulfillment -
                              invoice.receivedReferralCommission
                          ).toFixed("3")}
                        </b>
                      </li>
                    </ul>
                  </div>

                  <div>
                    <span style={{ fontSize: "12px" }}>
                      Find out about bills at <b>shopilam</b>{" "}
                      <Link to="#">help center</Link>
                    </span>
                  </div>
                </div>
                <div className=" flex-column text-center">
                  <button className="btn btn-success">Pay Now</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoice;

import React, { useState } from "react";
import {
  Row,
  Card,
  CardTitle,
  Input,
  Button,
  CardBody,
  Spinner,
  Form,
  ButtonGroup,
} from "reactstrap";
import { Cols } from "../SeparatorStyle/SeparatorStyle";
import { saveQuestions } from "../../api/questionnare";
import { toast } from "react-toastify";
import isEmpty from "validator/lib/isEmpty";
import { useHistory } from "react-router-dom";

const Questionnaire = () => {
  let history = useHistory();
  const [citiesCourierSpinner, setCitiesCourierSpinner] = useState(false);
  const [rSelected, setRSelected] = useState(null);
  const [quesSaveData, setQuesSaveData] = useState({
    dispatch: "",
    fulfilled: "",
    smsToCustomer: [],
    promotionalSms: [],
  });
  const { dispatch, fulfilled, smsToCustomer, promotionalSms } = quesSaveData;

  const saveQuesData = async (e) => {
    e.preventDefault();
    if (
      isEmpty(dispatch) ||
      isEmpty(fulfilled) ||
      smsToCustomer.length === 0 ||
      promotionalSms.length === 0
    ) {
      toast.error("All fields are reuired");
    } else {
      try {
        await saveQuestions(quesSaveData);
        toast.success("Settings saved ");
        setCitiesCourierSpinner((c) => !c);
        setTimeout(() => {
          history.push("/waiting");
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const changeValueHandler = (e) => {
    const { name, value } = e.target;
    setQuesSaveData({ ...quesSaveData, [name]: value });

    if (name === "smsToCustomer") {
      let updatedList = { ...quesSaveData };
      if (e.target.checked) {
        updatedList = {
          ...quesSaveData,
          smsToCustomer: [...quesSaveData.smsToCustomer, value],
        };
      } else {
        updatedList.smsToCustomer.splice(
          quesSaveData.smsToCustomer.indexOf(value),
          1
        );
      }
      setQuesSaveData(updatedList);
    }

    if (name === "promotionalSms") {
      let updatedList = { ...quesSaveData };
      if (e.target.checked) {
        updatedList = {
          ...quesSaveData,
          promotionalSms: [...quesSaveData.promotionalSms, value],
        };
      } else {
        updatedList.promotionalSms.splice(
          quesSaveData.promotionalSms.indexOf(value),
          1
        );
      }
      setQuesSaveData(updatedList);
    }
  };

  return (
    <Row>
      <Cols xxs="10" md="8" sm="8" className="mx-auto my-auto">
        <Card className="auth-card px-5 mt-5">
          <CardBody className="text-justify-center">
            <CardTitle className="text-center bold fs-5 mt-3">
              Tell us something about your business
            </CardTitle>
            <Form onSubmit={saveQuesData}>
              <div className="mt-1">
                <p className="bold">Do you confirm order before Dispatch?</p>
                <ButtonGroup>
                  <Button
                    className="questionnare"
                    color="secondary"
                    name="dispatch"
                    value="Yes"
                    onClick={(e) => {
                      setRSelected(1);
                      changeValueHandler(e);
                    }}
                    active={rSelected === 1}
                  >
                    Yes
                  </Button>
                  <Button
                    className="ms-3 questionnare"
                    color="secondary"
                    name="dispatch"
                    value="No"
                    onClick={(e) => {
                      setRSelected(2);
                      changeValueHandler(e);
                    }}
                    active={rSelected === 2}
                  >
                    No
                  </Button>
                </ButtonGroup>
              </div>
              <div className="mt-2">
                <p className="bold">
                  When do you mark order as Fulfilled in Shopify?
                </p>
                <ul>
                  <li>
                    <Input
                      type="radio"
                      name="fulfilled"
                      value="Packed"
                      onClick={changeValueHandler}
                    />

                    <span className="ms-2">Packed</span>
                  </li>
                  <li>
                    <Input
                      type="radio"
                      name="fulfilled"
                      value="Picked by courier"
                      onClick={changeValueHandler}
                    />
                    <span className="ms-2">Picked by courier</span>
                  </li>
                  <li>
                    <Input
                      type="radio"
                      name="fulfilled"
                      value="Delivered"
                      onClick={changeValueHandler}
                    />
                    <span className="ms-2">Delivered</span>
                  </li>
                  <li>
                    <Input
                      type="radio"
                      name="fulfilled"
                      value="Payment received"
                      onClick={changeValueHandler}
                    />
                    <span className="ms-2">Payment received</span>
                  </li>
                </ul>
              </div>

              <Row>
                <Cols lg="12" className="mt-1">
                  <p className="bold">
                    Select events when you want to send SMS to customer
                  </p>
                  <ul>
                    <li>
                      <Input
                        type="checkbox"
                        name="smsToCustomer"
                        value="Order received"
                        onClick={changeValueHandler}
                      />
                      <span className="ms-2">Order received</span>
                    </li>
                    <li>
                      <Input
                        type="checkbox"
                        name="smsToCustomer"
                        value="Order confirmred"
                        onClick={changeValueHandler}
                      />
                      <span className="ms-2">Order confirmred</span>
                    </li>
                    <li>
                      <Input
                        type="checkbox"
                        name="smsToCustomer"
                        value="Order Dispatched"
                        onClick={changeValueHandler}
                      />
                      <span className="ms-2">Order Dispatched</span>
                    </li>
                    <li>
                      <Input
                        type="checkbox"
                        name="smsToCustomer"
                        value="Thank you after Order Delivered"
                        onClick={changeValueHandler}
                      />
                      <span className="ms-2">
                        Thank you after Order Delivered
                      </span>
                    </li>
                  </ul>

                  <p className="bold">Promotional SMS</p>
                  <ul>
                    <li>
                      <Input
                        type="checkbox"
                        name="promotionalSms"
                        value="New product added SMS"
                        onClick={changeValueHandler}
                      />
                      <span className="ms-2">New product added SMS</span>
                    </li>
                    <li>
                      <Input
                        type="checkbox"
                        name="promotionalSms"
                        value="Abandoned Cart"
                        onClick={changeValueHandler}
                      />
                      <span className="ms-2">Abandoned Cart</span>
                    </li>
                    <li>
                      <Input
                        type="checkbox"
                        name="promotionalSms"
                        value="Sales and discount SMS"
                        onClick={changeValueHandler}
                      />
                      <span className="ms-2">Sales and discount SMS</span>
                    </li>
                    <li>
                      <Input
                        type="checkbox"
                        name="promotionalSms"
                        value="Subscription item / Regular purchase SMS"
                        onClick={changeValueHandler}
                      />
                      <span className="ms-2">
                        Subscription item / Regular purchase SMS
                      </span>
                    </li>
                  </ul>
                </Cols>
              </Row>

              <footer className="footer-questionnaire d-flex justify-content-center mt-3">
                <Button
                  type="submit"
                  outline
                  color="primary"
                  size="lg"
                  className="btn pointer py-2"
                  style={citiesCourierSpinner ? { padding: "10px 69px" } : null}
                >
                  {citiesCourierSpinner ? (
                    <Spinner size={"sm"}>Loading...</Spinner>
                  ) : (
                    " Save & Next"
                  )}
                </Button>
                <Button
                  color="secondary"
                  className="ms-2 pointer"
                  onClick={() => history.push("/waiting")}
                >
                  Skip
                </Button>
              </footer>
            </Form>
          </CardBody>
        </Card>
      </Cols>
    </Row>
  );
};

export default Questionnaire;

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';
//------- STORE SING UP CREDENTIALS--------
export const STORE_SIGNUP_CREDENTIALS = 'STORE_SIGNUP_CREDENTIALS';
export const SIGNUP_STEP1_COMPLETED = 'SIGNUP_STEP1_COMPLETED';

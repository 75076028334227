import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useState, useCallback, useEffect } from "react";
import { getUserData } from "../../api/auth";
import {
  Row,
  Card,
  CardBody,
  Label,
  Button,
  CardText,
  Form,
  FormGroup,
  Input,
  Table,
} from "reactstrap";
import { Cols } from "../SeparatorStyle/SeparatorStyle";

const Role = () => {
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  //Load Data at First Render
  const loadUser = useCallback(async () => {
    try {
      const userData = await getUserData();
      setUsername(userData.data.user.username);
      setEmail(userData.data.user.email);
      setPassword(userData.data.user.password);
    } catch (error) {
      console.log("error found when fetch order data", error);
    }
  }, []);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  return (
    <>
      <main>
        <Row>
          <Cols xxs="12">
            <div className=" mb-3 d-flex justify-content-between">
              <div className="d-flex">
                <i
                  onClick={() => history.goBack()}
                  className="simple-icon-arrow-left-circle fw-bold mr-3 fs-3 pointer"
                ></i>
                <h2 className="m-0">
                  <b>{username}</b>
                </h2>
              </div>
            </div>
          </Cols>
        </Row>
        <br />
        <h5 className="ml-3 mt-1 mb-3">
          <b>Store Profile</b>
        </h5>
        <br />
        <Form>
          <Row>
            <Cols xxs="12" className="row icon-cards-row mb-2">
              <Cols className="mb-4">
                <Card>
                  <CardBody className="m-2">
                    <div style={{ display: "inline" }}>
                      <img
                        src="demo.png"
                        alt="pic"
                        style={{ height: "40px", width: "40px" }}
                      ></img>
                    </div>
                    <div style={{ display: "inline" }}>
                      <b className="ml-3">{username}</b>
                      <span className="float-right mr-2">
                        <Link to="/admin/userAndPermissions/userDetails">
                          View Account Setting
                        </Link>
                      </span>
                      <CardText className="ml-5">
                        <span style={{ color: "blue" }}>{email}</span>
                      </CardText>
                    </div>
                    <Row>
                      <Cols md="4" sm="12" xs="12">
                        <div className="mb-1">
                          <b>TWO-STEP AUTHENTICATION</b>
                        </div>
                        <div className="mb-1">
                          <p>You have not enabled two-step authentication</p>
                        </div>
                      </Cols>
                      <Cols>
                        <div className="mb-1">
                          <b>PASSWORD</b>
                        </div>
                        <div className="mb-1">
                          <p>{password}</p>
                        </div>
                      </Cols>
                    </Row>

                    <hr />
                    <p>
                      <b>DETAILS</b>
                    </p>
                    <FormGroup>
                      <Label for="exampleText">
                        <b>Bio (optional)</b>
                      </Label>

                      <Input
                        id="exampleText"
                        name="text"
                        type="textarea"
                        rows="5"
                      />
                      <p className="mt-1">
                        You can select for this to appear on blog posts that{" "}
                        {username} publishes, from within your theme editor.
                      </p>
                      <Label for="exampleText">
                        <b>Personal website address (optional)</b>
                      </Label>

                      <Input id="exampleText" name="text" type="text" />
                    </FormGroup>
                    <br />
                    <hr />
                    <p>
                      <b>NOTIFICATIONS</b>
                    </p>
                    <FormGroup check>
                      <Input type="checkbox" />
                      <Label check>
                        <b>
                          {" "}
                          Allow important notifications to be sent by email
                        </b>
                      </Label>
                      <p>
                        We periodically send out important news about Shopilam
                        to our users via email. We keep the email volume to an
                        absolute minimum.
                      </p>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Cols>
            </Cols>
          </Row>
          <div className="ml-3">
            <h3>
              <b>Recent Access to Store</b>
            </h3>
            <p>
              If you don't recognize a location, IP address, or ISP, it could
              mean your store has be compromised. Take steps to secure your
              account
            </p>
          </div>
          <br />
          <Row>
            <Cols xxs="12" className="row icon-cards-row mb-2">
              <Cols className="mb-4">
                <Card>
                  <CardBody className="m-2">
                    <Table responsive borderless>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>IP Address</th>
                          <th>ISP</th>
                          <th>Location</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>16/11/2021</td>
                          <td>137.59.224.88</td>
                          <td>Cybernet</td>
                          <td>Faisalabad, 04, Pakistan</td>
                          <td>
                            <span className="simple-icon-question text-black fs-5"></span>
                          </td>
                        </tr>
                        <tr>
                          <td>16/11/2021</td>
                          <td>137.59.224.88</td>
                          <td>Cybernet</td>
                          <td>Faisalabad, 04, Pakistan</td>
                          <td>
                            <span className="simple-icon-question text-black fs-5"></span>
                          </td>
                        </tr>
                        <tr>
                          <td>16/11/2021</td>
                          <td>137.59.224.88</td>
                          <td>Cybernet</td>
                          <td>Faisalabad, 04, Pakistan</td>
                          <td>
                            <span className="simple-icon-question text-black fs-5"></span>
                          </td>
                        </tr>
                        <tr>
                          <td>26/10/2021</td>
                          <td>137.59.224.88</td>
                          <td>Cybernet</td>
                          <td>Faisalabad, 04, Pakistan</td>
                          <td>
                            <span className="simple-icon-question text-black fs-5"></span>
                          </td>
                        </tr>
                        <tr>
                          <td>26/10/2021</td>
                          <td>137.59.224.88</td>
                          <td>Cybernet</td>
                          <td>Faisalabad, 04, Pakistan</td>
                          <td>
                            <span className="simple-icon-question text-black fs-5"></span>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Cols>
            </Cols>
          </Row>
          <div className="ml-3">
            <h3>
              <b>Apps</b>
            </h3>
            <p>
              Your apps will be shown here once you authorize them to access
              your account.
            </p>
          </div>
          <br />

          <Row>
            <Cols xxs="12" className="row icon-cards-row ">
              <Cols className="mb-4">
                <Card>
                  <CardBody className="m-1">
                    <h3>
                      <b>You have not yet authorized apps</b>
                    </h3>
                    Your apps will be shown here once you authorize them to
                    access your account.
                  </CardBody>
                </Card>
              </Cols>
            </Cols>
          </Row>
          <div className="ml-3">
            <h5>
              <b>Manage Staff Access</b>
            </h5>
          </div>
          <br />
          <Row>
            <Cols xxs="12" className="row icon-cards-row ">
              <Cols className="mb-4">
                <Card>
                  <CardBody className="m-1">
                    <p>
                      {" "}
                      <b>SUSPEND ACCESS</b>
                    </p>
                    <p>
                      Your apps will be shown here once you authorize them to
                      access your account.
                    </p>
                    <Button color="secondary" outline>
                      Suspend access
                    </Button>
                  </CardBody>
                  <hr />
                  <CardBody className="m-1">
                    <p>
                      <b>REMOVE {username.toUpperCase()}</b>
                    </p>
                    <p>
                      Your apps will be shown here once you authorize them to
                      access your account.
                    </p>
                    <Button color="danger">Remove {username}</Button>
                  </CardBody>
                </Card>
              </Cols>
            </Cols>
          </Row>
          <Button className="float-right mr-4" color="secondary" outline>
            Save
          </Button>
        </Form>
      </main>
    </>
  );
};

export default Role;

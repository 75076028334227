import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import OrderHeader from './OrderHeader.jsx';
import { Cols } from '../SeparatorStyle/SeparatorStyle';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getOrderDetails } from '../../redux/actions/orderActions';
import { addTracking, fulfillOrderItems } from '../../api/orders';
import {
  fulFillmentStatus,
  paymentStatus,
} from './orderTabs/helperFunctions/ordersStatusInfo.jsx';
import { Badge } from 'reactstrap';
import { toast } from 'react-toastify';

const OrderDetails = () => {
  const { id: orderId } = useParams();
  const [trackingModal, setTrackingModal] = useState(false);
  const [paymentOptionsDD, setPaymentOptionsDD] = useState(false);
  const [userCourierOptions, setUserCourierOptions] = useState([]);
  const [selectedUserCourier, setSelectedUserCourier] = useState('');
  const [trackingNumber, setTrackingNumber] = useState('');
  const [showAddTrackingBtn, setShowAddTrackingBtn] = useState(true);
  const [customCustomerData, setCustomCustomerData] = useState({});

  const dispatch = useDispatch();
  const { loading, error, order, unFulfilledItems, fulfilledItems } =
    useSelector((state) => state.orderDetails);
  const {
    userCourierLoading,
    userCourierError,
    data: userCouriersData,
  } = useSelector((state) => state.userCouriers);

  useEffect(() => {
    dispatch(getOrderDetails(orderId));
    if (!userCourierLoading && !userCourierError && userCouriersData) {
      setUserCourierOptions(
        userCouriersData.courierNames.map((courier) => ({
          id: courier._id,
          label: courier.label,
        }))
      );
    }

    setCustomCustomerData(JSON.parse(localStorage.getItem('refinedCustomer')));
  }, [
    dispatch,
    orderId,
    userCourierError,
    userCourierLoading,
    userCouriersData,
  ]);

  const handleTrackingModal = () => setTrackingModal(!trackingModal);
  const handlePaymentOptionsDD = () => setPaymentOptionsDD(!paymentOptionsDD);

  const handleItemsFulfillment = async () => {
    const storeId = order.storeId;
    const unFulfilledItemIdsAndQty = unFulfilledItems.map((item) => ({
      id: item.id,
      quantity: item.fulfillable_quantity,
    }));
    const fulfillOrderItemsResponse = await fulfillOrderItems(
      unFulfilledItemIdsAndQty,
      orderId,
      storeId
    );
    if (fulfillOrderItemsResponse.status === 201) {
      dispatch(getOrderDetails(orderId));
    }
  };

  const addTrackingInfo = async () => {
    const trackingData = {
      storeId: order.storeId,
      orderId,
      trackingNumber,
      trackingCompany: selectedUserCourier,
      fulfillmentId: fulfilledItems[0].fulfillmentId,
    };
    try {
      const savedTrackingInfo = await addTracking(trackingData);
      if (savedTrackingInfo.status === 201) {
        setShowAddTrackingBtn(false);
      }
      setTrackingModal(false);
      toast.success('Tracking data successfully added');
    } catch (e) {
      toast.error(`Unable to add tracking info: ${e.message}`);
    }
  };

  return (
    <main>
      <OrderHeader OrdersComponent="Order Detail" />
      {order && Object.keys(order).length > 0 && !loading ? (
        !error ? (
          <Row>
            <Cols xxs="12">
              <div className="order_detail_header">
                <div className="order_detail_header_info">
                  <h4>{order.name}</h4>
                  <label>
                    {order.cancelled_at !== null ? (
                      <h6>
                        {' '}
                        <Badge color="warning" pill>
                          Cancelled{' '}
                        </Badge>
                      </h6>
                    ) : (
                      ''
                    )}
                  </label>
                  <label>{paymentStatus(order.financial_status)}</label>
                  <label>{fulFillmentStatus(order.fulfillment_status)}</label>
                </div>
              </div>
              <Row>
                <Cols md="8">
                  {unFulfilledItems && unFulfilledItems.length > 0 && (
                    <Card className="mb-4">
                      <CardBody>
                        <CardTitle className="order_table_card_title">
                          <h6>
                            <strong>
                              UNFULFILLED ({unFulfilledItems.length})
                            </strong>
                          </h6>
                        </CardTitle>
                        {unFulfilledItems.map((item) => (
                          <Row>
                            <div className="ordered_item">
                              <Cols md="8" lg="8" sm="8">
                                <div className="ordered_item_details">
                                  <label>{item.title}</label>
                                  <label className="ordered_item_subtext">
                                    {item.variant_title}
                                  </label>
                                  <label className="ordered_item_subtext">
                                    SKU: {item.sku}
                                  </label>
                                </div>
                              </Cols>
                              <Cols md="2" lg="2" sm="2">
                                <div className="ordered_item_price_details">
                                  <label>
                                    PKR {parseFloat(item.price).toFixed(2)}×
                                    {parseFloat(
                                      item.fulfillable_quantity
                                    ).toFixed(2)}
                                  </label>
                                </div>
                              </Cols>
                              <Cols md="2" lg="2" sm="2">
                                <div className="ordered_item_price_details">
                                  <label>
                                    PKR{' '}
                                    {parseFloat(
                                      item.price * item.fulfillable_quantity
                                    ).toFixed(2)}
                                  </label>
                                </div>
                              </Cols>
                            </div>
                          </Row>
                        ))}
                        {order.cancelled_at === null ? (
                          <Button
                            className="float-right"
                            color="primary"
                            onClick={handleItemsFulfillment}
                          >
                            Fulfill Items
                          </Button>
                        ) : null}
                      </CardBody>
                    </Card>
                  )}
                  {fulfilledItems && fulfilledItems.length > 0 && (
                    <Card className="mb-4">
                      <CardBody>
                        <CardTitle className="order_table_card_title">
                          <h6>
                            <strong>
                              FULFILLED ({fulfilledItems[0].lineItemsCount}){' '}
                            </strong>
                            <small>#{order.order_number}</small>
                          </h6>
                        </CardTitle>
                        {fulfilledItems[0].lineItems.map((item) => (
                          <div className="ordered_item">
                            <Row>
                              <Cols md="8" lg="8" sm="8">
                                <div className="ordered_item_details">
                                  <label>{item.title}</label>
                                  <label className="ordered_item_subtext">
                                    {item.variant_title}
                                  </label>
                                  <label className="ordered_item_subtext">
                                    SKU: {item.sku}
                                  </label>
                                </div>
                              </Cols>
                              <Cols md="2" lg="2" sm="2">
                                <div className="ordered_item_price_details text-right">
                                  <label>
                                    PKR {item.price} × {item.quantity}
                                  </label>
                                </div>
                              </Cols>
                              <Cols md="2" lg="2" sm="2">
                                <div className="ordered_item_price_details text-right">
                                  <label>
                                    PKR {item.price * item.quantity}
                                  </label>
                                </div>
                              </Cols>
                            </Row>
                          </div>
                        ))}
                        {fulfilledItems[0].trackingNumber === null &&
                        showAddTrackingBtn ? (
                          <Button
                            className="float-right"
                            color="primary"
                            onClick={handleTrackingModal}
                          >
                            Add Tracking
                          </Button>
                        ) : null}
                      </CardBody>
                    </Card>
                  )}

                  <Card className="mb-4">
                    <CardBody>
                      <CardTitle className="order_table_card_title">
                        <h6>
                          <strong>
                            {order.financial_status.toUpperCase()}{' '}
                          </strong>
                        </h6>
                      </CardTitle>
                      <table className="order_payment_table">
                        <tr className="vertical_table_rows">
                          <th>Subtotal</th>
                          {order.line_items && order.line_items.length > 0 ? (
                            <>
                              <td>{order.line_items.length} items</td>
                              <td>{order.subtotal_price} PKR</td>
                            </>
                          ) : (
                            <td>0 items</td>
                          )}
                        </tr>
                        <tr className="vertical_table_rows">
                          <th>Tax</th>
                          <td>
                            {order.tax_lines.length > 0 ? (
                              <>
                                {order.tax_lines[0].title}{' '}
                                {order.tax_lines[0].rate * 100} %
                              </>
                            ) : null}
                          </td>
                          <td>
                            {order.tax_lines.length > 0
                              ? order.tax_lines[0].price
                              : 0}{' '}
                            PKR
                          </td>
                        </tr>
                        <tr className="vertical_table_rows">
                          <th>
                            <b>Total</b>
                          </th>
                          <td></td>
                          <td>
                            <b>{order.total_price} PKR</b>
                          </td>
                        </tr>
                      </table>
                      {order.cancelled_at === null ? (
                        order.financial_status !== 'paid' ? (
                          <Dropdown
                            className="float-right"
                            isOpen={paymentOptionsDD}
                            toggle={handlePaymentOptionsDD}
                          >
                            <DropdownToggle caret>
                              Collect Payment
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem>Mark as Paid</DropdownItem>
                              <DropdownItem>Send Invoice</DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        ) : null
                      ) : null}
                    </CardBody>
                  </Card>
                </Cols>
                <Cols md="4">
                  <Card className="mb-4">
                    <CardBody>
                      <CardTitle className="order_table_card_title">
                        <h6>
                          <strong>Notes</strong>
                        </h6>
                      </CardTitle>
                      <label>
                        {order.note ? order.note : 'No notes from customer'}
                      </label>
                    </CardBody>
                  </Card>
                  <Card className="mb-4">
                    <CardBody>
                      <CardTitle className="order_table_card_title">
                        <h6>
                          <strong>Customer</strong>
                        </h6>
                      </CardTitle>
                      {order.customer && order.customer.first_name ? (
                        <>
                          <p>
                            {order.customer.first_name}{' '}
                            {order.customer.last_name}
                          </p>
                          <p>
                            {customCustomerData
                              ? customCustomerData.orders_count
                              : order.customer.orders_count}{' '}
                            Orders
                          </p>
                        </>
                      ) : (
                        <p>No Customer</p>
                      )}
                    </CardBody>
                    <CardBody>
                      <CardTitle className="order_table_card_title">
                        <h7>
                          <strong>Contact Information</strong>
                        </h7>
                      </CardTitle>
                      {order.customer && order.customer.email ? (
                        <p>{order.customer.email}</p>
                      ) : (
                        <p>No customer email provided</p>
                      )}
                      {order.customer && order.customer.phone ? (
                        <p>{order.customer.phone}</p>
                      ) : (
                        <p>No customer phone provided</p>
                      )}
                    </CardBody>
                    <CardBody>
                      <CardTitle className="order_table_card_title">
                        <h7>
                          <strong>Shipping Address</strong>
                        </h7>
                      </CardTitle>
                      {order.shipping_address ? (
                        <>
                          <p>{order.shipping_address.name}</p>
                          <p>{order.shipping_address.address1}</p>
                          <p>
                            {order.shipping_address.city}{' '}
                            {order.shipping_address.zip}
                          </p>
                          <p>{order.shipping_address.phone}</p>
                        </>
                      ) : (
                        <p>No shipping address provided</p>
                      )}
                    </CardBody>
                    <CardBody>
                      <CardTitle className="order_table_card_title">
                        <h7>
                          <strong>Billing Address</strong>
                        </h7>
                      </CardTitle>
                      {order.billing_address ? (
                        order.billing_address.name !==
                          order.shipping_address.name &&
                        order.billing_address.address1 !==
                          order.shipping_address.address1 &&
                        order.billing_address.phone !==
                          order.shipping_address.phone &&
                        order.billing_address.city !==
                          order.shipping_address.city &&
                        order.billing_address.zip !==
                          order.shipping_address.zip ? (
                          <>
                            <p>{order.billing_address.name}</p>
                            <p>{order.billing_address.address1}</p>
                            <p>
                              {order.billing_address.city}{' '}
                              {order.billing_address.zip}
                            </p>
                            <p>{order.billing_address.phone}</p>
                          </>
                        ) : (
                          <p>Same as shipping address</p>
                        )
                      ) : (
                        <p>No billing address provided</p>
                      )}
                    </CardBody>
                  </Card>
                </Cols>
              </Row>
            </Cols>
          </Row>
        ) : (
          <h3>No order with id {orderId} found</h3>
        )
      ) : (
        <div
          className="position-relative"
          style={{
            height: '70vh',
          }}
        >
          <div className="loading position-absolute" />
        </div>
      )}

      <Modal isOpen={trackingModal}>
        <ModalHeader toggle={handleTrackingModal}>Add Tracking</ModalHeader>
        <ModalBody>
          <div className="tracking_modal_inputs">
            <label>Tracking Number</label>
            <Input
              placeholder="1562678"
              value={trackingNumber}
              onChange={(e) => setTrackingNumber(e.target.value)}
            />
          </div>
          <div className="tracking_modal_inputs">
            <label>Shipping carrier</label>
            {!userCourierLoading ? (
              !userCourierError ? (
                <select
                  className="form-control"
                  onChange={(e) => setSelectedUserCourier(e.target.value)}
                >
                  <option value="" disabled selected>
                    Select
                  </option>
                  {userCourierOptions.map((courier) => (
                    <option value={courier.label} key={courier.id}>
                      {courier.label}
                    </option>
                  ))}
                </select>
              ) : (
                <label>{userCourierError}</label>
              )
            ) : (
              <div className="loading" />
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button outline onClick={handleTrackingModal}>
            Cancel
          </Button>
          <Button color="primary" onClick={addTrackingInfo}>
            Save
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </main>
  );
};

export default OrderDetails;

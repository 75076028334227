import { useState } from "react";
import XLSX from "xlsx";
import { toast } from "react-toastify";

const useExcelToJson = () => {
  const [fileName, setFileName] = useState("");
  const [uploadedFileData, setUploadedFileData] = useState([]);

  const fileValidation = (path) => {
    let allowedExtensions = /(\.csv|\.xlsx|\.xls)$/i;
    return allowedExtensions.exec(path) ? true : false;
  };
  const validateColumnNames = (headers) => {
    headers = headers.map((o) => o?.toLowerCase());
    const requiredColumns = ["name", "orderid", "phone"];
    return requiredColumns.every((column) => headers.includes(column));
  };

  const readFile = (file) => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      const binaryStr = evt.target.result;
      const workBook = XLSX.read(binaryStr, { type: "binary" });
      const workSheetName = workBook.SheetNames[0];
      const workSheet = workBook.Sheets[workSheetName];
      const data = XLSX.utils.sheet_to_csv(workSheet, { header: 1 });
      const headers = data.split("\n")[0].split(",");

      if (validateColumnNames(headers)) {
        const jsonData = convertToJson(data);
        jsonData && jsonData.length > 0
          ? setUploadedFileData(jsonData)
          : setUploadedFileData(null);
      } else {
        toast.error(
          "Invalid columns. Required Columns: (name, orderid, phone)",
          { autoClose: false }
        );
        setUploadedFileData(null);
        setFileName("");
        console.log(headers);
      }
    };
    reader.readAsBinaryString(file);
  };

  const convertToJson = (csv) => {
    const lines = csv.split("\n");
    const result = [];
    const headers = lines[0].split(",");
    const requiredColumns = ["name", "orderId", "phone"]; // Specify the columns you want to extract

    for (let i = 1; i < lines.length; i++) {
      let obj = {};
      let currentLine = lines[i].split(",");
      for (let j = 0; j < headers.length; j++) {
        // Check if the current header is in the list of required columns
        if (requiredColumns.includes(headers[j])) {
          obj[headers[j]] = currentLine[j];
        }
      }
      result.push(obj);
    }
    result.pop(); // Remove the last empty object if exists
    return result;
  };

  const handleFileUpload = (file) => {
    let result = fileValidation(file.name);
    if (result) {
      setFileName(file.name);
      readFile(file);
    } else {
      setFileName("");
      toast.info(".csv .xlsx .xls  are allowed");
    }
  };

  return {
    fileName,
    uploadedFileData,
    handleFileUpload,
    setUploadedFileData,
    setFileName,
  };
};

export default useExcelToJson;

import React, { useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import classnames from "classnames";
import DatatablePagination from "../../wizard/DatatablePagination.jsx";
import { Input, Table } from "reactstrap";

const DataTable = ({
  setPageLimitInParent,
  setPageNumberInParent,
  columns,
  pageCount: totalPages,
  checkedOrders,
  onCheckAllOrders,
  onCheckItem,
  data,
  divided = false,
  defaultPageSize = 50,
  fetchData,
  checkCounter,
  loadingBool,
  selectedOrdersActionBool,
  setPaginationLoading,
  paginationLoading,
  totalCount,
}) => {
  const [pageNum, setPageNum] = useState(0);
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: pageNum, pageSize: defaultPageSize },
      manualPagination: true,
      pageCount: totalPages,
    },
    useSortBy,
    usePagination
  );

  const PageChange = (p) => {
    gotoPage(p);
    setPageNum(p);
    setPaginationLoading(true);
    window.scrollTo(0, 0);
    setPageNumberInParent(p + 1);
    fetchData({ page: p + 1, limit: pageSize });
  };

  const PageSizeChange = (s) => {
    defaultPageSize = s;
    setPageSize(s);
    setPaginationLoading(true);
    window.scrollTo(0, 0);
    setPageLimitInParent(s);
    fetchData({ page: pageIndex + 1, limit: s });
  };
  const checkedCounter = (counter) => {
    if (counter > 0) {
      return <span>{counter} </span>;
    }
  };

  const startIndex = pageIndex * pageSize + 1;
  const endIndex = Math.min((pageIndex + 1) * pageSize, totalCount || 0);
  const dataInfo = `${startIndex} - ${endIndex} of ${totalCount || 0}`;

  return (
    <>
      <Table
        striped
        borderless
        responsive
        {...getTableProps()}
        className={
          paginationLoading
            ? ` table backgroundBlur ${classnames({
                "table-divided": divided,
              })}`
            : ` table ${classnames({
                "table-divided": divided,
              })}`
        }
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              <th style={{ width: "1%" }}>
                <p className="text-muted" style={{ minHeight: "22px" }}>
                  {checkedCounter(checkCounter)}
                </p>

                <label className="custom-control custom-checkbox mb-0 align-self-center">
                  <Input
                    className="item-check mb-0 pointer"
                    style={{
                      border: "0.1px solid gray",
                      fontSize: "16px",
                    }}
                    type="checkbox"
                    checked={data.length === checkCounter}
                    name="checkboxAllOrders"
                    onChange={(e) =>
                      onCheckAllOrders(
                        e,
                        page.map((row) => row.original)
                      )
                    }
                    label=""
                    disabled={loadingBool || selectedOrdersActionBool}
                  />
                </label>
              </th>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  key={`th_${columnIndex}`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={`${
                    column.isSorted
                      ? column.isSortedDesc
                        ? "sorted-desc"
                        : "sorted-asc"
                      : ""
                  }
                  ${columnIndex === 0 ? "px-0 text-start" : ""} `}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {column.render("Header")}
                  <span />
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} style={{ height: "50px" }}>
                <td>
                  <label className="custom-control custom-checkbox mb-0 align-self-center pointer">
                    <Input
                      className="item-check mb-0 pointer"
                      type="checkbox"
                      checked={
                        (checkedOrders && checkedOrders[row.original.id]) ||
                        false
                      }
                      style={{
                        border: "0.1px solid gray",
                        fontSize: "16px",
                      }}
                      name={row.original.id}
                      onChange={(e) =>
                        onCheckItem(e, row.original.id, row.original)
                      }
                      label=""
                      disabled={loadingBool || selectedOrdersActionBool}
                    />
                  </label>
                </td>
                {row.cells.map((cell, cellIndex) => (
                  <td
                    className="table-tab"
                    key={`td_${cellIndex}`}
                    {...cell.getCellProps({
                      className: cell.column.cellClass,
                    })}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <DatatablePagination
        paginationLoading={paginationLoading}
        page={pageIndex}
        pages={pageCount}
        canPrevious={canPreviousPage}
        canNext={canNextPage}
        pageSizeOptions={[50, 100, 150, 200, 250]}
        showPageSizeOptions={true}
        showPageJump={true}
        defaultPageSize={pageSize}
        onPageChange={(p) => PageChange(p)}
        onPageSizeChange={(s) => PageSizeChange(s)}
        paginationMaxSize={pageCount}
        dataInfo={totalCount && !paginationLoading ? dataInfo : null}
      />
    </>
  );
};

export default DataTable;

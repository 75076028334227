import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  userCouriersReducer,
  pageSize,
  allOrdersReducer,
  currentPage,
  orderDetailsReducer,
  loginReducer,
  registerReducer,
  userNameReducer,
  userImageReducer,
} from "./reducers/index";
import { getLocalStorage } from "../helpers/localStorage";

const reducer = combineReducers({
  orders: allOrdersReducer,
  orderDetails: orderDetailsReducer,
  userCouriers: userCouriersReducer,
  userLogin: loginReducer,
  userRegister: registerReducer,
  currentPage: currentPage,
  pageSize: pageSize,
  userNameReducer: userNameReducer,
  userImage: userImageReducer,
});

const userCouriersFromStorage = localStorage.getItem("userCouriers")
  ? JSON.parse(localStorage.getItem("userCouriers"))
  : {};
const userInfoFromStorage = getLocalStorage("user");

const initial_state = {
  orders: {
    allOrders: [],
    refundedOrders: [],
    pendingOrders: [],
    fulfilledOrders: [],
    openOrders: [],
    closedOrders: [],
    unfulfilledOrders: [],
  },
  orderDetails: {},
  userCouriers: userCouriersFromStorage,
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initial_state,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;

import React, { useState } from "react";
import { Card, CardBody, TabContent, TabPane } from "reactstrap";
import AssignedOrders from "./AssignedOrders.jsx";
import OrderTabs from "./OrderTabs.jsx";
import OrderHeader from "../OrderHeader.jsx";
import ConfirmedOrders from "./ConfirmedOrders.jsx";
import OpenOrders from "./OpenOrders.jsx";
import PendingOrders from "./PendingOrders.jsx";
import CancelledOrders from "./CancelledOrders.jsx";
import FulfilledOrders from "./FulfilledOrders.jsx";
import UnAssignedOrders from "./UnAssignedOrders.jsx";
import ManualOrders from "./ManualOrders.jsx";

const AssignmentOrdersList = () => {
  const activeTabFromStorage = localStorage.getItem("activeExportOrderTab");

  const [activeTab, setActiveTab] = useState(
    activeTabFromStorage ? activeTabFromStorage : "openOrders"
  );
  const [orderExported, setOrderExported] = useState(false);
  const [orderConfirmed, setOrderConfirmed] = useState(false);
  const [orderPending, setOrderPending] = useState(false);
  const [orderCancelled, setOrderCancelled] = useState(false);
  const [orderFulfilled, setOrderFulfilled] = useState(false);
  const [orderManual, setOrderManual] = useState(false);
  // const [skeletonOrderTab, setSkeletonOrderTab] = useState(false);

  // setTimeout(() => {
  //   setSkeletonOrderTab(true);
  // }, 1000);

  return (
    <>
      <main>
        <OrderHeader OrdersComponent="Fulfillment" />
        <Card>
          <CardBody>
            {/* {skeletonOrderTab ? ( */}
            <OrderTabs activeTab={activeTab} setActiveTab={setActiveTab} />
            {/* ) : (
              <div className="tabs-bottom-border mt-2">
                {new Array(8).fill(0).map((item, i) => {
                  return (
                    <p
                      className="skeleton skeleton-loading tab-skeleton-fullfilment me-4 mb-0"
                      key={i + 1}
                    ></p>
                  );
                })}
              </div>
            )} */}

            <TabContent activeTab={activeTab}>
              <TabPane tabId="openOrders">
                {activeTab === "openOrders" ? (
                  <OpenOrders
                    setOrderConfirmed={setOrderConfirmed}
                    setOrderPending={setOrderPending}
                    setOrderCancelled={setOrderCancelled}
                    setOrderManual={setOrderManual}
                  />
                ) : (
                  ""
                )}
              </TabPane>
              <TabPane tabId="pendingOrders">
                {activeTab === "pendingOrders" ? (
                  <PendingOrders
                    setOrderPending={setOrderPending}
                    orderPending={orderPending}
                    setOrderConfirmed={setOrderConfirmed}
                    setOrderCancelled={setOrderCancelled}
                    setOrderManual={setOrderManual}
                  />
                ) : (
                  ""
                )}
              </TabPane>
              <TabPane tabId="confirmedOrders">
                {activeTab === "confirmedOrders" ? (
                  <ConfirmedOrders
                    setOrderExported={setOrderExported}
                    setOrderConfirmed={setOrderConfirmed}
                    orderConfirmed={orderConfirmed}
                  />
                ) : (
                  ""
                )}
              </TabPane>
              <TabPane tabId="cancelledOrders">
                {activeTab === "cancelledOrders" ? (
                  <CancelledOrders
                    setOrderCancelled={setOrderCancelled}
                    orderCancelled={orderCancelled}
                  />
                ) : (
                  ""
                )}
              </TabPane>
              <TabPane tabId="unAssignedOrders">
                {activeTab === "unAssignedOrders" ? (
                  <UnAssignedOrders
                    setOrderExported={setOrderExported}
                    orderExported={orderExported}
                  />
                ) : (
                  ""
                )}
              </TabPane>
              <TabPane tabId="assignedOrders">
                {activeTab === "assignedOrders" ? (
                  <AssignedOrders setOrderFulfilled={setOrderFulfilled} />
                ) : (
                  ""
                )}
              </TabPane>
              <TabPane tabId="fulfilledOrders">
                {activeTab === "fulfilledOrders" ? (
                  <FulfilledOrders
                    setOrderFulfilled={setOrderFulfilled}
                    orderFulfilled={orderFulfilled}
                  />
                ) : (
                  ""
                )}
              </TabPane>
              <TabPane tabId="manualOrders">
                {activeTab === "manualOrders" ? (
                  <ManualOrders
                    setOrderManual={setOrderManual}
                    orderManual={orderManual}
                  />
                ) : (
                  ""
                )}
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </main>
    </>
  );
};

export default AssignmentOrdersList;

import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateTimeRange = ({
  onStartDateSelected,
  onEndDateSelected,
  startDate,
  endDate,
  allOrdersFlag,
  filtersLoader,
  paginationLoading,
  selectedOrdersInBulk,
}) => {
  return (
    <>
      <div className="qwerty">
        <div className="date-range-fields-container">
          <div className="dateTimeRange-from mb-2">
            <DatePicker
              selected={startDate}
              onChange={(date) => onStartDateSelected(date)}
              maxDate={new Date()}
              isClearable
              disabled={
                allOrdersFlag
                  ? paginationLoading ||
                    filtersLoader ||
                    selectedOrdersInBulk?.length > 0
                  : null
              }
              showYearDropdown
              scrollableMonthYearDropdown
              placeholderText=" From "
              dropdownMode
            />
          </div>
          <div className="dateTimeRange me-2">
            <DatePicker
              selected={endDate}
              onChange={(date) => onEndDateSelected(date)}
              maxDate={new Date()}
              isClearable
              showYearDropdown
              scrollableMonthYearDropdown
              placeholderText=" To "
              dropdownMode
              disabled={
                allOrdersFlag
                  ? paginationLoading ||
                    filtersLoader ||
                    selectedOrdersInBulk?.length > 0
                  : null
              }
              style={{ textAlign: "center" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DateTimeRange;

import React, { useState, useRef, useCallback, useEffect } from "react";
import "./productsdetail.css";
import emptVariantImg from "../../Variant/emptyvariant.png";
import { Link, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Tooltip,
  Label,
  Button,
  Breadcrumb,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Modal,
  Badge,
  Table,
  Input,
  Toast,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonGroup,
} from "reactstrap";
import ImageUploading from "react-images-uploading";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, EditorState } from "draft-js";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import CreateableSelect from "react-select/creatable";
import { getAllStoresLoginUser } from "../../../api";
import { toast } from "react-toastify";

const DetailedUI = ({
  showEditProduct,
  description2,
  deleteProducts,
  updateProducts,
  handleRemoveOptions,
  addEditOption,
  handleRemoveOptionValues,
  attributes,
  products,
  loading,
}) => {
  let editorState = EditorState.createEmpty();
  let refForm = useRef(null);
  const { id: shopifyProductId } = useParams();
  let defaultProductData = {
    title: "",
    body_html: "",
    image: null,
    images: [],
    platform: "Shopilam",
    // addFromURL: '',
    // price: '1',
    // compareAtPrice: '',
    // chargeTaxOnProduct: false,
    // costPerItem: '',
    // SKU: '',
    // barcode: '',
    // trackQuantity: false,
    // continueSellingWhenOutOfStock: false,
    // quantity: '',
    // physicalProduct: false,
    // weight: '',
    // units: '',
    // countryRegion: '',
    // HS: '',
    options: [],
    hasVariants: false,
    variants: [],
    // editSEO: false,
    // pageTitle: '',
    // metaDescription: '',
    // handleURL: '',
    status: "active",
    // onlineStore: false,
    // userStore: false,
    type: "",
    product_type: "",
    vendor: "",
    // collections: [],
    // handle: "",
    tags: "",
    // themeTemplate: '',
  };
  const [productData, setProductData] = useState(defaultProductData);

  const [storeId, setStoreId] = useState("");
  const [description, setDescription] = useState(editorState);
  const maxNumber = 100;
  const acceptType = ["jpg", "jpeg", "gif", "png"];
  const ref = useRef(null);

  const [images, setImages] = useState([]);

  const [selectedImages, setSelectedImages] = useState([]);

  const [showOptionsData, setShowOptionsData] = useState([]);
  const [inputTooltip, setInputTooltip] = useState(false);
  const [trackQuantityCheck, setTrackQuantityCheck] = useState(false);
  const [shippingCheck, setShippingCheck] = useState(false);
  const [open, setOpen] = useState(false);
  const [variants, setVariants] = useState([]);
  let variantItem = {
    selected: false,
    title: "",
    price: "",
    // quantity: "1",
    sku: "",
    // country: "",
    // location: "",
    // option: null,
    compare_at_price: null,
    // cost_per_item: "",
    // HS: "",
    option1: null,
    option2: null,
    option3: null,
    barcode: null,
    grams: 0,
    weight: 0,
    weight_unit: "lb",
    presentment_prices: [
      {
        price: {
          amount: "0.00",
          currency_code: "USD",
        },
        compare_at_price: null,
      },
    ],
  };

  let defaultOption = {
    name: "",
    values: [""],
  };

  let optionName = ["Size", "Color", "Material", "Style"];

  const [optionNames, setOptionNames] = useState(optionName);

  const [addOption, setAddOption] = useState(defaultOption);
  const [checkedOption, setCheckedOption] = useState(false);
  const [option, setOption] = useState([]);
  const [multiInputCount, setMultiInputCount] = useState([""]);
  const [multiInputCountIndex, setMultiInputCountIndex] = useState();
  let optionCount = ["1", "2"];
  const [countOption, setCountOption] = useState(optionCount);

  const [selectedOptionIndex, setSelectedOptionIndex] = useState();
  const [selectedOptionData, setSelectedOptionData] = useState();
  const [updateOptionMultiValueIndex, setUpdateOptionMultiValueIndex] =
    useState();
  const [showDuplicateValues, setShowDuplicateValues] = useState(false);
  const [multiDuplicateValues, setMultiDuplicateValues] = useState([]);

  const [showDuplicateUpdateValues, setShowDuplicateUpdateValues] =
    useState(false);
  const [multiDuplicateUpdateValues, setMultiDuplicateUpdateValues] = useState(
    []
  );

  const [variantsHeaderDropdown, setVariantsHeaderDropdown] = useState(false);
  const [option1DropDown, setOption1DropDown] = useState(false);
  const [option2DropDown, setOption2DropDown] = useState(false);
  const [option3DropDown, setOption3DropDown] = useState(false);
  const [dropDownValueChecked, setDropDownValueChecked] = useState([]);

  const [selectedVariants, setSelectedVariants] = useState([]);

  const [editVariantModal, setEditVariantModal] = useState(false);

  const [createThisVariantCheck, setCreateThisVariantCheck] = useState(true);
  const [editingVariant, setEditingVariant] = useState(variantItem);
  const [editingVariantIndex, setEditingVariantIndex] = useState();

  const [editingVariantsPrices, setEditingVariantsPrices] = useState([]);
  const [priceToAll, setPriceToAll] = useState("");
  const [editPriceModal, setEditPriceModal] = useState(false);

  const [editCountryModal, setEditCountryModal] = useState(false);
  let countryList = [
    "Afghanistan",
    "Iran",
    "India",
    "Pakistan",
    "Bangladesh",
    "Nepal",
    "China",
    "America",
  ];
  const [editingVariantsCountry, setEditingVariantsCountry] = useState([]);
  const [countryToAll, setCountryToAll] = useState("");
  const [editLocationsModal, setEditLocationsModal] = useState(false);
  const [editLocationsCheck, setEditLocationsCheck] = useState(true);

  const [seo, setSeo] = useState(false);
  const [onlineStoreCheckbox, setOnlineStoreCheckbox] = useState(false);
  const [userStoreCheckbox, setUserStoreCheckbox] = useState(false);
  let type = ["Adidas", "Nike", "Toshiba", "polo"];
  let vendor = ["Adidas", "Nike", "Acer", "polo"];
  let collections = [
    { value: "Black", label: "Black" },
    { value: "Ocean", label: "Ocean" },
    { value: "Blue", label: "Blue" },
    { value: "Purple", label: "Purple" },
    { value: "Red", label: "Red" },
  ];
  const [collectionValues, setCollectionValues] = useState([]);

  const [tagsPopup, setTagsPopup] = useState();
  const [creatableMenuOpen, setcreatableMenuOpen] = useState(false);
  const [checkedTags, setCheckedTags] = useState([]);
  let tag = [
    { value: "Galaxy", label: "Galaxy" },
    { value: "Adidas", label: "Adidas" },
    { value: "Nike", label: "Nike" },
    { value: "Pollo", label: "Pollo" },
    { value: "Toshiba", label: "Toshiba" },
  ];
  const [tags, setTags] = useState(tag);
  const [searchTagValue, setSearchTagValue] = useState("");
  const [newTags, setNewTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const loadStores = useCallback(async () => {
    try {
      const { data } = await getAllStoresLoginUser();
      if (data.length > 0 && data[0].stores.length > 0) {
        if (data[0].stores.length > 1) {
          let masterStore = data[0].stores.filter(
            (store) => store.isMasterStore
          );
          if (masterStore.length > 0) setStoreId(masterStore[0]._id);
          else setStoreId(data[0].stores[0]._id);
        } else {
          setStoreId(data[0].stores[0]._id);
        }
      }
    } catch (error) {
      console.log("Error in Fetch Stores!", error);
    }
  }, []);

  useEffect(() => {
    loadStores();
  }, [loadStores]);

  const onEditorStateChange = (v) => {
    setDescription(v);
  };

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleEditorValue = () => {
    const rawContentState = convertToRaw(description.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    var doc = new DOMParser().parseFromString(markup, "text/html");
    setProductData({ ...productData, body_html: doc.body.innerText.trim() });
  };

  const onImageChange = (imageList, addUpdateIndex) => {
    imageList?.filter((obj) => (obj.src = obj.data_url));
    setImages(imageList);
  };

  const handleSelectImage = (image, index, e) => {
    images.filter((m) => {
      if (m === image) {
        m.selected = e.target.checked;
      }
    });
    if (image.selected) {
      setSelectedImages([image, ...selectedImages]);
    } else {
      setSelectedImages(selectedImages.filter((img) => img !== image));
    }
  };
  const handleMasterCheckImages = () => {
    let allSelectImages = images.filter((img) => (img.selected = true));
    setSelectedImages(allSelectImages);
  };

  const handleMasterUncheckImages = () => {
    images.map((img) => (img.selected = !img.selected));
    setSelectedImages([]);
  };

  const handleDeleteSelectedImages = () => {
    if (selectedImages.length == images.length) {
      setImages([]);
      setSelectedImages([]);
    } else {
      let deleteSelectedImage = images.filter(
        (img) => !selectedImages.includes(img)
      );
      setImages(deleteSelectedImage);

      setSelectedImages([]);
    }
  };

  const handleTrackCheck = () => {
    setTrackQuantityCheck((check) => !check);
  };

  const handleShippingCheck = () => {
    setShippingCheck((check) => !check);
  };

  const handleOption = () => {
    let setCheck = !checkedOption;
    setCheckedOption(setCheck);
    if (setCheck) {
      setOption([...option, { name: "Select Name", value: "" }]);
    } else {
      setOption([]);
      setShowOptionsData([]);
      setCountOption(optionCount);
      setMultiInputCount([""]);
      setMultiInputCountIndex(null);
      setShowDuplicateValues(false);
      setMultiDuplicateValues([]);
      setSelectedOptionIndex(null);
      setOptionNames(optionName);

      setUpdateOptionMultiValueIndex(null);
      setShowDuplicateUpdateValues(false);
      setMultiDuplicateUpdateValues([]);

      setVariants([]);
      setSelectedVariants([]);
      setDropDownValueChecked([]);
      setEditingVariantsPrices([]);
      setEditingVariantsCountry([]);
    }
  };
  const handleAddOptions = () => {
    if (showOptionsData.length == 0) {
      if (option.length == 1) {
        setOption([...option, { name: "Select Name", values: [""] }]);
      } else if (option.length == 2) {
        setOption([...option, { name: "Select Name", values: [""] }]);
      } else if (option.length == 0) {
        setOption([...option, { name: "Select Name", values: [""] }]);
      }
    } else {
      countOption.pop();
      setCountOption(countOption);
      if (countOption.length == 1) {
        setOption([...option, { name: "Select Name", values: [""] }]);
      } else if (countOption.length == 0) {
        setOption([...option, { name: "Select Name", values: [""] }]);
      }
    }

    let createdOptionNames = [];
    showOptionsData.map((obj) => createdOptionNames.push(obj.name));
    setOptionNames(optionName.filter((o) => !createdOptionNames.includes(o)));
  };

  const handleRemoveOptionsCard = (obj) => {
    if (showOptionsData.length == 0) {
      let optionLength = option.filter((o) => o !== obj);
      setOption(optionLength);
      if (optionLength.length == 0 && showOptionsData.length == 0) {
        setCheckedOption(false);
      }
    } else {
      let optionLength = option.filter((o) => o !== obj);
      setOption(optionLength);
      if (showOptionsData.some((s) => s === obj)) {
        setShowOptionsData(showOptionsData.filter((o) => o !== obj));
      }
      if (optionLength.length == 0 && showOptionsData.length == 0) {
        setCheckedOption(false);
      }
      setCountOption([...countOption, 1]);
    }
  };

  const handleOptionNameChange = (e) => {
    const { name, value } = e.target;
    if (showOptionsData.some((s) => s.name == value)) {
      toast.error("You already selected this option");
      return false;
    } else {
      setAddOption({ ...addOption, name: value });
    }
  };

  const handleOptionValuesChange = (e, inpt, ind, optionIndex) => {
    let multiVal = multiInputCount;

    if (e.target.value === " ") {
      toast.warn("Only spaces are not allowed.");
    } else {
      multiVal[ind] = e.target.value;
    }

    let findingDuplicate = multiVal.filter((v, i) =>
      v.length !== 0 ? multiVal.indexOf(v) !== i : null
    );

    if (findingDuplicate.length !== 0) {
      setShowDuplicateValues(true);
      setMultiDuplicateValues([...findingDuplicate]);

      setMultiInputCountIndex(ind);
    } else {
      setShowDuplicateValues(false);
      setMultiInputCountIndex(null);

      setMultiDuplicateValues([]);
    }

    setAddOption({
      ...addOption,
      values: [...multiVal],
    });
    setMultiInputCount(multiVal);
  };

  const handleSetDuplicates = (e, index, value) => {
    let int = setInterval(() => {
      let duplicate = multiInputCount.filter((v, i) =>
        v.length !== 0 ? multiInputCount.indexOf(v.trim()) !== i : null
      );

      if (duplicate.length !== 0) {
        let update = duplicate[0].trim().slice(0, -1);

        multiInputCount[index] = String(update);
        setMultiInputCount([...multiInputCount]);
        setAddOption({ ...addOption, values: [...multiInputCount] });
      } else {
        clearInterval(int);
        setShowDuplicateValues(false);
        setMultiDuplicateValues([]);
        setMultiInputCountIndex(null);
      }
    }, 0.1);
  };

  const handleAddInput = (optionIndex) => {
    setMultiInputCount([...multiInputCount, ""]);
  };

  const handleDeleteMultiInput = (inpt, ind, optionIndex) => {
    if (ind == 0) {
      setMultiInputCount(["1"]);
      setAddOption({ ...addOption, values: [multiInputCount[0]] });
      setMultiInputCount([multiInputCount[0]]);
    } else {
      multiInputCount.splice(ind, 1);
      setMultiInputCount([...multiInputCount]);

      setAddOption({ ...addOption, values: multiInputCount });
    }
  };

  const creatingVariants = (optionsData) => {
    let totalVariants = [];
    let arr1 = [];
    let arr2 = [];
    let arr3 = [];

    let allOptionsValues = optionsData.map((obj) => obj.values);

    allOptionsValues[0]?.map((first, index1) => {
      arr1.push({
        ...variantItem,
        option1: optionsData[0]?.name,
        title: String([first].join(" / ")),
      });
      arr1.map((obj, i) => (totalVariants[i] = obj));

      setVariants(totalVariants);

      allOptionsValues[1]?.map((second, index2) => {
        arr2.push({
          ...variantItem,
          option1: optionsData[0]?.name,
          option2: optionsData[1]?.name,
          title: String([first, second].join(" / ")),
        });
        arr2.map((obj, i) => (totalVariants[i] = obj));

        setVariants(totalVariants);

        allOptionsValues[2]?.map((third) => {
          arr3.push({
            ...variantItem,
            option1: optionsData[0]?.name,
            option2: optionsData[1]?.name,
            option3: optionsData[2]?.name,
            title: String([first, second, third].join(" / ")),
          });
          arr3.map((obj, i) => (totalVariants[i] = obj));
          setVariants(totalVariants);
        });
      });
    });
    toast.success(`${totalVariants.length} Variants Created`);
  };
  let filter;
  const handleCreateOption = (obj, optionIndex) => {
    if (addOption.name && addOption.values.length !== 0) {
      {
        showEditProduct
          ? (filter = {
              ...addOption,
              values: addOption.values.filter((f) => f !== ""),
            })
          : (filter = {
              ...addOption,
              product_id: shopifyProductId,
              values: addOption.values.filter((f) => f !== ""),
            });
      }

      let optionsData = [...showOptionsData, filter];

      setShowOptionsData(optionsData);

      setOption(option.filter((o) => o !== obj));
      setMultiInputCount([""]);
      setSelectedVariants([]);
      creatingVariants(optionsData);
      setAddOption(defaultOption);
      if (!showEditProduct) {
        addEditOption(
          shopifyProductId,
          products[0] && products[0].storeId,
          optionsData
        );
      }
    } else {
      toast.warn("Please fill all fields first.");
    }
  };

  const handleEditNewOptions = (data, index) => {
    setSelectedOptionIndex(index);
    setSelectedOptionData(data);

    let createdOptionNames = [];

    showOptionsData.map((obj) => createdOptionNames.push(obj.name));
    setOptionNames(optionName.filter((o) => !createdOptionNames.includes(o)));
  };

  const handleOnChangeUpdateNameOption = (e, index) => {
    const { name, value } = e.target;
    if (showOptionsData.some((s, ind) => s.name == value && ind !== index)) {
      toast.error("You already selected this option name.");
    } else {
      setSelectedOptionData({ ...selectedOptionData, name: value });
    }
  };

  const handleAddInputUpdateOption = () => {
    let defaultArray = selectedOptionData.values;

    setSelectedOptionData({
      ...selectedOptionData,
      values: [...defaultArray, ""],
    });
  };

  const handleOnchangeMultiValuesUpdateOption = (e, index, value) => {
    let updateMultiValues = selectedOptionData.values;

    if (e.target.value === " ") {
      toast.warn("Only spaces are not allowed.");
    } else {
      updateMultiValues[index] = e.target.value;
    }

    let duplicates = updateMultiValues.filter((v, i) =>
      v.length !== 0 ? updateMultiValues.indexOf(v) !== i : null
    );

    if (duplicates.length !== 0) {
      setShowDuplicateUpdateValues(true);
      setUpdateOptionMultiValueIndex(index);
      setMultiDuplicateUpdateValues([...duplicates]);
    } else {
      setShowDuplicateUpdateValues(false);
      setUpdateOptionMultiValueIndex(null);
      setMultiDuplicateUpdateValues([]);
    }

    setSelectedOptionData({
      ...selectedOptionData,
      values: [...updateMultiValues],
    });
  };

  const handleSetDuplicatesUpdate = (e, index, value) => {
    let updateMultiValues = selectedOptionData.values;
    let intUpdate = setInterval(() => {
      let duplicate = updateMultiValues.filter((v, i) =>
        v.length !== 0 ? updateMultiValues.indexOf(v.trimEnd()) !== i : null
      );

      if (duplicate.length !== 0) {
        let update = duplicate[0].trim().slice(0, -1);

        updateMultiValues[index] = update;
        setSelectedOptionData({
          ...selectedOptionData,
          values: [...updateMultiValues],
        });
      } else {
        clearInterval(intUpdate);
        setShowDuplicateUpdateValues(false);
        setUpdateOptionMultiValueIndex(null);
        setMultiDuplicateUpdateValues([]);
      }
    }, 0.1);
  };

  const handleDeleteMultiInputUpdateOption = (inpt, index) => {
    if (index === 0) {
      setSelectedOptionData({
        ...selectedOptionData,
        values: [selectedOptionData.values[""]],
      });
    } else {
      selectedOptionData.values.splice(index, 1);
      setSelectedOptionData({
        ...selectedOptionData,
        values: [...selectedOptionData.values],
      });
    }
  };

  const handleUpdateOption = (obj, index) => {
    if (selectedOptionData.name && selectedOptionData.values[0].length !== 0) {
      {
        showEditProduct
          ? (filter = {
              ...selectedOptionData,
              product_id: shopifyProductId,
              values: selectedOptionData.values.filter((f) => f !== ""),
            })
          : (filter = {
              ...selectedOptionData,
              values: selectedOptionData.values.filter((f) => f !== ""),
            });
      }

      let updatedOptions = showOptionsData;

      updatedOptions[index] = filter;
      setShowOptionsData(updatedOptions);
      setSelectedOptionData(filter);
      setSelectedOptionIndex(null);
      setSelectedVariants([]);
      creatingVariants(updatedOptions);
      if (!showEditProduct) {
        addEditOption(
          shopifyProductId,
          products[0] && products[0].storeId,
          updatedOptions
        );
      }

      let createdOptionNames = [];

      updatedOptions.map((obj) => createdOptionNames.push(obj.name));
      setOptionNames(optionName.filter((o) => !createdOptionNames.includes(o)));
    } else {
      toast.warn("All fields are required");
    }
  };

  const handleDeleteEditingOption = (obj, index) => {
    showOptionsData.splice(index, 1);
    setShowOptionsData([...showOptionsData]);
    setCountOption([...countOption, 1]);
    setSelectedOptionIndex(undefined);
    setSelectedVariants([]);
    if (showOptionsData.length !== 0) {
      creatingVariants(showOptionsData);
    } else {
      setVariants([]);
      setOptionNames(optionName);
    }
  };

  const handleSingleCheckVariant = (e, variant) => {
    let checked = variants.filter((v) => {
      if (v === variant) {
        v.selected = e.target.checked;
        if (v.option2 == null && v.option3 == null) {
          if (v.selected) {
            dropDownValueChecked.push(v.title);
          } else {
            setDropDownValueChecked(
              dropDownValueChecked.filter((val) => val !== v.title)
            );
          }
        }
      }
      return v;
    });

    setSelectedVariants(checked.filter((v) => v.selected));
  };

  const handleVariantRowClick = (v) => {
    let fltr = variants.filter((ob) => {
      if (ob === v) {
        ob.selected = !ob.selected;
        if (ob.option2 == null && ob.option3 == null) {
          if (ob.selected) {
            dropDownValueChecked.push(ob.title);
          } else {
            setDropDownValueChecked(
              dropDownValueChecked.filter((val) => val !== ob.title)
            );
          }
        }
      }
      return ob;
    });

    setSelectedVariants(fltr.filter((v) => v.selected));
  };

  const handleMasterCheckVariants = (e, v, ind) => {
    variants.filter((v) => (v.selected = true));
    setSelectedVariants(variants);
    if (variants[0].option2 == null && variants[0].option3 == null) {
      variants.map((va) => dropDownValueChecked.push(va.title));
    }
  };

  const handleMasterUncheckVariants = () => {
    variants.map((v) => (v.selected = false));
    setSelectedVariants([]);
    setDropDownValueChecked([]);
  };

  const handleValuesDropDownCheck = (e, value, index) => {
    if (e.target.checked) {
      setDropDownValueChecked([value, ...dropDownValueChecked]);
    } else {
      setDropDownValueChecked(dropDownValueChecked.filter((v) => v !== value));
    }

    let filtered = variants.filter((va, i) => {
      if (va.title.includes(value)) {
        va.selected = e.target.checked;
      }
      return va;
    });

    setSelectedVariants(filtered.filter((v) => v.selected));
  };

  const handleDeleteVariant = (variant) => {
    setVariants(variants.filter((v) => v !== variant));
    setSelectedVariants(selectedVariants.filter((v) => v !== variant));
    toast.info(`Variant (${variant.title}) deleted.`);
  };

  const handleDeleteSelectedVariants = () => {
    if (variants[0].option2 !== null || variants[0].option3 !== null) {
      if (
        window.confirm(
          `Are you sure want to delete wariants ( ${selectedVariants.map(
            (v) => v.title + " & "
          )} )`
        )
      ) {
        setVariants(variants.filter((v) => !selectedVariants.includes(v)));
        toast.info(
          `Variants (${selectedVariants.map((v) => v.title + " & ")})`
        );
        setSelectedVariants([]);
        setDropDownValueChecked([]);
      }
    } else {
      setVariants(variants.filter((v) => !selectedVariants.includes(v)));
      setSelectedVariants([]);
      setDropDownValueChecked([]);
    }
  };

  const handleChangeVariantFields = (e, index) => {
    let { name, value } = e.target;
    setVariants(
      variants.filter((va, ind) => {
        if (ind === index) {
          va[name] = value;
        }
        return va;
      })
    );
  };

  const handleChangeEditVariant = (e) => {
    let { name, value } = e.target;

    setEditingVariant({ ...editingVariant, [name]: value });
  };

  const handleChanglePriceToAll = (e) => {
    setPriceToAll(e.target.value);
  };

  const handleChangeSinglePrice = (e, index) => {
    let updatePrice = editingVariantsPrices.filter((va, ind) => {
      if (ind == index) {
        va.price = e.target.value;
      }
      return va;
    });
    setEditingVariantsPrices(updatePrice);
  };

  const handleChangleCountryToAll = (e) => {
    setCountryToAll(e.target.value);
  };

  const handleChangeSingleCountry = (e, index) => {
    setEditingVariantsCountry(
      editingVariantsCountry.filter((vari, ind) => {
        if (ind == index) {
          vari.country = e.target.value;
        }
        return vari;
      })
    );
  };

  let seoShow = () => {
    setSeo(true);
  };

  const handleSalesChannelCheck = () => {
    if (onlineStoreCheckbox && userStoreCheckbox) {
      setOnlineStoreCheckbox(false);
      setUserStoreCheckbox(false);
      setProductData({ ...productData, onlineStore: false, userStore: false });
    } else {
      setOnlineStoreCheckbox(true);
      setUserStoreCheckbox(true);
      setProductData({ ...productData, userStore: true, onlineStore: true });
    }
  };

  const handleOnlineStore = () => {
    setOnlineStoreCheckbox((c1) => !c1);
  };

  const handleUserStore = () => {
    setUserStoreCheckbox((c2) => !c2);
  };

  const Option = (props) => (
    <components.Option {...props}>
      <div className=" d-flex rounded-2 " style={{ height: "23px" }}>
        <input
          type="checkbox"
          className="me-2 mycheckbox "
          checked={props.isSelected}
          onChange={() => null}
        />
        <Label className="pointer mt-1">{props.label}</Label>
      </div>
    </components.Option>
  );

  const collectionSelectedStyle = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#E7F2F8" : null,
        cursor: "pointer",
        color: "black",
      };
    },

    control: (base) => ({
      ...base,
      "&:hover": {
        cursor: "text",
      },
    }),
  };

  const handleGetCollectionValues = (v) => {
    setCollectionValues(v);
    let array = v.map((v) => v.label);
    // setProductData({ ...productData, handle: String(array) });
  };

  const handleTagsPopup = () => setTagsPopup(!tagsPopup);

  const handleDeleteSelectedValues = (e) => {
    setCollectionValues(collectionValues.filter((val) => val.label !== e));
    setProductData({
      ...productData,
      collections: collectionValues
        .filter((c) => c.label !== e)
        .map((v) => v.label),
    });
  };

  const createLabel = (e) => {
    return (
      <div
        className="d-flex"
        style={{ marginLeft: "-24px", marginTop: "-5px" }}
      >
        <i
          className="fas fa-plus-circle mr-1"
          style={{ color: "gray", marginTop: "4px", fontSize: "17.8px" }}
        ></i>
        <b className=" me-2 mt-1" style={{ fontSize: "15px" }}>
          Add{" "}
        </b>
        <p className="mt-1">{e}</p>
      </div>
    );
  };

  const handleCreateCustomTags = (t) => {
    if (tag.some((obj) => obj.label === t.trim())) {
      if (!selectedTags.includes(t.trim())) {
        let set = [...selectedTags, t.trim()];
        setSelectedTags(set);
        setTags(tags.filter((obj) => obj.label !== t));
      }
    } else {
      if (!selectedTags.includes(t.trim())) {
        setSelectedTags([...selectedTags, t]);
      }
    }
  };

  const handleUnCheckTags = (val) => {
    if (tag.some((t) => t.label == val)) {
      setTags([{ label: val, value: val }, ...tags]);
    }
    let updateChecked = checkedTags.filter((v) => v !== val);
    setCheckedTags(updateChecked);
  };

  const handleDeleteTags = (val) => {
    if (tag.some((t) => t.label == val)) {
      setTags([{ label: val, value: val }, ...tags]);
    }
    if (checkedTags.length !== 0) {
      let updateChecked = checkedTags.filter((v) => v !== val);
      setCheckedTags(updateChecked);
    }
    let updateNew = newTags.filter((v) => v !== val);
    setNewTags(updateNew);

    setSelectedTags(selectedTags.filter((v) => v !== val));
  };

  const handleSearchTags = (e) => {
    let valueInput = String(e.target.value);

    setSearchTagValue(valueInput);

    let filtering = tag.filter((t) => {
      if (valueInput === "") {
        return !checkedTags.includes(t.label);
      } else if (t.label.toLowerCase().includes(valueInput.toLowerCase())) {
        if (checkedTags.includes(t.label)) {
          return null;
        } else {
          return t;
        }
      }
    });
    setTags(filtering);
  };

  const handleSortingTags = (e) => {
    if (e.target.value === "Alphabetical") {
      let sorted = tags.sort((a, b) => a.label.localeCompare(b.label));
      setTags([...sorted]);
    } else {
      toast.warn("backend server waiting.");
    }
  };

  const hendleCreateNewTag = (v) => {
    if (tag.some((t) => t.label === searchTagValue.trim())) {
      if (checkedTags.includes(searchTagValue.trim())) {
        setTags(tag.filter((t) => !checkedTags.includes(t.label)));
      } else {
        let setNew = [searchTagValue.trim(), ...checkedTags];
        setCheckedTags(setNew);
        setTags(tag.filter((t) => !setNew.includes(t.label)));
      }
    } else {
      if (newTags.length !== 0) {
        if (newTags.some((v) => v === searchTagValue.trim())) {
          setTags(tag.filter((t) => !checkedTags.includes(t.label)));
        } else {
          let setNew = [searchTagValue.trim(), ...newTags];
          setNewTags(setNew);
        }
      } else {
        let setNew = [searchTagValue.trim(), ...newTags];
        setNewTags(setNew);
      }
      setTags(tag.filter((t) => !checkedTags.includes(t.label)));
    }
    setSearchTagValue("");
  };

  const handleDeleteNewTags = (t) => {
    let update = newTags.filter((v) => v !== t);
    setNewTags(update);
  };

  const handleCheckedTags = (val) => {
    if (val) {
      if (!checkedTags.includes(val.label)) {
        setCheckedTags([...checkedTags, val.label]);
        setTags(tags.filter((v) => v.label !== val.label));
      }
    }
  };

  const handleSetValues = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setProductData({
        ...productData,
        [name]: checked,
      });
    } else {
      setProductData({
        ...productData,
        [name]: value,
      });
    }
  };

  const handleSubmitProductData = async (e) => {
    e.preventDefault();
    if (!productData.body_html) {
      toast.warning("Please add description first.");
      return false;
    }
    let finalProduct;
    let filterImg = images.filter((obj) => {
      delete obj.selected;

      // obj.name = obj.file.name;
      delete obj.data_url;
      delete obj.file;
      return obj;
    });
    // let filterVariants = variants.filter((ob) => delete ob.selected);

    finalProduct = {
      ...productData,
      images: filterImg.filter((img) => img !== filterImg[0]),
      image: filterImg[0],
      hasVariants: false,
      variants: [],
      options: [],
      tags: String(selectedTags),
    };
    setProductData(finalProduct);

    // let finalProductData = JSON.parse(JSON.stringify(finalProduct));

    // const { data } = await addProduct({
    //   ...finalProduct,
    //   storeId,
    // });
    // if (data === `Product Added Successfully !`) {
    //   toast.success(data);
    // } else {
    toast.warning("API is Paused for Add Product");
    // }
    // setProductData({});
    // console.log({ data });
  };

  const handleUpdateProduct = (e) => {
    e.preventDefault();
    updateProducts({
      ...productData,
      shopifyProductId,
      storeId: products[0] && products[0].storeId,
    });
  };

  // if (products) {
  useEffect(() => {
    if (!showEditProduct) {
      if (products[0]?.image || products[0]?.images.length !== 0) {
        let apiImages = [products[0]?.image];

        products[0]?.images.map((obj, i) => (apiImages[i + 1] = obj));

        setImages([...apiImages]);
      } else {
        setImages([]);
      }
      setShowOptionsData(products[0]?.attributes);
      setVariants(products[0]?.variants);
      setDescription(description2 && description2);
    }
  }, [products]);
  // }

  return (
    <>
      {loading ? (
        <div className="loading" />
      ) : (
        <main className="container">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // showEditProduct
              //   ? handleSubmitProductData(e)
              //   : handleUpdateProduct(e);
            }}
          >
            <Row>
              <Col xxs="12">
                {showEditProduct ? (
                  <>
                    <h1>Add Product</h1>
                    <div style={{ display: "inline" }}>
                      <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block  d-lg-inline-block">
                        <ol className="breadcrumb pt-0">
                          <li className="breadcrumb-item">
                            <Link
                              to="/admin/dashboard"
                              style={{ color: "black" }}
                            >
                              Home
                            </Link>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Shopilam Add Product
                          </li>
                        </ol>
                      </Breadcrumb>
                    </div>
                  </>
                ) : (
                  <>
                    <h1> Product Detail </h1>
                    <div style={{ display: "inline" }}>
                      <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block  d-lg-inline-block">
                        <ol className="breadcrumb pt-0">
                          <li className="breadcrumb-item">
                            <Link
                              to="/admin/dashboard"
                              style={{ color: "black" }}
                            >
                              Home
                            </Link>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Shopilam Product Detail
                          </li>
                        </ol>
                      </Breadcrumb>
                    </div>
                  </>
                )}
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <Card className="mb-4">
                  <CardBody className="px-4">
                    <CardTitle className="mb-0">
                      <h5>
                        <strong>Title</strong>
                      </h5>
                    </CardTitle>
                    <input
                      required
                      className="form-control in"
                      name="title"
                      placeholder="Short sleeve t-shirt"
                      defaultValue={showEditProduct ? null : products[0]?.title}
                      onChange={(e) => {
                        handleSetValues(e);
                      }}
                    />
                    <h5 className="mt-4">
                      <strong>Description</strong>
                    </h5>

                    <Editor
                      onChange={handleEditorValue}
                      editorState={description}
                      toolbarClassName="bg-light rounded-2"
                      wrapperClassName="my-2 rounded-2"
                      wrapperStyle={{
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                      }}
                      editorClassName="form-control editorStyle border-0"
                      onEditorStateChange={onEditorStateChange}
                    />
                  </CardBody>
                </Card>
                <Card className="mb-4">
                  <CardBody className="px-4">
                    {selectedImages.length > 0 ? (
                      <div className="d-flex justify-content-between">
                        <div className="ms-4 d-flex">
                          {selectedImages.length > 0 &&
                          images.length !== selectedImages.length ? (
                            <span
                              className=" outerBox pointer"
                              onClick={handleMasterCheckImages}
                              style={{ marginLeft: "-19px" }}
                            >
                              <span className="innerBox"></span>
                            </span>
                          ) : (
                            <Input
                              type="checkbox"
                              checked
                              onClick={handleMasterUncheckImages}
                              className="mycheckbox"
                            />
                          )}

                          <b
                            className=" text-black ms-2"
                            style={{ fontSize: "15px" }}
                          >
                            {selectedImages.length} files selected
                          </b>
                        </div>
                        <h6
                          color="white"
                          className="text-danger pointer"
                          onClick={handleDeleteSelectedImages}
                        >
                          Delete file
                        </h6>
                      </div>
                    ) : (
                      <h5>
                        <strong>Media</strong>
                      </h5>
                    )}
                    <ImageUploading
                      multiple
                      value={images}
                      acceptType={acceptType}
                      maxFileSize={5000000}
                      onChange={onImageChange}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                    >
                      {({ imageList, onImageUpload, errors }) => (
                        <>
                          <div>
                            <div className="set-main mb-1">
                              {images.map((image, index) => (
                                <>
                                  <div
                                    key={index}
                                    className="image-item customScroll"
                                    style={
                                      images?.length !== 0
                                        ? index == 0
                                          ? { width: "270px", height: "270px" }
                                          : null
                                        : null
                                    }
                                  >
                                    <div className="imgDiv">
                                      <Input
                                        type="checkbox"
                                        checked={image?.selected}
                                        onChange={(e) =>
                                          handleSelectImage(image, index, e)
                                        }
                                        className={
                                          image?.selected
                                            ? `mycheckbox imgBoxSelected`
                                            : `mycheckbox imgBox`
                                        }
                                      />

                                      <img
                                        src={image?.src}
                                        width="100%"
                                        height={"100%"}
                                        className={
                                          image?.selected
                                            ? `imageSelected`
                                            : `image`
                                        }
                                      />
                                    </div>
                                  </div>
                                </>
                              ))}
                              {images.length !== 0 ? (
                                <div
                                  className=" text-center mediaAlign m-1"
                                  onClick={() => ref.current.click()}
                                >
                                  <div className="mt-5">
                                    <Label
                                      className="fileinputAlign"
                                      // onClick={() => ref.current.click()}
                                    >
                                      Add File
                                    </Label>
                                  </div>

                                  <p
                                    style={{ fontSize: "12px" }}
                                    className="mx-1"
                                  >
                                    Accepts images, videos, or 3D Modals
                                  </p>
                                </div>
                              ) : null}
                            </div>
                            <span
                              onClick={onImageUpload}
                              ref={ref}
                              hidden
                            ></span>
                          </div>
                          {errors && (
                            <div style={{ color: "red" }}>
                              {errors.maxNumber && (
                                <span>Number of selected images exceeded</span>
                              )}
                              {errors.acceptType && (
                                <span>
                                  Your selected file type is not allowed
                                </span>
                              )}
                              {errors.maxFileSize && (
                                <span>Selected file size exceeded</span>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </ImageUploading>
                    {images.length === 0 ? (
                      <div
                        className=" text-center mediaaa my-3"
                        onClick={() => ref.current.click()}
                      >
                        <div className="mt-5">
                          <Label
                            className="fileinput"
                            // onClick={() => ref.current.click()}
                          >
                            Add File
                          </Label>
                        </div>
                        <p>Accepts images, videos, or 3D Modals</p>
                      </div>
                    ) : null}
                  </CardBody>
                </Card>
                {showEditProduct && (
                  <div>
                    <Card className="mb-4">
                      <CardBody className="p-4">
                        <div className="">
                          <strong className="fs-6">Pricing</strong>
                          <div className="row mt-4">
                            <div className="col-lg-6">
                              <p className="form-label mb-1">Price</p>
                              <Input
                                required
                                min={"0.0"}
                                name="price"
                                onChange={handleSetValues}
                                placeholder="PKR 0.00"
                                type="number"
                                className=" rounded-3"
                              />
                            </div>
                            <div className="col-lg-6 mt-lg-0 mt-3">
                              <p className="form-label mb-1 text-black">
                                Compare at Price
                              </p>
                              <Input
                                // required
                                onChange={handleSetValues}
                                name="compare_at_price"
                                placeholder="PKR 0.00"
                                min={"0.0"}
                                type="number"
                                className=" rounded-3"
                                style={{ position: "relative" }}
                              />

                              <i
                                id="inputHelp"
                                className="fa fa-question-circle helpIcon pointer"
                              ></i>
                              <Tooltip
                                flip
                                isOpen={inputTooltip}
                                placement={"bottom"}
                                autohide={false}
                                target="inputHelp"
                                toggle={() => setInputTooltip((t) => !t)}
                              >
                                To show a reduced price, move the product's
                                original price into Compare at price. Enter a
                                lower value into Price.
                              </Tooltip>
                            </div>
                            <div className="my-3 ms-4 form-check">
                              <Input
                                // onChange={handleSetValues}
                                name="chargeTaxOnProduct"
                                type="checkbox"
                                className="form-check-input mycheckbox"
                                id="exampleCheck1"
                              />
                              <p className="form-check-label">
                                Charge Tax on the product
                              </p>
                            </div>
                          </div>
                          <hr className="addProductHr" />
                          <div className="mt-4">
                            <p className="form-label mb-1 text-black">
                              Cost per item
                            </p>
                            <Input
                              // required
                              // onChange={handleSetValues}
                              name="costPerItem"
                              type="number"
                              min={"0.0"}
                              className=" rounded-3"
                              placeholder="PKR 0.00"
                            />
                            <p id="emailHelp" className="mt-1">
                              Customers won't see this.
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <Card className="mb-4">
                      <CardBody className="p-4">
                        <div className="">
                          <strong className="fs-6">Inventory</strong>

                          <div className="row mt-4 ">
                            <div className="col-lg-6">
                              <p className="form-label mb-1 text-black">
                                SKU (stock keeping unit)
                              </p>
                              <Input
                                required
                                type="text"
                                name="sku"
                                onChange={handleSetValues}
                                className=" rounded-3"
                              />
                            </div>
                            <div className="col-lg-6 mt-lg-0 mt-3">
                              <p className="form-label mb-1 text-black">
                                Barcode (ISBN, UPC, GTIN, etc.)
                              </p>
                              <Input
                                required
                                type="number"
                                name="barcode"
                                // onChange={handleSetValues}
                                className=" rounded-3"
                              />
                            </div>
                            <div className="my-3 ms-4 form-check">
                              <Input
                                name="trackQuantity"
                                type="checkbox"
                                className="form-check-input mycheckbox"
                                id="exampleCheck1"
                                checked={trackQuantityCheck}
                                onChange={(e) => {
                                  handleTrackCheck();
                                  // handleSetValues(e);
                                }}
                              />
                              <p className="form-check-label">Track Quantity</p>
                            </div>
                            {trackQuantityCheck ? (
                              <div className="mb-3 ms-4 form-check">
                                <Input
                                  // onChange={handleSetValues}
                                  name="continueSellingWhenOutOfStock"
                                  type="checkbox"
                                  className="form-check-input mycheckbox"
                                  id="exampleCheck1"
                                />
                                <p className="form-check-label">
                                  Continue selling when out of stock.
                                </p>
                              </div>
                            ) : null}
                          </div>
                          <hr className="addProductHr" />
                          <div className="mt-4">
                            <CardTitle className="order_table_card_title">
                              <h6>Quantity</h6>
                            </CardTitle>
                            <CardTitle className="order_table_card_title">
                              <Label className="form-label">
                                <strong>Location Name</strong>
                              </Label>
                              <Label className="form-label me-5">
                                <strong>Available</strong>
                              </Label>
                            </CardTitle>
                            <hr className="addProductHr" />
                            <CardTitle className="order_table_card_title">
                              <p className="form-label text-black">
                                Regent Mall
                              </p>
                              <Input
                                // required
                                min={1}
                                // onChange={handleSetValues}
                                name="quantity"
                                type="number"
                                className=" rounded-3 col-5"
                              />
                            </CardTitle>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <Card className="mb-4">
                      <CardBody className="p-4">
                        <div className="">
                          <strong className="fs-6">Shipping</strong>

                          <div className="my-4 ms-2 form-check">
                            <Input
                              name="physicalProduct"
                              type="checkbox"
                              className="form-check-input mycheckbox"
                              id="exampleCheck1"
                              onChange={(e) => {
                                handleShippingCheck();
                                // handleSetValues(e);
                              }}
                            />
                            <p className="form-check-label">
                              This is a physical product
                            </p>
                          </div>
                          <hr className="addProductHr" />
                          {shippingCheck ? (
                            <div className="mt-4">
                              <strong>WEIGHT</strong>

                              <p className="mt-2">
                                Used to calculate shiping rate at checkout and
                                label price during fulfilment.
                              </p>
                              <p className="form-label mb-1 text-black">
                                Weight
                              </p>
                              <div className="d-flex">
                                <Input
                                  required
                                  onChange={handleSetValues}
                                  name="weight"
                                  type="number"
                                  className="rounded-3 w-25 me-1"
                                  min={"0.0"}
                                  step="0.1"
                                  placeholder="0.0"
                                />{" "}
                                <Input
                                  required
                                  onChange={handleSetValues}
                                  type="select"
                                  name="weight_unit"
                                  className="rounded-3"
                                  style={{ width: "45px" }}
                                >
                                  <option value="kg">kg</option>
                                  <option value="g">g</option>
                                  <option value="lb">lb</option>
                                </Input>
                              </div>
                              <hr className="addProductHr" />
                              <div className="mt-4">
                                <strong>CUSTOMS INFORMATION</strong>

                                <p className="mt-2">
                                  Custom Authorities use this information to
                                  calculate duties when shipping internationaly.
                                  <br />
                                  Shown on printed customs forms.
                                </p>
                                <div className="mb-3">
                                  <p className="form-label mb-1 text-black">
                                    Country / Region of origin
                                  </p>
                                  <Input
                                    // required
                                    // onChange={handleSetValues}
                                    className=" rounded-3"
                                    name="countryRegion"
                                    type="select"
                                  >
                                    <option disabled selected>
                                      Select country / region
                                    </option>
                                    <option value="Pakistan">Pakistan</option>
                                    <option value="India">India</option>
                                    <option value="China">China</option>
                                    <option value="Afghanistan">
                                      Afghanistan
                                    </option>
                                  </Input>
                                  <p id="emailHelp" className="mt-1">
                                    In most cases, where the product is
                                    manufactured.
                                  </p>
                                </div>
                                <div className="mb-3">
                                  <p className="form-label mb-1 text-black">
                                    HS (Hermonized System)code
                                  </p>
                                  <Input
                                    // required
                                    // onChange={handleSetValues}
                                    name="HS"
                                    type="search"
                                    className=" rounded-3"
                                    id="search"
                                    placeholder="Search"
                                  />
                                  <p id="emailHelp" className="mt-1">
                                    We'll never share your email with anyone
                                    else.
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <p>
                              Customer won't enter their shipping address or
                              choose a shipping method when buying the product
                            </p>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                )}
                <Card className="mb-4 px-4">
                  <CardTitle className="mt-4">
                    <h5>
                      <strong>Options</strong>
                    </h5>
                    {showEditProduct && (
                      <div className="mb-2 ms-2 form-check">
                        <Input
                          name="hasVariants"
                          type="checkbox"
                          className="form-check-input mycheckbox"
                          id="exampleCheck1"
                          checked={checkedOption}
                          onChange={(e) => {
                            handleOption();
                            // handleSetValues(e);
                          }}
                        />
                        <p className="form-check-label OptionHeading">
                          This product has options Like size, color
                        </p>
                      </div>
                    )}
                  </CardTitle>
                  <div>
                    {showOptionsData.map((data, ind) => (
                      <div key={ind}>
                        <hr className="addProductHr" />
                        {selectedOptionIndex == ind ? (
                          <div>
                            <CardBody className="px-4">
                              <p className="form-label mb-1 text-black">
                                Option name
                              </p>

                              <div className="d-flex">
                                <Input
                                  type="select"
                                  required
                                  name="name"
                                  className="form-control col-11"
                                  onChange={(e) =>
                                    handleOnChangeUpdateNameOption(e, ind)
                                  }
                                >
                                  <option
                                    defaultValue={selectedOptionData.name}
                                    hidden
                                  >
                                    {selectedOptionData.name}
                                  </option>
                                  {optionNames.map((name, i) => (
                                    <option value={name} key={i}>
                                      {name}
                                    </option>
                                  ))}
                                </Input>
                                <span
                                  className="far fa-trash-alt text-black col-1 mt-2 fs-5 pointer"
                                  onClick={() => {
                                    showEditProduct
                                      ? handleDeleteEditingOption(data, ind)
                                      : handleRemoveOptions(
                                          shopifyProductId,
                                          data.name,
                                          products[0].storeId
                                        );
                                  }}
                                ></span>
                              </div>
                              <br />
                              <p className="form-label mb-1 text-black">
                                Option values
                              </p>
                              {selectedOptionData.values.map((inpt, ind) => (
                                <>
                                  <div className="d-flex" key={ind}>
                                    <Input
                                      style={
                                        multiDuplicateUpdateValues.some(
                                          (v) => v.trim() === inpt.trim()
                                        )
                                          ? { border: "1px solid red" }
                                          : null
                                      }
                                      onChange={(e) =>
                                        handleOnchangeMultiValuesUpdateOption(
                                          e,
                                          ind,
                                          inpt
                                        )
                                      }
                                      onBlur={(e) =>
                                        handleSetDuplicatesUpdate(e, ind, inpt)
                                      }
                                      name="values"
                                      value={inpt}
                                      className=" in col-11 mb-1"
                                      placeholder={
                                        selectedOptionData.values.length == 1
                                          ? "Enter values"
                                          : ind === 0
                                          ? "Enter values"
                                          : "Add another value"
                                      }
                                    />
                                    {selectedOptionData.values.length > 1 ? (
                                      <span
                                        className="far fa-trash-alt text-black col-1 mt-2 fs-6 pointer"
                                        onClick={(e) => {
                                          showEditProduct
                                            ? handleDeleteMultiInputUpdateOption(
                                                inpt,
                                                ind
                                              )
                                            : handleRemoveOptionValues(
                                                shopifyProductId,
                                                data.name,
                                                e.target.previousElementSibling
                                                  .value,
                                                products[0] &&
                                                  products[0].storeId
                                              );
                                        }}
                                      ></span>
                                    ) : null}
                                  </div>
                                  {updateOptionMultiValueIndex === ind ? (
                                    <p className="text-danger">
                                      <b
                                        className=" bg-danger rounded-circle text-white mx-1"
                                        style={{
                                          padding: "0px 7px",
                                          fontSize: "12px",
                                        }}
                                      >
                                        &#33;
                                      </b>{" "}
                                      You've already used option value "{inpt}"
                                    </p>
                                  ) : null}
                                </>
                              ))}

                              <p
                                className="d-flex w-50 pointer text-black mt-1"
                                onClick={handleAddInputUpdateOption}
                              >
                                {" "}
                                <span className="iconsminds-add mr-1 fs-6"></span>{" "}
                                Add another values
                              </p>

                              <Button
                                disabled={showDuplicateUpdateValues}
                                type="button"
                                color="primary"
                                className=" rounded mt-1 shadow btn btn-primary"
                                onClick={() => handleUpdateOption(data, ind)}
                              >
                                Done
                              </Button>
                            </CardBody>
                          </div>
                        ) : (
                          <CardBody className="px-4 mb-2" key={ind}>
                            <div className="d-flex justify-content-between">
                              <h5>
                                <strong>{data.name}</strong>
                              </h5>
                              <Button
                                color="primary"
                                onClick={() => handleEditNewOptions(data, ind)}
                                type="button"
                                className="rounded px-3 py-0 btn btn-primary"
                              >
                                Edit
                              </Button>
                            </div>
                            <div className="d-flex  flex-wrap">
                              {data.values.map((val, i) => (
                                <b
                                  key={i}
                                  className="py-1 px-2 mt-1 ms-1 rounded-pill"
                                  style={{ background: "#dee0e6" }}
                                >
                                  {val}
                                </b>
                              ))}
                            </div>
                          </CardBody>
                        )}
                        {option.length == 0 ? (
                          <hr className="addProductHr" />
                        ) : null}
                      </div>
                    ))}
                  </div>

                  {option.map((elem, optionIndex) => (
                    <div key={optionIndex}>
                      <hr className="addProductHr" />

                      <CardBody className="px-4">
                        <p className="form-label mb-1 text-black">
                          Option name
                        </p>

                        <div>
                          <div className="d-flex">
                            <Input
                              type="select"
                              required
                              name="name"
                              className="form-control col-11"
                              onChange={(e) =>
                                handleOptionNameChange(e, optionIndex)
                              }
                            >
                              <option selected disabled>
                                {elem.name}
                              </option>
                              {optionNames.map((option, index) => (
                                <option value={option} key={index}>
                                  {option}
                                </option>
                              ))}
                            </Input>
                            <span
                              className="far fa-trash-alt text-black col-1 mt-2 fs-5 pointer"
                              onClick={() => handleRemoveOptionsCard(elem)}
                            ></span>
                          </div>
                          <br />
                          <p className="form-label mb-1 text-black">
                            Option values
                          </p>

                          {multiInputCount.map((inpt, ind) => (
                            <>
                              <div className="d-flex" key={ind}>
                                <Input
                                  style={
                                    multiDuplicateValues.some(
                                      (v) => v.trim() === inpt.trim()
                                    )
                                      ? {
                                          border: "1px solid red",
                                          background: "#FFE7BF",
                                        }
                                      : null
                                  }
                                  onChange={(e) =>
                                    handleOptionValuesChange(
                                      e,
                                      inpt,
                                      ind,
                                      optionIndex
                                    )
                                  }
                                  name="values"
                                  value={inpt}
                                  onBlur={(e) =>
                                    handleSetDuplicates(e, ind, inpt)
                                  }
                                  className=" in col-11 mb-1"
                                  placeholder={
                                    multiInputCount.length == 1
                                      ? "Enter values"
                                      : ind !== 0
                                      ? "Add another value"
                                      : "Enter values"
                                  }
                                />

                                {multiInputCount.length > 1 ? (
                                  <span
                                    className="far fa-trash-alt col-1 mt-2 fs-6 text-black pointer"
                                    onClick={() =>
                                      handleDeleteMultiInput(
                                        inpt,
                                        ind,
                                        optionIndex
                                      )
                                    }
                                  ></span>
                                ) : null}
                              </div>
                              {multiInputCountIndex === ind ? (
                                <p className="text-danger">
                                  <b
                                    className=" bg-danger rounded-circle text-white mx-1"
                                    style={{
                                      padding: "0px 7px",
                                      fontSize: "12px",
                                    }}
                                  >
                                    &#33;
                                  </b>{" "}
                                  You've already used option value "{inpt}"
                                </p>
                              ) : null}
                            </>
                          ))}

                          <p
                            className="d-flex w-50 pointer text-black mt-1"
                            onClick={() => handleAddInput(optionIndex)}
                          >
                            {" "}
                            <span className="iconsminds-add mr-1 fs-6"></span>{" "}
                            Add another values
                          </p>

                          {/* //////////////////////////////// handleCreateOption ///////////////////// */}

                          <Button
                            disabled={showDuplicateValues}
                            color="primary"
                            type="button"
                            className=" rounded my-2 shadow btn btn-primary"
                            onClick={() => {
                              handleCreateOption(elem, optionIndex);
                            }}
                          >
                            Done
                          </Button>
                        </div>
                      </CardBody>
                    </div>
                  ))}

                  {!checkedOption ? null : (
                    <>
                      {option.length == 3 ||
                      countOption.length == 0 ||
                      showOptionsData.length == 3 ||
                      option.length !== 0 ? null : (
                        <div className="d-flex mb-3 ms-3 pointer text-black align-items-center">
                          <span
                            onClick={handleAddOptions}
                            className="iconsminds-add fs-5"
                          ></span>
                          <b
                            className="ms-1"
                            style={{ fontSize: "15px" }}
                            onClick={handleAddOptions}
                          >
                            Add another option
                          </b>
                        </div>
                      )}
                    </>
                  )}
                  {showOptionsData.length === 3 ||
                    (!showEditProduct && (
                      <div className="d-flex mb-3 ms-3 pointer text-color">
                        <span
                          onClick={handleAddOptions}
                          className="iconsminds-add fs-5"
                        ></span>
                        <b
                          style={{ fontSize: "15px" }}
                          className="ms-1 mt-1"
                          onClick={handleAddOptions}
                        >
                          Add another option
                        </b>
                      </div>
                    ))}
                </Card>
                {!showEditProduct && (
                  <div>
                    <Modal isOpen={open} toggle={() => setOpen(false)}>
                      <ModalHeader toggle={() => setOpen(false)}>
                        Delete {products[0] && products[0].title}
                      </ModalHeader>
                      <ModalBody>
                        Are you sure you want to delete the product{" "}
                        <b>{products[0] && products[0].title}?</b> This can’t be
                        undone
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          type="button"
                          color="danger"
                          outline
                          onClick={() =>
                            deleteProducts(
                              shopifyProductId,
                              products[0] && products[0].storeId
                            )
                          }
                        >
                          Delete Product
                        </Button>
                        <Button
                          color="secondary"
                          className="rounded"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </div>
                )}
                {showOptionsData.length !== 0 ? (
                  <Card className="mb-4 px-3">
                    <CardBody>
                      <CardTitle className=" pb-0 mb-1  order_table_card_title">
                        <h6 className="mt-2">
                          <strong>Variants</strong>
                        </h6>
                        {!showEditProduct && (
                          <h6>
                            <strong>
                              <Link
                                className="text-color"
                                to={`/admin/products/${shopifyProductId}/variants/addNewVariant`}
                                onClick={handleScrollToTop}
                              >
                                Add Variants
                              </Link>
                            </strong>
                          </h6>
                        )}
                      </CardTitle>
                      <div className=" d-flex my-4">
                        <p className=" disabled">Select</p>
                        <p
                          className=" ms-3 textBtn pointer"
                          onClick={handleMasterCheckVariants}
                        >
                          All
                        </p>
                        <p
                          className=" ms-3 textBtn pointer"
                          onClick={handleMasterUncheckVariants}
                        >
                          None
                        </p>
                        {showEditProduct && (
                          <>
                            {variants.length !== 0 &&
                            variants[0].option1 !== null ? (
                              <div>
                                <Dropdown
                                  isOpen={option1DropDown}
                                  toggle={() => {
                                    setOption1DropDown((s) => !s);
                                  }}
                                >
                                  <DropdownToggle
                                    caret
                                    color="white"
                                    size="md"
                                    className="textBtn pointer rounded-2 px-1 py-0 ms-3"
                                  >
                                    {showOptionsData[0]?.name}{" "}
                                  </DropdownToggle>

                                  <DropdownMenu
                                    className={"bg-white px-1 pt-0 pb-0"}
                                  >
                                    {showOptionsData[0]?.values.map((v, i) => (
                                      <div>
                                        <DropdownItem
                                          onClick={() =>
                                            setOption1DropDown(false)
                                          }
                                          key={i}
                                          className=" my-1"
                                        >
                                          <Input
                                            checked={dropDownValueChecked.includes(
                                              v
                                            )}
                                            onChange={(e) =>
                                              handleValuesDropDownCheck(e, v)
                                            }
                                            type="checkbox"
                                            className="mycheckbox"
                                            style={{
                                              marginTop: "-1px",
                                              marginLeft: "-12px",
                                            }}
                                          />
                                          <p className="ms-3 my-0">{v}</p>
                                        </DropdownItem>
                                      </div>
                                    ))}
                                  </DropdownMenu>
                                </Dropdown>
                              </div>
                            ) : null}
                            {variants.length !== 0 &&
                            variants[0].option2 !== null ? (
                              <div>
                                <Dropdown
                                  isOpen={option2DropDown}
                                  toggle={() => {
                                    setOption2DropDown((s) => !s);
                                  }}
                                >
                                  <DropdownToggle
                                    caret
                                    color="white"
                                    size="md"
                                    className="textBtn pointer rounded-2 px-1 py-0 ms-3"
                                  >
                                    {showOptionsData[1].name}{" "}
                                  </DropdownToggle>

                                  <DropdownMenu
                                    className={"bg-white px-1 pt-0 pb-0"}
                                  >
                                    {showOptionsData[1].values.map((v, i) => (
                                      <div>
                                        <DropdownItem
                                          onClick={() =>
                                            setOption2DropDown(false)
                                          }
                                          key={i}
                                          className=" my-1"
                                        >
                                          <Input
                                            checked={dropDownValueChecked.includes(
                                              v
                                            )}
                                            onChange={(e) =>
                                              handleValuesDropDownCheck(e, v)
                                            }
                                            type="checkbox"
                                            className="mycheckbox"
                                            style={{
                                              marginTop: "-1px",
                                              marginLeft: "-12px",
                                            }}
                                          />
                                          <p className="ms-3 my-0">{v}</p>
                                        </DropdownItem>
                                      </div>
                                    ))}
                                  </DropdownMenu>
                                </Dropdown>
                              </div>
                            ) : null}
                            {variants.length !== 0 &&
                            variants[0].option3 !== null ? (
                              <div>
                                <Dropdown
                                  isOpen={option3DropDown}
                                  toggle={() => {
                                    setOption3DropDown((s) => !s);
                                  }}
                                >
                                  <DropdownToggle
                                    caret
                                    color="white"
                                    size="md"
                                    className="textBtn pointer rounded-2 px-1 py-0 ms-3"
                                  >
                                    {showOptionsData[2].name}{" "}
                                  </DropdownToggle>

                                  <DropdownMenu
                                    className={"bg-white px-1 pt-0 pb-0"}
                                  >
                                    {showOptionsData[2].values.map((v, i) => (
                                      <div>
                                        <DropdownItem
                                          onClick={() =>
                                            setOption3DropDown(false)
                                          }
                                          key={i}
                                          className=" my-1"
                                        >
                                          <Input
                                            checked={dropDownValueChecked.includes(
                                              v
                                            )}
                                            onChange={(e) =>
                                              handleValuesDropDownCheck(e, v)
                                            }
                                            type="checkbox"
                                            className="mycheckbox"
                                            style={{
                                              marginTop: "-1px",
                                              marginLeft: "-12px",
                                            }}
                                          />
                                          <p className="ms-3 my-0">{v}</p>
                                        </DropdownItem>
                                      </div>
                                    ))}
                                  </DropdownMenu>
                                </Dropdown>
                              </div>
                            ) : null}
                          </>
                        )}
                      </div>

                      <div className="customScroll overflow-auto">
                        <Table
                          borderless
                          // responsive
                          hover
                          style={{ width: "150%" }}
                        >
                          {selectedVariants.length !== 0 ? (
                            <ButtonGroup
                              className=" px-2 mb-2 rounded-2"
                              style={{
                                border: "1.7px solid lightgray",
                                position: "sticky",
                                left: "0",
                              }}
                            >
                              {selectedVariants.length == variants.length ? (
                                <div>
                                  <Button
                                    style={{
                                      width: "110px",
                                      marginLeft: "10px",
                                    }}
                                    color="white"
                                    className=" border-end d-flex rounded-0"
                                    onClick={() =>
                                      handleMasterUncheckVariants()
                                    }
                                  >
                                    <Input
                                      style={{ marginTop: "-1px" }}
                                      checked
                                      onChange={(e) =>
                                        handleMasterUncheckVariants(e)
                                      }
                                      className="item-check mycheckbox"
                                      type="checkbox"
                                    />
                                    <b className="ms-2 text-black">
                                      {selectedVariants.length} Selected
                                    </b>
                                  </Button>
                                </div>
                              ) : (
                                <div>
                                  <Button
                                    color="white"
                                    className=" border-end d-flex rounded-0"
                                    style={{ width: "120px" }}
                                    onClick={() => handleMasterCheckVariants()}
                                  >
                                    <span
                                      className=" outerBox"
                                      onClick={handleMasterCheckImages}
                                      style={{ marginLeft: "-9px" }}
                                    >
                                      <span className="innerBox"></span>
                                    </span>
                                    <b className="ms-2 text-black">
                                      {selectedVariants.length} Selected
                                    </b>
                                  </Button>
                                </div>
                              )}

                              <Dropdown
                                isOpen={variantsHeaderDropdown}
                                toggle={() =>
                                  setVariantsHeaderDropdown((t) => !t)
                                }
                              >
                                <DropdownToggle
                                  className="rounded-1 fw-bold ms-1"
                                  color="white"
                                  caret
                                >
                                  Actions{" "}
                                </DropdownToggle>
                                <DropdownMenu
                                  className="bg-white"
                                  style={{ marginTop: "-9%" }}
                                  container={"body"}
                                >
                                  <DropdownItem
                                    onClick={() => {
                                      setEditPriceModal(true);

                                      // let index = [];
                                      // selectedVariants.map((o) => {
                                      //   index.push(variants.indexOf(o));
                                      // });

                                      setEditingVariantsPrices(
                                        JSON.parse(
                                          JSON.stringify(selectedVariants)
                                        )
                                      );
                                    }}
                                  >
                                    Edit prices
                                  </DropdownItem>
                                  <DropdownItem onClick={() => {}} disabled>
                                    Edit quantities
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      handleDeleteSelectedVariants();
                                    }}
                                  >
                                    Delete variants
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      setEditCountryModal(true);
                                      setEditingVariantsCountry(
                                        selectedVariants
                                      );
                                    }}
                                  >
                                    Edit country / region of origin
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      setEditLocationsModal(true);
                                    }}
                                  >
                                    Edit locations
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </ButtonGroup>
                          ) : (
                            <thead style={{ fontSize: "14px" }}>
                              <tr>
                                <th
                                  style={{
                                    minWidth: "221px",
                                    maxWidth: "200px",
                                    position: "sticky",
                                    left: "0",
                                  }}
                                  className=" text-start bg-white"
                                >
                                  <div className="ms-3">
                                    <Input
                                      // checked={selectVariant}
                                      autoFocus={false}
                                      onChange={(e) =>
                                        handleMasterCheckVariants(e)
                                      }
                                      className=" mycheckbox"
                                      type="checkbox"
                                    />

                                    <b className="ms-3">Variant</b>
                                  </div>
                                </th>
                                <th className="text-start">Price</th>
                                <th className="text-start">Quantity</th>
                                <th className="text-start">Sku</th>
                                <th className="text-start">Barcode</th>
                                <th> </th>
                                <th> </th>
                              </tr>
                            </thead>
                          )}

                          <tbody>
                            {variants.map((v, index) => (
                              <tr
                                key={index}
                                className={` pointer border-top ${
                                  v.selected ? "custom-bg" : null
                                }`}
                              >
                                <th
                                  onClick={() => handleVariantRowClick(v)}
                                  style={{
                                    minWidth: "221px",
                                    maxWidth: "200px",
                                    position: "sticky",
                                    left: "0",
                                    overflow: "auto",
                                  }}
                                  className={` text-start bg-white`}
                                >
                                  <div className="d-flex ms-3">
                                    <Input
                                      checked={v.selected}
                                      className=" mycheckbox"
                                      style={{ marginTop: "-1px" }}
                                      type="checkbox"
                                      onChange={(e) =>
                                        handleSingleCheckVariant(e, v)
                                      }
                                    />
                                    {!showEditProduct && (
                                      <img
                                        className="mx-1"
                                        src={
                                          v.images?.[0]
                                            ? v.images[v.images.length - 1]?.src
                                            : emptVariantImg
                                        }
                                        height={50}
                                        width={50}
                                      />
                                    )}

                                    <b className="ms-3">{v.title}</b>
                                  </div>
                                </th>

                                <td>
                                  <Input
                                    value={v.price}
                                    name={"price"}
                                    placeholder="PKR 0.00"
                                    min={"0.0"}
                                    type="number"
                                    className=" rounded-2 me-3"
                                    style={{ height: "35px", width: "140px" }}
                                    onChange={(e) =>
                                      handleChangeVariantFields(e, index)
                                    }
                                  />
                                </td>
                                <td>
                                  <Input
                                    value={v.quantity}
                                    name="quantity"
                                    min={1}
                                    type="number"
                                    className=" rounded-2 me-3"
                                    style={{ height: "35px", width: "100px" }}
                                    onChange={(e) =>
                                      handleChangeVariantFields(e, index)
                                    }
                                  />
                                </td>
                                <td>
                                  <Input
                                    value={v.sku}
                                    name="sku"
                                    type="text"
                                    className=" rounded-2 me-3"
                                    style={{ height: "35px", width: "140px" }}
                                    onChange={(e) =>
                                      handleChangeVariantFields(e, index)
                                    }
                                  />
                                </td>
                                <td>
                                  <Input
                                    value={v.barcode}
                                    name="barcode"
                                    type="text"
                                    className=" rounded-2 me-3"
                                    style={{ height: "35px", width: "140px" }}
                                    onChange={(e) =>
                                      handleChangeVariantFields(e, index)
                                    }
                                  />
                                </td>
                                <td
                                  style={{ position: "sticky", right: "0" }}
                                  className={` px-0 ${
                                    v.selected ? "custom-bg" : "bg-white"
                                  }`}
                                >
                                  {showEditProduct ? (
                                    <Button
                                      onClick={() => {
                                        setEditVariantModal(true);
                                        setCreateThisVariantCheck(true);
                                        setEditingVariant(v);
                                        setEditingVariantIndex(index);
                                      }}
                                      type="button"
                                      color="primary"
                                      className="rounded py-1 fw-bold shadow btn btn-primary"
                                    >
                                      Edit
                                    </Button>
                                  ) : (
                                    <Link
                                      to={`/admin/products/${shopifyProductId}/variants/editVariant/${v.shopifyVariantId}`}
                                      onClick={handleScrollToTop}
                                    >
                                      <Button
                                        onClick={() => {
                                          setEditVariantModal(true);
                                          setCreateThisVariantCheck(true);
                                          setEditingVariant(v);
                                          setEditingVariantIndex(index);
                                        }}
                                        type="button"
                                        color="primary"
                                        className="rounded py-1 fw-bold shadow btn btn-primary"
                                      >
                                        Edit
                                      </Button>
                                    </Link>
                                  )}
                                </td>
                                {showEditProduct && (
                                  <td
                                    className={`px-0 ${
                                      v.selected ? "custom-bg" : "bg-white"
                                    }`}
                                    style={{ borderLeft: "1px solid black" }}
                                  >
                                    <Button
                                      style={{
                                        border: "1.5px solid lightgray",
                                        height: "30px",
                                        width: "63px",
                                      }}
                                      onClick={() => handleDeleteVariant(v)}
                                      type="button"
                                      color="white"
                                      className="rounded px-3 py-0 bg-white"
                                    >
                                      <i className="far fa-trash-alt mt-1 fs-6"></i>
                                    </Button>
                                  </td>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>

                      <Modal isOpen={editPriceModal}>
                        <ModalHeader
                          className=" shadow"
                          toggle={() => {
                            setEditPriceModal((v) => !v);
                            setPriceToAll("");
                            // setEditingVariantsPrices(editingVariantsPrices);
                            // setVariants(variants)
                          }}
                        >
                          Edit prices
                        </ModalHeader>
                        <ModalBody
                          style={{ maxHeight: "400px", overflow: "auto" }}
                        >
                          <p className="form-label mb-1 text-black ms-1">
                            Apply a price to all variants
                          </p>
                          <div className="row">
                            <div className=" col-9">
                              <Input
                                type="number"
                                min={"0.0"}
                                step="0.1"
                                className=" rounded-3"
                                onChange={(e) => handleChanglePriceToAll(e)}
                                placeholder="PKR  0.00"
                              />
                            </div>
                            <div className="col-3 ps-1">
                              <Button
                                disabled={!priceToAll}
                                onClick={() => {
                                  setEditingVariantsPrices(
                                    editingVariantsPrices.filter(
                                      (va) => (va.price = priceToAll)
                                    )
                                  );
                                }}
                                style={{
                                  border: "1px solid lightgray",
                                  width: "118px",
                                }}
                                color="white"
                                className=" border-1 rounded-2 text-dark fw-bold"
                              >
                                Apply to all
                              </Button>
                            </div>
                          </div>

                          <ul className="list-group">
                            {editingVariantsPrices.map((variant, ind) => (
                              <li
                                className="list-group-item border-0"
                                key={ind}
                              >
                                <hr className="addProductHr " />
                                <p className="float-start">{variant.title}</p>
                                <Input
                                  value={variant.price}
                                  placeholder="PKR 0.00"
                                  type="number"
                                  min={"0.0"}
                                  step="0.1"
                                  className=" rounded-2 float-end"
                                  style={
                                    !variant.price
                                      ? {
                                          height: "35px",
                                          width: "140px",
                                          border: "1px solid red",
                                        }
                                      : { height: "35px", width: "140px" }
                                  }
                                  onChange={(e) => {
                                    handleChangeSinglePrice(e, ind);
                                  }}
                                />
                                {!variant.price ? (
                                  <p
                                    className=" form-label text-danger"
                                    style={{
                                      position: "absolute",
                                      right: "20px",
                                      bottom: "-16px",
                                      zIndex: "2",
                                    }}
                                  >
                                    <b
                                      className=" bg-danger rounded-circle text-white mx-1"
                                      style={{
                                        padding: "0px 7px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      &#33;
                                    </b>{" "}
                                    Price can't be blank
                                  </p>
                                ) : null}
                              </li>
                            ))}
                          </ul>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            onClick={() => {
                              setEditPriceModal(false);
                              setPriceToAll("");
                            }}
                            color="secondary"
                            className="rounded"
                          >
                            Cancel
                          </Button>
                          <Button
                            disabled={editingVariantsPrices.some(
                              (va) => va.price == ""
                            )}
                            className="rounded btn btn-primary"
                            color="primary"
                            onClick={() => {
                              setEditPriceModal(false);
                              setPriceToAll("");
                              setSelectedVariants([]);
                              setDropDownValueChecked([]);

                              variants.filter((v) =>
                                editingVariantsPrices.filter((ed) => {
                                  if (v.title === ed.title) {
                                    ed.selected = false;
                                    variants[variants.indexOf(v)] = ed;
                                    setVariants([...variants]);
                                  }
                                })
                              );
                            }}
                          >
                            Done
                          </Button>{" "}
                        </ModalFooter>
                      </Modal>

                      <Modal isOpen={editCountryModal}>
                        <ModalHeader
                          className=" shadow"
                          toggle={() => {
                            setEditCountryModal((v) => !v);
                            setCountryToAll("");
                          }}
                        >
                          Edit country codes of origin
                        </ModalHeader>
                        <ModalBody
                          style={{ maxHeight: "400px", overflow: "auto" }}
                        >
                          <p className="form-label mb-1 text-black">
                            Country / Region of origin
                          </p>
                          <div className="row">
                            <div className=" col-9">
                              <Input
                                type="select"
                                className=" rounded-3"
                                onChange={(e) => handleChangleCountryToAll(e)}
                              >
                                <option selected disabled>
                                  Select country / region
                                </option>
                                {countryList.map((c, i) => (
                                  <option value={c}>{c}</option>
                                ))}
                              </Input>
                            </div>
                            <div className="col-3 ps-1">
                              <Button
                                disabled={!countryToAll}
                                onClick={() => {
                                  setEditingVariantsCountry(
                                    editingVariantsCountry.filter(
                                      (va) => (va.country = countryToAll)
                                    )
                                  );
                                }}
                                style={{
                                  border: "1px solid lightgray",
                                  width: "118px",
                                }}
                                color="white"
                                className=" border-1 rounded-2 text-dark fw-bold"
                              >
                                Apply to all
                              </Button>
                            </div>
                          </div>
                          <p className="form-label mt-1 ms-1">
                            In most cases, where the product is manufactured.
                          </p>

                          <ul className="list-group">
                            {editingVariantsCountry.map((variant, ind) => (
                              <li
                                className="list-group-item border-0"
                                key={ind}
                              >
                                <hr className="addProductHr " />

                                <p className="float-start">{variant.title}</p>
                                <div className="float-end">
                                  <p className="form-label mb-1 text-black">
                                    Country / Region of origin
                                  </p>
                                  <Input
                                    value={variant.country}
                                    type="select"
                                    className=" rounded-2"
                                    style={{ height: "35px", width: "150px" }}
                                    onChange={(e) => {
                                      handleChangeSingleCountry(e, ind);
                                    }}
                                  >
                                    <option selected>
                                      Select country / region
                                    </option>
                                    {countryList.map((c, i) => (
                                      <option value={c}>{c}</option>
                                    ))}
                                  </Input>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            onClick={() => {
                              setEditCountryModal(false);
                              setCountryToAll("");
                            }}
                            color="secondary"
                            className="rounded"
                          >
                            Cancel
                          </Button>
                          <Button
                            className="rounded btn btn-primary"
                            color="primary"
                            onClick={() => {
                              setEditCountryModal(false);
                              setCountryToAll("");
                              setSelectedVariants([]);
                              setDropDownValueChecked([]);
                              variants.filter((v) =>
                                editingVariantsCountry.filter((ed) => {
                                  if (v.title === ed.title) {
                                    ed.selected = false;
                                    variants[variants.indexOf(v)] = ed;
                                    setVariants([...variants]);
                                  }
                                })
                              );
                            }}
                          >
                            Done
                          </Button>{" "}
                        </ModalFooter>
                      </Modal>
                      <Modal isOpen={editLocationsModal}>
                        <ModalHeader
                          toggle={() => {
                            setEditLocationsModal((v) => !v);
                          }}
                        >
                          Edit locations
                        </ModalHeader>
                        <ModalBody
                          style={{ maxHeight: "400px", overflow: "auto" }}
                        >
                          {editLocationsCheck ? null : (
                            <div
                              className="alert alert-warning d-flex rounded-3"
                              role="alert"
                            >
                              <i class="fas fs-5 mt-1 fa-exclamation-circle"></i>
                              <p className="mb-0 mt-1 ms-2 text-black">
                                You must select at least one location for your
                                variant.
                              </p>
                            </div>
                          )}

                          <p className="form-label mb-3 ms-1">
                            Select locations that stock the selected variants.
                          </p>
                          <div className=" form-check ms-3">
                            <Input
                              checked={editLocationsCheck}
                              type="checkbox"
                              className="form-check-input mycheckbox"
                              onChange={(e) => {
                                setEditLocationsCheck((l) => !l);
                              }}
                            />
                            <p className="form-check-label">Regent Mall</p>
                          </div>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            onClick={() => {
                              setEditLocationsModal(false);
                            }}
                            color="secondary"
                            className="rounded"
                          >
                            Cancel
                          </Button>
                          <Button
                            disabled={editLocationsCheck ? false : true}
                            className="rounded btn btn-primary"
                            color="primary"
                            onClick={() => {
                              setEditLocationsModal(false);
                              setSelectedVariants([]);
                              setDropDownValueChecked([]);
                              variants.filter((v) => (v.selected = false));
                              setVariants(variants);
                            }}
                          >
                            Done
                          </Button>{" "}
                        </ModalFooter>
                      </Modal>
                    </CardBody>
                  </Card>
                ) : null}
                {showEditProduct && (
                  <Card className="mb-4">
                    <CardBody className="p-4">
                      <CardTitle className="order_table_card_title">
                        <strong className="fs-6">
                          Search engine listing preview
                        </strong>

                        {seo ? null : (
                          <Button
                            color="white"
                            name="editSEO"
                            onClick={() => {
                              seoShow();
                              setProductData({ ...productData, editSEO: true });
                            }}
                            className=" border-0 fs-6 textBtn"
                          >
                            Edit website SEO
                          </Button>
                        )}
                      </CardTitle>
                      <p>
                        Add a title and description to see how to product might
                        appear in a search engil listing
                      </p>
                      {seo ? (
                        <div className="">
                          <hr className="addProductHr" />
                          <div className="mb-3">
                            <p className="form-label text-black mb-1">
                              Page Title
                            </p>

                            <Input
                              required
                              onChange={handleSetValues}
                              name="pageTitle"
                              type="text"
                              className=" rounded-3 mt-1"
                              id="exampleInputEmail1"
                            />
                            <p id="emailHelp" className="mt-1">
                              0 to 70 character used
                            </p>
                          </div>
                          <div className="">
                            <p className="form-label text-black mb-1">
                              Meta description
                            </p>

                            <textarea
                              required
                              onChange={handleSetValues}
                              name="metaDescription"
                              className=" mt-1 form-control rounded-3"
                              id=""
                              cols="20"
                              rows="5"
                            />
                            <p className="mt-1">0 of 230 character used</p>
                          </div>
                          <div className="mb-3">
                            <p className="form-label text-black mb-1">
                              URL Handle
                            </p>

                            <Input
                              required
                              onChange={handleSetValues}
                              name="handleURL"
                              type="url"
                              className=" mt-1 rounded-3"
                              id=""
                              placeholder="https://lala-cosm.myshopify.com/admin/products/new"
                            />
                          </div>
                        </div>
                      ) : null}
                    </CardBody>
                  </Card>
                )}
              </Col>
              <Col md="4">
                <Card className="mb-4">
                  <CardBody className="p-4">
                    <CardTitle className="order_table_card_title">
                      <strong className="fs-6">Product Status</strong>
                    </CardTitle>
                    <div className="">
                      <Input
                        required
                        onChange={handleSetValues}
                        name="status"
                        type="select"
                        className=" rounded-3"
                      >
                        {!showEditProduct && (
                          <option
                            defaultValue={products[0] && products[0].status}
                            selected
                            hidden
                          >
                            {products[0] && products[0].status}
                          </option>
                        )}
                        <option value="active">Active</option>
                        <option value="draft">Draft</option>
                      </Input>
                    </div>
                    {showEditProduct ? (
                      <>
                        <hr className="addProductHr" />
                        <div className="show">
                          <p>
                            <b>SALES CHANNELS AND APPS</b>
                          </p>
                          <strong
                            className="text-info"
                            style={{ cursor: "pointer" }}
                            onClick={handleSalesChannelCheck}
                          >
                            {onlineStoreCheckbox && userStoreCheckbox
                              ? "Unselect All"
                              : "Select All"}
                          </strong>
                        </div>
                        <hr className="addProductHr" />
                        <div className="ms-2">
                          <div className=" form-check">
                            <Input
                              name="onlineStore"
                              type="checkbox"
                              className="form-check-input mycheckbox"
                              checked={onlineStoreCheckbox}
                              onChange={(e) => {
                                handleOnlineStore();
                                handleSetValues(e);
                              }}
                            />
                            <p className="form-check-label">
                              Online Store <br />
                              {onlineStoreCheckbox ? (
                                <p className="form-text textBtn pointer">
                                  Shedule Availability
                                </p>
                              ) : null}
                            </p>
                          </div>
                          <div className=" form-check">
                            <Input
                              name="userStore"
                              type="checkbox"
                              className="form-check-input mycheckbox"
                              checked={userStoreCheckbox}
                              onChange={(e) => {
                                handleUserStore();
                                handleSetValues(e);
                              }}
                            />
                            <p className="form-check-label">Saif's Store</p>
                          </div>
                        </div>

                        <hr className="addProductHr" />
                      </>
                    ) : (
                      <>
                        <hr />
                        <b>SALES CHANNELS AND APPS</b>
                        <hr />
                        <p>Online Store and Test Private App </p>
                      </>
                    )}
                  </CardBody>
                </Card>

                <Card className="mb-4">
                  <CardBody className="p-4">
                    <CardTitle className="order_table_card_title">
                      <strong className="fs-6">Product Organization</strong>
                    </CardTitle>
                    <div className="">
                      <div className="mb-3">
                        <p className="form-label mb-1 text-black">Type</p>
                        <Input
                          required
                          onChange={handleSetValues}
                          type="select"
                          name={showEditProduct ? "product_type" : "type"}
                          style={{ cursor: "text" }}
                          className="rounded-3"
                        >
                          {showEditProduct ? (
                            <option disabled selected>
                              Select type
                            </option>
                          ) : (
                            <option
                              value={products[0] && products[0].type}
                              selected
                              hidden
                            >
                              {products[0] && products[0].type}
                            </option>
                          )}
                          {type.map((t, i) => (
                            <option value={t} className="pointer" key={i}>
                              {t}
                            </option>
                          ))}
                        </Input>
                      </div>
                      <div className="mb-3">
                        <p className="form-label mb-1 text-black">Vendor</p>

                        <Input
                          required
                          onChange={handleSetValues}
                          type="select"
                          name="vendor"
                          style={{ cursor: "text" }}
                          className=" rounded-3"
                        >
                          {showEditProduct ? (
                            <option disabled selected>
                              Select Vendor
                            </option>
                          ) : (
                            <option
                              value={products[0] && products[0].vendor}
                              selected
                              hidden
                            >
                              {products[0] && products[0].vendor}
                            </option>
                          )}

                          {vendor.map((v, i) => (
                            <option value={v} key={i}>
                              {v}
                            </option>
                          ))}
                        </Input>
                      </div>
                      {showEditProduct && (
                        <div className="mb-3">
                          <p className="form-label mb-1 text-black">
                            Collections
                          </p>
                          <ReactSelect
                            name="handle"
                            styles={collectionSelectedStyle}
                            options={collections}
                            isMulti
                            controlShouldRenderValue={false}
                            placeholder={"Select Collections"}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{
                              Option,
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                            onChange={handleGetCollectionValues}
                            isClearable={false}
                            value={collectionValues}
                          />
                          <div
                            className="mt-1 d-flex"
                            style={{ maxHeight: "350px", flexWrap: "wrap" }}
                          >
                            {collectionValues.map((c, i) => (
                              <Badge
                                color={"light"}
                                className="rounded-3 me-1 mt-1 p-1 pt-1 fw-normal myBadge"
                                key={i}
                              >
                                <abbr
                                  className="myAbbr"
                                  title={`View the "${c.label}" Collection`}
                                >
                                  {c.label}
                                </abbr>

                                <i
                                  className="far fa-close ms-2 myClose"
                                  value={c.label}
                                  aria-hidden={"true"}
                                  onClick={() =>
                                    handleDeleteSelectedValues(c.label)
                                  }
                                ></i>
                              </Badge>
                            ))}
                          </div>
                        </div>
                      )}
                      <div className="mt-5 mb-2">
                        <hr className="addProductHr" />
                        <div className="order_table_card_title">
                          <b>TAGS</b>

                          <Label
                            color="white"
                            className=" border-0 textBtn pointer"
                            style={{ fontSize: "14px" }}
                            onClick={() => {
                              handleTagsPopup();
                              let clone = JSON.parse(
                                JSON.stringify(selectedTags)
                              );
                              setCheckedTags([...clone]);
                              setTags(
                                tag.filter(
                                  (t) => !selectedTags.includes(t.label)
                                )
                              );

                              setNewTags([]);
                            }}
                          >
                            Manage
                          </Label>
                        </div>
                        <CreateableSelect
                          styles={{
                            control: (base) => ({
                              ...base,
                              "&:hover": {
                                cursor: "text",
                              },
                            }),
                          }}
                          className=" rounded-3"
                          isMulti
                          controlShouldRenderValue={false}
                          // hideSelectedOptions={false}
                          createOptionPosition={"first"}
                          formatCreateLabel={createLabel}
                          // menuIsOpen={}
                          onFocus={() => setcreatableMenuOpen(false)}
                          openMenuOnClick={creatableMenuOpen}
                          // closeMenuOnSelect={false}
                          options={tags}
                          components={{
                            Option,
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                          onCreateOption={(t) => handleCreateCustomTags(t)}
                          // onInputChange={() => setcreatableMenuOpen(true)}
                          onBlur={() => setcreatableMenuOpen(false)}
                          onChange={(t) => {
                            let refine = t.filter(
                              (itm) => typeof itm === "object"
                            );

                            setSelectedTags([...selectedTags, refine[0].label]);
                          }}
                          // value={checkedTags}
                          isClearable={false}
                          placeholder="Find or create tags"
                        />
                        <div
                          className="mt-1 d-flex"
                          style={{ maxHeight: "350px", flexWrap: "wrap" }}
                        >
                          {selectedTags.map((c, i) => (
                            <Badge
                              color={"light"}
                              className="rounded-3 me-1 mt-1 p-1 pt-1 fw-normal myBadge"
                              key={i}
                            >
                              <abbr
                                className="myAbbr"
                                title={`View the "${c}" Collection`}
                              >
                                {c}
                              </abbr>

                              <i
                                className="far fa-close ms-2 myClose"
                                value={c}
                                aria-hidden={"true"}
                                onClick={() => {
                                  handleDeleteTags(c);
                                }}
                              ></i>
                            </Badge>
                          ))}
                        </div>
                        <Modal isOpen={tagsPopup}>
                          <ModalHeader
                            toggle={() => {
                              handleTagsPopup();
                            }}
                          >
                            Manage Your Tags
                          </ModalHeader>
                          <ModalBody
                            className="overflow-auto"
                            style={{ maxHeight: "400px" }}
                          >
                            <div>
                              <div className="row">
                                <div className="col-sm-8 col-md-8 col-6">
                                  <Input
                                    style={{
                                      marginLeft: "-10px",
                                      minWidth: "110%",
                                      height: "36px",
                                    }}
                                    type="search"
                                    placeholder="Search to find or create tags"
                                    className="form-control rounded-3"
                                    onChange={(e) => handleSearchTags(e)}
                                    value={searchTagValue}
                                  />
                                </div>
                                <div className=" col-sm-4 col-md-4 col-6">
                                  <span
                                    className="far fa-sort-alt fs-6"
                                    style={{
                                      position: "absolute",
                                      top: "8px",
                                      left: "30px",
                                    }}
                                  ></span>
                                  <Input
                                    style={{
                                      height: "36px",
                                      paddingLeft: "30px",
                                    }}
                                    type="select"
                                    className="rounded-3   ms-2 fw-bold"
                                    onChange={(e) => handleSortingTags(e)}
                                  >
                                    <option value={"Alphabetical"}>
                                      Alphabetical
                                    </option>
                                    <option value={"Frequently"}>
                                      Frequently used
                                    </option>
                                  </Input>
                                </div>
                              </div>
                              {searchTagValue !== "" ? (
                                tags.some(
                                  (t) =>
                                    t.label.toLowerCase() ===
                                    searchTagValue.toLowerCase()
                                ) ||
                                checkedTags?.some(
                                  (t) =>
                                    t.toLowerCase() ===
                                    searchTagValue.toLowerCase()
                                ) ||
                                newTags?.some(
                                  (t) =>
                                    t.toLowerCase() ===
                                    searchTagValue.toLowerCase()
                                ) ? null : (
                                  <div
                                    className="d-flex mt-2 ps-3 rounded-2 hoverPoint align-items-center"
                                    onClick={() =>
                                      hendleCreateNewTag(searchTagValue)
                                    }
                                  >
                                    <span className="iconsminds-add fs-6 me-1"></span>
                                    <p
                                      className=" me-2 my-2 textBtn"
                                      style={{ fontSize: "15px" }}
                                    >
                                      Add {` " ${searchTagValue} "`}
                                    </p>
                                  </div>
                                )
                              ) : null}
                              <div className="mt-3 overflow-auto">
                                {newTags.length !== 0 ? (
                                  <div className="">
                                    <div className="form-text ms-1">
                                      NEWLY ADDED
                                    </div>
                                    <div className="mb-2 mt-1">
                                      <ul className="list-group">
                                        {newTags.map((c, i) => (
                                          <li
                                            key={i}
                                            className="bg-light py-0 rounded-2 list-group-item border-0 mt-1 link"
                                            onClick={() =>
                                              handleDeleteNewTags(c)
                                            }
                                          >
                                            <div className="ms-1 py-1">
                                              <input
                                                type="checkbox"
                                                className="form-check-input mycheckbox"
                                                id="Check1"
                                                checked={c}
                                                value={c}
                                                onClick={() =>
                                                  handleDeleteNewTags(c)
                                                }
                                              />
                                              <Label className="form-check-label ms-1 link">
                                                {c}
                                              </Label>
                                            </div>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                ) : null}
                                {checkedTags.length !== 0 ? (
                                  <div className="">
                                    <div className="form-text ms-1">
                                      SELECTED
                                    </div>
                                    <div className="mb-2 mt-1">
                                      <ul className="list-group">
                                        {checkedTags.map((c, i) => (
                                          <li
                                            key={i}
                                            className="bg-light py-0 rounded-2 list-group-item border-0 mt-1 link"
                                            onClick={() => handleUnCheckTags(c)}
                                          >
                                            <div className="ms-1 py-1">
                                              <input
                                                type="checkbox"
                                                className="form-check-input mycheckbox"
                                                id="Check1"
                                                checked={c}
                                                value={c}
                                                onChange={() =>
                                                  handleUnCheckTags(c)
                                                }
                                              />
                                              <Label className="form-check-label ms-1 link">
                                                {c}
                                              </Label>
                                            </div>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                ) : null}
                                {tags.length == 0 ? null : (
                                  <div>
                                    <div className="form-text ms-1">
                                      AVAILABLE
                                    </div>
                                    <div className="mt-1">
                                      <ol className="list-group">
                                        {tags.map((m, i) => (
                                          <li
                                            className="link list-group-item py-0 border-0 rounded-2 "
                                            key={i}
                                            onClick={() => handleCheckedTags(m)}
                                          >
                                            <div className="ms-1 py-1">
                                              <input
                                                type="checkbox"
                                                className="form-check-input mycheckbox"
                                                autoFocus={false}
                                                id="Check1"
                                                checked={false}
                                                value={m}
                                                onChange={() =>
                                                  handleCheckedTags(m)
                                                }
                                              />
                                              <Label className="form-check-label ms-1 link">
                                                {m.label}
                                              </Label>
                                            </div>
                                          </li>
                                        ))}
                                      </ol>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              color="primary"
                              className="btn btn-primary rounded"
                              onClick={() => {
                                if (checkedTags.length == 0) {
                                  setSelectedTags(newTags);
                                } else if (newTags.length == 0) {
                                  setSelectedTags(checkedTags);
                                } else {
                                  let totalTags = [...checkedTags, ...newTags];

                                  setSelectedTags([...totalTags]);
                                }
                                setTagsPopup(!tagsPopup);
                              }}
                            >
                              Done
                            </Button>{" "}
                            <Button
                              color="secondary"
                              className="rounded"
                              onClick={() => {
                                handleTagsPopup();
                              }}
                            >
                              Cancel
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card className="mb-4">
                  <CardBody className="p-3">
                    <CardTitle className="order_table_card_title">
                      <strong className="fs-6">Online Store</strong>
                    </CardTitle>
                    <div>
                      <p className="form-label text-black mb-1">
                        Theme template
                      </p>
                      <Input
                        required
                        type="select"
                        name="themeTemplate"
                        // onChange={handleSetValues}
                        className=" rounded-3"
                      >
                        <option disabled selected>
                          Default Product
                        </option>
                        <option value="TPZ">The Panic Zone</option>
                        <option value="TTZ">The Twilight Zone</option>
                      </Input>
                      <p className="mt-1">
                        Assign a template from your current theme to define how
                        the product us displayed.
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Row>
                <Col>
                  {showEditProduct ? (
                    // <Link
                    //   to={`/admin/editProduct/${id}`}
                    //   onClick={handleScrollToTop}
                    // >
                    <Button
                      color="primary"
                      type="submit"
                      className="float-end rounded"
                    >
                      Save
                    </Button>
                  ) : (
                    // </Link>
                    <>
                      <Button
                        type="button"
                        color="primary"
                        outline
                        className="rounded ms-3"
                      >
                        Archive Product
                      </Button>
                      <Button
                        type="button"
                        outline
                        color="danger"
                        className="rounded ms-2 btn-outline-danger"
                        onClick={() => setOpen(true)}
                      >
                        Delete Product
                      </Button>
                      <Button
                        color="primary"
                        type="submit"
                        className=" rounded  float-right"
                      >
                        Save
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            </Row>
          </form>
          <Modal isOpen={editVariantModal}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setEditVariantModal(false);
                setSelectedVariants([]);
                setDropDownValueChecked([]);

                if (createThisVariantCheck) {
                  variants[editingVariantIndex] = editingVariant;
                  setVariants(variants);
                } else {
                  variants.splice(editingVariantIndex, 1);

                  setVariants([...variants]);
                }
                variants.filter((v) => (v.selected = false));
                setVariants(variants);
              }}
            >
              <ModalHeader
                toggle={() => {
                  setEditVariantModal((v) => !v);
                  setEditingVariant(variantItem);
                }}
              >
                Edit {editingVariant.title}
              </ModalHeader>
              <ModalBody style={{ maxHeight: "400px", overflow: "auto" }}>
                <div className=" ms-2 form-check">
                  <Input
                    onChange={() => setCreateThisVariantCheck((v) => !v)}
                    checked={createThisVariantCheck}
                    name=""
                    type="checkbox"
                    className="form-check-input mycheckbox"
                  />
                  <p className="form-check-label">Create this variant</p>
                </div>
                {createThisVariantCheck ? (
                  <div className="mt-5">
                    <div>
                      <p className="form-label mb-1">Price</p>
                      <Input
                        required
                        value={editingVariant.price}
                        name="price"
                        min={1}
                        type="number"
                        className="rounded-3"
                        placeholder="PKR 0.00"
                        onChange={(e) => handleChangeEditVariant(e)}
                      />
                    </div>
                    <div className="mt-3">
                      <p className="form-label mb-1 text-black">
                        Cost per item
                      </p>
                      <Input
                        value={editingVariant.cost_per_item}
                        required
                        name="cost_per_item"
                        min={"0"}
                        step="0.1"
                        type="number"
                        className=" rounded-3"
                        placeholder="PKR 0.00"
                        onChange={(e) => handleChangeEditVariant(e)}
                      />
                      <p id="emailHelp" className="mt-1">
                        Customers won't see this.
                      </p>
                    </div>
                    <hr className="addProductHr" />
                    <div className="">
                      <strong className="fs-6">Inventory</strong>

                      <div className="row mt-4 ">
                        <div className="col-lg-6">
                          <p className="form-label mb-1 text-black">
                            SKU (stock keeping unit)
                          </p>
                          <Input
                            value={editingVariant.sku}
                            required
                            type="text"
                            name="sku"
                            onChange={(e) => handleChangeEditVariant(e)}
                            className=" rounded-3"
                          />
                        </div>
                        <div className="col-lg-6 mt-lg-0 mt-3">
                          <p className="form-label mb-1 text-black">
                            Barcode (ISBN, UPC, GTIN, etc.)
                          </p>
                          <Input
                            value={editingVariant.barcode}
                            required
                            type="text"
                            name="barcode"
                            onChange={(e) => handleChangeEditVariant(e)}
                            className=" rounded-3"
                          />
                        </div>
                      </div>
                      <hr className="addProductHr" />
                      <div className="mt-4">
                        <CardTitle className="order_table_card_title">
                          <h6 className="text-black">Quantity</h6>
                        </CardTitle>
                        <CardTitle className="order_table_card_title">
                          <Label className="form-label">
                            <strong>Location Name</strong>
                          </Label>
                          <Label className="form-label me-5">
                            <strong>Available</strong>
                          </Label>
                        </CardTitle>
                        <hr className="addProductHr" />
                        <CardTitle className="order_table_card_title">
                          <p className="form-label text-black">Regent Mall</p>
                          <Input
                            value={editingVariant.quantity}
                            required
                            min={1}
                            onChange={(e) => handleChangeEditVariant(e)}
                            name="quantity"
                            type="number"
                            placeholder="1"
                            className=" rounded-3 col-5"
                          />
                        </CardTitle>
                      </div>
                    </div>
                    <hr className="addProductHr mt-4" />
                    <div>
                      <h6 className="text-black">Inventory</h6>

                      <div className=" mt-3">
                        <p className="form-label mb-1 text-black">
                          HS (Hermonized System)code
                        </p>
                        <Input
                          value={editingVariant.HS}
                          required
                          onChange={(e) => handleChangeEditVariant(e)}
                          name="HS"
                          type="search"
                          className=" rounded-3"
                          id="search"
                          placeholder="Search or enter a HS code"
                        />
                        <p id="emailHelp" className="mt-1">
                          Manually enter codes that are longer than 6 numbers.
                        </p>
                      </div>
                    </div>
                    <hr className="addProductHr mt-4" />

                    <div className="mt-2 d-flex text-black">
                      <span className="simple-icon-info fs-5"></span>
                      <p className=" ms-2">
                        Once you save the product, you’ll be able to edit more
                        variant details.
                      </p>
                    </div>
                  </div>
                ) : (
                  <p className="ms-4">
                    This variant will not be created when you save this product.
                  </p>
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={() => {
                    setEditVariantModal(false);
                    setEditingVariant(variantItem);
                  }}
                  color="secondary"
                  className="rounded"
                >
                  Cancel
                </Button>
                <Button color="primary" type="submit" className="rounded">
                  Done
                </Button>{" "}
              </ModalFooter>
            </form>
          </Modal>
        </main>
      )}
    </>
  );
};
export default DetailedUI;

import React, { useState } from "react";
import { Card, CardBody, TabContent, TabPane } from "reactstrap";
import OrderHeader from "../OrderHeader.jsx";
import AllOrdersTab from "./AllOrdersTab.jsx";
import AllOrders from "./AllOrders.jsx";
import UnFulFillOrders from "./UnFulFillOrders.jsx";
import ClosedOrders from "./CloseOrders.jsx";
import UnPaidOrders from "./UnPaidOrders.jsx";
import OpenOrders from "./OpenOrders.jsx";

const OrderList = () => {
  const activeTabFromStorage = localStorage.getItem("activeOrderTab");
  // const [skeletonTab, setSkeletonTab] = useState(false);
  const [activeTab, setActiveTab] = useState(
    activeTabFromStorage || "AllOrders"
  );
  // setTimeout(() => {
  //   setSkeletonTab(true);
  // }, 1000);

  return (
    <>
      <main>
        <OrderHeader OrdersComponent="Orders" />
        <Card>
          <CardBody>
            {/* {skeletonTab ? ( */}
            <AllOrdersTab activeTab={activeTab} setActiveTab={setActiveTab} />
            {/* ) : (
              <div className="tabs-bottom-border mt-2">
                {new Array(5).fill(0).map((item, i) => {
                  return (
                    <p
                      className="skeleton skeleton-loading tab-skeleton me-4 mb-0"
                      key={i + 1}
                    ></p>
                  );
                })}
              </div>
            )} */}
            <TabContent activeTab={activeTab}>
              <TabPane tabId="AllOrders">
                {activeTab === "AllOrders" ? <AllOrders /> : ""}
              </TabPane>
              <TabPane tabId="Unfulfilled">
                {activeTab === "Unfulfilled" ? <UnFulFillOrders /> : ""}
              </TabPane>
              <TabPane tabId="Unpaid">
                {activeTab === "Unpaid" ? <UnPaidOrders /> : ""}
              </TabPane>
              <TabPane tabId="Open">
                {activeTab === "Open" ? <OpenOrders /> : ""}
              </TabPane>
              <TabPane tabId="Close">
                {activeTab === "Close" ? <ClosedOrders /> : ""}
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </main>
    </>
  );
};

export default OrderList;

import React, { useState, useCallback, useEffect } from "react";
import { Row, Card, CardBody, Input, Button, Spinner } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Cols } from "../SeparatorStyle/SeparatorStyle";
import { getCourier, getUserPreferences } from "../../api/courier";
import { ErrorMessage } from "../Messages/message";
// import { updateOptions } from "./../../api/products";

const DropdownSelection = ({ update }) => {
  const [courierCompanyName, setCourierCompanyName] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [courierUpdate, setCourierUpdate] = useState();
  const [courierOptions, setCourierOptions] = useState([]);
  const [courierId, setCourierId] = useState([]);
  const [courierSpinner, setCourierSpinner] = useState(false);
  const history = useHistory();

  const loadCouriers = useCallback(async () => {
    try {
      const { data } = await getCourier();
      setCourierCompanyName(data);
    } catch (error) {
      console.log("error found when fetch order data", error);
    }
  }, []);

  const loadUserPreferences = useCallback(async () => {
    try {
      const { data } = await getUserPreferences();
      setCourierUpdate(data);
    } catch (error) {
      console.log("error found when fetch user data", error);
    }
  }, []);

  useEffect(() => {
    if (courierCompanyName.length === 0) {
      loadCouriers();
    }
    loadUserPreferences();
  }, [courierCompanyName, loadCouriers, loadUserPreferences]);

  const courierCardHandler = (courier) => {
    if (courierId.includes(courier._id)) {
      const filterId = courierId.filter((item) => {
        return item !== courier._id;
      });
      setCourierId(filterId);
      setCourierOptions(
        courierOptions &&
          courierOptions.filter((item) => item._id !== courier._id)
      );
    } else {
      setCourierId([...courierId, courier._id]);
      setCourierOptions([courier, ...courierOptions]);
    }
  };

  const nextPageHandler = () => {
    if (courierOptions.length > 0) {
      setTimeout(() => {
        history.push("/CourierSelectionList", {
          state: courierOptions,
          courierUpdate,
        });
      }, 1000);
      setCourierSpinner((a) => !a);
    } else setErrorMessage("Please check atleast One courier!");
  };

  const getImages = (name) => {
    if (name.label === "PostEx") {
      return (
        <>
          <img
            src="./img/companiesLogo/postEx.png"
            alt="postEx"
            className="couriersList-images"
          />
        </>
      );
    } else if (name.label === "TPL") {
      return (
        <>
          <img
            src="./img/companiesLogo/Rider.png"
            alt="tpl"
            className="couriersList-images"
          />
        </>
      );
    } else if (name.label === "Leopard") {
      return (
        <>
          <img
            src="./img/companiesLogo/LCS.png"
            alt="leopard"
            className="couriersList-images"
          />
        </>
      );
    } else if (name.label === "Swyft") {
      return (
        <>
          <img
            src="./img/companiesLogo/swyft.png"
            alt="swyft"
            className="couriersList-images"
          />
        </>
      );
    } else if (name.label === "M&P") {
      return (
        <>
          <img
            src="./img/companiesLogo/MP-Logo.png"
            alt="m&p"
            className="couriersList-images"
          />
        </>
      );
    } else if (name.label === "Trax") {
      return (
        <>
          <img
            src="./img/companiesLogo/Trax.png"
            alt="Trax"
            className="couriersList-images"
          />
        </>
      );
    }
  };
  return (
    <div className="container-fluid">
      <div className="mb-5">
        {errorMessage !== "" ? ErrorMessage(errorMessage) : null}
      </div>
      <Row className="justify-content-center">
        {courierCompanyName &&
          courierCompanyName.map((name) => (
            <>
              <Cols
                key={name._id}
                xxs="6"
                sm="4"
                md="3"
                lg="2"
                className={`mb-5 ${courierId.includes(name._id)}`}
              >
                <div className="text-center">
                  <Card>
                    <Input
                      type="checkbox"
                      className="courier-checkbox position-absolute"
                      onClick={() => courierCardHandler(name)}
                    />
                    <CardBody>{getImages(name)}</CardBody>
                  </Card>
                </div>
              </Cols>
            </>
          ))}
      </Row>

      <footer className="d-flex justify-content-center">
        <Button
          type="button"
          outline
          color="primary"
          size="lg"
          className="py-2 my-4"
          onClick={nextPageHandler}
        >
          {courierSpinner ? <Spinner size={"sm"}>Loading...</Spinner> : "Next"}
        </Button>
      </footer>
    </div>
  );
};

export default DropdownSelection;

import axios from "axios";
import { getCookie } from "../helpers/cookie";
import { getAPIURL } from "./utils";

const url = getAPIURL();
let response = "";

// Stock Quantity Update
export const stockQuantityUpdate = async (data) => {
  try {
    const token = getCookie();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    response = await axios.put(
      `${url}/api/inventory/updateStock`,
      { data },
      config
    );
  } catch (error) {
    response = error;
  }
  return response;
};

// Update Stock
export const updateStock = async (data) => {
  try {
    const token = getCookie();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    response = await axios.post(
      `${url}/api/jobs/inventoryUpdateBulkOperation`,
      { data: data },
      config
    );
  } catch (error) {
    response = error;
  }
  return response;
};

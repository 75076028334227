import React, { useEffect } from "react";
import Routing from "./routes/Routing";
import { ToastContainer } from "react-toastify";
import jwt_decode from "jwt-decode";
import { getCookie } from "./helpers/cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";

const App = () => {
  useEffect(() => {
    let token = getCookie();
    if (token) {
      let { exp } = jwt_decode(token);
      let currentDate = new Date().getTime();
      let changeInString = currentDate.toString();
      let sliceDate = changeInString.slice(0, 10);
      let convertInt = parseInt(sliceDate);
      if (convertInt >= exp) {
        toast.error("Your Session is Expired! Login Again...");
        localStorage.removeItem("user");
        localStorage.removeItem("userCouriers");
        Cookies.remove("token");
        window.location.href = "/";
        // history.push("/")
        // toast.error('your session expired')
      }
    }
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick={false}
        pauseOnHover={false}
        draggable={true}
        progress={undefined}
      />
      <Routing />
    </>
  );
};

export default App;

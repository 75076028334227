/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import {
  Pagination,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";

const DataTablePagination = ({
  paginationLoading,
  page,
  pages,
  pageSizeOptions,
  showPageSizeOptions,
  showPageJump,
  defaultPageSize,
  onPageChange,
  onPageSizeChange,
  dataInfo,
}) => {
  const [pageState, setPageState] = useState(0);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  useEffect(() => {
    setPageState(page);
  }, [page]);

  const getSafePage = (_page) => {
    let p = _page;
    if (Number.isNaN(_page)) {
      p = page;
    }
    return Math.min(Math.max(p, 0), pages - 1);
  };

  const changePageSize = (size) => {
    onPageSizeChange(size);
    setPageSize(size);
  };

  const changePage = (_page) => {
    const p = getSafePage(_page);
    setPageState(p);
    onPageChange(p);
  };

  const renderPageJump = () => {
    const pageNumbers = [];
    for (let i = 0; i < pages; i += 1) {
      pageNumbers.push(
        <DropdownItem
          key={i}
          onClick={() => changePage(i)}
          active={pageState + 1 === i + 1}
        >
          {i + 1}
        </DropdownItem>
      );
    }
    return pageNumbers;
  };

  const handleChange = (data) => {
    const p = getSafePage(data.selected);
    // setPageNum(p)
    setPageState(p);
    onPageChange(p);
  };

  return (
    <>
      <div
        className={`d-flex justify-content-between align-items-center flex-wrap ${
          paginationLoading ? "hide" : ""
        }`}
      >
        {showPageJump && (
          <div>
            <span>Go to Page:</span>
            <UncontrolledDropdown>
              <DropdownToggle caret color="outline-primary" size="xs">
                {pageState + 1}
              </DropdownToggle>
              <DropdownMenu
                direction="left"
                style={{ maxHeight: "200px" }}
                className="overflow-auto"
              >
                {renderPageJump()}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )}
        {/* <Pagination
        className="d-inline-block"
        size="sm"
        aria-label="Page navigation example"
      > */}
        <div className="mx-2 mt-2">
          <ReactPaginate
            forcePage={pageState}
            pageCount={pages}
            breakLabel={"....."}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            onPageChange={handleChange}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLabel={<i className="simple-icon-arrow-right"></i>}
            previousLabel={<i className="simple-icon-arrow-left"></i>}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
        {/* </Pagination> */}

        <div>
          <span>Go to Item:</span>
          <UncontrolledDropdown>
            <DropdownToggle caret color="outline-primary" size="xs">
              {pageSize}
            </DropdownToggle>
            <DropdownMenu
              right
              style={{ maxHeight: "200px" }}
              className="overflow-auto"
            >
              {pageSizeOptions.map((size, index) => {
                return (
                  <DropdownItem
                    key={index}
                    onClick={() => changePageSize(size)}
                    active={pageSize === size}
                  >
                    {size}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
      {dataInfo && <div className=" text-center">{dataInfo}</div>}
    </>
  );
};
export default DataTablePagination;

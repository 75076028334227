import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Input,
  Collapse,
  Form,
  Table,
} from "reactstrap";
import { Cols } from "../SeparatorStyle/SeparatorStyle";

const StatementOfCharges = () => {
  const history = useHistory();
  const [toggleSubscription, setToggleSubscription] = useState(false);
  const [toggleApps, setToggleApps] = useState(false);

  return (
    <>
      <main>
        <Row>
          <Cols xxs="12">
            <div className=" mb-3 d-flex justify-content-between">
              <div className="d-flex">
                <i
                  onClick={() => history.goBack()}
                  className="simple-icon-arrow-left-circle fw-bold mr-3 fs-3 pointer"
                ></i>
                <h2 className="m-0">
                  <b>Statement of Charges</b>
                </h2>
              </div>
              <div className=" d-flex align-items-center">
                <Input
                  className="mr-4"
                  id="exampleDate"
                  name="date"
                  type="date"
                />
                <Link className="mr-4 fs-6 text-black">
                  <span className="simple-icon-printer mr-2"></span>
                  Print
                </Link>
                <Link className="mr-4 d-flex text-black fs-6">
                  <span className="iconsminds-bottom-to-top mr-1"></span>
                  Export
                </Link>
              </div>
            </div>
          </Cols>
        </Row>
        <br />
        <Form>
          <Row>
            <Cols xxs="12" className="row icon-cards-row mb-2">
              <Cols className="mb-4 ">
                <Card>
                  <CardBody>
                    <CardTitle className="ml-3">
                      <h5>
                        <b>Apps</b>
                        <span style={{ fontSize: "small" }}> (1 Item)</span>
                        {toggleApps ? (
                          <span
                            className="simple-icon-arrow-up fs-6 mt-1 fw-bold mx-2 float-end"
                            onClick={() => setToggleApps(!toggleApps)}
                          ></span>
                        ) : (
                          <span
                            className="simple-icon-arrow-down fw-bold fs-6 mt-1 mx-2 float-end"
                            onClick={() => setToggleApps(!toggleApps)}
                          ></span>
                        )}
                        <b style={{ marginRight: "5px", float: "right" }}>
                          $5.00
                        </b>
                      </h5>
                    </CardTitle>
                    <Collapse isOpen={toggleApps}>
                      <div
                        style={{ paddingTop: "15px", paddingBottom: "15px" }}
                      >
                        <CardText
                          className="mb-3 ml-3 mr-3"
                          style={{ color: "black" }}
                        >
                          <Table className="text-center">
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Description</th>
                                <th>Fee</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>15 Nov 2021</td>
                                <td>Basic</td>
                                <td>$5.00</td>
                              </tr>
                            </tbody>
                          </Table>
                        </CardText>
                      </div>
                      <hr />
                    </Collapse>
                    <CardTitle className="ml-3">
                      <h5>
                        <b>Subscriptions</b>
                        <span style={{ fontSize: "small" }}> (1 Item)</span>
                        {toggleSubscription ? (
                          <span
                            className="simple-icon-arrow-up fs-6 mt-1 fw-bold mx-2 float-end"
                            onClick={() =>
                              setToggleSubscription(!toggleSubscription)
                            }
                          ></span>
                        ) : (
                          <span
                            className="simple-icon-arrow-down fw-bold fs-6 mt-1 mx-2 float-end"
                            onClick={() =>
                              setToggleSubscription(!toggleSubscription)
                            }
                          ></span>
                        )}
                        <b style={{ marginRight: "5px", float: "right" }}>
                          $29.00
                        </b>
                      </h5>
                    </CardTitle>
                    <Collapse isOpen={toggleSubscription}>
                      <div
                        style={{ paddingTop: "15px", paddingBottom: "15px" }}
                      >
                        <CardText
                          className="mb-3 ml-3 mr-3"
                          style={{ color: "black" }}
                        >
                          <Table className="text-center">
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Description</th>
                                <th>Fee</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>11 Nov 2021</td>
                                <td>Subscription Fee</td>
                                <td>$5.00</td>
                              </tr>
                            </tbody>
                          </Table>
                        </CardText>
                      </div>
                      <hr />
                    </Collapse>
                    <br />
                    <div style={{ float: "right" }}>
                      <h6>
                        <span className="mr-5">
                          <b>Running Total</b>
                        </span>
                        <span className="mr-4">
                          <b>$34.00 USD</b>
                        </span>
                      </h6>
                    </div>
                  </CardBody>
                </Card>
              </Cols>
            </Cols>
          </Row>
        </Form>
      </main>
    </>
  );
};

export default StatementOfCharges;

import React, { useEffect, useState } from "react";
import { variantAdd } from "../../api/variant";
import VariantDetail from "./VariantDetail.jsx";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

function VariantAdd() {
  const { proId: shopifyProductId } = useParams();
  const [options, setOptions] = useState({});
  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const [newVariantDetail, setNewVariantDetail] = useState({
    option1: null,
    option2: null,
    option3: null,
    imageList: "",
    price: "",
    comparePrice: "",
    chargeTax: false,
    costPerItem: "",
    sku: "",
    barcode: "",
    trackQuantity: false,
    outOfStock: false,
    quantity: [],
    physicalProduct: false,
    shippingRate: { weight: "", unit: "kg" },
  });

  useEffect(() => {
    const { option1, option2, option3 } = newVariantDetail;
    setOptions({ option1, option2, option3 });
  }, [newVariantDetail]);

  const saveVariant = async (
    e,
    reRender,
    setReRender,
    noOfOptions,
    newVariantDetail,
    shopifyProductId,
    storeId
  ) => {
    e.preventDefault();
    try {
      const {
        option1,
        option2,
        option3,
        price,
        comparePrice,
        sku,
        barcode,
        shippingRate: { weight, unit },
      } = newVariantDetail;
      await variantAdd(
        option1,
        option2,
        option3,
        Number(price),
        comparePrice,
        sku,
        barcode,
        Number(weight),
        unit,
        storeId,
        shopifyProductId,
        noOfOptions
      );

      setNewVariantDetail({
        option1: "",
        option2: "",
        option3: "",
        imageList: "",
        price: "",
        comparePrice: "",
        chargeTax: false,
        costPerItem: "",
        sku: "",
        barcode: "",
        trackQuantity: false,
        outOfStock: false,
        quantity: [],
        physicalProduct: false,
        shippingRate: { weight: "", unit: "kg" },
      });
      setOptions({ option1: "", option2: "", option3: "" });
      setReRender(!reRender);
      handleScrollToTop();
      toast.success("Variant added successfully!");
    } catch (error) {
      toast.error("Variant addition failed!");
    }
  };

  return (
    <>
      <VariantDetail
        options={options}
        setOptions={setOptions}
        variantAdded="variantAdded"
        saveVariant={saveVariant}
        newVariantDetail={newVariantDetail}
        setNewVariantDetail={setNewVariantDetail}
        shopifyProductId={shopifyProductId}
      />
    </>
  );
}

export default VariantAdd;

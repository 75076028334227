import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Card,
  Badge,
  CardBody,
  CardTitle,
  CardText,
  Form,
  Collapse,
} from "reactstrap";
import { Cols } from "../SeparatorStyle/SeparatorStyle";

const BillInvoice = () => {
  const history = useHistory();
  const [toggleSubscription, setToggleSubscription] = useState(false);
  const [toggleApps, setToggleApps] = useState(false);
  const [togglePaymentTimeline, setTogglePaymentTimeline] = useState(false);

  return (
    <>
      <main>
        <Row>
          <Cols xxs="12">
            <div className="mb-2 d-flex justify-content-between align-items-center">
              <div className="d-flex">
                <i
                  onClick={() => history.goBack()}
                  className="simple-icon-arrow-left-circle fw-bold mr-3 fs-3 pointer"
                ></i>
                <h2 className="mb-4">
                  <b>Current Billing Cycle: 11 Nov 2021</b>
                </h2>
              </div>
              <div className="d-flex text-black fw-bold mr-4">
                <p className="mr-5">Export CSV File</p>
                <p className="">Download</p>
              </div>
            </div>
          </Cols>
        </Row>
        <br />
        <Form>
          <Row>
            <Cols xxs="12" className="row icon-cards-row mb-2">
              <Cols xs="12" sm="12" md="7" lg="8" className="mb-4 ">
                <Card>
                  <CardBody>
                    <CardTitle className="ml-3">
                      <h5>
                        <b>Subscriptions</b>
                        <span style={{ fontSize: "small" }}> (1 Item)</span>
                        {toggleSubscription ? (
                          <span
                            className="simple-icon-arrow-up fw-bold fs-6 mt-1 mx-2 float-end"
                            onClick={() =>
                              setToggleSubscription(!toggleSubscription)
                            }
                          ></span>
                        ) : (
                          <span
                            className="simple-icon-arrow-down fw-bold fs-6 mt-1 mx-2 float-end"
                            onClick={() =>
                              setToggleSubscription(!toggleSubscription)
                            }
                          ></span>
                        )}
                        <b style={{ marginRight: "5px", float: "right" }}>
                          $29.00
                        </b>
                      </h5>
                    </CardTitle>
                    <Collapse isOpen={toggleSubscription}>
                      <div
                        style={{
                          backgroundColor: "whitesmoke",
                          paddingTop: "15px",
                        }}
                      >
                        <CardText
                          className="mb-3 ml-3 mr-3"
                          style={{
                            color: "black",
                            backgroundColor: "whitesmoke",
                          }}
                        >
                          <b>Customer Service</b>
                          <b style={{ float: "right", marginRight: "5px" }}>
                            $29.00
                          </b>
                          <br />
                          <span>Every 30 Day</span>
                        </CardText>
                      </div>
                    </Collapse>
                    <CardTitle className="ml-3">
                      <h5>
                        <b>Apps</b>
                        <span style={{ fontSize: "small" }}> (1 Item)</span>
                        {toggleApps ? (
                          <span
                            className="simple-icon-arrow-up fw-bold fs-6 mt-1 mx-2 float-end"
                            onClick={() => setToggleApps(!toggleApps)}
                          ></span>
                        ) : (
                          <span
                            className="simple-icon-arrow-down fw-bold fs-6 mt-1 mx-2 float-end"
                            onClick={() => setToggleApps(!toggleApps)}
                          ></span>
                        )}
                        <b style={{ marginRight: "5px", float: "right" }}>
                          $5.00
                        </b>
                      </h5>
                    </CardTitle>
                    <Collapse isOpen={toggleApps}>
                      <div
                        style={{
                          backgroundColor: "whitesmoke",
                          paddingTop: "15px",
                        }}
                      >
                        <CardText
                          className="mb-3 ml-3 mr-3"
                          style={{
                            color: "black",
                            backgroundColor: "whitesmoke",
                          }}
                        >
                          <p style={{ textDecoration: "underline" }}>
                            Exportible
                          </p>
                          <b style={{ float: "right", marginRight: "5px" }}>
                            $5.00
                          </b>
                          <br />
                          <span>Basic 21-04-2021 21-04-2022</span>
                        </CardText>
                      </div>
                    </Collapse>
                    <br />
                    <div style={{ float: "right" }}>
                      <h6>
                        <span className="mr-5">Subtotal</span>
                        <span className="mr-4">$34.00 USD</span>
                      </h6>
                    </div>
                    <br />
                    <hr />
                    <div style={{ float: "right" }}>
                      <h6>
                        <span className="mr-5">
                          <b>Total</b>
                        </span>
                        <span className="mr-4">
                          <b>$34.00 USD</b>
                        </span>
                      </h6>
                    </div>
                  </CardBody>
                </Card>
              </Cols>

              <Cols className="mb-4">
                <Card>
                  <CardBody>
                    <CardTitle style={{ textAlign: "center", color: "black" }}>
                      <h2>
                        <b>$34.00 USD</b>
                      </h2>
                      <br />
                      <div
                        className="text-center"
                        style={{ backgroundColor: "lightgreen" }}
                      >
                        <h4 style={{ padding: "12px" }}>
                          <b>Paid</b>
                        </h4>
                      </div>
                    </CardTitle>
                    <Row className="mb-3 ml-1">
                      <Cols>
                        <div>
                          <p>
                            <b>PRIMARY PAYMENT</b>
                            <div>
                              <Badge
                                color="light"
                                style={{ display: "inline", color: "navy" }}
                                className="mr-2"
                              >
                                VISA
                              </Badge>
                              <span style={{ display: "inline" }}>
                                Visa ending in 6590
                              </span>
                            </div>
                          </p>
                        </div>
                        <div>
                          <p>
                            <b>BILL NUMBER</b>
                            <div>
                              <span style={{ display: "inline" }}>
                                124926594
                              </span>
                            </div>
                          </p>
                        </div>
                      </Cols>
                    </Row>
                    <hr />
                    <Row>
                      <Cols>
                        <div className="ml-3" style={{ color: "black" }}>
                          <b>PAYMENT TIMELINE</b>
                          {togglePaymentTimeline ? (
                            <span
                              className="simple-icon-arrow-up fw-bold fs-6 mt-1 mx-2 float-end"
                              onClick={() =>
                                setTogglePaymentTimeline(!togglePaymentTimeline)
                              }
                            ></span>
                          ) : (
                            <span
                              className="simple-icon-arrow-down fw-bold fs-6 mt-1 mx-2 float-end"
                              onClick={() =>
                                setTogglePaymentTimeline(!togglePaymentTimeline)
                              }
                            ></span>
                          )}
                          <br />
                          <span>Bill Paid </span>
                          <br />
                          <span>11 Nov 2021</span>
                        </div>
                        <div>
                          <Collapse isOpen={togglePaymentTimeline}>
                            <br />
                            <div
                              style={{
                                backgroundColor: "whitesmoke",
                                padding: "10px",
                              }}
                            >
                              <div className="container-fluid ml-2">
                                <span>Bill Created </span>
                                <br />
                                <span>11 Nov 2021</span>
                                <br />
                                <span>Bill Paid </span>
                                <br />
                                <span>11 Nov 2021</span>
                              </div>
                            </div>
                          </Collapse>
                        </div>
                      </Cols>
                    </Row>
                  </CardBody>
                </Card>
              </Cols>
            </Cols>
          </Row>
          <div className="container mb-3 mt-3">
            <Row>
              <Cols xxs="12" md="4" lg="4" className="text-left">
                <p>
                  <h3>
                    <b>Understanding Your Bill</b>
                  </h3>
                </p>
                <h6>SUBSCRIPTIONS</h6>
                <p>
                  This is the charge for your shopify plan.If you've changed
                  plans mid-billing cycle, a subscription credit will be applied
                  in this section.
                </p>
                <h6>APPS</h6>
                <p>
                  App charges are set by the app developer. App developer can be
                  charge for apps with recurring fees, usage fees, and one-time
                  fees.If you have an issue with an app charge, contact the
                  developer for help.
                </p>
              </Cols>
              <Cols xxs="12" md="4" lg="4">
                <h6>SHIPPING</h6>
                <p>
                  These are the charges for your shipping labels and adjustments
                  during your billing cycle.
                </p>
                <h6>TRANSACTION FEE</h6>
                <p>
                  These fees are included in your bill if you aren't using
                  Shopilam Payments. Shopilam Payments fees are deducted
                  directly from your transactions, based on your credit card
                  rate.
                </p>
              </Cols>
            </Row>
          </div>
          <p className="text-center mt-2">
            Email{" "}
            <a
              href="https://accounts.google.com/b/0/AddMailService"
              style={{ textDecoration: "underline" }}
            >
              bill@shopilam.com{" "}
            </a>
            if you have any question about your Shopilam bill.
          </p>
        </Form>
      </main>
    </>
  );
};

export default BillInvoice;

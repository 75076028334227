import { Row, Card, Col, CardBody } from 'reactstrap';
import { motion } from 'framer-motion';
import { animationTop } from '../FramerMotion/FramerMotion';

const CardWrapper = (props) => {
  return (
    <>
      <div className="fixed-background" />
      <motion.div
        className="d-flex justify-content-center align-items-center main-wrapper margin_90"
        variants={animationTop}
        initial="hidden"
        animate="visible"
      >
        <div className="container">
          <Row className="justify-content-center  my-5">
            <Col className="col-md-12 ">
              <Row className="justify-content-center">
                <Col className="col-xxl-4 col-lg-5 col-md-8 col-sm-10 col-12">
                  <Card className="h-100 border_radius_15">
                    <CardBody className="px-4 py-0 mt-3 d-flex flex-column justify-content-center">
                      {props.children}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </motion.div>
    </>
  );
};

export default CardWrapper;

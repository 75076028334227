import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
} from "reactstrap";
import { Cols } from "../SeparatorStyle/SeparatorStyle";
import isEmpty from "validator/lib/isEmpty";
import {
  ErrorMessage,
  WarningMessage,
  SuccessMessage,
} from "../Messages/message.jsx";
import { checkPassword } from "../../api/auth";
import { deleteShopifyStores } from "../../api/store";

const DeleteStore = ({ toggle, modal, storeId, shopName, setModal }) => {
  const [password, setPassword] = useState("");
  const [passwordErrorMsg, setPasswordErrorMsg] = useState("");
  const [storeDeleteErrorMsg, setStoreDeleteErrorMsg] = useState("");
  const [storeDeleteSuccessMsg, setStoreDeleteSuccessMsg] = useState("");
  const [passwordWarningMsg, setPasswordWarningMsg] = useState("");
  const [isLoader, setIsLoader] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    setPasswordErrorMsg("");
    setPasswordWarningMsg("");
    setPassword(e.target.value);
    setStoreDeleteErrorMsg("");
    setStoreDeleteSuccessMsg("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEmpty(password)) {
      setIsLoader(false);
      setPasswordWarningMsg("Please Enter Your Password");
    } else {
      setIsLoader(true);
      checkPassword(password)
        .then((res) => {
          deleteShopifyStores(storeId)
            .then((response) => {
              setPassword("");
              setIsLoader(false);
              setStoreDeleteSuccessMsg(response.data.successMessage);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((error) => {
              setIsLoader(false);
              setStoreDeleteErrorMsg(error.response.data.errorMessage);
            });
        })
        .catch((err) => {
          setIsLoader(false);
          setPasswordErrorMsg(err.response.data.errorMessage);
        });
    }
  };

  const CancelModel = () => {
    setPassword("");
    setModal(false);
  };

  return (
    <div>
      <Modal isOpen={modal}>
        <ModalHeader toggle={toggle}>Delete Your Store</ModalHeader>
        <ModalBody>
          <Row className="row h-100">
            <Cols xxs="12" md="10" className="mx-auto my-auto">
              <div className="form-side">
                <Form className="av-tooltip tooltip-label-bottom" noValidate>
                  {passwordErrorMsg !== ""
                    ? ErrorMessage(passwordErrorMsg)
                    : null}
                  {passwordWarningMsg !== ""
                    ? WarningMessage(passwordWarningMsg)
                    : null}
                  {storeDeleteErrorMsg !== ""
                    ? ErrorMessage(storeDeleteErrorMsg)
                    : null}
                  {storeDeleteSuccessMsg !== ""
                    ? SuccessMessage(storeDeleteSuccessMsg)
                    : null}
                  <FormGroup className="form-group has-float-label">
                    If you want to delete your store <b>"{shopName}"</b> from
                    shopilam. Then first enter your password. To make sure that
                    you are the owner of this account.
                  </FormGroup>
                  <FormGroup className="form-group has-float-label">
                    <Label>Enter Your Password</Label>
                    <Input
                      className="form-control"
                      type="password"
                      name="password"
                      value={password}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Form>
              </div>
            </Cols>
          </Row>
        </ModalBody>
        <ModalFooter>
          {isLoader === true ? (
            <Button color="primary" disabled>
              Cancel
            </Button>
          ) : (
            <Button color="primary" onClick={CancelModel}>
              Cancel
            </Button>
          )}
          {isLoader === true ? (
            <Button color="secondary" disabled>
              <div
                style={{ width: "40px", height: "40px" }}
                className="loading"
              />
              Delete Your Store
            </Button>
          ) : (
            <Button color="secondary" onClick={handleSubmit}>
              Delete Your Store
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteStore;

import React from "react";
import { Row, Card, CardBody, CardTitle, Button } from "reactstrap";
import { Cols } from "../SeparatorStyle/SeparatorStyle";

const Apps = () => {
  return (
    <>
      <main>
        <div className="container mb-5">
          <h2>
            <b>Apps</b>
            <Button
              type="submit"
              color="success"
              style={{ display: "inline", float: "right" }}
            >
              Customize your store
            </Button>
          </h2>
          <hr />
        </div>
        <br />
        <Row>
          <Cols xxs="12" className="row icon-cards-row mb-2">
            <Cols className="mb-4">
              <Card>
                <CardBody className="m-2">
                  <div>
                    <CardTitle tag="h6" className="mb-1">
                      <b>Installed App</b>
                    </CardTitle>
                  </div>
                  <Row>
                    <Cols xxs="6" sm="6" md="6" lg="6">
                      <p className="mt-3">Showing 7 Apps</p>
                    </Cols>
                    <Cols xxs="6" sm="6" md="6" lg="6">
                      <input
                        className="form-control"
                        style={{
                          float: "right",
                          verticalAlign: "top",
                          width: "50%",
                        }}
                        type="number"
                        placeholder="Sort by App Name(A-Z)"
                      ></input>
                    </Cols>
                  </Row>
                  <hr />
                  {/*<div>*/}
                  {/*    <CardTitle tag="h6" className='mb-4'>*/}
                  {/*        <div style={{ display: 'inline' }}>*/}
                  {/*            <img src='demo.png' alt='pic' style={{ height: '40px', width: '40px' }}></img>*/}
                  {/*        </div>*/}
                  {/*        <div className='ml-2' style={{ display: 'inline' }}> <b style={{ fontSize: '14px' }}>Arena Dashboard</b></div>*/}
                  {/*        <span className='float-right mr-2 mt-3' style={{ fontSize: '14px' }}>*/}
                  {/*            <span className='mr-3'>*/}
                  {/*                <a style={{ color: '#0275d8' }}>About</a>*/}
                  {/*            </span>*/}
                  {/*            <a style={{ color: 'red' }}>Delete</a>*/}
                  {/*        </span>*/}
                  {/*    </CardTitle>*/}
                  {/*</div><hr />*/}
                  {/*<div>*/}
                  {/*    <CardTitle tag="h6" className='mb-4'>*/}
                  {/*        <div style={{ display: 'inline' }}>*/}
                  {/*            <img src='demo.png' alt='pic' style={{ height: '40px', width: '40px' }}></img>*/}
                  {/*        </div>*/}
                  {/*        <div className='ml-2' style={{ display: 'inline' }}> <b style={{ fontSize: '14px' }}>Exportible</b></div>*/}
                  {/*        <span className='float-right mr-2 mt-3' style={{ fontSize: '14px' }}>*/}
                  {/*            <span className='mr-3'>*/}
                  {/*                <a style={{ color: '#0275d8' }}>Get Support</a>*/}
                  {/*            </span>*/}
                  {/*            <span className='mr-3'>*/}
                  {/*                <a style={{ color: '#0275d8' }}>About</a>*/}
                  {/*            </span>*/}
                  {/*            <a style={{ color: 'red' }}>Delete</a>*/}
                  {/*        </span>*/}
                  {/*    </CardTitle>*/}
                  {/*</div><hr />*/}
                  {/*<div>*/}
                  {/*    <CardTitle tag="h6" className='mb-4'>*/}
                  {/*        <div style={{ display: 'inline' }}>*/}
                  {/*            <img src='demo.png' alt='pic' style={{ height: '40px', width: '40px' }}></img>*/}
                  {/*        </div>*/}
                  {/*        <div className='ml-2' style={{ display: 'inline' }}> <b style={{ fontSize: '14px' }}>Arena Dashboard</b></div>*/}
                  {/*        <span className='float-right mr-2 mt-3' style={{ fontSize: '14px' }}>*/}
                  {/*            <span className='mr-3'>*/}
                  {/*                <a style={{ color: '#0275d8' }}>Get Support</a>*/}
                  {/*            </span>*/}
                  {/*            <span className='mr-3'>*/}
                  {/*                <a style={{ color: '#0275d8' }}>About</a>*/}
                  {/*            </span>*/}
                  {/*            <a style={{ color: 'red' }}>Delete</a>*/}
                  {/*        </span>*/}
                  {/*    </CardTitle>*/}
                  {/*</div><hr />*/}
                  {/*<div>*/}
                  {/*    <CardTitle tag="h6" className='mb-4'>*/}
                  {/*        <div style={{ display: 'inline' }}>*/}
                  {/*            <img src='demo.png' alt='pic' style={{ height: '40px', width: '40px' }}></img>*/}
                  {/*        </div>*/}
                  {/*        <div className='ml-2' style={{ display: 'inline' }}> <b style={{ fontSize: '14px' }}>Facebook</b></div>*/}
                  {/*        <span className='float-right mr-2 mt-3'  style={{ fontSize: '14px' }}>*/}
                  {/*            <span className='mr-3'>*/}
                  {/*                <a style={{ color: '#0275d8' }}>Get Support</a>*/}
                  {/*            </span>*/}
                  {/*            <span className='mr-3'>*/}
                  {/*                <a style={{ color: '#0275d8' }}>About</a>*/}
                  {/*            </span>*/}
                  {/*            <a style={{ color: 'red' }}>Delete</a>*/}
                  {/*        </span>*/}
                  {/*    </CardTitle>*/}
                  {/*</div><hr />*/}
                  {/*<div>*/}
                  {/*    <CardTitle tag="h6" className='mb-4'>*/}
                  {/*        <div style={{ display: 'inline' }}>*/}
                  {/*            <img src='demo.png' alt='pic' style={{ height: '40px', width: '40px' }}></img>*/}
                  {/*        </div>*/}
                  {/*        <div className='ml-2' style={{ display: 'inline' }}> <b style={{ fontSize: '14px' }}>Leopard Courier</b></div>*/}
                  {/*        <span className='float-right mr-2 mt-3'  style={{ fontSize: '14px' }}>*/}
                  {/*            <span className='mr-3'>*/}
                  {/*                <a style={{ color: '#0275d8' }}>Get Support</a>*/}
                  {/*            </span>*/}
                  {/*            <span className='mr-3'>*/}
                  {/*                <a style={{ color: '#0275d8' }}>About</a>*/}
                  {/*            </span>*/}
                  {/*            <a style={{ color: 'red' }}>Delete</a>*/}
                  {/*        </span>*/}
                  {/*    </CardTitle>*/}
                  {/*</div><hr />*/}
                  {/*<div>*/}
                  {/*    <CardTitle tag="h6" className='mb-4'>*/}
                  {/*        <div style={{ display: 'inline' }}>*/}
                  {/*            <img src='demo.png' alt='pic' style={{ height: '40px', width: '40px' }}></img>*/}
                  {/*        </div>*/}
                  {/*        <div className='ml-2' style={{ display: 'inline' }}> <b style={{ fontSize: '14px' }}>Rio Hunter Easy</b></div>*/}
                  {/*        <span className='float-right mr-2 mt-3' style={{ fontSize: '14px' }}>*/}
                  {/*            <span className='mr-3'>*/}
                  {/*                <a style={{ color: '#0275d8' }}>Get Support</a>*/}
                  {/*            </span>*/}
                  {/*            <span className='mr-3'>*/}
                  {/*                <a style={{ color: '#0275d8' }}>About</a>*/}
                  {/*            </span>*/}
                  {/*            <a style={{ color: 'red' }}>Delete</a>*/}
                  {/*        </span>*/}
                  {/*    </CardTitle>*/}
                  {/*</div><hr />*/}
                  {/*<div>*/}
                  {/*    <CardTitle tag="h6" className='mb-4'>*/}
                  {/*        <div style={{ display: 'inline' }}>*/}
                  {/*            <img src='demo.png' alt='pic' style={{ height: '40px', width: '40px' }}></img>*/}
                  {/*        </div>*/}
                  {/*        <div className='ml-2' style={{ display: 'inline' }}> <b style={{ fontSize: '14px' }}>Shop Workflow Automation</b></div>*/}
                  {/*        <span className='float-right mr-2 mt-3'  style={{ fontSize: '14px' }}>*/}
                  {/*            <span className='mr-3'>*/}
                  {/*                <a style={{ color: '#0275d8' }}>Get Support</a>*/}
                  {/*            </span>*/}
                  {/*            <span className='mr-3'>*/}
                  {/*                <a style={{ color: '#0275d8' }}>About</a>*/}
                  {/*            </span>*/}
                  {/*            <a style={{ color: 'red' }}>Delete</a>*/}
                  {/*        </span>*/}
                  {/*    </CardTitle>*/}
                  {/*</div><hr />*/}
                  {/*<div>*/}
                  {/*    <CardTitle tag="h6" className='mb-4'>*/}
                  {/*        <div style={{ display: 'inline' }}>*/}
                  {/*            <img src='demo.png' alt='pic' style={{ height: '40px', width: '40px' }}></img>*/}
                  {/*        </div>*/}
                  {/*        <div className='ml-2' style={{ display: 'inline' }}> <b style={{ fontSize: '14px' }}>Sonix - Trax</b></div>*/}
                  {/*        <span className='float-right mr-2 mt-3' style={{ fontSize: '14px' }}>*/}
                  {/*            <span className='mr-3'>*/}
                  {/*                <a style={{ color: '#0275d8' }}>About</a>*/}
                  {/*            </span>*/}
                  {/*            <a style={{ color: 'red' }}>Delete</a>*/}
                  {/*        </span>*/}
                  {/*    </CardTitle>*/}
                  {/*</div>*/}
                </CardBody>
              </Card>
            </Cols>
          </Cols>
        </Row>
      </main>
    </>
  );
};

export default Apps;

import React from "react";
import { PopoverHeader, PopoverBody, UncontrolledPopover } from "reactstrap";

export const ProductDetails = ({ productDetails }) => {
  return (
    <div>
      {productDetails && productDetails ? (
        <UncontrolledPopover
          trigger="hover"
          placement="bottom"
          target="productDetailPopover"
        >
          <>
            <PopoverHeader>Product Details</PopoverHeader>
            <PopoverBody>
              {productDetails &&
                productDetails.map((data) => (
                  <ul key={Math.random()}>
                    {data ? (
                      <li style={{ listStyle: "none" }} key={Math.random()}>
                        {" "}
                        {data}
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                ))}
            </PopoverBody>
          </>
        </UncontrolledPopover>
      ) : (
        ""
      )}
    </div>
  );
};

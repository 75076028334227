export const extractFulfilledAndUnfulfilled = (data) => {
  let fulfilledItems = [];
  let unFulfilledItems = [];

  data.fulfillments.forEach((fulfillment) => {
    let lineItems = [];
    fulfillment.line_items.forEach((item) => {
      if (
        item.fulfillment_status === "partial" ||
        item.fulfillment_status === "fulfilled"
      ) {
        lineItems.push(item);
      }
    });
    fulfilledItems.push({
      fulfillmentId: fulfillment.id,
      lineItems,
      lineItemsCount: lineItems.length,
      trackingNumber: fulfillment.tracking_number,
    });
  });

  data.line_items.forEach((item) => {
    // fulfillment_status can be partial | fulfilled | null
    if (
      item.fulfillment_status === "partial" ||
      item.fulfillment_status === null
    ) {
      unFulfilledItems.push(item);
    }
  });

  return { fulfilledItems, unFulfilledItems };
};

import axios from "axios";
import { getCookie } from "../helpers/cookie";
import { getAPIURL } from "./utils";
const url = getAPIURL();

export const saveVariants = async (correctName, typedName, orderId) => {
  const token = getCookie();
  const data = { correctName, typedName, orderId };
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  let response;
  try {
    response = await axios.post(`${url}/api/cityVariants`, data, config);
  } catch (e) {
    response = e;
  }

  return response;
};

export const updateOrderFields = async (input, toUpdate, orderId) => {
  const token = getCookie();
  const data = { input, toUpdate, orderId };
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  let response;
  try {
    response = await axios.post(
      `${url}/api/orders/updateOrderFields`,
      data,
      config
    );
  } catch (e) {
    response = e;
  }

  return response;
};

export const updateShippingCityFromOrders = async (typedName, orderId) => {
  const token = getCookie();
  const data = { typedName, orderId };
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  let response;
  try {
    response = await axios.post(
      `${url}/api/cityVariants/updateOrderShipping`,
      data,
      config
    );
    console.log("api response from update shipping city order is", response);
  } catch (e) {
    response = e;
  }

  return response;
};

export const updateShippingConsigneeName = async (typedName, orderId) => {
  const token = getCookie();
  const data = { typedName, orderId };
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  let response;
  try {
    response = await axios.post(
      `${url}/api/updateShippingConsigneeName`,
      data,
      config
    );
    console.log("api response from update shipping city order is", response);
  } catch (e) {
    response = e;
  }

  return response;
};
export const updateShippingConsigneePhone = async (typedName, orderId) => {
  const token = getCookie();
  const data = { typedName, orderId };
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  let response;
  try {
    response = await axios.post(
      `${url}/api/updateShippingConsigneePhone`,
      data,
      config
    );
    console.log("api response from update shipping city order is", response);
  } catch (e) {
    response = e;
  }

  return response;
};
export const updateShippingConsigneeAddress = async (typedName, orderId) => {
  const token = getCookie();
  const data = { typedName, orderId };
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  let response;
  try {
    response = await axios.post(
      `${url}/api/updateShippingConsigneeAdress`,
      data,
      config
    );
    console.log("api response from update shipping city order is", response);
  } catch (e) {
    response = e;
  }

  return response;
};

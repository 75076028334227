import axios from "axios";
import { getCookie } from "../helpers/cookie";
import { getAPIURL } from "./utils";

const url = getAPIURL();

export const storeCustomers = async () => {
  const token = getCookie();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  let response;
  try {
    response = await axios.post(
      `${url}/api/customers/allCustomers`,
      null,
      config
    );
  } catch (e) {
    response = e;
  }

  return response;
};

export const getAllCustomers = async ({
  page = 1,
  limit = 50,
  title,
  sorting,
} = {}) => {
  let token = getCookie();
  const params = new URLSearchParams();
  params.append("page", page.toString());
  params.append("limit", limit.toString());
  params.append("search", title?.trim());
  params.append('sorting', sorting);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  };
  return axios.get(`${url}/api/customers/getAllCustomersOfStore`, config);
};

export const getAllFilterCustomers = async ({
  page = 1,
  limit = 50,
  searchValue,
  sortNameType,
} = {}) => {
  let token = getCookie();
  const params = new URLSearchParams();
  params.append("page", page);
  params.append("limit", limit);
  params.append("searchValue", searchValue.trim());
  params.append("sortNameType", sortNameType);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  };
  return axios.get(`${url}/api/customers/getAllFilterCustomersOfStore`, config);
};

export const updateCustomerPhone = async (customer) => {
  let token = getCookie();
  const params = new URLSearchParams();
  params.append("_id", customer.customerId);
  params.append("phone", customer.phone);
  params.append("storeId", customer.storeId);
  params.append("defaultAddressId", customer.defaultAddressId);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  };
  return axios.get(`${url}/api/customers/updateCustomerPhone`, config);
};

// Get today's date in local time
const today = new Date(new Date().setHours(0, 0, 0, 0));
const endDateWithTime = new Date(new Date().setHours(23, 59, 59, 0));

// Get yesterday's date in local time
const yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1);

// Get the date 7 days ago in local time
const lastWeek = new Date(today);
lastWeek.setDate(today.getDate() - 6);

// Get the date 15 days ago in local time
const last15Days = new Date(today);
last15Days.setDate(today.getDate() - 14);

// Get the date 30 days ago in local time
const last30Days = new Date(today);
last30Days.setDate(today.getDate() - 29);

// Get the date 90 days ago in local time
const last90Days = new Date(today);
last90Days.setDate(today.getDate() - 89);

// Convert dates to ISO 8601 format
const isoYesterday = yesterday.toISOString();
const isoLastWeek = lastWeek.toISOString();
const isoLast15Days = last15Days.toISOString();
const isoLast30Days = last30Days.toISOString();
const isoLast90Days = last90Days.toISOString();
const isoToday = today.toISOString();
const isoTodayEnd = endDateWithTime.toISOString();
// console.log({
//   isoToday,
//   isoTodayEnd,
//   isoYesterday,
//   isoLastWeek,
//   isoLast15Days,
//   isoLast30Days,
//   isoLast90Days,
// });
module.exports = {
  isoToday,
  isoTodayEnd,
  isoYesterday,
  isoLastWeek,
  isoLast15Days,
  isoLast30Days,
  isoLast90Days,
};

import React, { useState } from "react";
import {
  Row,
  Card,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CardBody,
  Spinner,
} from "reactstrap";
import { savedCouriers, savedUpdatedCouriers } from "../../api/courier";
import { ErrorMessage } from "../Messages/message";
import { useHistory, NavLink } from "react-router-dom";
import { Cols } from "../SeparatorStyle/SeparatorStyle";

const CourierSelectionList = () => {
  let history = useHistory();
  const [nationCity] = useState(history.location.state);
  const [checkbox, setCheckbox] = useState(false);
  const [checkboxIndex, setCheckboxIndex] = useState();
  const { state, courierUpdate } = nationCity;
  const [selectedOptions, setSelectedOptions] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [courierSelectionSpinner, setCourierSelectionSpinner] = useState(false);

  const handleChange = (e, index) => {};

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const checkboxHandler = (e, index, data) => {
    if (data === selectedOptions) {
      setSelectedOptions("");
    } else {
      setSelectedOptions(data);
    }
    setCheckbox(e.target.checked);
    setCheckboxIndex(index);
  };

  const SubmitCourier = async (e) => {
    e.preventDefault();
    if (state && state.length !== 0 && selectedOptions) {
      setCourierSelectionSpinner(true);
      if (courierUpdate && courierUpdate) {
        await savedUpdatedCouriers(state && state);
        setTimeout(() => {
          history.push("citiesCouriers", selectedOptions);
        }, 1000);
      } else {
        setCourierSelectionSpinner(true);
        await savedCouriers(state && state);
        setTimeout(() => {
          history.push("citiesCouriers", selectedOptions);
        }, 1000);
      }
    } else {
      setErrorMessage("Please Select One Nation Courier!");
      handleScrollToTop();
    }
  };

  return (
    <div>
      <Row>
        <Cols
          xxs="12"
          md="10"
          className="mx-auto d-flex justify-content-center"
        >
          <div
            className="same-line login-card form-side"
            style={{ margin: "3rem" }}
          >
            <span className="on-the-center">
              <NavLink to="/" className="white row justify-content-center">
                <span className="logo-single" />
              </NavLink>
            </span>
            <span className="on-the-right h6" disabled={checkbox}>
              {/* <NavLink to="/">Provide Later</NavLink> */}
              Provide Later
            </span>
          </div>
        </Cols>
      </Row>
      <div className="mb-5  d-flex justify-content-center">
        {errorMessage !== "" ? ErrorMessage(errorMessage) : null}
      </div>

      {nationCity?.state?.map((data, index) => (
        <Row className="justify-content-center">
          <Cols sm="8" xxs="9" md="8" lg="8" xl="5">
            <Card className="courier-selection-list mb-4 mx-auto ">
              <CardBody>
                <CardTitle className="mb-4 text-align-center">
                  <span className="bold">{data.label} Courier Keys</span>
                </CardTitle>
                <Form className="av-tooltip tooltip-label-bottom" noValidate>
                  <FormGroup>
                    <Label>API Key</Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="apiKey"
                      onChange={(e) => handleChange(e, index)}
                    />
                  </FormGroup>
                  {data.label === "Swyft" ||
                  data.label === "M&P" ||
                  data.label === "TPL" ? (
                    <FormGroup>
                      <Label>Secret Key</Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="SecretKey"
                        onChange={(e) => handleChange(e, index)}
                      />
                    </FormGroup>
                  ) : (
                    data.label === "Leopard" && (
                      <FormGroup>
                        <Label>Password</Label>
                        <Input
                          className="form-control"
                          type="password"
                          name="password"
                          onChange={(e) => handleChange(e, index)}
                        />
                      </FormGroup>
                    )
                  )}
                  <div className="mb-3">
                    <Input
                      type="checkbox"
                      className="select-keys"
                      checked={checkboxIndex === index && checkbox}
                      onChange={(e) => {
                        checkboxHandler(e, index, data);
                      }}
                    />
                    <span className="ms-3">This is my NationWide Courier</span>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Button
                      type="button"
                      outline
                      color="primary"
                      size="lg"
                      className="py-2"
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Cols>
        </Row>
      ))}
      <footer className="d-flex justify-content-center">
        <Button
          type="button"
          outline
          color="primary"
          size="lg"
          className="py-2 my-4"
          style={courierSelectionSpinner ? { padding: "10px 69px" } : null}
          onClick={SubmitCourier}
        >
          {courierSelectionSpinner ? (
            <Spinner size={"sm"}>Loading...</Spinner>
          ) : (
            "Update"
          )}
        </Button>
      </footer>
    </div>
  );
};

export default CourierSelectionList;

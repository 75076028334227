import React from "react";
import { PopoverBody, UncontrolledPopover } from "reactstrap";

import { UnFulfillOrderDetailsPopup } from "../../../Modals/Popups.jsx";

export const showCustomerDetail = (userDetail, i) => {
  return (
    <>
      {userDetail?.shipping_address?.name?.slice(0, 20) || "N/A"}
      {userDetail?.shipping_address?.name?.length > 20 ? " ... " : " "}
      <i className="simple-icon-info pointer" id={`Customer-${i}`}>
        <UncontrolledPopover
          trigger="hover"
          placement="top"
          target={`Customer-${i}`}
        >
          <PopoverBody className="text-center">
            <>
              <h6>Customer Details</h6>
              <ul className=" list-unstyled">
                <li>{userDetail?.shipping_address?.name || "N/A"}</li>
                <li>
                  {userDetail?.shipping_address?.city || "N/A"} ,{" "}
                  {userDetail?.shipping_address?.country || "N/A"}
                </li>

                <li>
                  {userDetail?.customer?.orders_count || "N/A"}{" "}
                  {userDetail?.customer?.orders_count > 1 ? "orders" : "order"}
                </li>

                <li>{userDetail?.customer?.email || "N/A"}</li>

                <li>{userDetail?.shipping_address?.phone || "N/A"}</li>
              </ul>
            </>
          </PopoverBody>
        </UncontrolledPopover>
      </i>
    </>
  );
};

export const showItemDetails = (
  item,
  setItemDetailsData,
  itemDetailsData,
  i
) => {
  let hoverIndex;
  return (
    <div>
      {`${item.length}`}
      <i
        className="ms-2 simple-icon-info pointer "
        onMouseEnter={() => {
          hoverIndex = i;
          setItemDetailsData(item);
        }}
        onMouseLeave={() => {
          setItemDetailsData();
          hoverIndex = undefined;
        }}
        id={"unFulfillOrderItemDetails" + hoverIndex}
      ></i>
      {itemDetailsData && (
        <UnFulfillOrderDetailsPopup
          itemDetailsData={itemDetailsData}
          index={hoverIndex}
        />
      )}
    </div>
  );
};

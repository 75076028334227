import { Link } from "react-router-dom";
// import { getAllInvoices } from "../../../api/invoice";
import { Row, Card, Badge, CardBody, CardTitle } from "reactstrap";
import { Cols } from "../../SeparatorStyle/SeparatorStyle";

const BillingHistoryShow = () => {
  // const history = useHistory();
  // const [invoices, setInvoices] = useState([]);

  //Load latestInvoice at First Render
  // const loadInvoices = useCallback(async () => {
  //   try {
  //     const invoiceData = await getAllInvoices();
  //     setInvoices(invoiceData.data);
  //   } catch (error) {
  //     console.log("error found when fetch order data", error);
  //   }
  // }, []);

  // useEffect(() => {
  //   loadInvoices();
  // }, [loadInvoices]);

  // const DateFormat = (dateFormate) => {
  //   const date = new Date(dateFormate);

  //   let getMonth = date.toLocaleString('en-us', { month: 'short' });
  //   let fullYear = date.getFullYear(dateFormate);
  //   let getDate = date.getDate(dateFormate);

  //   return `${getDate} ${getMonth} ${fullYear}`;
  // };

  let today = new Date();
  let date =
    today.getDate() +
    " " +
    today.toLocaleString("en-us", { month: "short" }) +
    " " +
    today.getFullYear();

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <main>
        <div className="ml-3 mb-3">
          <Link
            to={`/admin/payments/billing`}
            onClick={() => {
              handleScrollToTop();
            }}
          >
            <button
              className="material-icons-outlined mr-3"
              style={{
                border: "2px solid gray",
                padding: "3px",
                display: "inline",
              }}
            >
              arrow_back
            </button>
          </Link>
          <h2 style={{ display: "inline" }}>
            <b>Billing </b>
          </h2>
        </div>
        <br />
        <Row>
          <Cols xxs="12" className="row icon-cards-row mb-2">
            <Cols className="mb-4">
              <Card className="mb-2">
                <CardBody className="mx-4">
                  <CardTitle tag="h6" className="mb-4 mt-2">
                    <strong>Recent Bills</strong>
                  </CardTitle>
                  <hr className="text-muted" />
                  <CardTitle
                    tag="h6"
                    className="my-2 mt-4 d-flex justify-content-between align-items-center"
                  >
                    <div className="d-flex gap-5">
                      <p className="mr-5">
                        <strong>Billing Cycle</strong>
                      </p>
                      <p>Created {date}</p>
                    </div>
                    <div className="d-flex gap-4">
                      <div>
                        <Badge color="warning" pill>
                          o Pending{" "}
                        </Badge>
                      </div>
                      <p>
                        <strong>Rs. 35,000</strong>
                      </p>
                    </div>
                  </CardTitle>
                  <hr className="text-muted" />
                  <CardTitle
                    tag="h6"
                    className="my-2 mt-4 d-flex justify-content-between align-items-center"
                  >
                    <div className="d-flex gap-5">
                      <p className="mr-5">
                        <strong>Billing Cycle</strong>
                      </p>
                      <p>Created {date}</p>
                    </div>
                    <div className="d-flex gap-4">
                      <div>
                        <Badge color="light" pill>
                          ● Paid
                        </Badge>
                      </div>
                      <p>
                        <strong>Rs. 50,000</strong>
                      </p>
                    </div>
                  </CardTitle>
                  <hr className="text-muted" />
                  <CardTitle
                    tag="h6"
                    className="my-2 mt-4 d-flex justify-content-between align-items-center"
                  >
                    <div className="d-flex gap-5">
                      <p className="mr-5">
                        <strong>Billing Cycle</strong>
                      </p>
                      <p>Created {date}</p>
                    </div>
                    <div className="d-flex gap-4">
                      <div>
                        <Badge color="light" pill>
                          ● Paid
                        </Badge>
                      </div>
                      <p>
                        <strong>Rs. 25,000</strong>
                      </p>
                    </div>
                  </CardTitle>
                  <hr className="text-muted" />
                  <CardTitle
                    tag="h6"
                    className="my-2 mt-4 d-flex justify-content-between align-items-center"
                  >
                    <div className="d-flex gap-5">
                      <p className="mr-5">
                        <strong>Billing Cycle</strong>
                      </p>
                      <p>Created {date}</p>
                    </div>
                    <div className="d-flex gap-4">
                      <div>
                        <Badge color="light" pill>
                          ● Paid
                        </Badge>
                      </div>
                      <p>
                        <strong>Rs. 25,000</strong>
                      </p>
                    </div>
                  </CardTitle>
                  <hr className="text-muted" />
                  <CardTitle
                    tag="h6"
                    className="my-2 mt-4 d-flex justify-content-between align-items-center"
                  >
                    <div className="d-flex gap-5">
                      <p className="mr-5">
                        <strong>Billing Cycle</strong>
                      </p>
                      <p>Created {date}</p>
                    </div>
                    <div className="d-flex gap-4">
                      <div>
                        <Badge color="light" pill>
                          ● Paid
                        </Badge>
                      </div>
                      <p>
                        <strong>Rs. 25,000</strong>
                      </p>
                    </div>
                  </CardTitle>
                  <hr className="text-muted" />
                  <CardTitle
                    tag="h6"
                    className="my-2 mt-4 d-flex justify-content-between align-items-center"
                  >
                    <div className="d-flex gap-5">
                      <p className="mr-5">
                        <strong>Billing Cycle</strong>
                      </p>
                      <p>Created {date}</p>
                    </div>
                    <div className="d-flex gap-4">
                      <div>
                        <Badge color="light" pill>
                          ● Paid
                        </Badge>
                      </div>
                      <p>
                        <strong>Rs. 25,000</strong>
                      </p>
                    </div>
                  </CardTitle>
                </CardBody>
              </Card>
            </Cols>
          </Cols>
        </Row>
      </main>
    </>
  );
};

export default BillingHistoryShow;

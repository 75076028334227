import React from "react";
import DateTimeRange from "../orders/DateTimeRange.jsx";
import { setDateStart, setDateEnd } from "../../helpers/dateTime";
import Select from "react-select";



  const DateFilters = ({
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    selectedDateFilter,
    setSelectedDateFilter,
    resetFilters,
    setFiltersLoader,
    loadOrders,
    paginationLoading,
    filtersLoader,
    allOrdersFlag,
    selectedOrdersInBulk,
  }) => {
    const dateFilterOptions = [
      { value: 0, label: "Today" },
      { value: 1, label: "Yesterday" },
      { value: 7, label: "Last week" },
      { value: 30, label: "Last month" },
    ];
  


  const filterByDateOrders = async (startDateIs, endDateIs) => {
    if (allOrdersFlag) {
      setFiltersLoader(true);
    }
    setStartDate(startDateIs);
    setEndDate(endDateIs);
  };

  const handleDateFilter = async (selectedDateFilter) => {
    setSelectedDateFilter(selectedDateFilter);
    if (selectedDateFilter) {
      const interval = selectedDateFilter.value;

      // from date
      const fromDateStartTime = setDateStart(new Date());
      fromDateStartTime.setDate(fromDateStartTime.getDate() - interval);
      const fromDateEndTime = setDateEnd(new Date());
      fromDateEndTime.setDate(fromDateEndTime.getDate() - interval);

      // to date
      const toDateStartTime = setDateStart(new Date());
      const toDateEndTime = setDateEnd(new Date());

      switch (selectedDateFilter.label) {
        case "Today":
          filterByDateOrders(toDateStartTime, toDateEndTime);
          break;

        case "Yesterday":
          filterByDateOrders(fromDateStartTime, fromDateEndTime);
          break;

        default:
          filterByDateOrders(fromDateStartTime, toDateEndTime);
          break;
      }
    } else {
      resetFilters();
    }
  };

  //     setSelectedDateFilter(selectedDateFilter);
  //     if (selectedDateFilter) {
  //         const interval = selectedDateFilter.value
  //         const startDatePassed = new Date()
  //         const today = setDateStart(startDatePassed)
  //         const startDateEndofDayPassed = new Date()
  //         const todayEndOfDay = setDateEnd(startDateEndofDayPassed)
  //         const pastDatePassed = new Date()
  //         const pastDate = setDateEnd(pastDatePassed)
  //         pastDate.setDate(pastDate.getDate() - interval)
  //         const pastDateStartOfDayPassed = new Date()
  //         const pastDateStartOfDay = setDateStart(pastDateStartOfDayPassed)
  //         pastDateStartOfDay.setDate(pastDateStartOfDay.getDate() - interval)
  //         selectedDateFilter.label === "Today" ? setStartDate(today) : setStartDate(pastDate)
  //         selectedDateFilter.label === "Today" ? setEndDate(pastDate) : selectedDateFilter.label === "Yesterday" ? setEndDate(pastDateStartOfDay) : setEndDate(today)
  //         // selectedDateFilter.label === "Yesterday" ? filterByDateOrders(pastDate, pastDate) : filterByDateOrders(pastDate, today)
  //         selectedDateFilter.label === "Today" ? filterByDateOrders(today, pastDate) : selectedDateFilter.label === "Yesterday" ? filterByDateOrders(pastDateStartOfDay, today)
  //             : filterByDateOrders(pastDate, todayEndOfDay)
  //     }
  //     else {
  //         resetFilters();
  //     }

  // };

  const onStartDateSelected = (date) => {
    if (date <= endDate || endDate === null) {
      if (date === null) {
        if (allOrdersFlag) {
          setFiltersLoader(true);
        }
        setStartDate(date); //check if date passed is null such as cross clicked
        resetStartDateFilter();
      } else {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        setStartDate(date);
        if (endDate !== null) {
          filterByDateOrders(date, endDate);
        }
      }
    } else if (date > endDate) {
      if (date === null) {
        if (allOrdersFlag) {
          setFiltersLoader(true);
        }
        setStartDate(date); //check if date passed is null such as cross clicked
        resetStartDateFilter();
      } else {
        alert("Start Date must be less then end date"); //Date would not be updated
      }
    }
  };

  const onEndDateSelected = (date) => {
    if (date >= startDate) {
      if (date === null) {
        if (allOrdersFlag) {
          setFiltersLoader(true);
        }
        setEndDate(date); //check if date passed is null such as cross clicked
        resetEndDateFilter();
      } else {
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        setEndDate(date);
        if (startDate !== null) {
          filterByDateOrders(startDate, date);
        }
      }
    } else if (date < startDate) {
      if (date === null) {
        if (allOrdersFlag) {
          setFiltersLoader(true);
        }
        setEndDate(date); //check if date passed is null such as cross clicked
        resetEndDateFilter();
      } else {
        alert("End date must be greater than start date."); //Date would not be updated
      }
    }
  };

  const resetStartDateFilter = async () => {
    setStartDate(null);
    setSelectedDateFilter(null);
    await loadOrders({});
  };

  const resetEndDateFilter = async () => {
    setEndDate(null);
    setSelectedDateFilter(null);
  };

  return (
    <div className=" d-flex flex-wrap mr-1">
      <DateTimeRange
        onStartDateSelected={onStartDateSelected}
        onEndDateSelected={onEndDateSelected}
        startDate={startDate}
        endDate={endDate}
        paginationLoading={paginationLoading}
        filtersLoader={filtersLoader}
        allOrdersFlag={"allOrdersFlag"}
        selectedOrdersInBulk={selectedOrdersInBulk}
      />
      <div className="openOrderSelect mb-2" style={{ width: "150px" }}>
        <Select
          value={selectedDateFilter}
          onChange={(e) => {
            handleDateFilter(e, selectedDateFilter);
          }}
          options={dateFilterOptions}
          placeholder="Date Filters"
          isClearable
          isDisabled={
            allOrdersFlag
              ? paginationLoading ||
                filtersLoader ||
                selectedOrdersInBulk?.length > 0
              : null
          }
        />
      </div>
    </div>
  );
};

export default DateFilters;

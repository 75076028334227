import React from "react";
import { Spinner } from "reactstrap";

const SpinnerButton = ({ btnText, isProcessing, handler }) => {
  return (
    <button
      disabled={isProcessing}
      onClick={handler}
      type="button"
      className="btn btn-primary custom-btn"
    >
      {btnText}
      {isProcessing ? (
        <Spinner
          style={{ width: "1rem", height: "1rem" }}
          type="grow"
          color="light"
        />
      ) : null}
    </button>
  );
};

export default SpinnerButton;

import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { MdContentCopy } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import { Button, Card, CardBody, Row, Tooltip } from "reactstrap";
import { Cols } from "../SeparatorStyle/SeparatorStyle";

export const CustomerDetails = () => {
  const data = [];
  const { id: customer_id } = useParams();
  const [customer, setcustomer] = useState();
  useEffect(() => {
    const arr = data.filter((obj) => String(obj.id) === String(customer_id));

    setcustomer(arr[0]);
  }, [customer_id]);

  return (
    <div>
      <main>
        <Row className="mobileMargin">
          <Cols xxs="12">
            <div className=" d-flex justify-content-between flex-wrap mb-3">
              <div className=" w-50 d-flex flex-column">
                <h1 className="my-0">
                  {customer?.first_name} {customer?.last_name}
                </h1>
                <p
                  className=" text-muted ms-1 pb-0 mb-0"
                  style={{ marginTop: "-10px" }}
                >
                  {customer?.default_address.city}
                  {", "}
                  {customer?.default_address.country}
                </p>
              </div>
              <div className=" d-inline">
                <Button
                  size="sm"
                  type="button"
                  color="primary"
                  className="mr-1 mt-2"
                >
                  Export History
                </Button>
                <Button
                  size="sm"
                  type="button"
                  color="danger"
                  outline
                  className="mr-1 mt-2"
                >
                  Delete Customer
                </Button>
              </div>
            </div>
          </Cols>
        </Row>
        <div className=" custom- px-3 container-bg pt-5 px-5">
          <Row className=" mobileMargin">
            <Cols md="8">
              <Card className="mb-4">
                <CardBody className="px-3 card-bg">
                  <div className="d-flex justify-content-around flex-wrap">
                    <div className="mt-1 d-flex flex-column text-center">
                      <Link to={"#"}>
                        <p className="py-0 my-0">Last Order</p>
                      </Link>
                      <b className="my-2 fs-6"> 1 day ago</b>
                      <p>From online store</p>
                    </div>
                    <div className="mt-1 d-flex flex-column text-center">
                      <p className="py-0 my-0">Total spent to date</p>
                      <b className="my-2 fs-6">$ {customer?.total_spent}</b>
                      <p>{customer?.orders_count} Orders</p>
                    </div>
                    <div className="mt-1 d-flex flex-column text-center">
                      <p className="my-0 py-0">Average order value</p>
                      <b className="my-2 fs-6">$ {customer?.orders_count}</b>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="p-4">
                <div className="list-wrapper">
                  <p className="ms-4">YESTERDAY</p>
                  <div className="list-item-wrapper">
                    <div className="red-line"></div>
                    <div className="list-bullet" />
                    <div className="list-item">
                      <p className="list-title">
                        {" "}
                        Order confirmation email for order{" "}
                        <span className=" textBtn">
                          {customer?.last_order_name}
                        </span>{" "}
                        sent to this customer {`(${customer?.email}).`}
                      </p>
                      <div className="list-text">11:00 am</div>
                    </div>
                  </div>

                  <div className="list-item-wrapper">
                    <div className="red-line"></div>

                    <div className="list-bullet" />
                    <div className="list-item">
                      <p className="list-title">
                        The customer placed order{" "}
                        <span className=" textBtn">
                          {customer?.last_order_name}
                        </span>{" "}
                        on Online store{" "}
                        {`(checkout #${customer?.last_order_id} TEMP)`}
                      </p>
                      <div className="list-text">11:00 am</div>
                    </div>
                  </div>
                  <div className="list-item-wrapper">
                    <div className="list-bullet" />
                    <div className="list-item">
                      <p className="list-title">
                        {customer?.first_name} TEMP sent an order invice for
                        order{" "}
                        <span className=" textBtn">
                          {customer?.last_order_name} TEMP
                        </span>{" "}
                        to this customer {`(${customer?.email}).`}
                      </p>
                      <div className="list-text">11:00 am</div>
                    </div>
                    <div className="white-line" />
                  </div>
                </div>
              </div>
            </Cols>
            <Cols md="4">
              <Card className="mb-4 card-bg">
                <CardBody className="px-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="bold">Customer</h6>
                    <Link to="#">Edit</Link>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <Tooltip
                      placement="top"
                      autohide={true}
                      target="showCopyTextTooltip"
                    >
                      copy text!
                    </Tooltip>
                    <p className=" text-info mb-1">{customer?.phone}</p>
                    <CopyToClipboard text={customer?.phone}>
                      <MdContentCopy
                        style={{ cursor: "pointer", fontSize: "15px" }}
                        id="showCopyTextTooltip"
                      />
                    </CopyToClipboard>
                  </div>
                  <p>No account</p>
                  <hr className=" addProductHr" />
                  <div className="d-flex justify-content-between align-items-center">
                    <b className="bold">DEFAULT ADDRESS</b>
                    <Link to="#">Manage</Link>
                  </div>
                  <div>
                    {customer?.addresses.map((obj, i) => (
                      <div key={i} className="mt-3">
                        <p className="my-1 bold">{obj?.first_name}</p>
                        <p className="my-1 bold">{obj?.address1}</p>
                        <p className="my-1 bold">
                          {obj?.city} {obj.zip}
                        </p>
                        <p className="my-1 bold">{obj?.country}</p>
                        <p className="my-1 bold">{obj?.phone}</p>
                      </div>
                    ))}
                    <Link to="#">Add new address</Link>
                  </div>
                  <hr className=" addProductHr" />
                  <div className="d-flex justify-content-between align-items-center">
                    <b className="bold">TAX SETTINGS</b>
                    <Link to="#">Manage</Link>
                  </div>
                  <div className="mt-3">
                    <p className="mt-0">No examptions</p>
                  </div>
                </CardBody>
              </Card>
              <Card className="mb-4">
                <CardBody className="px-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <b className="bold">Marketing status</b>
                    <Link to="#">Edit</Link>
                  </div>
                  <div className="mt-2">
                    <ul className=" list-unstyled">
                      <li className="pt-2">
                        <span className=" custom-bg py-1 px-2 rounded-pill mt-1">
                          Email not subscribed
                        </span>
                      </li>
                      <li className="pt-2">
                        <span className=" custom-bg py-1 px-2 rounded-pill mt-1">
                          SMS not subscribed
                        </span>
                      </li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Cols>
          </Row>
        </div>
      </main>
    </div>
  );
};

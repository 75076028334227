import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  CardBody,
  Button,
  Label,
  Form,
  FormGroup,
  Input,
  Breadcrumb,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import { Offcanvas } from "react-bootstrap";
import { Cols } from "../SeparatorStyle/SeparatorStyle";
import { getAllStoresLoginUser, saveMasterStore } from "../../api/store";
import { getShipperInfo, updateShipperInfo } from "../../api";
import SaveMultipleShippers from "./SaveMultipleShippers";
import { getStoresWhatsappMsgs, updateMessages } from "../../api/whatsappMsg";
import SaveMultipleStores from "./SaveMultipleStores";
import DeleteStore from "./DeleteStore";
import { motion } from "framer-motion";
import { leftAnimate } from "../FramerMotion/FramerMotion";
const StoreSettings = () => {
  const [loading, setLoading] = useState(false);
  const [sidedrawerLoader, setSidedrawerLoader] = useState(false);
  const [storeData, setStoreData] = useState();
  const [currentStoreShipper, setCurrentStoreShipper] = useState([]);
  const [storeId, setStoreId] = useState("");
  const [storeDisplayName, setStoreDisplayName] = useState("");
  const [orderRefNumPattern, setOrderRefNumPattern] = useState("");
  const [supportPhoneNum, setSupportPhoneNum] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [sidedrawerShow, setSidedrawerShow] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [accountWhtsapMsgs, setAccountWhtsapMsgs] = useState([]);
  const [msgValueBool, setMsgValueBool] = useState({
    order: "",
    customer: "",
    wtsapOpen: "",
  });
  const [eidtMsgValue, setEidtMsgValue] = useState({
    orderMsg: "",
    customerMsg: "",
    accountId: "",
    storeId: "",
    openWhatsapp: "",
  });

  const [addShipperModal, setAddShipperModal] = useState(false);
  const toggleAddShipperModal = () => setAddShipperModal(!addShipperModal);
  const [addStoreModal, setAddStoreModal] = useState(false);

  const toggleAddStoreModal = () => setAddStoreModal(!addStoreModal);

  const [deleteStoreModal, setDeleteStoreModal] = useState(false);
  const toggleDeleteStoreModal = (id, shopName) => {};

  const loadWhatsappMessages = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getStoresWhatsappMsgs();
      setLoading(false);
      if (data.length === 0) {
        toast.error("No messages found!");
      }
      setAccountWhtsapMsgs(data);
    } catch (error) {
      console.log("Error found while fetch Whatsapp Messages!", error);
    }
  }, []);

  const loadStores = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getAllStoresLoginUser();
      const { stores } = data[0];
      stores.length === 1 && handleMakeStoreMaster(stores[0]._id, true);
      if (stores.length > 1) {
        const [master] = stores.filter((store) => store.isMasterStore);
        !master && handleMakeStoreMaster(stores[0]._id, true);
      }
      setStoreData(data[0]);
      setLoading(false);
    } catch (error) {
      console.log("Error found when fetch stores data!", error);
    }
  }, []);

  useEffect(() => {
    loadStores();
    loadWhatsappMessages();
  }, [loadStores]);

  const handleMakeStoreMaster = async (id, isMaster) => {
    try {
      await saveMasterStore(id, isMaster);
    } catch (err) {
      toast.error("Error in Setting up Master Store!");
    }
  };

  const getShipperDetails = async (storeId) => {
    setSidedrawerLoader(true);
    try {
      const { data } = await getShipperInfo(storeId);
      setCurrentStoreShipper(data);
      setStoreDisplayName(data.storeDisplayName);
      setOrderRefNumPattern(data.orderRefNumPattern);
      setSupportPhoneNum(data.supportPhoneNum);
      setEmail(data.email);
      setCity(data.city);
      setAddress(data.address);
      setSidedrawerLoader(false);
    } catch (err) {
      setSidedrawerShow(false);
      toast.error(err.response.data.message);
      toggleAddShipperModal();
    }
  };

  const handleShipperUpdate = async (e) => {
    e.preventDefault();
    setButtonSpinner(true);
    try {
      const { data } = await updateShipperInfo(
        storeId,
        storeDisplayName,
        orderRefNumPattern,
        supportPhoneNum,
        email,
        city,
        address
      );
      data.status === 200
        ? toast.success(data.message)
        : toast.error(data.message);
      loadStores();
      setSidedrawerShow(false);
      setButtonSpinner(false);
    } catch (err) {
      toast.error("Error in Data Submission for Label Update!");
    }
  };

  const updateWhatsappMsgs = async () => {
    try {
      let { data } = await updateMessages(eidtMsgValue);

      if (data) {
        await loadWhatsappMessages();
        toast.success(data);
      }
    } catch (error) {
      console.log("Orders Message updated failed!: ", error);
    }
  };

  return (
    <>
      <main>
        <SaveMultipleStores
          toggle={toggleAddStoreModal}
          modal={addStoreModal}
        />
        <DeleteStore
          toggle={toggleDeleteStoreModal}
          modal={deleteStoreModal}
          // storeId={storeId}
          // shopName={storeName}
          setModal={setDeleteStoreModal}
        />
        {addShipperModal && (
          <SaveMultipleShippers
            toggleShipper={toggleAddShipperModal}
            modalShipper={addShipperModal}
            storeId={storeId}
          />
        )}
        <Row className="top-text pt-3 mb-5">
          <Cols xxs="12">
            <div className="d-flex justify-content-between">
              <motion.div
                variants={leftAnimate}
                initial="hidden"
                animate="visible"
              >
                <h1>Store Settings</h1>
                <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
                  <ol className="breadcrumb pt-0">
                    <li className="breadcrumb-item">
                      <Link to="/admin/dashboard" style={{ color: "black" }}>
                        Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Store Settings
                    </li>
                  </ol>
                </Breadcrumb>
              </motion.div>
              <motion.div
              // variants={rightAnimate}
              // initial="hidden"
              // animate="visible"
              >
                {storeData?.stores.length === 0 ? (
                  <Button
                    className="mr-3"
                    onClick={toggleAddStoreModal}
                    style={{ float: "right", display: "inline" }}
                  >
                    Add Your Own Store
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    className="mr-3"
                    onClick={toggleAddStoreModal}
                    style={{ float: "right", display: "inline" }}
                  >
                    Add Another Store
                  </Button>
                )}
              </motion.div>
            </div>
          </Cols>
        </Row>
        <hr />
        <Row>
          <Cols xxs="12" className="row icon-cards-row justify-content-between">
            <Cols xs="12" sm="12" md="4" lg="4" className="mb-4 mt-3 ">
              <br />
              <br />
              <motion.h5
              // variants={leftAnimate}
              // initial="hidden"
              // animate="visible"
              >
                <h5>
                  <b className="mt-5">Label Display Info</b>
                </h5>
                <p className="mr-5">
                  Manage Shipper info that will be print on label.
                </p>
              </motion.h5>
            </Cols>
            {!loading ? (
              storeData && storeData.length === 0 ? (
                <>
                  <Cols
                    className="mb-4 mt-3 ms-auto"
                    xs="12"
                    sm="12"
                    md="8"
                    lg="8"
                  >
                    <motion.div
                      className="card my-3 pb-4"
                      // variants={rightAnimate}
                      // initial="hidden"
                      // animate="visible"
                    >
                      <Row>
                        <Cols xxs="12" sm="12" md="12" lg="12">
                          <div className="noStoreShow d-flex alignItem-center justify-content-center">
                            <h3 style={{ color: "red" }}>No Store to Show</h3>
                          </div>
                        </Cols>
                      </Row>
                    </motion.div>
                  </Cols>
                </>
              ) : (
                storeData &&
                storeData.stores.map((store, i) => (
                  <Cols
                    className="mb-3 mt-4 ms-auto"
                    xs="12"
                    sm="12"
                    md="8"
                    lg={storeData.stores.length === 1 ? "5" : "4"}
                    key={i + 1}
                  >
                    <motion.div
                      className="card"
                      // variants={rightAnimate}
                      // initial="hidden"
                      // animate="visible"
                    >
                      <CardBody>
                        <div className="d-flex align-items-center justify-content-between px-2 flex-nowrap">
                          <div className="d-flex align-items-center flex-wrap justify-content-start">
                            <i className="iconsminds-male-2 fs-2 text-black mr-3"></i>

                            <div>
                              <h5 className="fw-bold">
                                {store.shopDetail.name}
                              </h5>
                              <span>
                                {store.domainName}
                                <br />
                                {store.shopDetail.shop_owner}
                              </span>
                            </div>
                          </div>
                          <div>
                            <i
                              className="simple-icon-pencil text-black fs-5 fw-bold ml-2"
                              onClick={() => {
                                getShipperDetails(store._id);
                                setStoreId(store._id);
                                setSidedrawerShow(true);
                              }}
                            ></i>
                          </div>
                        </div>
                      </CardBody>
                    </motion.div>
                  </Cols>
                ))
              )
            ) : (
              <>
                <Cols xxs="3" sm="4" md="4" lg="4" className="mt-4">
                  <div className="skeleton store-skeleton-setting mb-2"></div>
                </Cols>
              </>
            )}
          </Cols>
        </Row>
        {/* <hr /> */}
        {/* <Row>
          <Cols
            xxs="12"
            className="row icon-cards-row mb-3 justify-content-between"
          >
            <Cols xs="12" sm="12" md="4" lg="4" className="mb-0 mt-0">
              <br />
              <br />
              <motion.div
              // variants={leftAnimate}
              // initial="hidden"
              // animate="visible"
              >
                <h5>
                  <b className="mt-5">Stores, Programs and Resources</b>
                </h5>
                <p className="mr-5">
                  Visit or manage the following stores, programs and resources
                  connected to your Shopilam ID.
                </p>
              </motion.div>
            </Cols>
            {!loading ? (
              storeData &&
              storeData.stores.map((store, i) => (
                <Cols
                  className="mt-4 ms-auto"
                  xxs="12"
                  sm="12"
                  md="8"
                  lg={storeData.stores.length === 1 ? "5" : "4"}
                  key={i + 1}
                >
                  <motion.div
                    className="card"
                    // variants={rightAnimate}
                    // initial="hidden"
                    // animate="visible"
                  >
                    <CardBody>
                      <div className="d-flex align-items-center justify-content-between px-2 flex-nowrap">
                        <div className="d-flex align-items-center flex-wrap justify-content-start">
                          <i className="iconsminds-shop fs-3 text-black mr-3"></i>
                          <div>
                            <h5 className="fw-bold">{store.shopDetail.name}</h5>
                            <span>
                              {store.domainName}
                              <br />
                              {store.shopDetail.shop_owner}
                            </span>
                          </div>
                        </div>
                        <div>
                          <i className="simple-icon-login fw-bold text-black fs-4"></i>
                        </div>
                      </div>
                    </CardBody>
                  </motion.div>
                </Cols>
              ))
            ) : (
              <>
                <Cols xxs="3" sm="4" md="4" lg="4" className="mt-4">
                  <div className="skeleton store-skeleton-setting mb-2"></div>
                </Cols>
              </>
            )}
          </Cols>
        </Row> */}
        <hr />
        <Row>
          <Cols
            xxs="12"
            className="row icon-cards-row mb-2 justify-content-between"
          >
            <Cols xs="12" sm="12" md="4" lg="4" className="mb-4 mt-3 ">
              <br />
              <br />
              <motion.h5
              // variants={leftAnimate}
              // initial="hidden"
              // animate="visible"
              >
                <h5>
                  <b className="mt-5">Edit Stores Whatsapp Message</b>
                </h5>
                <p className="mr-5">
                  Manage instant whatsapp messages for customers to be connectd.
                </p>
              </motion.h5>
            </Cols>
            {!loading ? (
              storeData && storeData.length === 0 ? (
                <h5>
                  <Cols
                    className="mb-4 mt-3 ms-auto"
                    xs="12"
                    sm="12"
                    md="8"
                    lg="4"
                  >
                    <motion.div
                      className="card my-3 pb-4"
                      // variants={rightAnimate}
                      // initial="hidden"
                      // animate="visible"
                    >
                      <Row>
                        <Cols xxs="12" sm="12" md="12" lg="12">
                          <div className="noStoreShow d-flex alignItem-center justify-content-center">
                            <h3 style={{ color: "red" }}>No Store to Show</h3>
                          </div>
                        </Cols>
                      </Row>
                    </motion.div>
                  </Cols>
                </h5>
              ) : storeData && accountWhtsapMsgs.length > 0 ? (
                storeData.stores.map((store, index) => (
                  <Cols
                    key={index + 1}
                    className="mb-4 mt-4 ms-auto"
                    xs="12"
                    sm="12"
                    md="8"
                    lg={storeData.stores.length === 1 ? "5" : "4"}
                  >
                    <motion.div
                      className="card"
                      // variants={rightAnimate}
                      // initial="hidden"
                      // animate="visible"
                    >
                      <CardBody>
                        <div className="d-flex align-items-center justify-content-between px-2 flex-nowrap">
                          <div className="d-flex align-items-center flex-wrap justify-content-start">
                            <i className="iconsminds-email text-black fs-2 mr-3"></i>
                            <div>
                              <h5 className="fw-bold">
                                {store.shopDetail.name}
                              </h5>
                              <span>
                                {store.domainName}
                                <br />
                                {store.shopDetail.shop_owner}
                              </span>
                            </div>
                          </div>
                          <div>
                            <i
                              className="simple-icon-pencil text-black fs-5 fw-bold"
                              onClick={() => {
                                accountWhtsapMsgs?.filter((obj) => {
                                  if (obj.storeId === store._id) {
                                    setMsgValueBool({
                                      ...msgValueBool,
                                      order: obj.ordersMessage,
                                      customer: obj.customerMessage,
                                      wtsapOpen: obj.openWhatsappIn,
                                    });

                                    setEidtMsgValue({
                                      ...eidtMsgValue,
                                      accountId: storeData.accountId,
                                      orderMsg: obj.ordersMessage,
                                      customerMsg: obj.customerMessage,
                                      openWhatsapp: obj.openWhatsappIn,
                                      storeId: store._id,
                                    });
                                  }
                                  return obj;
                                });
                              }}
                              to="#"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasRight2"
                              aria-controls="offcanvasRight"
                            ></i>
                          </div>
                        </div>
                      </CardBody>
                    </motion.div>
                  </Cols>
                ))
              ) : (
                <Cols xs="12" sm="12" md="8" lg="4" className="mt-5 mb-4">
                  <h5>No whatsapp messages were saved.</h5>
                </Cols>
              )
            ) : (
              <>
                <Cols xxs="3" sm="4" md="4" lg="4" className="mt-4">
                  <div className="skeleton store-skeleton-setting mb-2"></div>
                </Cols>
              </>
            )}
          </Cols>
        </Row>
        <hr />
        <Row>
          <Cols
            xxs="12"
            className="row icon-cards-row mb-2 justify-content-between"
          >
            <Cols xs="12" sm="12" md="4" lg="4" className="mb-4 mt-3 ">
              <br />
              <br />
              <motion.h5
              // variants={leftAnimate}
              // initial="hidden"
              // animate="visible"
              >
                <h5>
                  <b className="mt-5">Manage Store Webhooks</b>
                </h5>
                <p className="mr-5">
                  Handle webhooks to be synced with your Store and shopilam
                  data.
                </p>
              </motion.h5>
            </Cols>
            {!loading ? (
              storeData && storeData.length === 0 ? (
                <h5>
                  <Cols
                    className="mb-4 mt-3 ms-auto"
                    xs="12"
                    sm="12"
                    md="8"
                    lg="4"
                  >
                    <motion.div
                      className="card my-3 pb-4"
                      // variants={rightAnimate}
                      // initial="hidden"
                      // animate="visible"
                    >
                      <Row>
                        <Cols xxs="12" sm="12" md="12" lg="12">
                          <div className="noStoreShow d-flex alignItem-center justify-content-center">
                            <h3 style={{ color: "red" }}>No Store to Show</h3>
                          </div>
                        </Cols>
                      </Row>
                    </motion.div>
                  </Cols>
                </h5>
              ) : (
                storeData?.stores.map((store, index) => (
                  <Cols
                    key={index + 1}
                    className="mb-4 mt-4 ms-auto"
                    xs="12"
                    sm="12"
                    md="8"
                    lg={storeData.stores.length === 1 ? "5" : "4"}
                  >
                    <motion.div
                      className="card"
                      // variants={rightAnimate}
                      // initial="hidden"
                      // animate="visible"
                    >
                      <CardBody>
                        <div className="d-flex align-items-center justify-content-between px-2 flex-nowrap">
                          <div className="d-flex align-items-center flex-wrap justify-content-start">
                            <i className="iconsminds-arrow-mix text-black fs-2 mr-3"></i>
                            <div>
                              <h5 className="fw-bold">
                                {store.shopDetail.name}
                              </h5>
                              <span>
                                {store.domainName}
                                <br />
                                {store.shopDetail.shop_owner}
                              </span>
                            </div>
                          </div>
                          <div>
                            {/* <Link to={`/admin/webhooks/${store._id}`}> */}
                            <i className="simple-icon-pencil text-black fs-5 fw-bold"></i>
                            {/* </Link> */}
                          </div>
                        </div>
                      </CardBody>
                    </motion.div>
                  </Cols>
                ))
              )
            ) : (
              <>
                <Cols xxs="3" sm="4" md="4" lg="4" className="mt-4">
                  <div className="skeleton store-skeleton-setting mb-2"></div>
                </Cols>
              </>
            )}
          </Cols>
        </Row>

        {/* Sidebar (OffCanvas) */}
        <div>
          <Offcanvas
            show={sidedrawerShow}
            onHide={() => setSidedrawerShow(false)}
            placement={"end"}
            backdrop={false}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                <b>Label Managment</b>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              {sidedrawerLoader ? (
                <div className="text-center mx-auto mt-5">
                  <Spinner>Loading...</Spinner>
                </div>
              ) : (
                <Form onSubmit={handleShipperUpdate}>
                  <FormGroup className="form-group has-float-label">
                    <Label>Display Store Name </Label>
                    <Input
                      className="form-control"
                      type="text"
                      defaultValue={storeDisplayName}
                      required
                      onChange={(e) => setStoreDisplayName(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="form-group has-float-label">
                    <Label>Order Reference Number Pattern</Label>
                    <Input
                      className="form-control"
                      type="text"
                      defaultValue={orderRefNumPattern}
                      onChange={(e) => setOrderRefNumPattern(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup className="form-group has-float-label">
                    <Label>Support Phone Number</Label>
                    <Input
                      className="form-control"
                      type="text"
                      defaultValue={supportPhoneNum}
                      onChange={(e) => setSupportPhoneNum(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup className="form-group has-float-label">
                    <Label>Shipper Email</Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="form-group has-float-label">
                    <Label>City (Origin)</Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="city"
                      required
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="form-group has-float-label">
                    <Label>Address</Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="address"
                      defaultValue={address}
                      required
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </FormGroup>
                  <footer className="d-flex justify-content-center">
                    <Button
                      type="submit"
                      className={"mt-2"}
                      size={"md"}
                      style={buttonSpinner ? { padding: "8px 33px" } : null}
                      disabled={
                        (buttonSpinner ||
                          storeDisplayName === "" ||
                          storeDisplayName ===
                            currentStoreShipper?.storeDisplayName) &&
                        (address === "" ||
                          address === currentStoreShipper?.address) &&
                        (supportPhoneNum === "" ||
                          supportPhoneNum ===
                            currentStoreShipper?.supportPhoneNum) &&
                        (email === "" ||
                          email === currentStoreShipper?.email) &&
                        (city === "" || city === currentStoreShipper?.city) &&
                        (orderRefNumPattern === "" ||
                          orderRefNumPattern ===
                            currentStoreShipper?.orderRefNumPattern)
                      }
                    >
                      {buttonSpinner ? (
                        <Spinner size={"sm"}>Loading...</Spinner>
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </footer>
                </Form>
              )}
            </Offcanvas.Body>
          </Offcanvas>
        </div>
        <div>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasRight2"
            aria-labelledby="offcanvasRightLabel"
          >
            <div className="offcanvas-header d-flex align-items-center">
              <h5 id="offcanvasRightLabel" className="m-0 fw-bold">
                Whatsapp Messages
              </h5>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>

            <div className="offcanvas-body">
              <FormGroup className="form-group has-float-label">
                <Label>Order message</Label>
                <textarea
                  style={{
                    maxHeight: "120px",
                    minHeight: "120px",
                    overflow: "auto",
                  }}
                  value={eidtMsgValue.orderMsg}
                  className="w-100 form-control pt-2"
                  rows={"5"}
                  placeholder="Text Message"
                  onChange={(evt) =>
                    setEidtMsgValue({
                      ...eidtMsgValue,
                      orderMsg: evt.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup className="form-group has-float-label">
                <Label>Customer message</Label>
                <textarea
                  style={{
                    maxHeight: "120px",
                    minHeight: "120px",
                    overflow: "auto",
                  }}
                  value={eidtMsgValue.customerMsg}
                  className="w-100 form-control pt-2"
                  rows={"5"}
                  placeholder="Text Message"
                  onChange={(evt) =>
                    setEidtMsgValue({
                      ...eidtMsgValue,
                      customerMsg: evt.target.value,
                    })
                  }
                />
              </FormGroup>
              <div className=" ps-2">
                <h6 className="fw-bold"> Open Whatsapp on</h6>
                <FormGroup check>
                  <Input
                    className="pointer fs-6"
                    name="radio2"
                    type="radio"
                    checked={eidtMsgValue.openWhatsapp === "web" ? true : false}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setEidtMsgValue({
                          ...eidtMsgValue,
                          openWhatsapp: "web",
                        });
                      }
                    }}
                  />{" "}
                  <Label check>Whatsapp Web</Label>
                </FormGroup>
                <FormGroup check className="pointer">
                  <Input
                    className="pointer fs-6"
                    checked={
                      eidtMsgValue.openWhatsapp === "desktop" ? true : false
                    }
                    name="radio2"
                    type="radio"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setEidtMsgValue({
                          ...eidtMsgValue,
                          openWhatsapp: "desktop",
                        });
                      }
                    }}
                  />{" "}
                  <Label check>Whatsapp Desktop App</Label>
                </FormGroup>
              </div>
              <div className=" text-center mt-5">
                <Button
                  disabled={
                    (eidtMsgValue.orderMsg === msgValueBool.order ||
                      eidtMsgValue.orderMsg.trim() === "") &&
                    (eidtMsgValue.customerMsg === msgValueBool.customer ||
                      eidtMsgValue.customerMsg.trim() === "") &&
                    eidtMsgValue.openWhatsapp === msgValueBool.wtsapOpen
                  }
                  color="primary"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  onClick={() => {
                    updateWhatsappMsgs();
                  }}
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default StoreSettings;

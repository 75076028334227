import React from "react";
import DetailedUI from "./DetailedUI.jsx";

function AddProduct() {
  return (
    <>
      <DetailedUI showEditProduct={true} />
    </>
  );
}

export default AddProduct;

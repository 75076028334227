import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { motion } from "framer-motion";
// import { leftAnimate } from '../../FramerMotion/FramerMotion';
const ProductTabs = ({ activeTab, setActiveTab }) => {
  return (
    <motion.div
    // variants={leftAnimate} initial="hidden" animate="visible"
    >
      <Nav
        tabs
        className="separator-tabs"
        style={{ cursor: "pointer", alignContent: "center" }}
      >
        <NavItem>
          <NavLink
            className={`${classnames({
              active: activeTab === "allProducts",
              "nav-link": true,
            })} ${activeTab === "allProducts" ? "" : " text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeProductListingTab", "allProducts");
              setActiveTab("allProducts");
            }}
            location={{}}
            to="#"
          >
            All
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${classnames({
              active: activeTab === "activeProducts",
              "nav-link": true,
            })} ${activeTab === "activeProducts" ? "" : " text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeProductListingTab", "activeProducts");
              setActiveTab("activeProducts");
            }}
            location={{}}
            to="#"
          >
            Active
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${classnames({
              active: activeTab === "draftProducts",
              "nav-link": true,
            })} ${activeTab === "draftProducts" ? "" : " text-muted"}`}
            onClick={() => {
              localStorage.setItem("activeProductListingTab", "draftProducts");
              setActiveTab("draftProducts");
            }}
            location={{}}
            to="#"
          >
            Draft
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${classnames({
              active: activeTab === "archivedProducts",
              "nav-link": true,
            })} ${activeTab === "archivedProducts" ? "" : " text-muted"}`}
            onClick={() => {
              localStorage.setItem(
                "activeProductListingTab",
                "archivedProducts"
              );
              setActiveTab("archivedProducts");
            }}
            location={{}}
            to="#"
          >
            Archived
          </NavLink>
        </NavItem>
      </Nav>
    </motion.div>
  );
};

export default ProductTabs;
